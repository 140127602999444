import React, { Fragment } from "react";
import { useEffect, useState } from "react"
import { appState } from "../../AppState";;
import { useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";
import useAsyncEffect from "../../utility/use-async-effect";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody,ModalHeader, ModalFooter} from 'reactstrap';
import Select from "react-select";
import axios from "axios";

import ConnectriaBackupService from "../../services/ConnectriaBackupService";
import {AllJobsReusable} from "./AllJobsReusable";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
// reactstrap components
import  {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";
import { useRef } from "react";
import MfaWizard from "../../utility/mfa/MfaWizard";
import {ClipboardCopyButton} from "../misc";
import {NewTabComponent} from "../../utility/NewTabComponent";
import moment from 'moment';


const BackupJobsDashborard = props => {
  const source = axios.CancelToken.source();
  const [clients,setClients] = useState([])
  const [IsLoadingClients, setIsLoadingClients] = useState(false);
  const [IsLoadingJobs, setIsLoadingJobs] = useState(false);
  const [jobs,setJobs] = useState([]);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const pageSize = 20;
  const [jobsPagesCount, setJobsPagesCount] = useState(0);
  const [stateApp, stateAppActions] = appState();
  const [timeRange, setTimeRange] = useState(stateApp.commserveTimeRange || "Last 7 Days");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  async function getRestOfJobs(totalRecords,totalFetchedJobs, body, initialJobs){
    let offset = totalFetchedJobs;
    while (totalFetchedJobs < totalRecords){
      var copiedBody = JSON.parse(JSON.stringify(body));
      copiedBody['pagingConfig']['offset'] = offset;
      let res = await ConnectriaBackupService.getCommServeJobs(copiedBody, {cancelToken: source.token})
      initialJobs.push(res['data']['jobs'])
      offset = offset + res['data']['jobs'].length
      totalFetchedJobs = res['data']['jobs'].length + totalFetchedJobs
    }
    setJobs(initialJobs.flat())
    setJobsPagesCount(Math.ceil(initialJobs.flat().length / pageSize))
  }

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoadingJobs(false);

        document.title = "Backup Jobs";

        let clientGroupsRes = await ConnectriaBackupService.getCommServeClientGroup({cancelToken: source.token});
        if (!isMounted()) return;

        let clientGroups = clientGroupsRes['data'];
        console.log('client groups: ', clientGroups);
        if (clientGroups.length === 0) {
          setIsError(true);
        } else if (clientGroups.length === 1){
          const copiedBody = ConnectriaBackupService.getJobsApiCallBody();
          copiedBody['jobFilter']['entity']['clientId'] = parseInt(clientGroups[0]['ClientId']);
          let numDays = 1;
          if (timeRange == "Last 7 Days") {
            numDays = 7
          } else if (timeRange == "Last 30 Days") {
            numDays = 30;
          }
          copiedBody["jobFilter"]["endTimeRange"]["fromTime"] = moment().subtract(numDays, "days").unix()
          copiedBody["jobFilter"]["endTimeRange"]["toTime"] = moment().unix()
          const jobsData = await ConnectriaBackupService.getCommServeJobs(copiedBody, {cancelToken: source.token});

          setIsLoadingJobs(true);
          if (jobsData['data'].hasOwnProperty('jobs')){
            const totalRecordsWithoutPaging = jobsData['data']['totalRecordsWithoutPaging'];
            const allJobs = jobsData['data']['jobs'];
            await getRestOfJobs(totalRecordsWithoutPaging, allJobs.length, copiedBody, allJobs)
          }else{
            setJobs([])
            setJobsPagesCount(0)
          }
        } else {
          setIsLoadingClients(true);
          //TODO: make setClients as a SET of unique clientIds ???
          setClients(clientGroups)

        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error)
          // request cancelled
        } else {
          console.log(error)
        }
      }
    },
    () => {
      source.cancel();
    },
    [timeRange]
  );

  const getClientsWithJobsInRange = (clients, timeRange) => {
    let numDays;
    if (timeRange == "Last 24 Hours") {
      numDays = "1";
    } else if (timeRange == "Last 7 Days") {
      numDays = "7";
    } else if (timeRange == "Last 30 Days") {
      numDays = "30";
    }

    let newClients = clients.filter((client, i) => {
      let clientJobStats = JSON.parse(client.StatsJSON);
      if (clientJobStats == null) {
        return false
      }
      return (
        clientJobStats["completed_" + numDays] > 0 ||
        clientJobStats["inProgress_" + numDays] > 0 ||
        clientJobStats["completedWithErrors_" + numDays] > 0 ||
        clientJobStats["noBackups_" + numDays] > 0 ||
        clientJobStats["unsuccessful_" + numDays] > 0
      )
    });

    return newClients;
  }

  const toggleTimeRange = (newRange) => {
    setTimeRange(newRange);
    stateAppActions.setCommserveTimeRange(newRange)
  }

  return (
    <div className="row justify-content-center mt-2">
      <div className="col-lg-12">
        <Card className="card-minimal">
          <CardHeader className="card-header-minimal mb-2">
            <div className="d-flex">
              <CardTitle className="card-title-minimal" tag="h3" style={{fontSize: 2 + "rem"}}>
                {IsLoadingClients ? 'BackUp Clients': 'Backup Jobs'}
              </CardTitle>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                  style={{paddingLeft: "15px", paddingTop: "12px"}}
                  className=""
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                  >
                    {timeRange}
                    <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => { toggleTimeRange("Last 24 Hours") }}>
                      Last 24 Hours
                    </DropdownItem>
                    <DropdownItem onClick={() => { toggleTimeRange("Last 7 Days") }}>
                      Last 7 Days
                    </DropdownItem>
                    <DropdownItem onClick={() => { toggleTimeRange("Last 30 Days") }}>
                      Last 30 Days
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
          </CardHeader>
          {isError ?
            (<CardBody className="text-center">
              <p className="pt-3 pb-3">
                TRiA did not identify Backup services associated with your account. Please contact us if this is an error.
              </p>
              <Button className="btn-report" size="md" onClick={createNewTicket}>Report an Error</Button>
            </CardBody>) :
          IsLoadingClients ?
              (
            <CardBody>
            <div className="table-responsive-sm">
              <table className="table table-hover"  id="csClients">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Operating System</th>
                  <th>Cipher Type</th>
                  <th>Jobs</th>
                </tr>
                </thead>
                <tbody>
                {getClientsWithJobsInRange(clients, timeRange).map((client,index) => {
                  // console.log(client)
                    const pathForClient = `/app/view/backup/jobs/client/${client.ClientId}`;
                    return (
                      <tr key={index} className="cursor" role="button" onClick={() => history.push(pathForClient, {timeRange: timeRange})}>
                        <td>{client.name}</td>
                        <td>{client.OSName}</td>
                        <td>{client.CipherType}</td>
                        <td>
                          <NewTabComponent itemId={client.ClientId} link={pathForClient}
                            tooltipMessage={"Show Jobs In New Tab"}
                            alertMessage={''}/>
                        </td>
                      </tr>
                  )})}
                </tbody>
              </table>
            </div>
          </CardBody> ) : 
          IsLoadingJobs ? (
              <CardBody>
                <AllJobsReusable
                    allJobs={jobs} pageSize={pageSize} jobsPagesCount = {jobsPagesCount}
                />
              </CardBody> ) : <div className="text-center">
          <i
            className="fas fa-spinner m-3 mt-4 fa-spin"
            style={{ fontSize: "30px" }}
          ></i>
        </div>
          }
        </Card>
      </div>
    </div>
  )
}

const createNewTicket = (e) => {
  if (e != null) {
      e.preventDefault();
  }
  history.push({
    pathname: "/app/create-ticket",
    state: {
      closeOnSuccess: true,
      subject: 'TRiA did not identify Backup services associated with my account and this is an error.',
      description: 'TRiA did not identify Backup services associated with my account and this is an error.',
      attachmentElementId: ''
    }
  });
}

export {
  BackupJobsDashborard,
  createNewTicket
};
