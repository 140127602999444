import React, { useState, Suspense, lazy } from "react";
import moment from 'moment';
import CostService from "../services/CostService";

import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import { Bar, Doughnut } from "react-chartjs-2";
import StackedBarChart from "../components/StackedBarChart"
// core components
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { TriaSavingsRecommendor } from "../components/TriaSavingsRecommendor"
import { TrustedAdvisorRecs } from "../components/TrustedAdvisorRecs"
import { appState } from "../../AppState";
import CurrentSpendWidget from "../components/CurrentSpendWidget";

import { CloudAccountSpendList } from "../components/CloudAccountSpendList"
import CostUtility from "../utilities/cost-utility";

const CostDashboard = props => {
  const [state, setState] = useState({ host: {}, services: [] });
  const [awsMonthlySpend, setAwsMonthlySpend] = useState({});
  const [barChart, setBarChart] = useState({
    datasets: [],
    labels: [],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true
          }
        ]
      }
    }
  });
  //global state
  const [stateApp, stateAppActions] = appState();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [clouds, setClouds] = useState([]);
  const [selectedCloud, setSelectedCloud] = useState({cloud_name: "All Accounts", type: null, account: null});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      try {
        var d = selectedMonth == null ? new Date() : new Date(selectedMonth);
        var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12

        let cloudAccounts = await CostService.getCloudAccountsWithCosts(
          moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        );

        if (!isMounted()) return;

        cloudAccounts.unshift({cloud_name: "All Accounts", type: null, account: null})
        setClouds(cloudAccounts);

        // setSpendSavings({cost: cost, savings: savings})
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  const toggle = item => {
    switch (item) {
      case "account":
        setDropdownOpen(!dropdownOpen);
        break;
      default:
        break;
    }
  };

  const toggleCloud = item => {
    setSelectedCloud(item);

    stateAppActions.setCloudAccount(item.type, item.account);
  }

  return (
    <>
      <div className="mt-4 d-flex flex-wrap">
      <h3 className= "mb-0 tria-header-class" style={{paddingRight: 30+"px"}}>Spend Dashboard</h3>
      <Dropdown className="cursor" isOpen={dropdownOpen} toggle={() => toggle("account")} style={{paddingTop: 7+"px"}}>
        <DropdownToggle
          tag="div"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <React.Fragment>
            <p style={{fontSize: 1.5+"rem"}}>
              {selectedCloud.cloud_name}{" "}
              <i className="fas fa-caret-down" />
            </p>
          </React.Fragment>
        </DropdownToggle>
        <DropdownMenu style={{maxHeight: 60+"vh", overflow: "auto"}}>
          {clouds.map(function(item, idx) {
            return (
              <DropdownItem onClick={e => toggleCloud(item)} key={idx}>
                {item.cloud_name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
      </div>
      <hr className="mt-2" />
      {selectedCloud.cloud_name === "All Accounts" ?
        <>
        <div className="row">
          <div className="col-xl-8 col-sm-12 mb-2">
            <StackedBarChart />
          </div>
          <div className="col-xl-4 col-sm-12 mb-2">
            <CurrentSpendWidget type="current" />
          </div>
        </div>
        <hr className="mb-1" />
        <h3 className="mb-0 mt-0 tria-sub-header">Monthly Spend Breakdown</h3>
          <CloudAccountSpendList />
        </>
      :
      <>
        <div className="row">
          <div className="col-lg-8 col-sm-12 mb-2">
            <StackedBarChart cloud_id={selectedCloud.type} account_id={selectedCloud.account} cloud_name={selectedCloud.cloud_name} />
          </div>
          <div className="col-lg-4 col-sm-12 mb-2">
            <CurrentSpendWidget type="current" account_id={selectedCloud.account} />
          </div>
        </div>
        <hr />
        <h3 className="mb-0 mt-0 tria-sub-header">Monthly Spend Breakdown</h3>
          <CloudAccountSpendList cloud_id={selectedCloud.type} account_id={selectedCloud.account} />
        </>
      }
    </>
  );
};

export default CostDashboard;
