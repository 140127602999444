import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css"
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import hippaPNG from "../../assets/img/hippa.png";
import pciPNG from "../../assets/img/pci.png";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";


// reactstrap components
import {CardBody, Table} from "reactstrap";
import useAsyncEffect from "../../utility/use-async-effect";
import ComplianceService from "../../services/ComplianceService";

function setPackComplianceByCloud(pack, packCompliance,clouds,_cloudGroups){
    let _cloudCompliance = 0;
    let _totalCompliance = 0;
    let _cloudNonCompliance = 0;
    clouds.map(function(cloud) {
        _cloudNonCompliance = _cloudGroups[cloud.cloud_type_id].cloud_ids.reduce(function (total, item) {
            total += packCompliance.failed_cloud_mapping[item];
            return total;
        }, 0);
        _totalCompliance = packCompliance.total_insights * _cloudGroups[cloud.cloud_type_id].cloud_ids.length;
        _cloudCompliance = isNaN(_cloudNonCompliance) ? 0 : (_totalCompliance - _cloudNonCompliance);
        pack[cloud.cloud_type_id] = { 'total_compliance' : _totalCompliance, 'cloud_compliance' : _cloudCompliance};
    });
    return pack
}

function sortClouds(baseClouds, _cloudGroups){
    let clouds = baseClouds.filter(function (el) {
                return Object.keys(_cloudGroups).includes(el.cloud_type_id)
    });
    clouds.sort(function(a, b){
        return a.name.localeCompare(b.name);
    });
    return clouds
}

function groupCloudsByType(_cloudsList) {
    return _cloudsList.reduce(function(grouped_clouds, item) {
        grouped_clouds[item['cloud_type_id']] = grouped_clouds[item['cloud_type_id']] || {
            name: item['cloud_type_id'],
            cloud_ids: []
        };
        let cloudId = item['resource_id'].split(":")[1];
        grouped_clouds[item['cloud_type_id']].cloud_ids.push(cloudId);//grouped_clouds.indexOf(cloudGroup.cloud_type_id) > -1
        return grouped_clouds;
    }, {});
}

const basePacks = [
    {
        'id': 1,
        'name': 'HIPAA',
        'icon': hippaPNG,
    },
    {
        'id': 2,
        'name': 'PCI',
        'icon': pciPNG
    }
];

const getPackAcronym = (packName) => {
    if (packName.includes('(CIS)')) {
        const vendorCheck = /\(CIS\)(.*-.*)$/.exec(packName);
        if (vendorCheck) {
            return `CIS${vendorCheck[1]}`.replace('Microsoft Azure', 'Azure');
        }
        return 'CIS';
    }
    if (packName.includes('CSF')) {
        return 'CSF';
    }
    if (packName.includes('CCM')) {
        return `${packName.split(' ')[0]} CCM`;
    }
    return packName.replace(/\s\([^)]+\)/, '');
}

const ComplianceWidget = props => {
    const [packs, setPacks] = useState([]);
    const [packCompliance, setPackCompliance] = useState([]);
    const [clouds, setClouds] = useState([])
    const source = axios.CancelToken.source();
    const history = useHistory();
    let _cloudsList, _cloudGroups, _insightsPacks = [];
    let complianceByCloud = [];
    let _raxCloudId = 'RAX';
    let _vmwareCloudId = 'VMWARE_VSPHERE';
    let _listCloudsQuery = {
        "filters": [],
        "limit": 25,
        "offset": 0,
        "order_by": "cloud_type_id"
    }
    let baseClouds = [
      {'name':'AZURE', 'cloud_type_id':'AZURE_ARM','logo':'divvy/img/azure_arm-logo.png'},
      {'name':'AWS', 'cloud_type_id':'AWS', 'logo':'divvy/img/aws-logo.png'},
      {'name':'AWS GOV', 'cloud_type_id':'AWS_GOV', 'logo':'divvy/img/aws_gov-logo.png'},
      {'name':'GCE', 'cloud_type_id':'GCE', 'logo':'divvy/img/gce-logo.png'},
      {'name':'RAX', 'cloud_type_id':'RAX', 'logo':'divvy/img/rax-logo.png'},
      {'name':'VMWARE', 'cloud_type_id':'VMWARE_VSPHERE', 'logo':'divvy/img/vmware_vsphere-logo.png'}];

  let chartData = {
    columns:[[]],
    type: 'gauge',
    gauge: {
        min: 0,
        max: 100,
        width: 8,
        label: {show: false},
        expand: true,
        units: '%'
    },
      legend: {
        show: false
    },
    size: {
        height: 40
    },
    color: {
        pattern: ["#C40807","#FFE325","#5FC417"],
        threshold: {values: [75,90,100]}
    },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    }
  }

  useAsyncEffect(
      async isMounted => {
        try {
            let res = await ComplianceService.getCloudsList(_listCloudsQuery,{cancelToken: source.token})
            _cloudsList = res.data.clouds;
            _cloudsList = _cloudsList.filter(function( obj ) {
                return obj.cloud_type_id !== _raxCloudId && obj.cloud_type_id !==_vmwareCloudId;
            });
            _cloudGroups = groupCloudsByType(_cloudsList);
            let sortedClouds = sortClouds(baseClouds, _cloudGroups)

            const _packRes = await ComplianceService.getCompliancePacks();
            const _packs = _packRes && _packRes.data && _packRes.data.length > 0 ? _packRes.data : basePacks;

            for(const pack of _packs){
                let packDetails = await ComplianceService.getComplianceData(pack.id,'backoffice');
                let compliance = setPackComplianceByCloud(pack,packDetails.data,sortedClouds,_cloudGroups)
                complianceByCloud.push(compliance)
            }
            setPacks(_packs);
            setPackCompliance(complianceByCloud);
            setClouds(sortedClouds);
            if (!isMounted()) return;
        } catch (error) {
            if (axios.isCancel(error)) {
                // request cancelled
            } else {
                throw error;
            }
        }
    },
    () => {
        source.cancel();
    },
    []
);

  return (
    <>
        <div className="d-flex flex-column h-100">
        <div className="w-100 mb-2">
          <h3 className="text-left">Compliance Insights</h3>
        </div>
        <div className="flex-fill">
        <CardBody className="pt-0 px-0">
            <div style={{ overflow: 'auto' }}>
                <Table>
                    <thead>
                        <tr className="mt-2">
                            <th></th>
                            {(isMobile ? packCompliance.slice(0, 3) : packCompliance).map(p => (
                                <th key={p.name} className="text-center">
                                    <p className="my-auto">{getPackAcronym(p.name)}</p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {clouds.map((cloud) => {
                            return (
                                <tr key={cloud.name}>
                                    <td className="pr-2">
                                        <div className="text-center">
                                            <img src={`https://tria.connectria.com/${cloud.logo}`} style={{ width: 25 }} />
                                            <p className="pt-1 text-center" style={{ fontSize: '0.75rem', whiteSpace: 'normal' }}>
                                                {cloud.name}
                                            </p>
                                        </div>
                                    </td>
                                    {(isMobile ? packCompliance.slice(0, 3) : packCompliance).map((pack) => {
                                        const hasChartData = pack.hasOwnProperty(cloud.cloud_type_id) && (!pack.supported_clouds || pack.supported_clouds.includes(cloud.cloud_type_id));
                                        //deep copy chartdata
                                        let packChart = JSON.parse(JSON.stringify(chartData));
                                        if (hasChartData) {
                                            packChart.columns = [[pack.name, (pack[cloud.cloud_type_id].cloud_compliance / pack[cloud.cloud_type_id].total_compliance) * 100]];
                                        }
                                        return (
                                            <td key={`${cloud.name} - ${pack.name}`} className="px-0 pt-0 text-center" style={{ overflow: "visible" }}>
                                                {hasChartData ?
                                                    <>
                                                        <C3Chart 
                                                            className="compliance-gauge-chart m-auto" 
                                                            style={{fontSize: "25px"}} 
                                                            data={packChart} 
                                                            gauge={chartData.gauge} 
                                                            legend={chartData.legend} 
                                                            color={chartData.color} 
                                                            padding={chartData.padding} 
                                                            size={{width: 80, height: 50,}} />
                                                        {!isMobile &&
                                                            <p className="small my-0 text-center" style={{ fontSize: 10 }}>
                                                                {pack[cloud.cloud_type_id].cloud_compliance} / {pack[cloud.cloud_type_id].total_compliance} Passed
                                                            </p>
                                                        }
                                                    </>
                                                : <span style={{ fontSize: '1rem' }}>N/A</span>}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            
        </CardBody>
        </div>
        <button
          type="button"
          className="btn btn-light align-self-end btn-sm mt-3"
          onClick={() => {
            history.push("/app/cloud/compliance");
          }}
        >
          {" "}
          <span
            style={{ display: "inline", verticalAlign: "middle" }}
            className="fas fa-chart-line"
          ></span>{" "}
          View Detail
        </button>
        </div>

    </>
  );
}
export {ComplianceWidget};
