import React, { useState } from "react";
import { Button } from "./Button";
import {
  WizardContainer,
  WizardHeader,
  WizardTopContainer,
  WizardFooter,
  ButtonGroup
} from "./wizard-styles";

export function WizardStep({ children }) {
  return <WizardContainer>{children}</WizardContainer>;
}

const CancelButton = props => (
  <Button className="btn" {...props} link>
    Cancel
  </Button>
);
const PreviousButton = props => (
  <Button className="btn" {...props} secondary>
    Previous
  </Button>
);
const NextButton = props => (
  <Button  className="btn"{...props} primary>
    Continue <i className="fas fa-arrow-right"></i>
  </Button>
);
const SubmitButton = props => (
  <Button className="btn" {...props} primary>
    Submit
  </Button>
);

function getComputedState({ currentStep, numberOfSteps }) {
  return {
    isFirstStep: currentStep === 0,
    isLastStep: currentStep === numberOfSteps
  };
}

const WizardNavigation = ({
  isFirstStep,
  isLastStep,
  onClickNext,
  onClickPrevious,
  onSubmit,
  onCancel
}) => (
  <WizardFooter>
    <ButtonGroup>
      {!isFirstStep && <PreviousButton onClick={onClickPrevious} />}
      <CancelButton onClick={onCancel} />
    </ButtonGroup>
    <div>
      {!isLastStep && <NextButton onClick={onClickNext} />}
      {isLastStep && <SubmitButton onClick={onSubmit} />}
    </div>
  </WizardFooter>
);

export function Wizard({
  header,
  onSubmit,
  onCancel,
  stepIndex = 0,
  children,
  parentCallback
}) {
  const [currentStep, setStepIndex] = useState(0);
  const incrementStep = () => {setStepIndex(currentStep + 1);parentCallback(currentStep + 1)};
  const decrementStep = () => {setStepIndex(currentStep - 1);parentCallback(currentStep - 1)};
  const computedState = getComputedState({
    currentStep,
    numberOfSteps: children.length - 1
  });

  return (
    <WizardTopContainer>
      <WizardHeader>{header}</WizardHeader>
      {children[currentStep]}
      <WizardNavigation
        {...computedState}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onClickNext={incrementStep}
        onClickPrevious={decrementStep}
      />
    </WizardTopContainer>
  );
}