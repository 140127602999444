import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import FadeIn from "react-fade-in";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { appState } from '../../AppState';

import TrendMicroService from "../../services/TrendMicroService.js";
import {timezone} from "../../utility/DateUtil";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

const TrendMicroEvents = (props) => {

  const [date, setDate] = useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [operator, setOperator] = useState("ge"); 
  const [eventsType, setEventsType] = useState("malware");
  const [searchedEventsType, setSearchedEventsType] = useState("malware");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [computersTabLoaded, setComputersTabLoaded] = useState(false);
  const [opLabel, setOpLabel] = useState("Greater than or equal to")
  const [eventsTypeLabel, setEventsTypeLabel] = useState("Anti-Malware");
  const [isError, setIsError] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [searchSwitch, setSearchSwitch] = useState(false);
  const [stateApp, stateAppActions] = appState();
  const source = axios.CancelToken.source();

  const trendEnabled = stateApp.integrations.includes("trendmicro");

  // Number of rows per page
  const offset = 25
  // 0 means put no limit on the number of items
  const maxItems = 0;

  const toggleOperator = (operator, label) => {
    setDropdownOpen(!dropdownOpen);
    if(typeof operator === "object") return;
    setOperator(operator);
    setOpLabel(label);
  }

  const toggleEventsType = (type, label) => {
    setTypeDropdownOpen(!typeDropdownOpen);
    if(typeof type === "object") return;
    setEventsType(type);
    setEventsTypeLabel(label);
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <div className="row" style={{width: 125+"px", marginTop: -1+"rem"}}>
      <Input
        onChange={(e) => setQuery(e.target.value)}
        type="text"
        value={value}
        placeholder="Enter Date"
        style={{borderTop: 0 + 'px', borderLeft: 0 + 'px', borderRight: 0 + 'px', WebkitBorderRadius: 0,
                fontSize: 0.875+"rem", color: "#525f7f"}}
        onClick={onClick}
      />
      <i className="fas fa-calendar-alt"
        style={{marginLeft: -1.25 + "rem", marginTop: .75 + 'rem', cursor: "pointer"}}
        onClick={onClick}
      />
    </div>
  );

  return (
    <>
      {trendEnabled ? 
      <>
      <h3 className="mb-0 mt-4 tria-header-class">
        Anti-Malware
      </h3>
      <hr></hr>
      <ButtonGroup className="d-flex mb-3 justify-content-center" role="group">
        <Button onClick={() => setActiveTab('1')} active={activeTab === '1'} className={`btn-sm w-100 ${(activeTab !== '1') ? "btn-simple" : ""}`} style={{maxWidth:"250px", border:"unset"}}>Trend Micro: Anti-Malware</Button>
        {/*<Button onClick={() => {setActiveTab('2'); setComputersTabLoaded(true);}} active={activeTab === '2'} className={`btn-sm w-100 ${(activeTab !== '2') ? "btn-simple" : ""}`} style={{maxWidth:"250px", border:"unset"}}>Computers</Button>*/}
      </ButtonGroup>
      <TabContent activeTab={activeTab} id="tab-content">
        <TabPane tabId="1">
          <FadeIn delay={500}>
            <div className="d-flex justify-content-center">
              <Card className="card-minimal">
                <div className="d-flex w-100 mt-3">
                  {/* <Dropdown className="ml-3" isOpen={typeDropdownOpen} toggle={toggleEventsType}>
                  <DropdownToggle 
                      tag="span"
                      data-toggle="dropdown"
                      aria-expanded={typeDropdownOpen}
                    >
                      {eventsTypeLabel}
                      <i className={"fas fa-caret-" + (typeDropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => {toggleEventsType("malware", "Anti-Malware")}}>Anti-Malware</DropdownItem>
                      <DropdownItem onClick={() => {toggleEventsType("integrity", "Integrity Monitoring")}}>Integrity Monitoring</DropdownItem>
                    </DropdownMenu>
                  </Dropdown> */}
                  <span className="ml-3"><b>Filter:</b></span>
                  <Dropdown className="ml-2 mr-2" isOpen={dropdownOpen} toggle={toggleOperator}>
                    <DropdownToggle 
                      tag="span"
                      data-toggle="dropdown"
                      aria-expanded={dropdownOpen}
                    >
                      {opLabel}
                      <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => {toggleOperator("ge", "Greater than or equal to")}}>Greater than or equal to</DropdownItem>
                      <DropdownItem onClick={() => {toggleOperator("gt", "Greater than")}}>Greater than</DropdownItem>
                      <DropdownItem onClick={() => {toggleOperator("le", "Less than or equal to")}}>Less than or equal to</DropdownItem>
                      <DropdownItem onClick={() => {toggleOperator("lt", "Less than")}}>Less than</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <DatePicker
                    selected={moment(date, "YYYY-MM-DD").toDate()}
                    onChange={d => { setDate(moment(d).format("YYYY-MM-DD")) }}
                    customInput={<ExampleCustomInput/>}
                  />
                  <Button className="ml-3 m-0 p-1" onClick={() => {setSearchSwitch(!searchSwitch); setSearchedEventsType(eventsType); setIsError(false);}} style={{width: 100+"px"}}>Submit</Button>
                </div>
                
                <CardBody>
                  {searchedEventsType === 'malware' && <TrendMicroAntiMalwareEvents date={date} operator={operator} maxItems={maxItems} offset={offset} search={searchSwitch}/>}
                  {searchedEventsType === 'integrity' && <TrendMicroIntegrityEvents date={date} operator={operator} maxItems={maxItems} offset={offset} search={searchSwitch}/>}
                </CardBody>
              </Card>
            </div>
          </FadeIn>
        </TabPane>
        <TabPane tabId="2">
          <FadeIn delay={500}>
            {(activeTab === '2' || computersTabLoaded) && <TrendMicroComputers offset={offset}></TrendMicroComputers>}
          </FadeIn>
        </TabPane>
      </TabContent>
      </>
      :
      <>
      <div className="row">
      <div className="col-md-12 text-center">
        <div className="error-template">
            <h2>404 Page Not Found</h2>
        </div>
        <Link to="/">Go Back Home</Link>
      </div>
      </div>
      </>
      }
    </>
  );
}

const TrendMicroAntiMalwareEvents = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [events, setEvents] = useState([]);
  const source = axios.CancelToken.source();

  const [sortTimeAsc, setSortTimeAsc] = useState(false);
  const [sortFilesAsc, setSortFilesAsc] = useState(true);
  const [sortMalwareAsc, setSortMalwareAsc] = useState(true);
  const [sortComputerAsc, setSortComputerAsc] = useState(true);

  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationRange, setPaginationRange] = useState([]);

  const offset = props.offset;

  useAsyncEffect(
    async isMounted => {
      try {
      setIsLoading(true);
      
      let resp = await TrendMicroService.antimalware(props.date, props.operator, props.maxItems, {
        cancelToken: source.token
      });
    
      if (!isMounted()) return;

      if (resp.data.antiMalwareEventListing && resp.data.antiMalwareEventListing.events) {
        resp.data.antiMalwareEventListing.events.sort(function (a, b) {
          return moment(b.logTime) - moment(a.logTime);
        });
        setEvents(resp.data.antiMalwareEventListing.events);
        let numPages = Math.ceil(resp.data.antiMalwareEventListing.events.length / offset);
        setPagesCount(numPages);
        if (numPages >= 5) {
          setPaginationRange(range(0,5));
        } else {
          setPaginationRange(range(0,numPages));
        }
      }
  
      setIsLoading(false);
      //setIsError(false);
       
      } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error);
        
        // request cancelled
      } else {
        console.log(error);
        setIsError(true);
      }
      }
    },
    () => {
      source.cancel();
    }, [props.search]
  );

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    if (index > 2 && index < pagesCount-2) {
      setPaginationRange(range(index-2, index+3));
    } else if (index <= 2) {
      if (pagesCount <= 5) {
        setPaginationRange(range(0, pagesCount));
      } else {
        setPaginationRange(range(0, 5));
      }
    } else {
      setPaginationRange(range(pagesCount-5, pagesCount));
    }
  }

  const sortColumnByTime = () => {
    let data = events;
    if (!sortTimeAsc) {
      data.sort(function (a, b) {
        return moment(a.logTime) - moment(b.logTime);
      });
    } else {
      data.sort(function (a, b) {
        return moment(b.logTime) - moment(a.logTime);
      });
    }
    setEvents(data);
  }

  const sortColumnByString = (propertyName, ascFlag) => {
    let data = events;
    sortByStringField(data, propertyName, ascFlag);
    setEvents(data);
  }

  return (
    <>
      {isError ? ( 
        <div className="text-center text-bold">
          <i className="fas fa-exclamation-triangle"></i> 
            Error Loading Events.
        </div>
      ) :
      isLoading ? (
        <div className="text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
      ) : (
        <>
          <div className="table-responsive-sm">
            <table className="table">
              <thead>
                <tr>
                  <th onClick={() => {sortColumnByTime(); setSortTimeAsc(!sortTimeAsc);}} style={{cursor: "pointer"}}>Time 
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortTimeAsc ? "up" : "down")}></i> 
                  </th>
                  <th>Status</th>
                  <th onClick={() => {sortColumnByString("infectedFilePath", sortFilesAsc); setSortFilesAsc(!sortFilesAsc);}} style={{cursor: "pointer"}}>Infected File(s) 
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortFilesAsc ? "up" : "down")}></i> 
                  </th>
                  <th onClick={() => {sortColumnByString("malwareName", sortMalwareAsc); setSortMalwareAsc(!sortMalwareAsc);}} style={{cursor: "pointer"}}>Malware 
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortMalwareAsc ? "up" : "down")}></i> 
                  </th>
                  <th onClick={() => {sortColumnByString("hostName", sortComputerAsc); setSortComputerAsc(!sortComputerAsc);}} style={{cursor: "pointer"}}>Agent
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortComputerAsc ? "up" : "down")}> </i> 
                  </th>
                </tr>
              </thead>
              <tbody>
                {events.length > 0 ? events.slice(currentPage * offset, (currentPage+1) * offset).map((event) => {
                  return (
                    <tr key={event.antiMalwareEventID}>
                      <td>{moment(event.logTime).format('MM/DD/YY h:mm:ss A')} {timezone(event.logTime, "YYYY-MM-DDThh:mm:ss.SSSZ")}</td>
                      <td>{event.summaryScanResult}</td>
                      <td>{event.infectedFilePath}</td>
                      <td>{event.malwareName}</td>
                      <td>{event.hostName}</td>
                    </tr>
                  );
                }) : (
                  <tr>
                    <td colSpan={5}>No recent threats</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex w-100 justify-content-center">
            <Pagination>
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink first href="#" onClick={(e) => handlePageClick(e, 0)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink previous href="#" onClick={(e) => handlePageClick(e, currentPage-1)}/>
              </PaginationItem>
              {paginationRange.map((page, i) => (
              <PaginationItem active={page === currentPage} key={page}>
                <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
                  {page + 1}
                </PaginationLink>
              </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage >= pagesCount-1}>
                <PaginationLink next href="#" onClick={(e) => handlePageClick(e, currentPage+1)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage >= pagesCount-1}>
                <PaginationLink last href="#" onClick={(e) => handlePageClick(e, pagesCount-1)}/>
              </PaginationItem>
            </Pagination>
          </div>
        </>
      )}
    </>
  );
}

const TrendMicroIntegrityEvents = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [isError, setIsError] = useState(false);
  const source = axios.CancelToken.source();

  const [sortTimeAsc, setSortTimeAsc] = useState(false);
  const [sortSeverityAsc, setSortSeverityAsc] = useState(true);
  const [sortKeyAsc, setSortKeyAsc] = useState(true);
  const [sortComputerAsc, setSortComputerAsc] = useState(true);
  
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationRange, setPaginationRange] = useState([]);

  const offset = props.offset;
  
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
      
        let resp = await TrendMicroService.integrity(props.date, props.operator, props.maxItems, {
          cancelToken: source.token
        });
    
        if (!isMounted()) return;

        if (resp.data.ListEventsResponse && resp.data.ListEventsResponse.events) {
          resp.data.ListEventsResponse.events.sort(function (a, b) {
            return moment(b.logTime) - moment(a.logTime);
          });
          setEvents(resp.data.ListEventsResponse.events);
          let numPages = Math.ceil(resp.data.ListEventsResponse.events.length / offset);
          setPagesCount(numPages);
          if (numPages >= 5) {
            setPaginationRange(range(0,5));
          } else {
            setPaginationRange(range(0,numPages));
          }
        }

        setIsLoading(false);
        //setIsError(false);

      } catch (error) {
        if (axios.isCancel(error)) {
        console.log(error);
        
        // request cancelled
        } else {
          console.log(error);
          setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    }, [props.search]
  );

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    if (index > 2 && index < pagesCount-2) {
      setPaginationRange(range(index-2, index+3));
    } else if (index <= 2) {
      if (pagesCount <= 5) {
        setPaginationRange(range(0, pagesCount));
      } else {
        setPaginationRange(range(0, 5));
      }
    } else {
      setPaginationRange(range(pagesCount-5, pagesCount));
    }
  }

  const sortColumnByTime = () => {
    let data = events;
    if (!sortTimeAsc) {
      data.sort(function (a, b) {
        return moment(a.logTime) - moment(b.logTime);
      });
    } else {
      data.sort(function (a, b) {
        return moment(b.logTime) - moment(a.logTime);
      });
    }
    setEvents(data);
  }

  const sortColumnByString = (propertyName, ascFlag) => {
    let data = events;
    sortByStringField(data, propertyName, ascFlag);
    setEvents(data);
  }
  
  return (
    <>
      {isError ? ( 
        <div className="text-center text-bold">
          <i className="fas fa-exclamation-triangle"></i> 
          Error Loading Events.
        </div>
      ) :
      isLoading ? (
        <div className="text-center">
            <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
      ) : (
        <>
          <div className="table-responsive-sm">
            <table className="table">
              <thead>
                <tr>
                  <th onClick={() => {sortColumnByTime(); setSortTimeAsc(!sortTimeAsc);}} style={{cursor: "pointer"}}>Time 
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortTimeAsc ? "up" : "down")}></i> 
                  </th>
                  <th onClick={() => {sortColumnByString("hostName", sortComputerAsc); setSortComputerAsc(!sortComputerAsc);}} style={{cursor: "pointer"}}>Computer 
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortComputerAsc ? "up" : "down")}></i> 
                  </th>
                  <th onClick={() => {sortColumnByString("severity", sortSeverityAsc); setSortSeverityAsc(!sortSeverityAsc);}} style={{cursor: "pointer"}}>Severity 
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortSeverityAsc ? "up" : "down")}></i> 
                  </th>
                  <th onClick={() => {sortColumnByString("key", sortKeyAsc); setSortKeyAsc(!sortKeyAsc);}} style={{cursor: "pointer"}}>Key 
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortKeyAsc ? "up" : "down")}> </i> 
                  </th>
                </tr>
              </thead>
              <tbody>
                {events.length > 0 ? events.slice(currentPage * offset, (currentPage+1) * offset).map((event) => {
                    return (
                      <tr key={event.eventID}>
                        <td>{moment(event.logTime).format('MM/DD/YY hh:mm:ssZ')}</td>
                        <td>{event.hostName}</td>
                        <td>{event.severity}</td>
                        <td>{event.key}</td>
                      </tr>
                    );
                  }
                ) : (
                  <tr>
                    <td colSpan={4}>No recent events</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex w-100 justify-content-center">
            <Pagination>
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink first href="#" onClick={(e) => handlePageClick(e, 0)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink previous href="#" onClick={(e) => handlePageClick(e, currentPage-1)}/>
              </PaginationItem>
              {paginationRange.map((page, i) => (
                <PaginationItem active={page === currentPage} key={page}>
                  <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
                    {page + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage >= pagesCount-1}>
                <PaginationLink next href="#" onClick={(e) => handlePageClick(e, currentPage+1)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage >= pagesCount-1}>
                <PaginationLink last href="#" onClick={(e) => handlePageClick(e, pagesCount-1)}/>
              </PaginationItem>
            </Pagination>
          </div>
        </>
      )}
    </>
  );
}

const TrendMicroComputers = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [computers, setComputers] = useState([]);
  const [isError, setIsError] = useState(false);
  const source = axios.CancelToken.source();

  const [sortNameAsc, setSortNameAsc] = useState(true);
  const [sortDescriptionAsc, setSortDescriptionAsc] = useState(true);
  const [sortPlatformAsc, setSortPlatformAsc] = useState(true);
  const [sortPolicyAsc, setSortPolicyAsc] = useState(true);
  const [sortStatusAsc, setSortStatusAsc] = useState(true);
  const [sortMaintenanceAsc, setSortMaintenanceAsc] = useState(true);
  const [sortSendPolicyAsc, setSortSendPolicyAsc] = useState(true);

  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationRange, setPaginationRange] = useState(range(0, 5));

  const offset = props.offset

  useAsyncEffect(
    async isMounted => {
      try {
      setIsLoading(true);
      
      let resp = await TrendMicroService.computers({
      cancelToken: source.token
      });
  
      if (!isMounted()) return;

      if (resp.data.computers && resp.data.computers.length > 0) {
        sortByStringField(resp.data.computers, "name", false);
        setComputers(resp.data.computers);
        let numPages = Math.ceil(resp.data.computers.length / offset);
        setPagesCount(numPages);
        if (numPages >= 5) {
          setPaginationRange(range(0,5));
        } else {
          setPaginationRange(range(0,numPages));
        }
      }
  
      setIsLoading(false);
      //setIsError(false);
       
      } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error);
        
        // request cancelled
      } else {
        console.log(error);
        setIsError(true);
      }
      }
    },
    () => {
      source.cancel();
    }, []
  );

  const sortColumn = (propertyName, ascFlag) => {
    let data = computers;
    sortByStringField(data, propertyName, ascFlag);
    setComputers(data);
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    if (index > 2 && index < pagesCount-2) {
      setPaginationRange(range(index-2, index+3));
    } else if (index <= 2) {
      if (pagesCount <= 5) {
        setPaginationRange(range(0, pagesCount));
      } else {
        setPaginationRange(range(0, 5));
      }
    } else {
      setPaginationRange(range(pagesCount-5, pagesCount));
    }
  }
  
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="col-lg">
        <Card className="card-minimal">
          <CardHeader className="card-header-minimal">
            <CardTitle className="card-title-minimal" tag="h4">
            General Overview
            </CardTitle>
          </CardHeader>
            <CardBody>
              {isError ? ( 
                  <div className="text-center text-bold">
                    <i className="fas fa-exclamation-triangle"></i> 
                    Error Loading Computers.
                  </div>
                ) :
                isLoading ? (
                  <div className="text-center">
                      <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
                  </div>
                ) : (
                <>
                  <div className="table-responsive-sm">
                    <table className="table">
                      <thead>
                        <tr>
                          <th onClick={() => {sortColumn("name", sortNameAsc); setSortNameAsc(!sortNameAsc);}} style={{cursor: "pointer"}}> Name 
                            <i className={"ml-1 fas fa-long-arrow-alt-" + (sortNameAsc ? "up" : "down")}></i> 
                          </th>
                          <th onClick={() => {sortColumn("description", sortDescriptionAsc); setSortDescriptionAsc(!sortDescriptionAsc);}} style={{cursor: "pointer"}}> Description 
                            <i className={"ml-1 fas fa-long-arrow-alt-" + (sortDescriptionAsc ? "up" : "down")}></i> 
                          </th>
                          <th onClick={() => {sortColumn("platform", sortPlatformAsc); setSortPlatformAsc(!sortPlatformAsc);}} style={{cursor: "pointer"}}> Platform 
                            <i className={"ml-1 fas fa-long-arrow-alt-" + (sortPlatformAsc ? "up" : "down")}></i> 
                          </th>
                          <th onClick={() => {sortColumn("policy", sortPolicyAsc); setSortPolicyAsc(!sortPolicyAsc);}} style={{cursor: "pointer"}}> Policy 
                            <i className={"ml-1 fas fa-long-arrow-alt-" + (sortPolicyAsc ? "up" : "down")}></i> 
                          </th>
                          <th onClick={() => {sortColumn("status", sortStatusAsc); setSortStatusAsc(!sortStatusAsc);}} style={{cursor: "pointer"}}> Status 
                            <i className={"ml-1 fas fa-long-arrow-alt-" + (sortStatusAsc ? "up" : "down")}></i> 
                          </th>
                          <th onClick={() => {sortColumn("maintenance_mode", sortMaintenanceAsc); setSortMaintenanceAsc(!sortMaintenanceAsc);}} style={{cursor: "pointer"}}> Maintenance Mode 
                            <i className={"ml-1 fas fa-long-arrow-alt-" + (sortMaintenanceAsc ? "up" : "down")}></i> 
                          </th>
                          <th onClick={() => {sortColumn("send_policy_sucessful", sortSendPolicyAsc); setSortSendPolicyAsc(!sortSendPolicyAsc);}} style={{cursor: "pointer"}}> Send Policy Successful 
                            <i className={"ml-1 fas fa-long-arrow-alt-" + (sortSendPolicyAsc ? "up" : "down")}></i> 
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {computers.length > 0 ? computers.slice(currentPage * offset, (currentPage+1) * offset).map((comp) => {
                            return (
                              <tr key={comp.eventID}>
                                <td>{comp.name}</td>
                                <td>{comp.description}</td>
                                <td>{comp.platform}</td>
                                <td>{comp.policy}</td>
                                <td>{comp.status}</td>
                                <td>{comp.maintenance_mode}</td>
                                <td>{comp.send_policy_sucessful}</td>
                              </tr>
                            );
                          }
                        ) : (
                          <tr>
                            <td colSpan={7}>No computers</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex w-100 justify-content-center">
                    <Pagination>
                      <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink first href="#" onClick={(e) => handlePageClick(e, 0)}/>
                      </PaginationItem>
                      <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink previous href="#" onClick={(e) => handlePageClick(e, currentPage-1)}/>
                      </PaginationItem>
                      {paginationRange.map((page, i) => (
                        <PaginationItem active={page === currentPage} key={page}>
                          <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
                            {page + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= pagesCount-1}>
                        <PaginationLink next href="#" onClick={(e) => handlePageClick(e, currentPage+1)}/>
                      </PaginationItem>
                      <PaginationItem disabled={currentPage >= pagesCount-1}>
                        <PaginationLink last href="#" onClick={(e) => handlePageClick(e, pagesCount-1)}/>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </>
                )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

const range = (start, end) => {
  let result = []
  for (var i=start; i<end; i++) {
    result.push(i)
  }
  return result;
}

const sortByStringField = (data, field, ascFlag) => {
  if (ascFlag) {
    data.sort((a, b) => {
      if (a[field] != null && b[field] == null) {
        return -1
      } else if (a[field] == null && b[field] != null) {
        return 1
      } else if (a[field] == null && b[field] == null) {
        return 0
      }

      if (a[field].toLowerCase() > b[field].toLowerCase() || a[field] == null) { return -1 } 
      else if (a[field].toLowerCase() < b[field].toLowerCase() || b[field] == null) { return 1 } 
      else { return 0 }
    });
  } else {
    data.sort((a, b) => {
      if (a[field] == null && b[field] != null) {
        return -1
      } else if (a[field] != null && b[field] == null) {
        return 1
      } else if (a[field] == null && b[field] == null) {
        return 0
      }

      if (a[field].toLowerCase() < b[field].toLowerCase()) { return -1 } 
      else if (a[field].toLowerCase() > b[field].toLowerCase()) { return 1 } 
      else { return 0 }
    });
  }
}

export {TrendMicroEvents};
