
import React, {useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Badge
} from "reactstrap";

import { ConnectriaTicketsDashboardTab } from "../../components/ConnectriaTickets/ConnectriaTicketsDashboard";

import { appState } from "../../AppState";


const DesktopDashboard = (props) => {
    //local state
    //global state
    const [stateApp, stateAppActions] = appState();

    var flippyIBMi;
    return (
        <>
            <div className="row">
                {(props.width) > 992 ? (
                    <>
                        <div className="col-md-12 col-lg-8">
                            <Card className="card-chart card-home">
                                <CardHeader>
                                    <CardTitle tag="h3">Ticketing</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ConnectriaTicketsDashboardTab/>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-12 col-lg-4">
                         
                           {props.children}

                        </div>
                      
                    </>
                ) : (
                    <>
                        <div className="col-md-12 col-lg-4">
                        {props.children}

                        </div>
                        <div className="col-md-12 col-lg-8">
                            <Card className="card-chart card-home">
                                <CardHeader>
                                    <CardTitle tag="h3">Ticketing</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ConnectriaTicketsDashboardTab/>
                                </CardBody>
                            </Card>
                            
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default DesktopDashboard;