import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const CloudAdminService = {
    
    getConsoleUri: function(vcenter, vm) {
      let resp = axios.get(
          base_api + `/plugin/tria_interface/vmware/console/${vcenter}/${vm}/uri`
          );
        return resp;
    }

};

export default CloudAdminService;