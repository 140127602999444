import React, { useEffect, useState } from "react";
import useAsyncEffect from "../../utility/use-async-effect";
import PublicCloudService from "../services/service";
import axios from "axios";
import Enum from "../utility/constants";
import IBMService from "../../services/IBMService";
import { Table } from "reactstrap";

const buildCloudResourcePromisesOrdered = (clouds, types) => {
  let promises = [], cloudScopes =[]

  //lets build the promise array by type
  for (var i = 0; i < types.length; i++) {
    //get array of orgservice id's to use in query
    let scopesIds = clouds
      .filter(e => e.cloud_type_id === types[i])
      .map(e => e.id);

    cloudScopes.push({scope_ids: scopesIds, type: types[i]}) 
    let model = {
      scopes: scopesIds,
      filters: [],
      offset: 0,
      limit: 50,
      tags: [],
      insight_exemptions: false
    };
    promises.push(PublicCloudService.resource_query(model));
  }

  return {promises: promises, cloudScopes: cloudScopes};
};
const ResourceLink = (cloud_type, text, scopes) => {
  switch (cloud_type) {
    case "AWS":
      return <a href={`/app/cloud/resources?ids=${get_cloud_scope(scopes, cloud_type)}`}>{text}</a>;
    case "AZURE_ARM":
      return <a href={`/app/cloud/resources?ids=${get_cloud_scope(scopes, cloud_type)}`}>{text}</a>;
    case "K8S":
      return <a href={`/app/cloud/resources?ids=${get_cloud_scope(scopes, cloud_type)}`}>{text}</a>;
    case "VMWARE_VSPHERE":
      return <a href="/app/devices">{text}</a>;
    case "IBM i":
      return <a href="/app/ibm-dashboard">{text}</a>;
    default:
      break;
  }

  return text;
};
const getResourceTypeCounts = (type, items) => {
  return Enum.ResourceTypes[type].reduce(function(count, item) {
    if (items.hasOwnProperty(item)) {
      count += items[item] || 0;
    }
    return count;
  }, 0);
};
const getResourcesTypesTotal = items => {
  let total = 0
  for (let group of ['computeResources', 'storageResources', 'networkResources', 'containerResources', 'identityResources']) {
    total += getResourceTypeCounts(group, items)
  }
  return total
};
const getResourcesTotal = items => {
  return Object.values(items).reduce((a, b) => a + b, 0)
}
const count_iseries = items => {
  let counts = { lpars: 0, network: 0 };
  items.forEach(host => {
    host.services.forEach(service => {
      if (service.name.toLowerCase() === "network interface") {
        let value = service.value.split(": ")[1].trim();
        counts.network = counts.network + parseInt(value);
      }
      if (service.name.toLowerCase() === "version information") {
        counts.lpars++;
      }
    });
  });
  
  return counts;
};
const get_cloud_scope = (items, type) =>{
  for(var i = 0; i < items.length; i++){
    if(items[i].type === type) return items[i].scope_ids.join(",");
  }
  return ''
}
const ResourceMatrix = props => {
  const [cloudTypes, setClouds] = useState([]);
  const [counts, setCounts] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [ibmi, setIbmI] = useState([]);

  const [ibmiLoaded, setIbmiLoaded] = useState(false);
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      try {
        let resource_group = await PublicCloudService.resourcegroup_list({
          cancelToken: source.token
        });

        let clouds = resource_group.data.resource_groups.filter(e =>
          e.hasOwnProperty("cloud_type_id")
        );

        // we want to build the promise by cloud type.. lets get these lambos
        //here are the types we are targeting (unique list)
        //we want to sort because Promise.All will return in same order
        let types = [...new Set(clouds.map(e => e.cloud_type_id))].sort();

        let resourcesPromises = buildCloudResourcePromisesOrdered(
          clouds,
          types
        );
        setScopes(resourcesPromises.cloudScopes);
        let resources = (await axios.all(resourcesPromises.promises)).map(e => e.data);

        if (!isMounted()) return;

        setClouds(types);
        setCounts(resources);
        setResourcesLoaded(true);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          setResourcesLoaded(true);
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  useAsyncEffect(
    async isMounted => {
      try {
        //time for iseries :(
        let iseries = await IBMService.hosts();
        let iSeriesCount = count_iseries(iseries.data);

        if (!isMounted()) return;

        setIbmI([iSeriesCount]);
        setIbmiLoaded(true);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          setIbmiLoaded(true);
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  useEffect(() => {
    if (ibmiLoaded && resourcesLoaded) {
      setIsLoaded(true);
    }
  }, [ibmiLoaded, resourcesLoaded]);

  return (
    <>
      <h3 className="w-100">Resource Management</h3>
      <Table
        id="table-resource-matrix"
        className="table"
        size="sm"
        style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
      >
        <tbody>
          <tr>
            <td>
              <i className="fas fa-cloud text-dark" />
              &nbsp;Clouds
            </td>
            <td>
              <i className="fas fa-server text-dark" />
              &nbsp;Compute
            </td>
            <td>
              <img src={require('../../assets/img/harddisk.svg')} height="18" />
              &nbsp;Storage
            </td>
            <td>
              <img src={require('../../assets/img/server-network.svg')} height="18" />
              &nbsp;Network
            </td>
            <td>
              <i className="fab fa-docker text-dark" />
              &nbsp;Containers
            </td>
            <td>
              <i className="fas fa-user text-dark" />
              &nbsp;Identity
            </td>
            <td>
              <i className="fas fa-bars text-dark" />
              &nbsp;Total
            </td>
          </tr>
          {isLoaded ? 
          <>
          {counts.map((item, i) => {
            return (
              <tr key={i}>
                <td style={{ width: 15 + "%" }}>
                  <div className="row">
                    <div className="col text-right">{cloudTypes[i].indexOf("_") > -1 ? cloudTypes[i].split("_")[0] : cloudTypes[i]}</div>
                    <div className="col text-left">
                      <img
                        style={{ width: 25 + "px" }}
                        src={`https://tria.connectria.com/divvy/img/${cloudTypes[
                          i
                        ].toLowerCase()}-logo.png`}
                      />
                    </div>
                  </div>
                </td>
                <td className="resource-matrix-count">
                  {ResourceLink(cloudTypes[i],getResourceTypeCounts("computeResources", item.counts),scopes)}
                </td>
                <td className="resource-matrix-count">
                {ResourceLink(cloudTypes[i],getResourceTypeCounts("storageResources", item.counts),scopes)}
                </td>
                <td className="resource-matrix-count">
                   {ResourceLink(cloudTypes[i],getResourceTypeCounts("networkResources", item.counts),scopes)}
                </td>
                <td className="resource-matrix-count">
                   {ResourceLink(cloudTypes[i],getResourceTypeCounts("containerResources", item.counts),scopes)}
                </td>
                <td className="resource-matrix-count">
                   {ResourceLink(cloudTypes[i],getResourceTypeCounts("identityResources", item.counts),scopes)}
                </td>
                <td className="resource-matrix-count"> {ResourceLink(cloudTypes[i],getResourcesTypesTotal(item.counts),scopes)}</td>
              </tr>
            );
          })}
          {ibmi.map((item, i) => {
            if (isNaN(item.network)) item.network = 0

            return (
              <tr key={i}>
                <td>
                  <div className="row">
                    <div className="col text-right">IBM i</div>
                    <div className="col text-left">
                      {" "}
                      <img
                        src="//tria.connectria.com/plugin/tria_interface/html/images/IBM_i.png"
                        height="20"
                        width="23"
                      />
                    </div>
                  </div>
                </td>
                <td> {ResourceLink('IBM i',item.lpars)}</td>
                <td> {ResourceLink('IBM i',0)}</td>
                <td> {ResourceLink('IBM i',item.network)}</td>
                <td> {ResourceLink('IBM i',0)}</td>
                <td> {ResourceLink('IBM i',0)}</td>
                <td> {ResourceLink('IBM i',getResourcesTotal(item))}</td>
              </tr>
            );
          })}
          </>
          :
          <></>}
        </tbody>
      </Table>
    </>
  );
};
export { ResourceMatrix };
