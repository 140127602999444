import React, { useState, Suspense, lazy } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";
import CostService from "../services/CostService";
import CostUtility from "../utilities/cost-utility";
import moment from 'moment';
// core components
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardTitle,
  Row,
  Col,
  ButtonGroup,
  TabContent,
  TabPane
} from "reactstrap";

const getCloudSpend = async cloud_type => {
  try {
    return await CostService.costByMonth(cloud_type);
  } catch (e) {
    console.error(e);
  }
  return null;
};


const StackedBarChart = props => {
  const [barChart, setBarChart] = useState({
    datasets: [],
    labels: [],
    options: {
      responsive:true,
      maintainAspectRatio: false,
      legend: {
        onClick: function(e, legendItem) {
          var index = legendItem.datasetIndex;
          var ci = this.chart;
          var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
  
          ci.data.datasets.forEach(function(e, i) {
            var meta = ci.getDatasetMeta(i);
  
            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null;
              } else if (meta.hidden === null) {
                meta.hidden = true;
              }
            } else if (i === index) {
              meta.hidden = null;
            }
          });
          ci.update();
        }
      },
      plugins: {
        labels: {
          render: () => {}
        },
        datalabels: {display: false}
      },
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                return CostUtility.float2dollar(value);
              }
            },
            stacked: true
          }
        ]
      }
    }
  });

  const source = axios.CancelToken.source();
  useAsyncEffect(
    async isMounted => {
      try {
        let forcastMonths = 6;
        let dataSet = [];
        let awsMonthlySpend, awsGovMonthlySpend, azureMonthlySpend;

        // if we pass in a specific account only call one getcloudspend methods for the passed in cloud
        if (props.account_id !== undefined){
          awsMonthlySpend = props.cloud_id === 'AWS' ? await CostUtility.getCloudSpend("AWS", props.account_id || null) : 
                            props.cloud_id === 'AWS_GOV' ? await CostUtility.getCloudSpend("AWS_GOV", props.account_id || null) : null;
          azureMonthlySpend = props.cloud_id === 'AZURE_ARM' ? await CostUtility.getCloudSpend("AZURE", props.account_id || null) : null;
        } else {
          awsMonthlySpend = await CostUtility.getCloudSpend("AWS") ;
          awsGovMonthlySpend = await CostUtility.getCloudSpend("AWS_GOV");
          azureMonthlySpend = await CostUtility.getCloudSpend("AZURE");
        }
        let totalCharges = [], categories = [], accountMonthlySpend = awsMonthlySpend || azureMonthlySpend;

        if (accountMonthlySpend) {
          categories = (awsMonthlySpend || awsGovMonthlySpend || azureMonthlySpend).data.map((x) => { return x.month });
        }

        if (awsMonthlySpend) {
          let awsCharges = awsMonthlySpend.data.map(function(x) {
            return x.charge;
          });
          
          // Rollup aws gov spend in overall aws spend
          if (awsGovMonthlySpend) {
            awsGovMonthlySpend.data.map(function(x, i) {
              awsCharges[i] = awsCharges[i] + x.charge
            })
          }
          // Round to 2 decimal places, because of strange values when adding above ^^
          awsCharges = awsCharges.map(function(x) {
            return +x.toFixed(2);
          })

          totalCharges = totalCharges.concat(awsCharges);

          dataSet.push({
            label: props.cloud_name || '' + "AWS",
            data: awsCharges,
            stack: "Stack 0",
            backgroundColor: "#fabf08"
          });
        } else if (awsGovMonthlySpend) {
          // If there is only AWS gov spend, then make this the total "AWS" spend
          let awsGovCharges = awsGovMonthlySpend.data.map(function(x) {
            return x.charge;
          });
          totalCharges = totalCharges.concat(awsGovCharges);
          dataSet.push({
            label: props.cloud_name || '' + "AWS",
            data: awsGovCharges,
            stack: "Stack 0",
            backgroundColor: "#fabf08"
          });
        }

        if (azureMonthlySpend) {
          let azureCharges = azureMonthlySpend.data.map(function(x) {
            return x.charge;
          });
          
          if (totalCharges.length > 0) {
            totalCharges = totalCharges.map(function(num, idx) {
              return num + azureCharges[idx];
            });
          } else totalCharges = [...azureCharges];
          
          dataSet.push({
            label: props.cloud_name || '' + "AZURE",
            data: azureCharges,
            stack: "Stack 0",
            backgroundColor: "#33adee"
          });
        }

        //here is our predtion logic
        //lets only do this if we have at least 6 months of data
        //CREDIT https://travishorn.com/d3-line-chart-with-forecast-90507cb27ef2
        if (categories.length >= 6) {
          var d = new Date();
          let day = d.getDay();
          //to start lets make sure we use the forcasted spend if we are not too far in the year
          if (day < 25)
            totalCharges[totalCharges.length - 1] = CostUtility.getForecastedSpend(
              totalCharges[totalCharges.length - 1]
            );
          
          let forcastedMonthsArray = [], year = d.getFullYear();

          for (var i = 1; i <= forcastMonths; i++) {
            var month = d.getMonth() + (i + 1); // Since getMonth() returns month from 0-11 not 1-12 and add an additional 1 to get us to the next month
            forcastedMonthsArray.push(moment(d).add(i, 'months').format('YYYY-MM'));
          }
          totalCharges[totalCharges.length -1] =  parseFloat(totalCharges[totalCharges.length -1])
          const historyIndex = totalCharges.map((d, i) => [i, d]);

          let forecastedValues = forcastedMonthsArray.map((d, i) =>
            CostUtility.predict(historyIndex, historyIndex.length - 1 + i)
          );
         
          //initalize the data array with null values for the months we do have data
          let forecastDataset = new Array(categories.length).fill(null);
          //now combine the categories so we can show our forecasted months
          categories = categories.concat(forcastedMonthsArray);
          
          //now combine the values so we can show our forecasted predictions
          forecastDataset = forecastDataset.concat(forecastedValues);

          let currentMonthPrediction = CostUtility.get_prediction(totalCharges, 1, 1);
          
          //add current month prediction
          forecastDataset[11] = currentMonthPrediction[0];
          
          // remove negative values
          for (let n in forecastDataset) {
            if (forecastDataset[n] < 0) forecastDataset[n] = 0;
          }

          dataSet.push({
            label: "Forcasted Spend",
            data: forecastDataset,
            type: "line",
            order: 1
          });
        }

        if (!isMounted()) return;

        setBarChart(prevState => {
          // Object.assign would also work
          return {
            ...prevState,
            labels: categories,
            datasets: dataSet
          };
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [props.cloud_id, props.account_id]
  );

  return (
    <>
      <Card className=" card-minimal h-100 mb-0">
        <CardHeader className="card-header-minimal">
          <h5 className="card-category"></h5>
          <CardTitle className="card-title-minimal" tag="h3">
            Last 12 Months Of Spend
          </CardTitle>
        </CardHeader>
        <hr className="mb-0"></hr>
        <CardBody>
          <div style={{minHeight: 300 + 'px'}}>
            <Bar data={barChart} options={barChart.options} />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default StackedBarChart;
