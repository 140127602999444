import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { DesktopOnlyWidget } from "../../utility/DesktopOnly";
import { appState } from "../../AppState";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import { prop, propSatisfies } from "ramda";
const LegacyFrame = props =>{
    const [stateApp, stateAppActions] = appState();

    useAsyncEffect(
        async isMounted => {
          try {
           
            if (!isMounted()) return;
            //if(props.pageTitle) stateAppActions.setPageNavTitle(props.pageTitle);
            // setSpendSavings({cost: cost, savings: savings})
          } catch (error) {
            if (axios.isCancel(error)) {
              // request cancelled
            } else {
              throw error;
            }
          }
        },
        () => {
         
        },
        []
      );
    
    return(
        <DesktopOnlyWidget>
        {props.pageTitle ?
            <> <h3 className="mb-3 mt-4 tria-header-class">{props.pageTitle}</h3><hr></hr> </>
        : 
            <></>}
        <Card className="card-minimal">
            <CardBody>
        <iframe id="legacyFrame" style={{width: "100%", height: "800px"}} frameBorder="0" src={props.url + ''+ window.location.search.replace('?', '&')}></iframe>
        </CardBody>
        </Card>
        </DesktopOnlyWidget>
    )
}

export { LegacyFrame }