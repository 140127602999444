import React, { Fragment } from "react";
import { useEffect, useState } from "react"

import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";

import CloudWatchService from "../../services/CloudWatchService";
import {Card, CardHeader, CardBody, CardTitle, ButtonGroup, Button} from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {appState} from "../../AppState";
import { useHistory } from "react-router-dom";
import moment from "moment";

const sortByStatus = (a, b) => {
  switch (a) {
    case "OK":
      if (b === "OK") return 0;
      else return 1;
    case "INSUFFICIENT_DATA":
      if (b === "OK") return -1;
      if (b === "ALARM") return 1;
      else return 0;
    case "ALARM":
      if (b === "ALARM") return 0;
      else return -1;
  }
}

const CloudWatchAlarmsWidget = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [alarms, setAlarms] = useState([]);
  const [alarmCounts, setAlarmCounts] = useState({});
  const [allAlarmsOk, setAllAlarmsOk] = useState(true);
  const source = axios.CancelToken.source();
  const history = useHistory();

  const DATETIME_FORMAT = "YYYY-MM-DDThh:mm:ss.SSSZ", today = moment()

  useAsyncEffect(
    async isMounted => {
      try {
        let response = await CloudWatchService.getAlarms();

        if (!isMounted()) return;

        // remove all OK alarms older than 90 days
        let alarms = response.data.clouds_alarms_list.filter((alarm) => {
          alarm.dt = moment(alarm.StateUpdatedTimestamp, DATETIME_FORMAT)
          return !(alarm.StateValue === 'OK' && today.diff(alarm.dt, 'days') > 90)
        })

        // set counter after filter older OK alarms
        // we can't use counters from the response, bcoz it's not filtered
        let alarm_state_counter = {OK: 0, INSUFFICIENT_DATA: 0, ALARM: 0};
        alarms.map((alarm) => { alarm_state_counter[alarm.StateValue] += 1 })
        setAlarmCounts(alarm_state_counter);

        // sort alarms and get first 3 alarms
        let sortedAlarms = alarms
          .sort((a, b) => sortByStatus(a.StateValue, b.StateValue))
          .slice(0, 3);

        setAlarms(sortedAlarms)

        for (let alarm of sortedAlarms) {
          if (alarm.StatusValue !== "OK") setAllAlarmsOk(false);
          break;
        }

        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <h3 className="text-left">CloudWatch Alarms</h3>
        {isLoading ? (
        <div className="text-center">
            <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
        ) : (
        alarms.length === 0 ?
          <div className="w-100 justify-content-center">There are no CloudWatch alarms available</div> :
        allAlarmsOk ? 
          <div className="w-100 justify-content-center">All CloudWatch Alarms have OK status</div> :
          <>
          <CloudWatchAlarmsCounts {...alarmCounts}/>
          <div className="table-responsive-sm">
            <table className="table">
              <tbody>
                {alarms.map((alarm, i) => {
                  return (
                    <tr key={i}>
                      <td style={{width: "25px"}}>{getStatusIcon(alarm.StateValue)}</td>
                      <td>
                        <div className="row"><b className="pr-1">Cloud:</b>{alarm.tria_cloud_name}</div>
                        <div className="row flex-nowrap d-flex text-truncate"><b className="pr-1">Threshold:</b>{alarm.tria_threshold_description}</div>
                      </td>
                    </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
          </>
        )}
      </div>
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push("/app/cloud/cloudwatch", {statusFilter: ""});
        }}
      >
        {" "}
        <span
          style={{ display: "inline", verticalAlign: "middle" }}
          className="fas fa-chart-line"
        ></span>{" "}
        View All
      </button>
    </div>
  );
}

const CloudWatchAlarmsCounts = (props) => {
  const history = useHistory();
  return (
    <div className="d-flex w-100" style={{padding: "12px", justifyContent: "space-evenly"}}>
      <i className="cursor fas fa-check text-success" style={{fontSize: "1rem"}}
        onClick={() => {history.push("/app/cloud/cloudwatch", {statusFilter: "OK"}) }}> {props.OK}
      </i>
      <i className="cursor fas fa-exclamation-triangle text-warning" style={{fontSize: "1rem"}}
        onClick={() => { history.push("/app/cloud/cloudwatch", {statusFilter: "INSUFFICIENT_DATA"}) }}> {props.INSUFFICIENT_DATA}
      </i>
      <i className="cursor fas fa-bell text-danger" style={{fontSize: "1rem"}}
        onClick={() => { history.push("/app/cloud/cloudwatch", {statusFilter: "ALARM"}) }}> {props.ALARM}
      </i>
    </div>
  );
}

const getStatusIcon = (status) => {
  switch (status) {
    case 'OK':
      return <i className="fas fa-check text-success"></i>;
    case 'INSUFFICIENT_DATA':
      return <i className="fas fa-exclamation-triangle text-warning"></i>
    case 'ALARM':
      return <i className="fas fa-bell text-danger"></i>
  }
}

export {CloudWatchAlarmsWidget}