import React, { Fragment } from "react";
import { useEffect, useState } from "react"
import Enum from "../../utility/enum.js"
import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";

import CloudWatchService from "../../services/CloudWatchService";
import {Card, CardHeader, CardBody, CardTitle, ButtonGroup, Button} from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {appState} from "../../AppState";
import { useHistory } from "react-router-dom";
import moment from "moment";
import AWSBusinessLevelSupportNotification from "../../utility/AWSBusinessLevelSupport"

const CloudWatchAlarms = (props) => {
  const allClouds = 'All Clouds';
  const source = axios.CancelToken.source();
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();

  const [unconfigured, setUnconfigured] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [allAlarms, setAllAlarms] = useState([]);
  const [alarms, setAlarms] = useState([]);

  const [clouds, setClouds] = useState([]);
  const [cloudDropdownOpen, setCloudDropdownOpen] = useState(false);
  const [selectedCloud, setSelectedCloud] = useState(allClouds);
  const [cloudIcons, setCloudIcons] = useState({});

  const [statusTypes, setStatusTypes] = useState({OK: 0, INSUFFICIENT_DATA: 0, ALARM: 0});
  const [statusType, setStatusType] = useState('All Clouds');
  const today = moment(), DATETIME_FORMAT = "YYYY-MM-DDThh:mm:ss.SSSZ"

  // Get CloudWatch Alarms
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let response = await CloudWatchService.getAlarms();

        if (!isMounted()) return;
        response.data.clouds_alarms_list = response.data.clouds_alarms_list.sort((a, b) => moment(b.StateUpdatedTimestamp) - moment(a.StateUpdatedTimestamp));

        const stateCounts = Object.values(response.data.alarm_state_counter);
        const stateCountsSum = stateCounts.reduce((total, num) => total + num);
        if (stateCountsSum === 0) {
          setUnconfigured(true);
        }

        // remove all OK alarms older than 90 days
        setAllAlarms(response.data.clouds_alarms_list.filter((alarm) => {
          alarm.dt = moment(alarm.StateUpdatedTimestamp, DATETIME_FORMAT)
          return !(alarm.StateValue === 'OK' && today.diff(alarm.dt, 'days') > 90)
        }));
        
        // set status type to the selected filter from the dashboard or empty to run useEffect and populate alarms & filters
        if (history.location.state != null) {
          setStatusType(history.location.state.statusFilter ? history.location.state.statusFilter : '');
        } else {
          setStatusType('');
        }
        // reset the pushed state to be blank
        history.replace(history.location.pathname, {})

        // add default option to clouds list
        let cloudsList = response.data.clouds_names_list.map((cloud) => { return cloud.name })
        cloudsList.unshift(allClouds);
        setClouds([...new Set(cloudsList)]);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  // Apply the filters when the user selects one
  useEffect(() => {
    let alarms = allAlarms;

    if (selectedCloud !== allClouds) {
      alarms = alarms.filter((alarm) => { return alarm.tria_cloud_name === selectedCloud });
    }

    let states = {OK: 0, INSUFFICIENT_DATA: 0, ALARM: 0};
    alarms.map((alarm) => { states[alarm.StateValue] += 1 });
    setStatusTypes(states);

    if (statusType !== '') {
      alarms = alarms.filter((alarm) => { return alarm.StateValue === statusType });
    }

    setAlarms(alarms);
  }, [selectedCloud, statusType]);

  const toggleCloud = (cloud) => {
    setCloudDropdownOpen(!cloudDropdownOpen);
    if (typeof cloud === 'object') return;
    setSelectedCloud(cloud);
  }

  const toggleStatusType = (type) => {
    if (statusType !== type) {
      setStatusType(type);
    } else {
      setStatusType("");
    }
  }

  const getState = (state) => {
    let result = {icon: '', color: ''};
    switch (state.StateValue) {
      case 'OK':
        result.icon = (<i className="fas fa-check text-success"> Ok</i>);
        break;
      case 'INSUFFICIENT_DATA':
        result.icon = (<i className="fas fa-exclamation-triangle text-warning"> Insufficient</i>);
        result.color = 'table-warning';
        break;
      case 'ALARM':
        result.icon = (<i className="fas fa-bell text-danger"> Alarm</i>);
        result.color = 'table-danger';
        break;
    }
    return result;
  }

  return (
    <>
      <h3 className="mb-0 mt-4 tria-header-class">
        CloudWatch Alarms
      </h3>
      <hr></hr>
      
       {(stateApp.integrations.includes(Enum.Integration.CLOUDWATCH) && <Card className="card-minimal">
        <CardBody>
  <div>{(unconfigured && !isLoading) && <AWSBusinessLevelSupportNotification></AWSBusinessLevelSupportNotification>}</div>
          <div className="row">
            <div className="col-12 col-md-3 pt-2">
              <CloudDropdownFilter
              dropdownOpen = {cloudDropdownOpen}
              toggle = {toggleCloud}
              clouds = {clouds}
              cloud = {selectedCloud}
              cloudIcons = {cloudIcons}
            />
            </div>
            <div className="col-12 col-md-9 col-lg-6">
              <StatusButtonGroup
                toggle={toggleStatusType}
                statusType={statusType}
                statusTypes={statusTypes}
              />
            </div>
          </div>
          {isError ? (
            <div className="text-center text-bold">
              <i className="fas fa-exclamation-triangle"></i>
              Error Loading CloudWatch Alarms.
            </div>
          ) :
          isLoading ? (
            <div className="text-center">
                <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
            </div>
          ) :
          (!isLoading && !isError && alarms.length === 0) ? (
            <div className="text-center mt-4 text-bold"><i className="fas fa-exclamation-triangle"></i> No cloud alarms found. Try adjusting your filter.</div>
          ) :
          <div className="table-responsive-sm">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>State</th>
                  <th>Cloud</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Threshold</th>
                </tr>
              </thead>
              <tbody>
                {alarms.map((alarm, i) => {
                  let state = getState(alarm);
                  return (
                  <tr key={i} onClick={() => {showAlarmBlade(alarm, stateApp, stateAppActions)}}>
                    <td>{state.icon}</td>
                    <td>{alarm.tria_cloud_name}</td>
                    <td>{alarm.AlarmName}</td>
                    <td>{alarm.dt.format('LLL')}</td>
                    <td>{alarm.tria_threshold_description}</td>
                  </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
          }
        </CardBody>
      </Card>)}
    </>
  );
}

const CloudDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const clouds = props.clouds;
  const cloud = props.cloud;
  const cloudIcons = props.cloudIcons;

  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {cloud}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {clouds.map((cloud) => {
          return (<DropdownItem onClick={() => { toggle(cloud) }} key={cloud}>
            {cloud}
            </DropdownItem>
        )})}
      </DropdownMenu>
    </Dropdown>
  );
}

const StatusButtonGroup = (props) => {
  const toggle = props.toggle;
  const statusTypes = props.statusTypes;
  const statusType = props.statusType;
  return (
    <ButtonGroup size="sm" className="d-flex justify-content-center mb-3" role="group">
      <Button
        onClick={() => {toggle("OK")}}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== "OK") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="text-success fas fa-check mr-1 d-none d-md-block"></i>
        {" Ok (" + statusTypes.OK + ")"}
        </div>
      </Button>
      <Button
        onClick={() => {toggle("INSUFFICIENT_DATA")}}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== "INSUFFICIENT_DATA") ? "btn-simple" : ""}`} style={{maxWidth:"230px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="text-warning fas fa-exclamation-triangle mr-1 d-none d-md-block"></i>
        {" Insufficient (" + statusTypes.INSUFFICIENT_DATA + ")"}
        </div>
      </Button>
      <Button
        onClick={() => {toggle("ALARM")}}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== "ALARM") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="text-danger fas fa-bell mr-1 d-none d-md-block"></i>
        {" Alarm (" + statusTypes.ALARM + ")"}
        </div>
      </Button>
    </ButtonGroup>
  );
}

const showAlarmBlade = (alarm, stateApp, stateAppActions) => {
  let periodMin = Math.floor(alarm.Period/60);
  let periodSec = alarm.Period - periodMin * 60;
  let period = periodSec > 0 ? periodMin + ' minutes ' + periodSec + ' seconds' : periodMin + ' minutes';

  stateAppActions.setBlade({
    title: "Alarm Details",
    visible: true,
    content: (<>
      <div className="ml-1 mb-2"><b>Stage Details:</b><p>{alarm.StateReason}</p></div>
      <div className="ml-1 mb-2"><b>Description:</b><p>{alarm.AlarmDescription}</p></div>
      <div className="ml-1 mb-2"><b>Threshold:</b><p>{alarm.tria_threshold_description}</p></div>
      <div className="ml-1 mb-2">
        <b>Actions:</b>
        <p>{alarm.tria_alarms_actions_list.map((item) => { return <>{item.action_name}<br /></> })}</p>
      </div>
      <div className="ml-1 mb-2"><b>Namespace:</b><p>{alarm.Namespace}</p></div>
      <div className="ml-1 mb-2"><b>MetricName:</b><p>{alarm.AlarmName}</p></div>
      <div className="ml-1 mb-2">
        <b>Dimensions:</b>
        <p>{alarm.Dimensions.map((item) => { return <>{item.Name}: {item.Value}<br /></> })}</p>
      </div>
      <div className="ml-1 mb-2"><b>Statistic:</b><p>{alarm.Statistic}</p></div>
      <div className="ml-1 mb-2"><b>Period:</b><p>{period}</p></div>
      <div className="ml-1 mb-2"><b>Treat missing data as:</b><p>{alarm.TreatMissingData}</p></div>
    </>)
  });
}

export { CloudWatchAlarms }
