import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import useTriaTicketsState, { getTicketPriority, getTicketType, createdBy } from "./ConnectriaTicketsState";
import { ClipboardCopyButton } from "../misc";
import { appState } from "../../AppState";
import moment from "moment";
import { groupBy } from 'ramda';
import { Inbox } from "../ConnectriaTickets/ConnectriaTicketsDashboard";
import { ConnectriaTicketsDetailBlade } from "./ConnectriaTicketsBlade";
import { Doughnut, defaults } from "react-chartjs-2";
import FileService from "../../services/FileService";
import SentimentService from "../../services/SentimentService";
import CookieService from "../../services/CookieService";
import PortalService from "../../services/PortalService";
import { NewTabLink } from "./ConnectriaTicketsBlade"
import { ExpandingTextArea } from "../misc";
// reactstrap components
import {
  Input,
  Row,
  Col,
  Form,
  FormFeedback,
  Label,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button
} from "reactstrap";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
import ScreenshotService from "../../services/ScreenshotService";
import 'chartjs-plugin-labels';

const NewTicketForm = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [types, setTypes] = useState();
    const [priorities, setPriorities] = useState();
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [devices, setDevices] = useState();
    const [subject, setSubject] = useState();
    const [cc, setCC] = useState();
    const [ticketType, setTicketType] = useState(null);
    const [ticketPriority, setTicketPriority] = useState();
    const [ticketCategory, setTicketCategory] = useState();
    const [ticketSubCategory, setTicketSubCategory] = useState();
    const [ticketDevice, setTicketDevice] = useState();
    const [description, setDescription] = useState();
    const [ticketError, setTicketError] = useState();
    const [isCreating, setIsCreating] = useState(false);
    const [attachmentElementId, setAttachmentElementId] = useState();
    const [carbonCopies, setCarbonCopies] = useState([]);
    const [defaultCarbonCopies, setDefaultCarbonCopies] = useState([]);
    const [customerActiveUsers, setCustomerActiveUsers] = useState([]);
    const [ticketMeta, setTicketMeta] = useState([]);
    const [includeCSM, setIncludeCSM] = useState(false);
    const [csmInfo, setCsmInfo] = useState(null);
    const [hasIncludeCsmMeta, setHasIncludeCsmMeta] = useState(false);
    const [descriptionInvalid, setDescriptionInvalid] = useState(false);
    const [errorsInvalid, setErrorsInvalid] = useState(false);
    //global state
    const [stateApp, stateAppActions] = appState();
    //plugin state
    const [stateTickets, stateTicketActions] = useTriaTicketsState();
    const source = axios.CancelToken.source();
    const history = useHistory();


  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        var types = await ConnectriaTicketsService.ticketTypes({
            cancelToken: source.token
          });
        var priorities = ConnectriaTicketsService.ticketPriorities();
        
        let isCustomer = stateApp.userInfo.organization_id != 0;
        

        
        var preferences = await ConnectriaTicketsService.getCustomerTicketCarbonCopies({name: "customerTicketsCarbonCopy"}, {
            cancelToken: source.token
        });
       
        if (!isMounted()) return;

        
       await Promise.all([setCacheUsers(preferences), setCacheCategories(), setCacheDevices(), setCsmInfoAndMeta()])

        const CCs = preferences.map((cc) => { return {user_id: cc} });
        setCarbonCopies(CCs);
        setDefaultCarbonCopies(CCs);
        
        setTypes(types.filter(e => e.name != 'Problem'));
        setPriorities(priorities);
        
       
        setFormDefaults(types[0].id, priorities[2].id);
        //check for passed-in default values
        if (props.subject != null) {
            setSubject(props.subject);
        }
        if (props.category != null) {
           setTicketCategory(props.category);
        }
        if (props.subCategory != null) {
            setTicketSubCategory(props.subCategory);
         }
         if (props.type != null) {
            setTicketType(props.type);
         }
        if (props.description != null) {
            setDescription(props.description);
        }
        if (props.attachmentElementId != null) {
            setAttachmentElementId(props.attachmentElementId);
        }
        if (props.deviceId != null) {
            //lookup by asset tage
            let devices = await ConnectriaTicketsService.getAllDevicesLike({
                ar_assoc: 'devicetype',
                limit: 100
            }) || []
            let assetTag = devices.filter(e => e.id == props.deviceId);

            if(assetTag.length > 0){
            setTicketDevice(assetTag[0].assettag);
            }
        }
       
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );
    const setCacheCategories = async () =>{
        //if cache is still loading set a half second timeout and call again
    
    
        let categories = await ConnectriaTicketsService.ticketCategories({});
        categories.names = [...new Set(categories.map((category) => { return category.name}))];   
        setCategories(categories);
    }
    const setCacheDevices = async () =>{
        //if cache is still loading set a half second timeout and call again
        let devices = await ConnectriaTicketsService.getAllDevicesLike({
            ar_assoc: 'devicetype',
            sort_by: 'assettag'
        })
        if (devices == null || devices.length < 0) {
            setDevices([]);
        } else {
            setDevices(groupBy(d => d.devicetype.category)(devices));
        }
    }
    const setCacheUsers = async (preferences) =>{
        let isCustomer = stateApp.userInfo.organization_id != 0;
        var users = await ConnectriaTicketsService.getAllUsersLike({}, isCustomer)
        for (var user of users) {
          if (preferences.includes(user.id)) {
            user['selected'] = true;
          } else {
            user['selected'] = false;
          }
        }
        users.sort((a, b) => {
            return a.fname.localeCompare(b.fname);
        });
        setCustomerActiveUsers(users.filter((user) => {return user.active == true}));
    }
    const setFormDefaults = (ticketTypeId, ticketPriorityId) => {
        setTicketType(null);
        setTicketPriority(ticketPriorityId);
        setTicketCategory("");
        setTicketSubCategory("");
        setTicketDevice('');
        setSubject("");
        setDescription("");
        setIncludeCSM(false);
        setTicketError("");
        setDescriptionInvalid(false);
        setErrorsInvalid(false);
        setIncludeCSM(false)
        setIsLoading(false);
    }
    const createSentimentMeta = async (ticket_id, text) =>{
       
        let sentiment = await SentimentService.getSentiment(text);
        let value = JSON.stringify({
            ...sentiment.data.SentimentScore,
            readable: sentiment.data.Sentiment
        });
        //fire and forget
        ConnectriaTicketsService.createTicketMeta(ticket_id,'sentiment',value);
        
       
    }
    const handleCreate = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsCreating(true);
        try {
            
            var ticketId = await ConnectriaTicketsService.createTicket(
                subject, 
                description, 
                ticketError, 
                ticketPriority, 
                ticketType, 
                ticketDevice, 
                carbonCopies, 
                ticketSubCategory,
                ticketMeta
            );
            createSentimentMeta(ticketId, description);
               
            setFormDefaults(null, priorities[2].id);
            resetDefaultCarbonCopies();
           
            //add new ticket to inbox
            var detail = await ConnectriaTicketsService.ticketDetail(ticketId);
            stateTicketActions.unshiftTicket(detail);
            //check for form attachment
            const file = document.querySelector('#fileCreate').files[0];
            if (file) {
                let fileBase64 = await FileService.toBase64(file);
                let attachmentResponse = await ConnectriaTicketsService.addAttachment(ticketId, file.name, fileBase64);
                document.querySelector('#fileCreate').value = "";
            }
            //check for context attachment
            if (attachmentElementId) {
                let ssData = await ScreenshotService.createScreenshotBase64(attachmentElementId);
                await ConnectriaTicketsService.newComment(ticketId, "Auto-generated screenshot.", "TRiAScreenshot.png", ssData);
            }
            if (props.closeOnSuccess) {
                stateAppActions.setBlade({
                    visible: false,
                    content: "",
                    title: ""
                });
            }
            if (props.fullPage) {
                history.push(`/app/view/ticket/${ticketId}`);
            } else {
                stateAppActions.setAlert({
                    content: <p style={{color:"white"}}>Ticket #{ticketId} created successfully.</p>,
                    className: "success",
                    visible: true
                });
                setTimeout(() => {
                    stateAppActions.setAlert(prevState => ({...prevState, visible: false }))
                }, 2000);
            }
            
        } catch (error) {
            console.log(error);
            stateAppActions.setAlert({
                content: <p style={{color:"white"}}>Error creating ticket.</p>,
                color: "danger",
                visible: true
            })
            setTimeout(() => {
                stateAppActions.setAlert(prevState => ({...prevState, visible: false }))
              }, 2000);
        }
        setIsCreating(false);
    }

    const resetDefaultCarbonCopies = () => {
        var users = [...customerActiveUsers];
        const preferences = defaultCarbonCopies.map((cc) => { return cc.user_id })
        for (var user of users) {
            if (preferences.includes(user.id)) {
                user.selected = true;
            } else {
                user.selected = false;
            }
        }
        setCustomerActiveUsers(users);
    }

    const handleCCSelect = (selectedUser) => {
        var usersCopy = [...customerActiveUsers];
        var newCarbonCopies = [];
        if (selectedUser === "Select All") {
          usersCopy.map((user) => { user.selected = true; });
          newCarbonCopies = usersCopy.map((user) => { return {user_id: user.id} });
          setCarbonCopies(newCarbonCopies)
        } else if (selectedUser === "Deselect All" ) {
          usersCopy.map((user) => { user.selected = false; });
          setCarbonCopies([]);
        } else {
          for (var user of usersCopy) {
            if (user.id == selectedUser.id) {
              user.selected = !user.selected;
            }
            if (user.selected) {
                newCarbonCopies.push({user_id: user.id});
            }
          }
          setCarbonCopies(newCarbonCopies);
        }
        setCustomerActiveUsers(usersCopy);
      }

    const handleCsmSelect = () => {
        if (includeCSM) {
            setTicketMeta(ticketMeta.filter(meta => meta.meta_key != "notify_csm"));
        } else {
            setTicketMeta([...ticketMeta, { "meta_key": "notify_csm", "meta_value": "1" }])
        }
        setIncludeCSM(!includeCSM);
    }

    const setCsmInfoAndMeta = async () => {
        try {
            let [accountAgents, metadata] = await Promise.all([
                ConnectriaTicketsService.getParamData({cancelToken: source.token}),
                PortalService.getPortalCustomerMeta({ meta_key: 'tria_show_cc_csm' }, {headers: {"x-auth-token": CookieService.getCookie("session_id")} })
            ]);
            accountAgents = accountAgents.dynamic.response.data.customer_account_agents;

            for (var agent of accountAgents) {
                if (agent.agent_type.name == "Customer Success Manager") {
                    setCsmInfo(agent)
                }
            }
            if (metadata.meta_value == "TRUE") {
                setHasIncludeCsmMeta(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleDescriptionChange = (value) => {
        try {
            // Check if string is valid utf-8
            decodeURIComponent(escape(value));
            // If so, the text is valid
            setDescriptionInvalid(false);
        } catch (e) {
            // If the text is not valid utf-8 then display message to user
            setDescriptionInvalid(true);
        }
        setDescription(value);
    }

    const handleErrorsChange = (value) => {
        try {
            // Check if string is valid utf-8
            decodeURIComponent(escape(value));
            // If so, the text is valid
            setErrorsInvalid(false);
        } catch (e) {
            // If the text is not valid utf-8 then display message to user
            setErrorsInvalid(true);
        }
        setTicketError(value);
    }

    if (isLoading) {
        return (
            <div className="text-center w-100 h-100">
                <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
            </div>
        )
    } else {
        return (
            <>
                <div className="h-100 w-100 d-flex connectria-new-ticket-form-contain">
                    <Form onSubmit={(e) => handleCreate(e)} className="d-flex w-100 flex-column">
                        <Row className="h-100">
                            <Col lg={props.fullPage ? "7" : "6"} md="12">
                                <Label className="font-weight-bold">Subject*</Label>
                                <Input type="text" id="txtSubject" onChange={(e) => setSubject(e.target.value)} value={subject} autoComplete="off" required></Input>
                                <div>
                                    <Label className="font-weight-bold" for="txtDescription">Description*</Label>
                                    <ExpandingTextArea id="txtDescription" onChange={(e) => handleDescriptionChange(e.target.value)} value={description} rows={props.textAreaRows} required valid={!descriptionInvalid} invalid={descriptionInvalid}></ExpandingTextArea>
                                    <FormFeedback invalid>This text is not valid UTF-8. Ticket creation might fail.</FormFeedback>
                                </div>
                                <div>
                                    <Label for="txtErrors">Error(s)</Label>
                                    <ExpandingTextArea id="txtErrors" onChange={(e) => handleErrorsChange(e.target.value)} value={ticketError} rows={props.textAreaRows} valid={!errorsInvalid} invalid={errorsInvalid}></ExpandingTextArea>
                                    <FormFeedback invalid>This text is not valid UTF-8. Ticket creation might fail.</FormFeedback>
                                </div>
                            </Col>
                            <Col lg={props.fullPage ? "5" : "6"} md="12">
                            <Label>cc 
                                  <small className="ml-1" onClick={() => { handleCCSelect("Select All") }} style={{cursor: "pointer"}}>(Select all)</small>
                                  <small className="ml-1" onClick={() => { handleCCSelect("Deselect All") }} style={{cursor: "pointer"}}>(Deselect all)</small>
                                </Label>
                                <div className="form-control resizable" style={{overflowY: "scroll", height: 100+"px"}}>
                                    {customerActiveUsers.map((user, index) =>
                                      <div className={"d-flex w-100"}
                                        key={index}
                                        onClick={(e) => { handleCCSelect(user) }}
                                        style={{backgroundColor: (user.selected ? "lightblue" : ""), cursor: "pointer"}}
                                      >
                                        <p style={{flex: "0 50%"}}>{user.fname + " " + user.surname}</p>
                                        <p style={{flex: "0 50%"}}>{"<" + user.email + ">"}</p>
                                      </div>
                                    )}
                                </div>
                                {hasIncludeCsmMeta && (<div className="position-relative form-check mb-1">
                                    <label className="form-check-label">
                                        <nobr>cc {csmInfo.user.fname + " " + csmInfo.user.surname}</nobr>
                                        <input type="checkbox" className="form-check-input" checked={includeCSM} onChange={handleCsmSelect}/>
                                        <span className="form-check-sign">
                                        <span className="check"></span>
                                        </span>
                                    </label>
                                </div>)}
                                <Row>
                                    <Col md="6">
                                        <Label className="font-weight-bold">Type*</Label>
                                        <Input required type="select" id="ddTicketType" onChange={(e) => setTicketType(e.target.value)} value={ticketType == null ? "" : ticketType}>
                                        <option value={""} data-content={"<span>Relish ex</span>"}></option>
                                            {types.map((type, index) => 
                                                <option key={index} value={type.id}>{type.name}</option>
                                            )}
                                        </Input>
                                    </Col>
                                    <Col md="6">
                                        <Label className="font-weight-bold">Priority*</Label>
                                        <Input type="select" id="ddTicketPriority" onChange={(e) => setTicketPriority(e.target.value)} value={ticketPriority}>
                                            {priorities.map((priority, index) =>
                                                <option key={index} value={priority.id}>{priority.name}</option>
                                            )}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Label className="font-weight-bold">Category*</Label>
                                        <Input type="select" id="ddTicketCategory" required onChange={(e) => setTicketCategory(e.target.value)} value={ticketCategory}>
                                            <option value=""></option>
                                            {categories.names.map((name, index) =>
                                                <option key={index} value={name}>{name}</option>
                                            )}
                                        </Input>
                                    </Col>
                                    <Col md="6">
                                        <Label className="font-weight-bold">Sub-Category*</Label>
                                        <Input type="select" id="ddTicketSubCategory" required onChange={(e) => setTicketSubCategory(e.target.value)} value={ticketSubCategory}>
                                          <option value=""></option>
                                            {categories.filter((category) => {
                                              return category.name == ticketCategory
                                            }).sort((a, b) => a.subcategory.localeCompare(b.subcategory)).map((category, index) =>
                                                <option key={index} value={category.id}>{category.subcategory}</option>
                                            )}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label>Device (Asset Tag - Service Tag)</Label>
                                        <Input type="select" id="ddTicketDevice" onChange={(e) => setTicketDevice(e.target.value)} value={ticketDevice}>
                                            <option value="">Choose a device...</option>
                                            {Object.keys(devices).map((cat) =>
                                                <optgroup key={cat} label={cat}>
                                                    {devices[cat].map(dv =>
                                                        <option key={dv.id} value={dv.assettag}>{dv.assettag} - {dv.servicetag}</option>
                                                    )}
                                                </optgroup>
                                            )}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label>Attachment</Label>
                                        <Input type="file" name="fileCreate" id="fileCreate" />
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="text-right mt-4">
                                            <Label>{" "}</Label>
                                            {isCreating ? ( 
                                                <Button type="submit" disabled={isCreating} className="btn-sm"><i className="fas fa-spinner fa-spin ml-1" style={{fontSize: "15px"}}></i> Creating</Button>
                                            ) : (
                                                <Button type="submit" className="btn-sm">Submit</Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <small className="font-weight-bold" style={{color: "#0C618D"}}>* Required</small>
                    </Form>
                </div>
            </>
        )
    }
}

function showItemBlade(item, stateApp, stateAppActions) {
    stateAppActions.setBlade({
        title: 
            <span>{'#'+item.ticket_id}{" "}
                <ClipboardCopyButton
                    className="mb-1"
                    value={`${window.location.origin}/app/support/ticket/${item.ticket_id}`} 
                    tooltipMessage={"Copy ticket link"} 
                    alertMessage={`Ticket #${item.ticket_id} link copied.`}/>
                      <NewTabLink
                        tooltipMessage={"Open In New Tab"} 
                        link={`/app/view/ticket/${item.ticket_id}`}
          alertMessage={''}/>
            </span>,
        visible: true,
        content: <ConnectriaTicketsDetailBlade id={item.ticket_id}></ConnectriaTicketsDetailBlade>
    });
}

const PriorityPieChart = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [metrics, setMetrics] = useState();
    const source = axios.CancelToken.source();
    useAsyncEffect(
      async isMounted => {
        try {
            setIsLoading(true);
            let resp = await ConnectriaTicketsService.ticketPriorityMetric(30,{
                cancelToken: source.token
              })
          if (!isMounted()) return;
          setMetrics(resp);
            setIsLoading(false);
         
        } catch (error) {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            throw error;
          }
        }
      },
      () => {
        source.cancel();
      },
      []
    );

    return (
        <Card className="card-minimal mb-0" id="connectria-ticket-priority-card">
            <CardHeader className="card-header-minimal">
                <CardTitle className="card-title-minimal" tag="h3">
                    Ticket Priorities
                </CardTitle>
            </CardHeader>
            <CardBody style={{height:"350px"}}>
                {isLoading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
                    </div>
                ) : (
                    <>
                        <h5>All Tickets Over the Last 30 Days</h5>
                        <div style={{height:"265px"}}>
                            <Doughnut data={metrics}   
                                options={{ maintainAspectRatio: false, plugins: {
                                    labels: {
                                      render: 'percentage',
                                      fontColor: 'white',
                                      fontSize: 14,
                                      fontStyle: 'bold',
                                    },
                                    datalabels: {display: false}
                                 } }}/>
                        </div>
                    </>
                )}
            </CardBody>
        </Card>
    )
}

const RecentUpdates = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [didLoad, setDidLoad] = useState(false);
    const [audit, setAudit] = useState();
    const [cardBodyStyle, setCardBodyStyle] = useState();
    const source = axios.CancelToken.source();
  useAsyncEffect(
    async isMounted => {
      try {
        if (props.visible) {
            if (didLoad) {
                return;
            }
            setIsLoading(true);
            let resp = await ConnectriaTicketsService.ticketAudits(15,{
                cancelToken: source.token
              });
           
        
              if (!isMounted()) return;
              setAudit(resp);
        setIsLoading(false);
        setDidLoad(true);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [props.visible]
  );


    return (
        <Card className="card-minimal mb-0" style={cardBodyStyle}>
        <CardHeader className="card-header-minimal">
            <CardTitle className="card-title-minimal" tag="h3">
                Recent Updates
            </CardTitle>
        </CardHeader>
            <CardBody id="connectria-support-recent-updates-body">
                {isLoading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
                    </div>
                ) : (
                    <div className="list-group" style={cardBodyStyle}>
                        {audit.map((item, index) => {
                            return (
                                <a onClick={() => showItemBlade(item, props.stateApp, props.stateAppActions)} key={index} className={`list-group-item list-group-item-action flex-column align-items-start`}>
                                    <p className="mb-1">{item.log}</p>
                                    <small>{moment(item.date, "YYYY-MM-DDThh:mm:ss.SSSZ").fromNow()}</small>
                                </a>
                            )
                        })}
                    </div>
                )}
            </CardBody>
        </Card>
    );
}

const ConnectriaTicketsSupportDashboard = props => {
    //local state
    const [activeTab, setActiveTab] = useState('1');
    //plugin state
    const [state, stateActions] = useTriaTicketsState();
    //global state
    const [stateApp, stateAppActions] = appState();

    useEffect(() => {
        //check for ticket route
        let paths = window.location.pathname.split("/");
        if ((paths != null) && (paths.length > 2) && (paths[paths.length - 2] === "ticket")) {
            showItemBlade({ ticket_id: paths[paths.length - 1] }, stateAppActions, stateAppActions);
        }
    }, []);

    const toggleTab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <>
            <h3 className= "mb-3 mt-4 tria-header-class">Support & Ticketing</h3>
            <hr></hr>
            <Row>
                <Col md="12" lg="8">
                    <Card className="card-minimal mb-0 h-100">
                        <CardHeader className="card-header-minimal">
                            <CardTitle className="card-title-minimal" tag="h3">
                                Create New Ticket
                            </CardTitle>
                        </CardHeader>
                        <CardBody id="connectria-support-new-ticket-body" className="d-flex flex-column">
                            <NewTicketForm textAreaRows={4}></NewTicketForm>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="12" lg="4">
                    <PriorityPieChart></PriorityPieChart>
                    {/* Tabbed dashboard card approach
                        <Nav tabs className="nav-fill nav-justified" id="connectria-ticket-db-tabs">
                        <NavItem>
                            <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggleTab('1'); }}
                            >
                            Priorities
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggleTab('2'); }}
                            >
                            Recent Changes
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            
                            <PriorityPieChart></PriorityPieChart>
                        </TabPane>
                        <TabPane tabId="2">
                            <RecentUpdates stateApp={stateApp} stateAppActions={stateAppActions} visible={activeTab === "2"}></RecentUpdates>
                        </TabPane>
                    </TabContent> */}
                </Col>
            </Row>
            <Card className="card-minimal mb-0 mt-2">
                <CardHeader className="card-header-minimal">
                    <CardTitle className="card-title-minimal" tag="h3">
                        Ticketing Inbox
                    </CardTitle>
                </CardHeader>
                    <CardBody id="connectria-support-inbox-body">
                        <Inbox 
                            isLoading={state.isLoading} 
                            tickets={state.tickets}
                            statePlugin={state}
                            statePluginActions={stateActions}
                            stateApp={stateApp} 
                            stateAppActions={stateAppActions}>
                        </Inbox>
                    </CardBody>
                </Card>

        </>
    )
}

export {
    ConnectriaTicketsSupportDashboard,
    NewTicketForm
};
