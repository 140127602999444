import Utils from "../../utility/ibm_utility";
import IBMService from "../../services/IBMService";
import { Line } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button, Label } from 'reactstrap';
import { CountdownSeconds } from "../IBMHost/IBMHost";
import LabsModal from "../../utility/Modal"
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

moment.locale('en')
momentLocalizer()
import 'react-widgets/dist/css/react-widgets.css';

const IBMLineChart = props => {
  let customText = props.tooltipPrefix || '';
  let displayUnit = props.displayUnit || '';
  let options = {
    plugins: {
      labels: {
        render: () => {}
      },
      datalabels: {display: false}
    },
    title: { text: "" },
    responsive: true,
    legend: { display: true }, tooltips: {
      callbacks: {
          label: function(tooltipItem, data) {
              return customText + ' ' + tooltipItem.yLabel + displayUnit;
          }
      }
  },
    scales: {
      // yAxes: [{ticks: { beginAtZero: true, max: 100 }}],
      xAxes: [
        {
          title: "time",
          type: "time",

          gridLines: {
            lineWidth: 2
          },
        
        }
      ]
    }
  };
  const [chartType, setType] = useState({name: null});
  const [chart, setChart] = useState({ chart: null, last_value: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState({count: 4, interval: "hours"});
  const [customDateRange, setCustomDateRange] = useState({start: null, end: null}); // Not live updating, different logic than dateRange
  const [dateRangeLabel, setDateRangeLabel] = useState("Last 4 Hours");
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);
  const [interval, setCustomInterval] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const toggle = (item) => {
    setDropdownOpen(!dropdownOpen);

    if(typeof item === "object") return;
    
    setType({name: item});

   };
  const toggleDateRange = (count, interval, label) => {
    setInvalidDateRange(false);
    setDateRange({count: count, interval: interval});
    setDateRangeLabel(label);
  }
  async function fetch(start, end) {
    let response = await IBMService.chart_series(
      props.host.host_name,
      chartType.name || props.type,
      start,
      end
    );
    let chart_data = Utils.getChart(chartType.name || props.type, props.host, response.data);

    if(typeof chart_data != 'undefined')
    setChart({
      data: chart_data,
      last_value: Utils.getLastDataSetValue(
        (chart_data.datasets || [])[0].data || [],
        displayUnit
      )
    });
    setIsLoading(false);
  }

  function fetchCustomDateRange() {
    if ((customDateRange.start > customDateRange.end && customDateRange.end != null) ||
        (customDateRange.start == null || isNaN(customDateRange.start))) {
      setInvalidDateRange(true);
      return
    }
    setInvalidDateRange(false);
    var start = customDateRange.start;
    var end = customDateRange.end;
    if (end == null) {
      end = moment().unix();
    }
    fetch(start, end);
  }

  useEffect(() => {
    setType({name: props.type});
    async function create_interval() {
      setCustomInterval((setInterval(async () => {

         //await fetch();
        //set to 0 then to 15 to force countdown component to update
        setTimeLeft(0);
        setTimeLeft(15);

      }, 15000)))
    }

    if (props.auto_refresh) create_interval();

    if (!props.auto_refresh) clearInterval(interval);
  }, [props.auto_refresh, chartType.name]);

  useEffect(() => {
    //use an IIFE for async/await
    if (dateRangeLabel != "Custom") {
      var start = moment().subtract(dateRange.count, dateRange.interval).unix();
      var end = moment().unix();
      if (Object.keys(props.host).length > 1) fetch(start, end);
    }
  }, [props.host, chartType.name, dateRange]);

  return (
    <Card className="card-minimal line-chart-dashboard">
      <CardHeader className="card-header-minimal">
        <h5 className="card-category">{props.host.display_name} <span className="pull-right">Refreshing in approx. <CountdownSeconds timeLeft={timeLeft}></CountdownSeconds> seconds...</span></h5> 
        <CardTitle className="card-title-minimal" tag="h3">
          <div className="row justify-content-between">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} disabled>
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
              >
                <React.Fragment>
              {chartType.name}  <span
                    className={Utils.getStatusIcon(chartType.name || props.type, chart.last_value || "")}
                  >
                    &nbsp;{chart.last_value}
                  </span>  &nbsp;
                  {/* <span className="tim-icons icon-minimal-down"></span> */}
              </React.Fragment>
              </DropdownToggle>
              <DropdownMenu>
              <DropdownItem onClick={() => toggle('CPU Utilization')}>CPU Utilization</DropdownItem>
              <DropdownItem onClick={() => toggle('Disk Utilization')}>Disk Utilization</DropdownItem>
              <DropdownItem onClick={() => toggle('ASP 1 Disk Space')}>ASP 1 Disk Space</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <div className="row">
              <Dropdown isOpen={dateDropdownOpen} toggle={() => { setDateDropdownOpen(!dateDropdownOpen)}} style={{fontSize: "0.8rem"}}>
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dateDropdownOpen}
                >
                  <React.Fragment>
                    {dateRangeLabel}
                  </React.Fragment>
                  <i className={"fas fa-caret-" + (dateDropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px', paddingRight: "10px"}}></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => toggleDateRange(4, "hours", "Last 4 Hours")}>Last 4 Hours</DropdownItem>
                  <DropdownItem onClick={() => toggleDateRange(24, "hours", "Last 24 Hours")}>Last 24 Hours</DropdownItem>
                  <DropdownItem onClick={() => toggleDateRange(7, "days", "Last 7 Days")}>Last 7 Days</DropdownItem>
                  <DropdownItem onClick={() => toggleDateRange(30, "days", "Last 30 Days")}>Last 30 Days</DropdownItem>
                  <DropdownItem onClick={() => toggleDateRange(90, "days", "Last 90 Days")}>Last 90 Days</DropdownItem>
                  <DropdownItem onClick={() => toggleDateRange(365, "days", "Last 365 Days")}>Last 365 Days</DropdownItem>
                  <DropdownItem onClick={() => setDateRangeLabel("Custom")}>Custom</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {dateRangeLabel == "Custom" && <Button color="secondary m-0 mr-1" size="sm" onClick={fetchCustomDateRange} style={{fontSize: "0.55rem"}}>Update</Button>}
            </div>
          </div>
          {dateRangeLabel == "Custom" && (
          <div className="row" style={{paddingTop: "15px"}}>
            <div className="col-lg-6 col-sm-1">
              <Label className="m-0" for="startDatePicker">Start</Label>
              <DateTimePicker
                id="startDatePicker"
                format={'YYYY-MM-DD HH:mm:ss'}
                placeholder={""}
                max={new Date()}
                onChange={(value) => { setCustomDateRange({...customDateRange, start: moment(value).unix()}) }}/>
            </div>
            <div className="col-lg-6 col-sm-1">
              <Label className="m-0" for="endDatePicker">End</Label>
              <DateTimePicker
                id="endDatePicker"
                format={'YYYY-MM-DD HH:mm:ss'}
                placeholder={""}
                max={new Date()}
                onChange={(value) => { setCustomDateRange({...customDateRange, end: moment(value).unix()}) }}/>
            </div>
          </div>)}
          {invalidDateRange && <small className="text-danger">Please enter an valid date range</small>}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {//Check if message failed
        isLoading ? (
          <div className="text-center">
            <i
              className="fas fa-spinner m-3 mt-4 fa-spin"
              style={{ fontSize: "30px" }}
            ></i>
          </div>
        ) : (
          <>
          
          <Line data={chart.data} options={options} />
          <LabsModal className={"modal-full"} showModal={showModal} title={''} componenet={<Line data={chart.data} options={options} />}></LabsModal>
          
          <i onClick={() => toggleModal()} className="fas fa-expand cursor pull-right"></i>
          </>
        )}
      </CardBody>
    </Card>
  );
};
export { IBMLineChart };
