/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Enum from "./utility/enum.js";

import Icons from "./views/Icons.jsx";
import Map from "./views/Map.jsx";
import Notifications from "./views/Notifications.jsx";
import Rtl from "./views/Rtl.jsx";
import TableList from "./views/TableList.jsx";
import Typography from "./views/Typography.jsx";
import UserProfile from "./views/UserProfile.jsx";
import Dashboard from "./views/Dashboard.jsx";
import IbmDashboard from "./views/IbmDashboard.jsx";
import IbmHostDashboard from "./views/IbmHostDashboard.jsx";
import IbmHostReportDashboard from "./views/IbmHostReportDashboard.jsx";
import { ConnectriaTicketsSupportDashboard } from "./components/ConnectriaTickets/ConnectriaTicketsSupportDashboard.jsx";
import CostDashboard from "./costexplorer/dashboard/cost-dashboard";
import CostResourceDashboard from "./costexplorer/dashboard/cost-resource-dashboard";
import CostSavingsDashboard from "./costexplorer/dashboard/cost-savings-dashboard";
import { SpendByTagsReport } from "./costexplorer/components/SpendByTagsReport";
import { SpendByResourcesReport } from "./costexplorer/components/SpendByResources";
import { IBMiLparMonitorDesktopWidget } from "./components/IBMiDashboard/IBMiDashboard.jsx";
import { BlueMatadorWidget } from "./components/BlueMatadorDashboard/BlueMatadorDashboard.jsx";
import { BlueMatadorEventsInbox } from "./components/BlueMatadorDashboard/BlueMatadorDetails.jsx";
import { CloudWatchAlarms } from "./components/CloudWatch/CloudWatchAlarms.jsx";
import { CloudWatchAlarmsWidget } from "./components/CloudWatch/CloudWatchAlarmsDashboard.jsx";
import { AzureAlerts } from "./components/AzureAlerts/AzureAlerts.jsx";
import { AzureAlertsWidget } from "./components/AzureAlerts/AzureAlertsDashboard.jsx";
import { CostSavingsCard } from "./costexplorer/components/CostSavingsWidget.jsx";
import { SolarWindsDesktopWidget, SolarWindsDetailWidget, SolarWindsDetailLayout } from "./components/VMwareSolarwind/SolarwindForecast.jsx";
import {AddACloud} from "./components/AddACloud/AddACloud.jsx"
import { CloudsListing } from "./components/Clouds/CloudsListing.jsx"
import { DevicesPage } from "./components/Devices/DevicesPage.jsx";
import { DevicesWidget } from "./components/Devices/DevicesDashboard.jsx";
import { DeviceDetailPage } from "./components/Devices/DeviceDetailPage.jsx";
import { TrendMicroWidget } from "./components/TrendMicro/TrendMicroDashboard.jsx";
import { ComplianceWidget } from "./components/Compliance/Compliance.jsx";
import { TrendMicroEvents } from "./components/TrendMicro/TrendMicroDetail.jsx";
import { AWSTickets } from "./public_cloud/components/AWSTickets";
import { SecurityAdvisor } from "./public_cloud/components/SecurityAdvisor";
import { SecurityAdvisorWidget } from "./public_cloud/components/SecurityAdvisorDashboard.jsx";
import FeatureToggle from "./utility/FeatureToggle.js";
import { ConnectriaTicketsDashboardTab } from "./components/ConnectriaTickets/ConnectriaTicketsDashboard.jsx";
import { ContactDashboard } from "./components/Contact/ContactDashboard.jsx";
import {ResourceMatrix} from "./public_cloud/components/ResourceMatrix"
import { LegacyFrame } from "./public_cloud/components/LegacyFrame"
import { ConnectriaTicketFullPage } from "./components/ConnectriaTickets/ConnectriaTicketFullPage"
import { ConnectriaNewTicketFullPage } from "./components/ConnectriaTickets/ConnectriaNewTicketFullPage"
import { IBMCartCard } from "./components/IBMiCart/IBMCartCard.jsx";
import { BackupJobsDashborard } from "./components/BackupJobs/BackupJobsDashborard.jsx";
import { BackupsDashboardCard } from "./components/BackupJobs/BackupsDashboardCard.jsx";
import { BackupAllJobs } from "./components/BackupJobs/BackupAllJobs.jsx";
import { BackupSingleJob } from "./components/BackupJobs/BackupSingleJob.jsx";
import { OpsList } from './components/BotsAutomation/OpsList';
import { OpsConfigurationForm } from './components/BotsAutomation/OpsConfigurationForm';
import UpsellCardContainer from './components/Upsell/UpsellCardContainer';
import UpsellCard from './components/Upsell/UpsellCard';

const _legacyComplianceUrl = 'https://sandbox.tria.connectria.com/insights/saved?hideside&order=-severity';
const _legacyResourceUrl = 'https://sandbox.tria.connectria.com/resources?hideside';
const _legacyBotUrl = 'https://sandbox.tria.connectria.com/bots/listing?hideside';
const _legacyBotTemplatesUrl = 'https://sandbox.tria.connectria.com/bots/templates?hideside';
const _legacyBotCreateUrl = 'https://sandbox.tria.connectria.com/bots/factory?hideside';
const _legacyTriaTaggingUrl = 'https://sandbox.tria.connectria.com/tag-manager/tag-analyzer?hideside';
const _legacyDTaggingUrl = 'https://sandbox.tria.connectria.com/tags/saved?hideside';
const _legacyTriaResourceGroupsUrl = 'https://sandbox.tria.connectria.com/groups?hideside';
const _legacyTriaComplianceScorecardUrl = 'https://sandbox.tria.connectria.com/compliance/summary/scorecard?hideside';
const _legacyExemptionsUrl = 'https://sandbox.tria.connectria.com/exemptions/list?hideside';

var routes = [
  {
    path: "/dashboard",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: "fas fa-tachometer-alt",
    component: Dashboard,
    layout: "/app",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "tria-dashboard",
      "event_data": {
        "category": "tria"
      }
    }
  },
  {
    group: "AWS",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.AWS],
    routes: [
      {
        path: "/cloud/cloudwatch",
        name: "CloudWatch Alarms",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AWS],
        // integrations: [Enum.Integration.CLOUDWATCH],
        rtlName: "ار تي ال",
        icon: "fas fa-bell",
        component: CloudWatchAlarms,
        layout: "/app",
        key: "cloudwatch-alarms",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "cloudwatch-alarms",
          "event_data": {
            "category": "cloudwatch"
          }
        }
      },
      {
        path: "/cloud/aws-tickets",
        name: "AWS Support Tickets",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AWS],
        rtlName: "ار تي ال",
        icon: "fas fa-list",
        component: AWSTickets,
        layout: "/app",
        key: "aws-tickets",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "aws-tickets",
          "event_data": {
            "category": "aws"
          }
        }
      },
      {
        path: "/cloud/aws-security-advisor",
        name: "AWS Security Advisor",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AWS],
        rtlName: "ار تي ال",
        icon: "fas fa-shield-alt",
        props: {cloudType: "AWS"},
        component: SecurityAdvisor,
        layout: "/app",
        key: "aws-security-advisor",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "aws-security-advisor",
          "event_data": {
            "category": "cloud"
          }
        }
      },
    ]
  },
  {
    group: "Azure",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.AZURE],
    routes: [
      {
        path: "/cloud/azure-alerts",
        name: "Azure Monitor",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AZURE],
        rtlName: "ار تي ال",
        icon: "tim-icons icon-chart-pie-36",
        component: AzureAlerts,
        layout: "/app",
        key: "azure-alerts",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "azure-alerts",
          "event_data": {
            "category": "azure"
          }
        }
      },
      {
        path: "/cloud/azure-security-advisor",
        name: "Azure Security Advisor",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AZURE],
        rtlName: "ار تي ال",
        icon: "fas fa-shield-alt",
        props: {cloudType: "AZURE_ARM"},
        component: SecurityAdvisor,
        layout: "/app",
        key: "security-advisor",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "azure-security-advisor",
          "event_data": {
            "category": "cloud"
          }
        }
      },
    ]
  },
  {
    group: "IBM i",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.IBMI],
    routes: [
      {
        path: "/ibm-dashboard",
        name: "IBM i LPARs",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.IBMI],
        rtlName: "لوحة القيادة",
        icon: "fas fa-server",
        component: IbmDashboard,
        layout: "/app",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "ibmi-dashboard",
          "event_data": {
            "category": "ibmi"
          }
        }
      },
      {
        path: "/ibm/host/report/:id",
        name: "IBM i Report",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.IBMI],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: IbmHostReportDashboard,
        layout: "/app",
        suppress: true,
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "ibmi-report",
          "event_data": {
            "category": "ibmi"
          }
        }
      },
      {
        path: "/ibm/host/:id",
        name: "Dashboard",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.IBMI],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: IbmHostDashboard,
        layout: "/app",
        suppress: true,
        key: "ibm-host-dashboard",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "ibmi-host-dashboard",
          "event_data": {
            "category": "ibmi"
          }
        }
      },
    ]
  },
  {
    group: "Public Cloud",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
    routes: [
      {
        path: "/clouds/listing",
        name: "Clouds Listing",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD, Enum.CloudCategory.VMWARE],
        icon: "fas fa-cloud",
        component: CloudsListing,
        layout: "/app"
      },
      {
        path: "/cloud/cost/dashboard",
        name: "Spend Dashboard",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: CostDashboard,
        layout: "/app",
        suppress: false  
      },
      {
        path: "/cloud/cost/spend-resources",
        name: "Spend By Resources",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: SpendByResourcesReport,
        layout: "/app",
        suppress: false
      },
      {
        path: "/cloud/cost/spend-by-tags-report",
        name: "Spend By Tags Report",
        envs: [Enum.Env.DEV],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: SpendByTagsReport,
        layout: "/app",
        suppress: false
      },
      /*{
        path: "/cloud/cost/:cloud_id/:account_id",
        name: "Cloud Cost Dashboard",
        envs: [Enum.Env.DEV],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: CostResourceDashboard,
        layout: "/app",
        suppress: true  
      },*/
      {
        path: "/cloud/monitoring",
        name: "Cloud Monitoring",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        integrations: [Enum.Integration.BLUEMATADOR],
        rtlName: "ار تي ال",
        icon: "tim-icons icon-chart-pie-36",
        component: BlueMatadorEventsInbox,
        layout: "/app",
        key: "bluematador-events-inbox",
        clickstreamMeta: {
          "event_id": "visited",
          "event_target": "bluematador-events-inbox",
          "event_data": {
            "category": "bluematador"
          }
        }
      },
      {
        path: "/cloud/resources",
        name: "Resource Management",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-cloud",
        props: {url: _legacyResourceUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: false
      },
      {
        path: "/cloud/groups",
        name: "Resource Groups",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-layer-group",
        props: {url: _legacyTriaResourceGroupsUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: false  
      },
      {
        path: "/cloud/compliance",
        name: "Compliance Insights",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-file-signature",
        props: {url: _legacyComplianceUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: false
      },
      {
          path: "/cloud/compliance-scorecard",
          name: "Compliance Scorecard",
          envs: [Enum.Env.ALL],
          clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
          rtlName: "لوحة القيادة",
          icon: "tim-icons icon-chart-pie-36",
          props: {url: _legacyTriaComplianceScorecardUrl},
          component: LegacyFrame,
          iframe: true,
          layout: "/app",
          suppress: false
      },
      {
        path: "/cloud/bots_listing",
        name: "Bot Factory",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-robot",
        props: {url: _legacyBotUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: true
      },
        {
        path: "/cloud/bots_templates",
        name: "Bot Factory",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-robot",
        props: {url: _legacyBotTemplatesUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: true
      },
        {
        path: "/cloud/bots_create",
        name: "Bot Factory",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-robot",
        props: {url: _legacyBotCreateUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: false
      },
      {
        path: "/tag/explorer",
        name: "Tag Explorer",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-user-tag",
        props: {url: _legacyDTaggingUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: false  
      },
      {
        path: "/tag/analyzer",
        name: "Tag Analyzer",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-tags",
        props: {url: _legacyTriaTaggingUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: false  
      },
      {
        path: "/cloud/exemptions",
        name: "Exemptions",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "fas fa-table",
        props: {url: _legacyExemptionsUrl},
        component: LegacyFrame,
        iframe: true,
        layout: "/app",
        suppress: true
      },
      {
          path: "/cloud/savings/dashboard",
          name: "Cloud Savings Dashboard",
          envs: [Enum.Env.ALL],
          clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
          rtlName: "لوحة القيادة",
          icon: "tim-icons icon-chart-pie-36",
          component: CostSavingsDashboard,
          layout: "/app",
          suppress: true
      }
    ]
  },
  {
    path: "/ops",
    name: "Ops Automation",
    envs: [Enum.Env.DEV, Enum.Env.LOCAL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fas fa-life-ring",
    component: OpsList,
    layout: "/app",
    exact: true,
    suppress: true,
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "ops",
      "event_data": {
        "category": "ops"
      }
    }
  },
  {
    path: "/ops/:id",
    name: "Ops Configuration",
    envs: [Enum.Env.DEV, Enum.Env.LOCAL],
    clouds: [Enum.CloudCategory.ALL],
    suppress: true,
    component: OpsConfigurationForm,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "ops-detail-view",
      "event_data": {
        "category": "ops"
      }
    }
  },
  {
    path: "/devices",
    name: "Devices",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fas fa-life-ring",
    component: DevicesPage,
    layout: "/app",
    exact: true,
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "devices",
      "event_data": {
        "category": "support"
      }
    }
  },
  {
    path: "/devices/:id",
    name: "Node Detail",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    suppress: true,
    component: DeviceDetailPage,
    layout: "/app"
  },
  {
    path: "/backup",
    name: "Your Backup Jobs",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.VMWARE],
    rtlName: "لوحة القيادة",
    icon: "fas fa-life-ring",
    component: BackupJobsDashborard,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "backup client",
      "event_data": {
        "category": "backup"
      }
    }
  },
  {
    path: "/view/backup/jobs/client/:id",
    name: "Backup Jobs",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    suppress: true ,
    component: BackupAllJobs,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "backup-jobs",
      "event_data": {
        "category": "backup"
      }
    }
  },
    {
    path: "/view/backup/job/:id/:status",
    name: "Backup Job",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    suppress: true ,
    component: BackupSingleJob,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "backup-job",
      "event_data": {
        "category": "backup"
      }
    }
  },
  {
    path: "/anti-malware/events",
    name: "Anti-Malware",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.VMWARE, Enum.CloudCategory.PUBLIC_CLOUD],
    integrations: [Enum.Integration.TRENDMICRO],
    rtlName: "لوحة القيادة",
    icon: "fas fa-shield-alt",
    component: TrendMicroEvents,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "anti-malware-events",
      "event_data": {
        "category": "security"
      }
    }
  },
  {
    path: "/support",
    name: "Support and Ticketing",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fas fa-life-ring",
    component: ConnectriaTicketsSupportDashboard,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "ticketing-dashboard",
      "event_data": {
        "category": "support"
      }
    }
  },
  {
    path: "/view/ticket/:id",
    name: "Ticketing Detail",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    suppress: true ,
    component: ConnectriaTicketFullPage,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "ticketing-detail-view",
      "event_data": {
        "category": "support"
      }
    }
  },
  {
    path: "/create-ticket",
    name: "Create Ticket",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    suppress: true ,
    component: ConnectriaNewTicketFullPage,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "create-ticket-view",
      "event_data": {
        "category": "support"
      }
    }
  },
  {
    name: "VMware Capacity",
    path: "/vm/capacity",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.VMWARE],
    rtlName: "ار تي ال",
    icon: "fas fa-server",
    component: SolarWindsDetailLayout,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "vm-capacity",
      "event_data": {
        "category": "vm"
      }
    }
  },
  {
    path: "/contact",
    name: "Contact Us",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fas fa-address-book",
    component: ContactDashboard,
    layout: "/app",
    suppress: true,
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "contact-us",
      "event_data": {
        "category": "tria"
      }
    }
  },
];

const dashBoardConfigIbmi = [
  {
    equalHeightCards: false,
    cards: [
      {
        className: "col-md-8 d-none d-md-block",
        title: "Ticketing",
        components: [
          <FeatureToggle envs={[Enum.Env.ALL]} clouds={[Enum.CloudCategory.ALL]}><ConnectriaTicketsDashboardTab></ConnectriaTicketsDashboardTab></FeatureToggle>
        ]
      },
      {
        nestedConfig: [
          {
            className: "col-md-4",
            equalHeightCards: false,
            cards: [
              {
                className: "col-md-12",
                title: "Security & Compliance at a Glance",
                components: [
                  <FeatureToggle envs={[Enum.Env.ALL]} clouds={[Enum.CloudCategory.PUBLIC_CLOUD,Enum.CloudCategory.VMWARE]} integrations={[Enum.Integration.TRENDMICRO]}><TrendMicroWidget></TrendMicroWidget></FeatureToggle>
                ]
              }
            ]
          },
          {
            equalHeightCards: false,
            cards: [
              {
                className: "col-md-12",
                title: "Operation & Performance at a Glance",
                components: [
                  <FeatureToggle clouds={[Enum.CloudCategory.IBMI]} envs={[Enum.Env.ALL]}><IBMiLparMonitorDesktopWidget></IBMiLparMonitorDesktopWidget></FeatureToggle>,
                  <FeatureToggle clouds={[Enum.CloudCategory.VMWARE,Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.ALL]}><SolarWindsDesktopWidget></SolarWindsDesktopWidget></FeatureToggle>,
                  <FeatureToggle clouds={[Enum.CloudCategory.ALL]} envs={[Enum.Env.ALL]}><DevicesWidget></DevicesWidget></FeatureToggle>,
                  <FeatureToggle clouds={[Enum.CloudCategory.VMWARE]} envs={[Enum.Env.ALL]}><BackupsDashboardCard/></FeatureToggle>
                ]
              }
            ]
          },
          {
            equalHeightCards: false,
            cards: [
              {
                className: "col-sm-12",
                noCard: true,
                components: [
                  <FeatureToggle clouds={[Enum.CloudCategory.ALL]} envs={[Enum.Env.ALL]}><UpsellCardContainer /></FeatureToggle>,
                ]
              }
            ]
          },
        ]
      }
    ]
  }
]

const dashBoardConfig = [
  {
    equalHeightCards: false,
    cards: [
      {
        className: "col-md-12 d-none d-md-block",
        title: "Resource Management",
        components: [
          <FeatureToggle clouds={[Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.ALL]}><ResourceMatrix></ResourceMatrix></FeatureToggle>
        ]
      }
    ]
  },
  {
    equalHeightCards: true,
    cards: [
      {
        className: "col-sm-12 col-lg-4",
        title: "Cost Optimization at a Glance",
        components: [
          <FeatureToggle clouds={[Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.ALL]}><CostSavingsCard></CostSavingsCard></FeatureToggle>
        ]
      },
      {
        className: "col-sm-12 col-lg-4",
        title: "Security & Compliance at a Glance",
        components: [
          <FeatureToggle envs={[Enum.Env.ALL]} clouds={[Enum.CloudCategory.PUBLIC_CLOUD]}><ComplianceWidget></ComplianceWidget></FeatureToggle>,
          <FeatureToggle envs={[Enum.Env.ALL]} clouds={[Enum.CloudCategory.PUBLIC_CLOUD,Enum.CloudCategory.VMWARE]} integrations={[Enum.Integration.TRENDMICRO]}><TrendMicroWidget></TrendMicroWidget></FeatureToggle>,
          <FeatureToggle envs={[Enum.Env.ALL]}integrations={[Enum.Integration.CLOUDWATCH]}  clouds={[Enum.CloudCategory.AWS]}><SecurityAdvisorWidget cloudType="AWS"></SecurityAdvisorWidget></FeatureToggle>,
          <FeatureToggle envs={[Enum.Env.ALL]} clouds={[Enum.CloudCategory.AZURE]}><SecurityAdvisorWidget cloudType="AZURE_ARM"></SecurityAdvisorWidget></FeatureToggle>,
          <FeatureToggle envs={[Enum.Env.DEV, Enum.Env.DEMO]} clouds={[Enum.CloudCategory.IBMI]}><IBMCartCard></IBMCartCard></FeatureToggle>,
      ]
      },
      {
        className: "col-sm-12 col-lg-4",
        title: "Operation & Performance at a Glance",
        components: [
          <FeatureToggle clouds={[Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.ALL]} integrations={[Enum.Integration.BLUEMATADOR]}><BlueMatadorWidget></BlueMatadorWidget></FeatureToggle>,
          <FeatureToggle clouds={[Enum.CloudCategory.AWS]} envs={[Enum.Env.ALL]} integrations={[Enum.Integration.CLOUDWATCH]}><CloudWatchAlarmsWidget></CloudWatchAlarmsWidget></FeatureToggle>,
          <FeatureToggle clouds={[Enum.CloudCategory.AZURE]} envs={[Enum.Env.ALL]}><AzureAlertsWidget></AzureAlertsWidget></FeatureToggle>,
          <FeatureToggle clouds={[Enum.CloudCategory.IBMI]} envs={[Enum.Env.ALL]}><IBMiLparMonitorDesktopWidget></IBMiLparMonitorDesktopWidget></FeatureToggle>,
          <FeatureToggle clouds={[Enum.CloudCategory.VMWARE]} envs={[Enum.Env.ALL]}><SolarWindsDesktopWidget></SolarWindsDesktopWidget></FeatureToggle>,
          <FeatureToggle clouds={[Enum.CloudCategory.ALL]} envs={[Enum.Env.ALL]}><DevicesWidget></DevicesWidget></FeatureToggle>,
          <FeatureToggle clouds={[Enum.CloudCategory.VMWARE]} envs={[Enum.Env.ALL]}><BackupsDashboardCard/></FeatureToggle>
        ]
      }
    ]
  },
  {
    equalHeightCards: false,
    cards: [
      {
        className: "col-lg-8 d-none d-lg-block",
        title: "Ticketing",
        components: [
          <FeatureToggle envs={[Enum.Env.ALL]} clouds={[Enum.CloudCategory.ALL]}><ConnectriaTicketsDashboardTab></ConnectriaTicketsDashboardTab></FeatureToggle>
        ]
      },
      {
        nestedConfig: [
          {
            className: "col-sm-12 col-lg-4",
            equalHeightCards: false,
            cards: [
              {
                className: "col-sm-12",
                title: "Automations",
                components: [
                  <FeatureToggle clouds={[Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.ALL]}>
                    <div>
                      <h3 className="text-left">Bot Factory</h3>
                      <ul className="list-unstyled text-center">
                        <li><a href="/app/cloud/bots_create" className="btn btn-link" style={{fontSize:"15px"}}><i className="fas fa-plus-square"></i> Create Bot</a></li>
                        <li><a href="/app/cloud/bots_listing" className="btn btn-link" style={{fontSize:"15px"}}><i className="fas fa-list"></i> Bot Listing</a></li>
                        <li><a href="/app/cloud/bots_templates" className="btn btn-link" style={{fontSize:"15px"}}><i className="fas fa-ruler-combined"></i> Templates</a></li>
                      </ul>
                    </div>
                  </FeatureToggle>
                ]
              }
            ]
          },
          {
            equalHeightCards: false,
            cards: [
              {
                className: "col-sm-12",
                noCard: true,
                components: [
                  <FeatureToggle clouds={[Enum.CloudCategory.ALL]} envs={[Enum.Env.ALL]}><UpsellCardContainer /></FeatureToggle>,
                ]
              }
            ]
          },
        ]
      }
    ]
  }
]

export {routes, dashBoardConfig, dashBoardConfigIbmi }

