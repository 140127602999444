import React, { useContext, useEffect, useState, Component } from "react";
import { Redirect, Route } from "react-router-dom";
import UserService from "../services/UserService";
import PublicCloudService from "../public_cloud/services/service";
import CookieService from "../services/CookieService";
import ChatService from "../services/ChatService";
import OrganizationService from "../services/OrganizationService";
import BlueMatadorService from "../services/BlueMatadorService";
import CloudWatchService from "../services/CloudWatchService";
import TrendMicroService from "../services/TrendMicroService";
import PortalService from "../services/PortalService";
import axios from "axios";
import Enum from "../utility/enum";
import { appState } from "../AppState";
import { useHistory } from "react-router-dom";
import { sortBy, compose, prop, toLower } from 'ramda';

import { buildRurl } from "./misc";
import IBMService from "../services/IBMService";

function PrivateRoute({ component: Component, ...rest }) {
  
  const [stateApp, stateAppActions] = appState();
  const [inFlight, setInFlight] = useState(true);
  const [rurl] = useState(buildRurl());
  const history = useHistory();
  const setAuthInterceptor = () => {
    axios.interceptors.request.use(
      async config => {
        //console.log(stateApp);
        config.headers["x-auth-token"] = CookieService.getCookie("session_id");
        return config;
        // or throw new Error('User required')
      },
      // I don't think this function is required
      function(error) {
        return Promise.reject(error);
      }
    );
    //theyre authenticated so default them to the homepage if the url doesnt match anything
    //if(props.location.pathname == '/'){
    //  history.push("/app/dashboard");
    //  }
  };
  async function determineClouds() {
    let cloud_types = {data: []}
    let ibmi = {data: []}
    try {
      cloud_types = await PublicCloudService.list_clouds({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
    } catch(e) {
      console.log(e);
    }
    try {
      ibmi = await IBMService.hosts({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
    }
    catch(e){
      console.error(e);
    }

    let types = [];
    for (var i = 0; i < cloud_types.data.clouds.length; i++) {
      if (cloud_types.data.clouds[i].cloud_type_id.includes("AWS")) {
        types.push(Enum.CloudCategory.AWS);
        types.push(Enum.CloudCategory.PUBLIC_CLOUD);
      }
      if (cloud_types.data.clouds[i].cloud_type_id.includes("AZURE")) {
        types.push(Enum.CloudCategory.AZURE);
        types.push(Enum.CloudCategory.PUBLIC_CLOUD);
      }
      if (cloud_types.data.clouds[i].cloud_type_id.includes("VMWARE")) {
        types.push(Enum.CloudCategory.VMWARE);
      }
    }
    if (ibmi.data.length > 0) types.push(Enum.CloudCategory.IBMI);

    stateAppActions.setClouds([...new Set(types)]);
    return new Promise(resolve => {
      resolve('resolved');
    });
  }

  async function determineOrgs() {
   
    let orgs = await OrganizationService.list_orgs({
      headers: {
        "x-auth-token": CookieService.getCookie("session_id")
      }
    });
    try{
    stateAppActions.setOrganizations(sortBy(compose(toLower, prop('name')))(orgs.data));
      return new Promise(resolve => {
      resolve('resolved');
    });
  }
  catch{}
  }

  async function determineIntegrations() {
    let integrations = [];
    try {
      let hasBlueMatador = await BlueMatadorService.checkSetup({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
      if (hasBlueMatador.data.has_bluematador) integrations.push(Enum.Integration.BLUEMATADOR)

      let hasTrendMicro = await TrendMicroService.checkSetup({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
      if (hasTrendMicro.data.has_trendmicro) integrations.push(Enum.Integration.TRENDMICRO)

      let cloudWatchAlarms = await CloudWatchService.getAlarms({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
      if (cloudWatchAlarms.data.clouds_alarms_list.length > 0) integrations.push(Enum.Integration.CLOUDWATCH)

      stateAppActions.setIntegrations(integrations);
      return new Promise(resolve => {
        resolve("resolved");
      });
    }
    catch(ex) {
      console.log(ex);
    }
  }

  async function determineSkyLiftLink() {
    try{
      let metadata = {};
      let show_skylift = CookieService.getCookie("show_skylift");
      if (show_skylift == null) {
        metadata = await PortalService.getPortalCustomerMeta({ meta_key: 'tria_show_skylift_link' }, {
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        });
        show_skylift = metadata.meta_value == "TRUE" ? 1 : 0;
        CookieService.setCookie("show_skylift", show_skylift, 0, stateApp.env, '/');
      } else {
        metadata = {"meta_key":"Tria_Show_Skylift_Link", "meta_value": (show_skylift ? "TRUE" : "FALSE")}
      }

      stateAppActions.setPortalCustomerMeta(metadata);
      return new Promise(resolve => {
        resolve("resolved");
      });
    }
    catch(ex){
      console.log(ex);
    }
  }

  async function determineUpsellsEnabled() {
    try{
      let metadata = {};
      let upsellsEnabled = CookieService.getCookie("tria_upsell_ads");
      if (!upsellsEnabled) {
        metadata = await PortalService.getPortalCustomerMeta({ meta_key: 'tria_upsell_ads' }, {
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        });
        CookieService.setCookie("tria_upsell_ads", metadata.meta_value, 0, stateApp.env, '/');
      } else {
        metadata = { meta_key: 'tria_upsell_ads', meta_value: upsellsEnabled };
      }

      stateAppActions.setPortalCustomerMeta(metadata);
      return new Promise(resolve => {
        resolve("resolved");
      });
    }
    catch(ex){
      console.log(ex);
    }
  }

  useEffect(() => {
    //try to get user info to check if session is still valid

    async function validateExistingCookie() {
      
      let user_info = await UserService.user_info({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });

      await Promise.all([
        determineClouds(),
        determineOrgs(),
        determineIntegrations(),
        determineSkyLiftLink(),
        determineUpsellsEnabled(),
      ])
      .then() // 1,Error: 2,3
      .catch(e => console.log(e));

      stateAppActions.setAuthenticated(user_info.data);
      ChatService.getChatCustomer(user_info.data.username);

      var d = new Date();
      d.setMinutes(d.getMinutes() + 720);
      CookieService.setCookie("session_timeout_time", d.getTime(), 1, stateApp.env);
      stateAppActions.setSessionTimeoutTime(d.getTime());

      setAuthInterceptor();
      setInFlight(false);
    }
    // console.log(
    //   "checking cookie value " + CookieService.getCookie("session_id")
    // );

    if (
      (CookieService.getCookie("session_id") || "").length > 0 &&
      (!stateApp.authenticated || stateApp.reload > 0)
    ) {
      validateExistingCookie();
    } else {
      setAuthInterceptor();
      setInFlight(false);
    }
  }, [stateApp.authenticated, stateApp.reload]);
  //if(!CookieService.getCookie('session_id')) return;
  // console.log(props.children );

  return (
    <Route
      {...rest}
      render={props =>
        inFlight ? (
          <></>
        ) : stateApp.authenticated ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={`/login${rurl}`} />
        )
      }
    />
  );
}
export default PrivateRoute;
