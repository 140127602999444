import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const Tickets = {
  getAWSTicket: function(id, params={}) {
    return axios.get(base_api + '/plugin/tria_interface/tickets/' + id + '/get', params);
  },
  getAWSTickets: function (params={}) {
    return axios.get(base_api + '/plugin/tria_interface/tickets', params);
  }
}

export default Tickets;