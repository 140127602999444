import React, { Fragment } from "react";
import { useEffect, useState } from "react"

import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";

import AlertsService from '../../services/AlertsService';
import {Card, CardHeader, CardBody, CardTitle, ButtonGroup, Button} from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {appState} from "../../AppState";
import { useHistory } from "react-router-dom";
import moment from "moment";

const sortByStatus = (a, b) => {
  // Sort in order of New -> Acknowledged -> Closed
  switch (a) {
    case "Closed":
      if (b === "Closed") return 0;
      else return 1;
    case "Acknowledged":
      if (b === "Closed") return -1;
      if (b === "New") return 1;
      else return 0;
    case "New":
      if (b === "New") return 0;
      else return -1;
  }
}

const getAlertStatusCounts = (alerts) => {
  var statusCounts = {"Closed": 0, "Acknowledged": 0, "New": 0};
  for (var alert of alerts) {
    statusCounts[alert.state]++;
  }
  return statusCounts;
}

const AzureAlertsWidget = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [alertCounts, setAlertCounts] = useState({});
  const [allAlertsClosed, setAllAlertsClosed] = useState(true);
  const source = axios.CancelToken.source();
  const history = useHistory();
  const DATETIME_FORMAT = "YYYY-MM-DDThh:mm:ss.SSSZ", today = moment();

  useAsyncEffect(
    async isMounted => {
      try {
        let response = await AlertsService.getAzureAlerts();

        if (!isMounted()) return;
        let sortedAlerts = response.data.clouds_alerts_list
          .filter((alert) => {
            alert.dt = moment(alert.start_date_time, DATETIME_FORMAT)
            return !(alert.state === 'Closed' && today.diff(alert.dt, 'days') > 90)
          })
          .sort((a, b) => sortByStatus(a.state, b.state))
        setAlerts(sortedAlerts.slice(0, 3));
        setAlertCounts(getAlertStatusCounts(sortedAlerts));
        for (let alert of sortedAlerts) {
          if (alert.state !== "Closed") setAllAlertsClosed(false);
          break;
        }
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <h3 className="text-left">Azure Monitor</h3>
        {isLoading ? (
          <div className="text-center">
              <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
          </div>
          ) : (
          <>
            {alerts.length==0 ?
              <div className="w-100 justify-content-center">There are no Azure Monitor alerts available</div> :
            allAlertsClosed ? 
              <div className="w-100 justify-content-center">All Azure Monitor Alerts have Closed status</div> :
              <AzureAlertsCounts {...alertCounts}/>
            }
            <div className="table-responsive-sm">
            <table className="table">
              <tbody>
                {alerts.map((alert, i) => {
                  return (
                    <tr key={i}>
                      <td style={{width: "25px"}}>{getStatusIcon(alert.state)}</td>
                      <td>
                      <div className="col-lg" style={{}}>
                        <div className="row"><b className="pr-1">Cloud:</b>{alert.tria_cloud_name}</div>
                        <div className="row flex-nowrap d-flex text-truncate"><b className="pr-1">Threshold:</b>{alert.threshold}</div>
                      </div>
                      </td>
                    </tr>
                  )}
                )}
              </tbody>
            </table>
            </div>
          </>
        )}
      </div>
      
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push("/app/cloud/azure-alerts", {statusFilter: ""});
        }}
      >
        {" "}
        <span
          style={{ display: "inline", verticalAlign: "middle" }}
          className="fas fa-chart-line"
        ></span>{" "}
        View All
      </button>
    </div>
  );
}

const AzureAlertsCounts = (props) => {
  const history = useHistory();
  return (
    <div className="d-flex w-100" style={{padding: "12px", justifyContent: "space-evenly"}}>
      <i className="cursor fas fa-check text-success" style={{fontSize: "1rem"}}
        onClick={() => {history.push("/app/cloud/azure-alerts", {statusFilter: "Closed"}) }}> {props.Closed}
      </i>
      <i className="cursor fas fa-exclamation-triangle text-warning" style={{fontSize: "1rem"}}
        onClick={() => {history.push("/app/cloud/azure-alerts", {statusFilter: "Acknowledged"}) }}> {props.Acknowledged} 
      </i>
      <i className="cursor fas fa-bell text-danger" style={{fontSize: "1rem"}}
        onClick={() => {history.push("/app/cloud/azure-alerts", {statusFilter: "New"}) }}> {props.New}
      </i>
    </div>
  );
}

const getStatusIcon = (status) => {
  switch (status) {
    case 'Closed':
      return <i className="fas fa-check text-success"></i>;
    case 'Acknowledged':
      return <i className="fas fa-exclamation-triangle text-warning"></i>
    case 'New':
      return <i className="fas fa-bell text-danger"></i>
  }
}

export {AzureAlertsWidget}