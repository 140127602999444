import React from "react";
import { useState } from "react"

import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import useAsyncEffect from "../../utility/use-async-effect";

import TicketService from "../services/tickets";
import {Card, CardHeader, CardBody, CardTitle} from "reactstrap";
import {appState} from "../../AppState";
import {AWSTicketDetailBlade} from "./AWSTicketDetailBlade";


const AWSTickets = (props) => {
  const source = axios.CancelToken.source();
  const [stateApp, stateAppActions] = appState();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [tickets, setTickets] = useState([]);

  // Get AWS Support Tickets
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let response = await TicketService.getAWSTickets();
        setIsLoading(false);

        if (!isMounted()) return;
        setTickets(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  // https://boto3.amazonaws.com/v1/documentation/api/latest/reference/services/support.html#Support.Client.describe_cases
  const ticketColor = {
    'low': 'info',
    'normal': 'primary',
    'high': 'warning',
    'urgent': 'danger',
    'critical': 'danger',
  }
  const getTicketColor = (ticket) => {
    if (ticketColor.hasOwnProperty(ticket.severity)) {
      return 'badge-' + ticketColor[ticket.severity];
    }
    return 'badge-primary';
  }

  return (
    <>
      <Card className="card-minimal">
        <CardHeader className="card-header-minimal mb-2 d-flex justify-content-between flex-wrap">
          <CardTitle className="card-title-minimal" tag="h3" style={{fontSize: 2 + "rem"}}>
            AWS Support Tickets
          </CardTitle>
        </CardHeader>
        <CardBody>
          {isError ? (
            <div className="text-center text-bold">
              <i className="fas fa-exclamation-triangle"></i>
              Error loading AWS support tickets.
            </div>
          ) :
          isLoading ? (
            <div className="text-center">
              <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
            </div>
          ) :
          (!isLoading && !isError && tickets.length === 0) ? (
            <div className="text-center mt-4 text-bold"><i className="fas fa-exclamation-triangle"></i> No AWS support tickets found.</div>
          ) :
          <div className="table-responsive-sm">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Account</th>
                  <th>Subject</th>
                  <th>Severity</th>
                  <th>Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket, i) => {
                  return (
                  <tr key={i} onClick={() => {showTicketBlade(ticket, stateApp, stateAppActions)}}>
                    <td>{ticket.account_name}</td>
                    <td>{ticket.subject}</td>
                    <td><span className={ classNames('badge', 'badge-pill', getTicketColor(ticket)) }>{ticket.severity}</span></td>
                    <td>{moment(ticket.creation_date, "YYYY-MM-DDThh:mm:ssZ").fromNow()}</td>
                    <td>{ticket.status}</td>
                  </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
          }
        </CardBody>
      </Card>
    </>
  );
}

const showTicketBlade = (ticket, stateApp, stateAppActions) => {
  stateAppActions.setBlade({
    title: 'Ticket Details #' + ticket.case_id,
    visible: true,
    content: (<AWSTicketDetailBlade id={ticket.case_id} />)
  });
}

export { AWSTickets }
