/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import axios from "axios";
import AppLayout from "./layouts/App/App.jsx";
import LoginLayout from "./layouts/Login/Login.jsx";
import NotFound from "./components/NotFound";
import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import PrivateRoute from './utility/PrivateRoute';
import { buildRurl } from "./utility/misc"
import CommonService from "./services/CommonService"
import Maintenance from "./views/Maintenance.js";
//REDIRECT OLD SITE TO NEW - TEMP TILL WE FIND DNS
if(window.location.hostname.indexOf('tria-labs.connectria.com') > -1){
  window.location.hostname = 'next-gen.tria.connectria.com'
}

const hist = createBrowserHistory();
const ignoredRoutes = ["v3/auth/provider/local",".js","/login", "/tfa/verify", "/domain/organizations/get", "/cloud/all/cost/savings/", "/plugin/support/portal/","/v3/auth/initiate"];
let failedAttempts = 0;

setInterval(async () => {
  await HealthCheck() 
}, 10000);

async function HealthCheck(){
  let status = await CommonService.statusOk();
  if(failedAttempts == 2) window.location.href ="/maintenance"
  if(!status) failedAttempts++;
  if(status) failedAttempts = 0;
}

//REDIRECT OLD SITE TO NEW - TEMP TILL WE FIND DNS
if(window.location.hostname.indexOf('tria-labs.connectria.com') > -1){
  window.location.hostname = 'next-gen.tria.connectria.com'
}

  const initialState = {
    // theme: { primary: 'blue' },
    userInfo: null,
    authenticated: false
  };
  
  axios.interceptors.response.use(response => {
    return response;
 }, error => {

  console.log(error)
   if (typeof error.response != 'undefined' && error.response.status === 401) {
     if ((error.response != null) && (error.response.config != null)) {
        for (var i=0; i < ignoredRoutes.length; i++) {
          if (error.response.config.url.indexOf(ignoredRoutes[i]) > -1) return;
        }
     }
      //not on the login page, redirect
      console.log('unauthorized')
      window.location.href = `/login${buildRurl()}`;
   }
  //  else if( typeof error.response != 'undefined' && error.response.status === 503){
    
  //  }
  //  else if ( error.message === 'Network Error' && window.location.href.indexOf('maintenance') < 0) {
  //   window.location.href = `/maintenance`;
  // }
   throw error;
 });

ReactDOM.render(

  <Router history={hist}>
    <Switch>
    <Redirect exact from="/" to="/app/dashboard" />  
    <Route path="/login" exact render={props => <LoginLayout {...props} />} />
    <Route path="/maintenance" exact render={props => <Maintenance {...props} />} />
    <PrivateRoute path="/app/*" component={AppLayout} />
    <Route path="*" component={NotFound} /> 
    </Switch>
  </Router>

  ,
  document.getElementById("root")
);