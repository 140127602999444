import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const TrendMicroService = {
    
    checkSetup: function(params={}) {
      let request = axios.get(
        base_api + "/plugin/tria_interface/trendmicro/check/setup",
        params
        );
      return request;
    },
    computers: function(params ={}) {
      let request = axios.get(
        base_api + "/plugin/tria_interface/trendmicro/computers",
        params
        );
      return request;
    },
    antimalware: function(date, operator, max, params ={}) {
        let request = axios.post(
            base_api + "/plugin/tria_interface/trendmicro/antimalware",
            {
              dateToSearch: date,
              dateOperator: operator,
              maxItems: max,
              ...params
            }
            );
          return request;
      },
      integrity: function(date, operator, max, params ={}) {
        let request = axios.post(
            base_api + "/plugin/tria_interface/trendmicro/integrity",
            {
              dateToSearch: date,
              dateOperator: operator,
              maxItems: max,
              ...params
            }
            );
          return request;
      }
};

export default TrendMicroService;