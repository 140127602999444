import React from "react";
import { useState } from "react"

import axios from "axios";
import moment from "moment";
import useAsyncEffect from "../../utility/use-async-effect";

import TicketService from "../services/tickets";


const AWSTicketDetailBlade = (props) => {
  const source = axios.CancelToken.source();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [ticket, setTicket] = useState({communications: []});

  // Get AWS Support Tickets
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let response = await TicketService.getAWSTicket(props.id);
        setIsLoading(false);

        if (!isMounted()) return;
        setTicket(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  const showDate = (date) => {
    return moment(date, "YYYY-MM-DDThh:mm:ssZ").fromNow()
  }

  return (
    <>
      {isError ? (
        <div className="text-center text-bold">
          <i className="fas fa-exclamation-triangle"></i>
          Error Loading AWS support tickets.
        </div>
      ) :
      isLoading ? (
        <div className="text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
      ) :
      <div>
        <div className="ml-1 mb-2"><b>Subject:</b> {ticket.subject}</div>
        <div className="ml-1 mb-2"><b>Status:</b> <span className="text-capitalize">{ticket.severity}</span></div>
        <div className="ml-1 mb-2"><b>Reporter:</b> {ticket.submitted_by}</div>
        <div className="ml-1 mb-2"><b>Created:</b> {showDate(ticket.creation_date)}</div>
        <hr />
        <div className="ml-1 mb-2 communications">
          <b>Communications</b>
          <div>
            {ticket.communications.map((item, i) => {
              return (
                <div key={i}>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="font-weight-bold">{item.submittedBy}</div>
                    <div className="ml-3 text-muted">{ showDate(item.timeCreated) }</div>
                  </div>
                  <div style={{whiteSpace: 'break-spaces'}}>{item.body}</div>
                  <hr />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      }
    </>
  );
}

export { AWSTicketDetailBlade }
