import React, {useEffect} from "react";
import { useState } from "react"

import axios from "axios";
import moment from "moment";
import useAsyncEffect from "../../utility/use-async-effect";
import {Button, Card, CardHeader, CardBody, CardTitle, Input, Table} from "reactstrap";

import OpsService from "./OpsService";

const repeatOnce = 'once', repeatEvery = 'every'

const OpsConfigurationForm = (props) => {
  const source = axios.CancelToken.source();

  const DATE_FORMAT = 'YYYY-MM-DD', UI_DATE_FORMAT = 'MM/DD/YYYY',
    repeats = [
      {id: "once", name: 'once', icon: 'fa-clock'},
      {id: "every", name: 'every', icon: 'fa-redo'}
    ],
    types = [
      {id: "app", name: 'Select an Application', icon: 'fa-window-restore', description: 'Select your App/Service'},
      {id: "resource", name: 'Select a Resource', icon: 'fa-cloud', description: 'Select your Resource'},
    ]

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [step, setStep] = useState(1)
  const [type, setType] = useState('');
  const [trigger, setTrigger] = useState({
    action: 'restart',
    flow_id: null,
    model_id: null,
    period: 'day',
    repeat: 'every',
    start_date: moment().format(UI_DATE_FORMAT),
    value: '5:00',
    repeats: 1,
    no_end_date: false,

    minutes: '',
    hours: '',
    days: '',
    months: '',
    weekdays: '',
  })
  const [resource, setResource] = useState({})
  const [resources, setResources] = useState([])

  useAsyncEffect(
    async isMounted => {
      if (props.match.params?.id === 'new') return

      try {
        setIsLoading(true);
        let response = await OpsService.getTrigger(props.match.params.id)
        setIsLoading(false);
        if (!isMounted()) return;

        let trigger = response.data.trigger
        trigger.value = trigger.hours + ':' + trigger.minutes

        if (trigger.months === '*') {
          trigger.period = 'month'
          trigger.repeat = 'every'
        }
        if (trigger.days === '*') {
          trigger.period = 'day'
          trigger.repeat = 'every'
        }

        let weekdays = ''
        if (trigger.weekdays === '*') {
          weekdays = '1,2,3,4,5,6,0'
        }
        if (weekdays.indexOf('1')) trigger.monday = true
        if (weekdays.indexOf('2')) trigger.tuesday = true
        if (weekdays.indexOf('3')) trigger.wednesday = true
        if (weekdays.indexOf('4')) trigger.thursday = true
        if (weekdays.indexOf('5')) trigger.friday = true
        if (weekdays.indexOf('6')) trigger.saturday = true
        if (weekdays.indexOf('0')) trigger.sunday = true
        trigger.no_end_date = trigger.end_date === null

        trigger.start_date = moment(trigger.start_date, DATE_FORMAT).format(UI_DATE_FORMAT)
        if (trigger.end_date) trigger.end_date = moment(trigger.end_date, DATE_FORMAT).format(UI_DATE_FORMAT)

        setTrigger(trigger)
        setResource(response.data.trigger.model)
        setResources([response.data.trigger.model])
        setType('app')
        setStep(5)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        let response;

        switch (step) {
          case 2:
            response = await OpsService.listModels(type);
            break;
        }
        setIsLoading(false);
        if (!isMounted()) return;

        switch (step) {
          case 2:
            setResources(response.data.models)
            break;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    [step]
  );

  const getActionIcon = (action) => {
    let icon;
    switch (action) {
      case 'restart': icon = 'fa-sync-alt'; break;
      case 'start': icon = 'fa-play'; break;
      case 'stop': icon = 'fa-stop'; break;
      default: icon = 'fa-star'; break;
    }
    return icon;
  }

  const chooseType = (type) => {
    setType(type.id)

    // show next step
    setStep(2)
  }
  const chooseResource = (resource) => {
    let _trigger = {...trigger}
    _trigger.model_id = resource.id
    _trigger.flow_id = resource.flowId
    updateTrigger(_trigger)

    setResource(resource)

    // show next step
    setStep(3)
  }
  const setAction = (action) => {
    let _trigger = {...trigger}
    _trigger.action = action
    _trigger.period = 'day'
    _trigger.repeat = 'once'
    updateTrigger(_trigger)

    // show next step
    setStep(4)
  }
  const setRepeat = (repeat) => {
    updateTriggerField('repeat', repeat.id)

    // show next step
    setStep(5)
  }

  const updateTriggerField = (field, value) => {
    let _trigger = {...trigger}
    _trigger[field] = value
    updateTrigger(_trigger)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const updateTrigger = (trigger) => {
    let weekdays = []
    if (trigger.monday) weekdays.push('1')
    if (trigger.tuesday) weekdays.push('2')
    if (trigger.wednesday) weekdays.push('3')
    if (trigger.thursday) weekdays.push('4')
    if (trigger.friday) weekdays.push('5')
    if (trigger.saturday) weekdays.push('6')
    if (trigger.sunday) weekdays.push('0')
    trigger.weekdays = weekdays.join(',')
    if (trigger.weekdays === '1,2,3,4,5,6,0') trigger.weekdays = '*'

    let time = moment(trigger.value, 'HH:mm')
    trigger.hours = time.format('H')
    trigger.minutes = time.format('m')

    switch (trigger.period) {
      case 'day':
        trigger.days = '*'
        trigger.months = '*'
        trigger.weekdays = '*'
        break

      case 'month':
        trigger.days = trigger.day
        trigger.months = '*'
        break

      default:
        console.error('Period ' + trigger.period + ' does not support')
        break
    }

    setTrigger(trigger)
  }

  const save = () => {
    let trigger_data = {...trigger}

    trigger_data.start_date = moment(trigger_data.start_date, UI_DATE_FORMAT).format(DATE_FORMAT)
    if (trigger_data.end_date) {
      trigger_data.end_date = moment(trigger_data.end_date, UI_DATE_FORMAT).format(DATE_FORMAT)
    }

    console.log(trigger_data)

    OpsService.saveTrigger(trigger_data)
  }

  return (
    <>
      <Card className="card-white card-nextgen">
        <CardHeader>
          <CardTitle className="text-uppercase h4">
            How this works
          </CardTitle>
        </CardHeader>
        <CardBody>

        </CardBody>
      </Card>

      <Card className="card-white card-nextgen">
        <CardHeader>
          <CardTitle className="text-uppercase h4">
            1. Select an app/resource
          </CardTitle>
        </CardHeader>
        <CardBody>
          <p className="mb-2">Select a resource or application you would like to add automations to.</p>
          {types.map((type, i) => {
            return (
            <Button size="lg" key={i} color="primary" active={type === type.id} outline className={'mr-3'}
                    onClick={() => chooseType(type)}>
              <div className={'fas ' + type.icon} style={{fontSize: "30px"}} />
              <div className="my-2">{ type.name }</div>
              <div className="small">{ type.description }</div>
            </Button>
            )}
          )}
        </CardBody>
      </Card>

      {step > 1 ? <>
        <Card className="card-white card-nextgen">
          <CardHeader>
            <CardTitle className="text-uppercase h4">
              2. Select an application or service
            </CardTitle>
          </CardHeader>
          <CardBody>
            <p className="mb-2">Select a resource or application you would like to set up an automation for.</p>
            <Table>
              <thead>
                <tr>
                  <th width="5%">Cloud</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {resources.map((resource, i) => {
                  return (
                  <tr key={i} onClick={() => chooseResource(resource)} style={{cursor: 'pointer'}}>
                    <td><img src={`https://tria.connectria.com/divvy/img/${resource.cloud_type.toLowerCase()}-logo.png`} alt="" style={{ width: 25 + "px" }} /></td>
                    <td>{resource.name}</td>
                  </tr>
                  )}
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </> : ''}

      {step > 2 ? <>
        <Card className="card-white card-nextgen">
          <CardHeader>
            <CardTitle className="text-uppercase h4">
              2. Select an action
            </CardTitle>
          </CardHeader>
          <CardBody>
            <p className="mb-2">Select an action you would like to automate.</p>
            {resource.actions.map((action, i) => {
              return (
              <Button key={i} size="lg" className="mr-3" color="primary" outline active={trigger.action === action} onClick={() => setAction(action)}>
                <i className={'mr-4 fas ' + getActionIcon(action)} style={{fontSize: "30px"}} />
                { action.toUpperCase() }
              </Button>
              )}
            )}
          </CardBody>
        </Card>
      </> : ''}

      {step > 3 ? <>
        <Card className="card-white card-nextgen ops-form">
          <CardHeader>
            <CardTitle className="text-uppercase h4">
              4. Configure: your trigger
            </CardTitle>
          </CardHeader>
          <CardBody>
            <p className="mb-2">Select a time and repeat for your trigger.</p>
            {repeats.map((repeat, i) => {
              return (
              <Button key={i} size="lg" className="mr-3" color="primary" outline active={trigger.repeat === repeat.id} onClick={() => setRepeat(repeat)}>
                <i className={'mr-4 fas ' + repeat.icon} style={{fontSize: "30px"}} />
                { capitalizeFirstLetter(trigger.action)}&nbsp;{ repeat.name }
              </Button>
              )}
            )}

            {trigger.repeat === repeatOnce ? <>
              <div className="row mt-5">
                <div className="col-2">
                  <label>Date:</label>
                  <Input placeholder="11/22/2020" onBlur={(e) => updateTriggerField('start_date', e.target.value)} />
                </div>
                <div className="col-2 offset-2">
                  <label>Time:</label>
                  <Input placeholder="10:00" onBlur={(e) => updateTriggerField('start_date', trigger.start_date + ' ' + e.target.value)} />
                </div>
              </div>
            </> : <div>
              <div className="row my-3 mt-5">
                <div className="col-2">
                  <label>Time:</label>
                  <Input placeholder="6:00" value={trigger.value} onChange={(e) => updateTriggerField('value', e.target.value)} />
                </div>
                {trigger.period !== 'day' ? <div className="col-3 offset-2">
                    <label>Day:</label>
                    <Input placeholder="10" value={trigger.days} onChange={(e) => updateTriggerField('days', e.target.value)} />
                  </div> : ''
                }
              </div>
              <div className="row mt-5">
                <div className="col-3 pl-5 col-border-right">
                  <h5>Recurrence pattern</h5>
                  <div className="mb-3"><label className="text-dark"><input type="radio" name="period" value="daily" onChange={() => updateTriggerField('period', 'day')} checked={trigger.period === 'day'} /> Daily</label></div>
                  <div className="mb-3"><label className="text-dark"><input type="radio" name="period" value="weekly" onChange={() => updateTriggerField('period', 'week')} checked={trigger.period === 'week'} /> Weekly</label></div>
                  <div className="mb-3"><label className="text-dark"><input type="radio" name="period" value="monthly" onChange={() => updateTriggerField('period', 'month')} checked={trigger.period === 'month'} /> Monthly</label></div>
                  <div className="mb-3"><label className="text-dark"><input type="radio" name="period" value="yearly" onChange={() => updateTriggerField('period', 'year')} checked={trigger.period === 'year'} /> Yearly</label></div>
                </div>
                <div className="col-8 offset-1">
                  <h5>
                    Recurs every&nbsp;
                    <input value={trigger.repeats} size="3" onChange={(e) => updateTriggerField('repeats', e.target.value)} className="text-center" />
                    &nbsp;{trigger.period.toUpperCase()}(s) on:
                  </h5>
                  {trigger.period !== 'day' ? <div className="row mb-3">
                    <div className="col-2 mb-3">
                      <label className="text-dark">
                        <input type="checkbox" name="monday" checked={trigger.monday} onChange={(e) => updateTriggerField('monday', !trigger.monday)} /> Monday
                      </label>
                    </div>
                    <div className="col-2 mb-3">
                      <label className="text-dark">
                        <input type="checkbox" name="tuesday" checked={trigger.tuesday} onChange={(e) => updateTriggerField('tuesday', !trigger.tuesday)} /> Tuesday
                      </label>
                    </div>
                    <div className="col-2 mb-3">
                      <label className="text-dark">
                        <input type="checkbox" name="wednesday" checked={trigger.wednesday} onChange={(e) => updateTriggerField('wednesday', !trigger.wednesday)} /> Wednesday
                      </label>
                    </div>
                    <div className="col-2 mb-3">
                      <label className="text-dark">
                        <input type="checkbox" name="thursday" checked={trigger.thursday} onChange={(e) => updateTriggerField('thursday', !trigger.thursday)} /> Thursday
                      </label>
                    </div>
                    <div className="col-2 mb-3">
                      <label className="text-dark">
                        <input type="checkbox" name="friday" checked={trigger.friday} onChange={(e) => updateTriggerField('friday', !trigger.friday)} /> Friday
                      </label>
                    </div>
                    <div className="col-2 mb-3">
                      <label className="text-dark">
                        <input type="checkbox" name="saturday" checked={trigger.saturday} onChange={(e) => updateTriggerField('saturday', !trigger.saturday)} /> Saturday
                      </label>
                    </div>
                    <div className="col-2 mb-3">
                      <label className="text-dark">
                        <input type="checkbox" name="sunday" checked={trigger.sunday} onChange={(e) => updateTriggerField('sunday', !trigger.sunday)} /> Sunday
                      </label>
                    </div>
                  </div> : <></>}
                  <h5>Range of recurrence</h5>
                  <div className="row">
                    <div className="col-2">
                      <label>Start date:</label>
                      <Input placeholder="11/22/2020" value={trigger.start_date} onChange={(e) => updateTriggerField('start_date', e.target.value)} />
                    </div>
                    <div className="col-2 offset-2">
                      <label>End by:</label>
                      <Input placeholder="12/31/2023" value={trigger.end_date} disabled={trigger.no_end_date} onChange={(e) => updateTriggerField('end_date', e.target.value)} />
                    </div>
                    <div className="col-2 offset-1 pt-4">
                      <label><Input type="checkbox" checked={trigger.no_end_date} onChange={() => updateTriggerField('no_end_date', !trigger.no_end_date) } /> No end date</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </CardBody>
        </Card>
      </> : ''}

      {step === 5 ? <>
        <Card className="card-white card-nextgen">
          <CardHeader>
            <CardTitle className="text-uppercase h4">
              4. Confirm automation
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-4">
                <Button size="lg" color="primary" active outline>
                  <img src={`https://tria.connectria.com/divvy/img/${resource.cloud_type.toLowerCase()}-logo.png`} alt="" style={{ width: 25 + "px" }} />
                  <div className="my-2">{ resource.name }</div>
                  <div>{ capitalizeFirstLetter(trigger.action) }&nbsp;{ trigger.repeat }</div>
                </Button>
              </div>
              <div className="col-6">
                <Table width="100%" border="0" cellPadding="5">
                  <tbody>
                  <tr><th>Action</th><td>{ capitalizeFirstLetter(trigger.action) }</td></tr>
                  <tr><th>Name</th><td>{resource.name}</td></tr>
                  <tr><th>Cloud</th><td>{resource.cloud_type.toUpperCase()}</td></tr>
                  <tr><th>Triggered</th><td>{trigger.repeat.toUpperCase()}</td></tr>
                  <tr><th>Period</th><td>{capitalizeFirstLetter(trigger.period.toUpperCase()) }</td></tr>
                  <tr><th>At</th><td>{ trigger.value }</td></tr>
                  {trigger.period !== 'day' ? <tr><th>Day</th><td>{trigger.days}</td></tr> : ''}
                  <tr><th>Start date</th><td>{ trigger.start_date }</td></tr>
                  <tr><th>End date</th><td>{ trigger.end_date ? trigger.end_date : 'No end date' }</td></tr>
                  </tbody>
                </Table>
                <Button color="blue" className="collapse-button" onClick={() => { save(trigger) }}>
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-save my-auto pr-2"/>
                    <p className="my-auto tria-btn" style={{color: "#ffffff"}}>Save</p>
                  </div>
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </> : ''}
    </>
  );
}

export { OpsConfigurationForm }
