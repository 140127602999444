import axios from "axios";

const base_api = 'http://localhost:8001';


const OpsService = {
  listModels: async function (type) {
    return axios.get(base_api + '/plugin/tria_interface/ops/models?type=' + type)
  },
  getTrigger: async function (id) {
    return axios.get(base_api + '/plugin/tria_interface/ops/triggers/' + id)
  },
  listTriggers: async function () {
    return axios.get(base_api + `/plugin/tria_interface/ops/triggers`)
  },
  saveTrigger: async function (trigger) {
    if (trigger.id) {
      // update an exists trigger
      return axios.patch(base_api + '/plugin/tria_interface/ops/triggers/' + trigger.id, trigger)
    } else {
      // save new trigger
      return axios.post(base_api + '/plugin/tria_interface/ops/triggers', trigger)
    }
  }
}


export default OpsService