import React, {useEffect, useState, Suspense, lazy} from "react";
import {useHistory} from "react-router-dom";
import CostService from "../services/CostService";
import PublicCloudService from "../../public_cloud/services/service";
import AWSBusinessLevelSupportNotification from "../../utility/AWSBusinessLevelSupport"
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import {TriaSavingsRecommendor} from "../components/TriaSavingsRecommendor";
import {TrustedAdvisorRecs} from "../components/TrustedAdvisorRecs";
import {CostSavingsTable} from "../components/CostSavingsTable";
// core components
import BestPractices from "../components/BestPractices"
import Isotope from "isotope-layout/js/isotope";
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledCollapse,
  Button,
  ButtonGroup,
  CardTitle,
  TabContent,
  TabPane
} from "reactstrap";

import {appState} from "../../AppState";

import CostUtility from "../utilities/cost-utility";
import GenericBrand from "../../utility/GenericBrandText"

const CostSavingsDashboard = props => {
  let opportunityThreshold = null;
  let cloudTypes = [];
  let accountIds = [];
  if (props.location.state) {
    opportunityThreshold = props.location.state.savingOpportunity
    cloudTypes = props.location.state.savings.map((item) => {
      return item.cloud_type_id
    });
    accountIds = props.location.state.savings.map((item) => {
      return item.account_id
    });
  }
  const [savings, setSavings] = useState([]);
  const [bestPractices, setBestPractices] = useState({
    "": {results: [], cloud_type_id: ""}
  });
  const [stateApp, stateAppActions] = appState();
  const [isLoading, setIsLoading] = useState(true);
  const [awsAdvisorTabLoaded, setAwsAdvisorTabLoaded] = useState(false);
  const [azureAdvisorTabLoaded, setAzureAdvisorTabLoaded] = useState(false);
  const [hasCloudcheckrSavings, setHasCloudcheckrSavings] = useState(false);
  const [hasAwsAdvisorChecks, setHasAwsAdvisorChecks] = useState(false);
  const [hasAzureAdvisorChecks, setHasAzureAdvisorChecks] = useState(false);
  const [showBusinessSupportUpgrade, setShowBusinessSupportUpgrade] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  let urlParams = new URLSearchParams(props.location.search);
  let cloud_type = urlParams.get('cloud');
  let selected_tab;

  useEffect(() => {
    stateAppActions.setPageBackTitle(<SavingsBackButton></SavingsBackButton>);

  }, []);

  let useCache;
  if (stateApp.cache.costSavings.data.length > 0) {
    useCache = true;
  }

  useAsyncEffect(
    async isMounted => {
      try {
        let savings;
        if (props.location.state && props.location.state.savings) {
          savings = props.location.state.savings;
        } else {
          try {
            let clouds = await PublicCloudService.list_clouds();
            let cloud_ids = clouds.data.clouds.map(cloud => cloud.id);

            let savingsPromises = cloud_ids.map(id => CostService.cloudCostSavings(id));
            savings = await Promise.all(savingsPromises);

            let serviceCheckPromises = cloud_ids.map(id => CostService.cloudCostServiceChecks(id));
            let serviceChecks = await Promise.all(serviceCheckPromises);

            savings = savings.map((item, i) => {
              return {...item.data, service_checks: serviceChecks[i].data}
            });
          } catch (e) {
            savings = [];
            setShowBusinessSupportUpgrade(true);
          }
        }

        if (!isMounted()) return;

        savings.sort((a, b) => {
          if (a.cloud_name === undefined || b.cloud_name === undefined) {
            return 0
          }
          if (a.cloud_name.toLowerCase() < b.cloud_name.toLowerCase()) {
            return -1;
          } else if (a.cloud_name.toLowerCase() > b.cloud_name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        if (props.location.state) {
          savings = savings.filter((item) => {
            return cloudTypes.includes(item.cloud_type_id)
          });
          savings = savings.filter((item) => {
            return accountIds.includes(item.account_id);
          });
        }
        setSavings(savings);

        var hasCCRecs = findCloudcheckrSavings(savings);
        var hasAwsRecs = savings.filter((account) => { return account.cloud_type_id === "AWS" }).length !== 0
        var hasAzureRecs = savings.filter((account) => { return account.cloud_type_id === "AZURE_ARM" }).length !== 0

        switch (cloud_type) {
          case 'AWS':
            selected_tab = hasCCRecs ? "1" : "2";
            break;
          case 'AZURE_ARM':
            selected_tab = "3";
            console.log('im azure')
            break;
          default:
            if (hasCCRecs) {
              selected_tab = "1";
            } else if (hasAwsRecs) {
              selected_tab = "2";
            } else if (hasAzureRecs) {
              selected_tab = "3";
            } else {
              selected_tab = "0";
            }
            break;
        }
        setActiveTab(selected_tab)

        setHasCloudcheckrSavings(hasCCRecs);
        setHasAwsAdvisorChecks(hasAwsRecs);
        setHasAzureAdvisorChecks(hasAzureRecs);

        setIsLoading(false);

      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
    },
    []
  );


  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    if (activeTab === "2") {
      setAwsAdvisorTabLoaded(true);
    } else if (activeTab === "3") {
      setAzureAdvisorTabLoaded(true);
    }
  }

  const findCloudcheckrSavings = savings => {
    for (let account of savings) {
      if (!account.cost_savings) continue

      let keys = Object.keys(account.cost_savings);
      for (let key of keys) {
        if (Array.isArray(account.cost_savings[key]) && account.cost_savings[key].length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <>
      <h3 className="d-flex mb-0 mt-4 tria-header-class flex-wrap">
        Savings Opportunities
        <span className="d-flex">
          {(opportunityThreshold != null) &&
          <p className="mb-0" style={{paddingLeft: 5 + "px", paddingRight: 5 + "px"}}>{" - "}</p>}
          {(opportunityThreshold != null) && <p className={" mb-0 text-" + opportunityThreshold.color}>
            {opportunityThreshold.label}
          </p>}
          </span>
      </h3>
      <hr></hr>
      <ButtonGroup role="group" className="d-flex mb-4 flex-wrap justify-content-center">
        <Button onClick={() => toggleTab('1')} active={activeTab === '1'}
                className={`btn-sm w-100 ${hasCloudcheckrSavings ? "" : "d-none"} ${(activeTab !== '1') ? "btn-simple" : ""}`}
                style={{maxWidth: "190px", border: "unset"}}><GenericBrand>CloudCheckr</GenericBrand></Button>
        <Button onClick={() => toggleTab('2')} active={activeTab === '2'}
                className={`btn-sm w-100 ${hasAwsAdvisorChecks ? "" : "d-none"} ${(activeTab !== '2') ? "btn-simple" : ""}`}
                style={{maxWidth: "190px", border: "unset"}}>AWS Trusted Advisor</Button>
        <Button onClick={() => toggleTab('3')} active={activeTab === '3'}
                className={`btn-sm w-100 ${hasAzureAdvisorChecks ? "" : "d-none"} ${(activeTab !== '3') ? "btn-simple" : ""}`}
                style={{maxWidth: "190px", border: "unset"}}>Azure Trusted Advisor</Button>
      </ButtonGroup>
      {isLoading ?
        <div className="text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
        :
        <div className="d-flex w-100 justify-content-center">
          <TabContent activeTab={activeTab} id="tab-content" style={{width: 100 + '%'}}>
            <TabPane tabId="1">
              {(activeTab === "1") && <CostSavingsTable savings={savings} type={"cloudcheckr"}/>}
              {(activeTab === "1" && cloudTypes.includes("AWS") || !isLoading) && <BestPractices></BestPractices>}
            </TabPane>
            <TabPane tabId="2">
              {(activeTab === "2" || awsAdvisorTabLoaded) && <CostSavingsTable savings={savings} type={"aws"}/>}
            </TabPane>
            <TabPane tabId="3">
              {(activeTab === "3" || azureAdvisorTabLoaded) && <CostSavingsTable savings={savings} type={"azure"}/>}
            </TabPane>
          </TabContent>
        </div>
      }
      {((showBusinessSupportUpgrade && activeTab === "2") &&
        <AWSBusinessLevelSupportNotification></AWSBusinessLevelSupportNotification>)}
      {activeTab === "0" &&
      <div className="text-center mt-4 text-bold">No savings checks found.</div>
      }
    </>
  );
};

const SavingsBackButton = () => {
  const history = useHistory();
  return (
    <>
      <a href="#" onClick={() => {
        history.push("/app/cloud/cost/dashboard")
      }}>
        <div className="row text-primary">
          <i className="fas fa-chevron-left mr-1"
             style={{paddingRight: 2 + "px", marginTop: "auto", marginBottom: "auto"}}></i>
          <div className="">
            <div className="text-primary">Spend</div>
            <div className="text-primary" style={{marginTop: -0.25 + "rem"}}>Dashboard</div>
          </div>
        </div>
      </a>
    </>
  );
}


export default CostSavingsDashboard;
