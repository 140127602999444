import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";

import { appState } from "../../AppState";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const menuRef = useRef();
  const [stateApp, stateAppActions] = appState();
  const [visibleGroups, setVisibleGroups] = useState({});

  const handleOutsideClick = (e) => {
    const menu = menuRef?.current;

    if (isOpen && menu && !menu.contains(e.target)) {
      toggleSidebar(false)
    }
  };

  const toggleSubMenu = (submenu) => {
    if (!isOpen) {
      toggleSidebar();
    }
    setVisibleGroups(groups => ({ ...groups, [submenu]: !groups[submenu] }));
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setVisibleGroups({});
    }
  }, [isOpen]);

  return (
    <div
      ref={menuRef}
      className={`left-sidebar ${isOpen ? 'expanded' : ''} text-center py-3`}
    >
      <div className={`burger-container ${isOpen ? 'burger-change' : ''}`} onClick={toggleSidebar}>
        <div className="burger-bar1"></div>
        <div className="burger-bar2"></div>
        <div className="burger-bar3"></div>
      </div>
      <div className="sidebar-thumbnail d-flex align-items-center my-4">
        <div className="burger-thumbnail d-flex align-items-center justify-content-center flex-shrink-0 text-uppercase" role="button">
          {stateApp.userInfo?.name.substr(0, 2)}
        </div>
        <div className="ml-2 slidable">
          <div className="d-flex align-items-center">
            <p className="text-dark mb-0">Welcome</p>
            <i className="ml-1 fas fa-caret-down"></i>
          </div>
          <p className="text-truncate text-header m b small mb-0">{stateApp.userInfo?.name}</p>
        </div>
      </div>
      <div className="sidebar-item active" role="button">
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-th-large text-header" />
        </div>
        <p className="sidebar-item-text slidable">Overview</p>
      </div>
      <div className="sidebar-item" role="button" onClick={() => toggleSubMenu('clouds')}>
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-cloud text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Clouds</p>
      </div>
      <Collapse isOpen={!!visibleGroups.clouds}>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Cloud List</p>
        </div>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Cloud Resources</p>
        </div>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Resource Groups</p>
        </div>
      </Collapse>
      <div className="sidebar-item" role="button" onClick={() => toggleSubMenu('spend')}>
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-dollar-sign text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Spend</p>
      </div>
      <Collapse isOpen={!!visibleGroups.spend}>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Spend Dashboard</p>
        </div>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Spend By Resources</p>
        </div>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Spend By Tags</p>
        </div>
      </Collapse>
      <div className="sidebar-item" role="button" onClick={() => toggleSubMenu('security')}>
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-shield-alt text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Security</p>
      </div>
      <Collapse isOpen={!!visibleGroups.security}>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Anti Malware</p>
        </div>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Security Advisors</p>
        </div>
      </Collapse>
      <div className="sidebar-item" role="button" onClick={() => toggleSubMenu('compliance')}>
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-user-shield text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Compliance</p>
      </div>
      <Collapse isOpen={!!visibleGroups.compliance}>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Cloud Compliance</p>
        </div>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Compliance Reports</p>
        </div>
      </Collapse>
      <div className="sidebar-item" role="button" onClick={() => toggleSubMenu('automation')}>
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-magic text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Automation</p>
      </div>
      <Collapse isOpen={!!visibleGroups.automation}>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Bot Automation</p>
        </div>
      </Collapse>
      <div className="sidebar-item" role="button">
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-life-ring text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Ticketing</p>
      </div>
      <div className="sidebar-item" role="button">
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-phone-volume text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Contact Us
        </p>
      </div>
      <div className="sidebar-item" role="button" onClick={() => toggleSubMenu('settings')}>
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-cog text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Settings & Portals</p>
      </div>
      <Collapse isOpen={!!visibleGroups.settings}>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Portal</p>
        </div>
        <div className="sidebar-item" role="button">
          <div className="sidebar-item-icon"></div>
          <p className="sidebar-item-text slidable">Sky-Lift</p>
        </div>
      </Collapse>
      <div className="sidebar-item" role="button">
        <div className="sidebar-item-icon">
          <i className="fas fa-fw fa-user-friends text-header" />  
        </div>
        <p className="sidebar-item-text slidable">Accounts</p>
      </div>
      
    </div>
  )
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

export default Sidebar;
