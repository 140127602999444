import React from "react";
import { useState } from "react"

import moment from "moment";
import useAsyncEffect from "../../utility/use-async-effect";
import {Button} from "reactstrap";
import {useHistory} from "react-router-dom";

import OpsService from './OpsService'
import {appState} from "../../AppState";


const OpsList = (props) => {
  const [stateApp, stateAppActions] = appState();
  const history = useHistory(), DATE_FORMAT = 'ddd, MM/DD/YYYY', DATETIME_FORMAT = 'MM/DD/YYYY - hh:mm A'

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [items, setItems] = useState([]);

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let resp = await OpsService.listTriggers()
        setIsLoading(false);

        if (!isMounted()) return;
        setItems(resp.data.triggers)

      } catch (error) {
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      }
    },
    () => {},
    []
  );

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
          <h3 className="my-0 tria-header-class text-dark d-none d-md-block">
            Auto Ops
          </h3>
          <h5 className="my-0 text-info d-none d-md-block font-weight-bold">{items?.length || 0} Total</h5>
          <div className="d-flex align-items-center flex-fill flex-md-grow-0">
           <Button color="blue" className="collapse-button" onClick={() => { history.push('/app/ops/new')} }>
             <div className="d-flex justify-content-center">
               <i className="fas fa-plus my-auto pr-2"/>
               <p className="my-auto tria-btn" style={{color: "#ffffff"}}>Add new</p>
             </div>
           </Button>
         </div>
      </div>
      <div>
        {isError ? (
          <div className="text-center text-bold">
            <i className="fas fa-exclamation-triangle" />
            Error loading items list.
          </div>
        ) :
        isLoading ? (
          <div className="text-center">
            <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}} />
          </div>
        ) :
        (!isLoading && !isError && items.length === 0) ? (
          <div className="text-center mt-4 text-bold"><i className="fas fa-exclamation-triangle" /> No records found.</div>
        ) :
        <div className="table-responsive-sm">
          <table className="table devices-table no-border table-hover">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Name</th>
                <th className="text-center">Type</th>
                <th className="text-center">Operations</th>
                <th className="text-center">Start date</th>
                <th className="text-center">End date</th>
                <th className="text-center">Clouds</th>
                <th className="text-center">Actions</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, i) => {
                return (
                <tr key={i}>
                  <td className="text-center"><i className={'fas display-4 ' + (item.active ? 'fa-chevron-circle-down text-success' : 'fa-pause text-danger')} /></td>
                  <td><span className="text-dark">{item.model.name}</span><div className="small">#{item.id}</div></td>
                  <td className="text-center">{ capitalizeFirstLetter(item.action) }</td>
                  <td className="text-center">
                    <div className="text-dark">{item.last_run ? moment(item.last_run).format(DATETIME_FORMAT) : ''}</div>
                    <div className={'small ' + (item.active ? 'text-success': 'text-black-50')}>{ item.active ? 'Successful Run' : 'Failed Run'}</div>
                  </td>
                  <td className="text-center">{moment(item.start_date).format(DATE_FORMAT)}</td>
                  <td className="text-center">{item.end_date ? moment(item.end_date).format(DATE_FORMAT) : ''}</td>
                  <td className="text-center"><img src={`https://tria.connectria.com/divvy/img/${item.model.cloud_type.toLowerCase()}-logo.png`} alt="" style={{ width: 25 + "px" }} /></td>
                  <td className="text-center">
                    {item.active ? <a href="#"><i className="fas fa-pause text-danger" /></a> : <a href="#"><i className="fas fa-play text-success" /></a>}
                    <a href="#" onClick={() => { history.push('/app/ops/' + item.id); return false }}><i className="fas fa-trash-alt ml-3" /></a>
                  </td>
                  <td className="text-center"><a href="#" onClick={() => { history.push('/app/ops/' + item.id); return false }}>View details</a></td>
                </tr>
                )}
              )}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export { OpsList }
