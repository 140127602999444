import styled from "styled-components";

const getBackgroundColour = ({ primary, secondary, link }) => {
  if (primary) return `#0C618D`;
  return `transparent`;
};

const getLinkColour = ({ primary, secondary, link }) => {
  if (primary) return `white`;
  else if (link) return `#3ba2f7`;
  return `#3ba2f7`;
};

const getBorderColour = ({ secondary }) => {
  return `transparent`;
};

export const Button = styled.button`
  background: ${getBackgroundColour};
  color: ${getLinkColour};
  font-size: 14px;
  border-radius: 100px;
  min-width: 100px;
  border-color: ${getBorderColour};
  height: 40px;
  padding: 10px;
  &:hover {
    ${props => props.link && `text-decoration: underline;`};
  }
`;
