import React, { useState }from "react";
import {Card, CardHeader, CardBody, CardTitle, ButtonGroup, Button} from "reactstrap";
import { appState } from "../AppState";
import { NewTicketForm } from "../components/ConnectriaTickets/ConnectriaTicketsSupportDashboard";
import Enum from "./enum.js";
import useAsyncEffect from "./use-async-effect";
import axios from "axios/index";
import OrganizationService from "../services/OrganizationService";

const AWSBusinessLevelSupportNotification = (props) => {
  //global state
  const [stateApp, stateAppActions] = appState();
  const [customer_type, setCustomerType] = useState('MSP')
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      
        try {

            let customer_type = await OrganizationService.customer_type();
            
            if (!isMounted()) return;
            setCustomerType(customer_type.data?.customer_type)
        } catch (error) {
            if (axios.isCancel(error)) {
                // request cancelled
            } else {
                throw error;
            }
        }
    },
    () => {
        source.cancel();
    },
    []
);

  const createNewTicket = (e, stateAppActions) => {
    if (e != null) {
      e.preventDefault();
    }
    stateAppActions.setBlade({
      title: "Create New Ticket",
      visible: true,
      content: (
        <NewTicketForm
          closeOnSuccess={true}
          subject={"Please Upgrade Our AWS Account To Bussiness Level Support."}
          description={"Please Upgrade Our AWS Account To Bussiness Level Support."}
          attachmentElementId={""}
          deviceId={''}
          category={'AWS'}
          type={5}
          subCategory={325}
        ></NewTicketForm>
      ),
    });
  };
  return (
    <>
      { (customer_type != 'MSP') && (
          <div className="row">
              <div className="col-lg-4 offset-md-4 col-sm-12">
        <Card className="card-minimal">
          <CardHeader className="card-header-minimal">
            <h5 className="card-category"></h5>
            <CardTitle className="card-title-minimal" tag="h3">
            <i className="fa fa-exclamation-triangle warning" aria-hidden="true"></i> AWS Business Level Support Required
            </CardTitle>
          </CardHeader>
          <CardBody><p>In order to activate this capability please <a onClick={(e) => createNewTicket(e, stateAppActions)}href="#">create a ticket</a> to upgrade to Business Level Support.</p></CardBody>
        </Card>
        </div>
        </div>
      )}
    </>
  );
};

export default AWSBusinessLevelSupportNotification;
