import React, { useState } from "react";
import CostService from "../services/CostService";

import useAsyncEffect from "../../utility/use-async-effect";
import { range } from '../../utility/misc';
import axios from "axios";
// core components
import Isotope from "isotope-layout/js/isotope";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import GenericBrand from "../../utility/GenericBrandText"
import { appState } from "../../AppState";
import PublicCloudService from "../../public_cloud/services/service";


function makeid(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const getIcon = item => {
  var textColorClass, iconName;
  var style = {}
  if (item.Results.length > 0) {
    if (item.Importance == "Low") {
      textColorClass = "Low";
      iconName = "info-circle";
    } else if (item.Importance == "Medium") {
      textColorClass = "Medium";
      iconName = "exclamation-triangle";
    } else if (item.Importance == "Informational") {
      textColorClass = "Informational";
      iconName = "info-circle";
    } else if (item.Importance == "High") {
      textColorClass = "High";
      iconName = "exclamation-circle";
    }
    if (textColorClass != "Medium") {
      style.fontSize = "1rem";
    }
    return  <i className={`my-auto fas fa-${iconName} bp-text-${textColorClass}`} style={style}></i>;

  } else {
    return <i className="my-auto fas fa-check bp-text-Addressed" ></i>;
  }
};
const getUnorderedList = item => {
  let items = item.split("|");
  let html = [];

  for (var i = 0; i < items.length; i++) {
    html.push(<li>{items[i]}</li>);
  }
  return html;
};

const BestPractiseNotificationBlade = item => {
  return (
    <>
      <p>Overview:</p>
      <div dangerouslySetInnerHTML={{ __html: item.LongDescription }}></div>
      {item.Results.map((r, i) => {
        return (
          <>
            <hr />
            <ul>{getUnorderedList(r)}</ul>
            <hr />
          </>
        );
      })}
    </>
  );
};
const BestPractices = props => {
    let filterOptions = [
      { name: "All", value: "All", color: "" },
      { name: "Informational", value: "Informational", color: "Informational" },
      { name: "Low", value: "Low", color: "Low" },
      { name: "Medium", value: "Medium", color: "Medium" },
      { name: "High", value: "High", color: "High" },
      { name: "Addressed", value: "Addressed", color: "Addressed"}
    ];
  
    const toggle = item => {
      switch (item) {
        case "filter":
          setDropdownOpen(!dropdownOpen);
          break;
        default:
          break;
      }
    };
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [stateApp, stateAppActions] = appState();
    const [isotope, setIsotope] = React.useState(null);
    // store the filter keyword in another state
    const [importance, setImportance] = React.useState("All");
 
    const [bestPractices, setBestPractices] = useState({
      "": { results: [], cloud_type_id: "" }
    });
    const [filteredBestPractices, setFilteredBestPractices] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownAccountOpen, setDropdownAccountOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState("");
    const [statusType, setStatusType] = useState("");
    const [statusTypeNums, setStatusTypeNums] = useState({});
    const [pagesCount, setPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginationRange, setPaginationRange] = useState([]);

    //pagination offset
    const offset = 10;

    function showDashboardBlade(item) {
      stateAppActions.setBlade({
        visible: true,
        title: item.Name,
        content: BestPractiseNotificationBlade(item)
      });
    }
    function createBestPractiseObject(data) {
      let obj = {};
      for (var i = 0; i < data.length; i++) {
        if (
          (data[i].cloud_type_id.indexOf("AWS") > -1 && data[i].best_practice) ||
          {}.BestPracticeChecks ||
          [].length > 0
        ) {
          obj[data[i].cloud_name] = {
            results: (data[i].best_practice?.BestPracticeChecks ||[]).filter(
              x => x.Category == "Cost"
            ).sort((a, b) =>
              a.Results.length > b.Results.length
                ? -1
                : 1 || a.Importance < b.Importance
            ),
            cloud_type_id: data[i].cloud_type_id
          };
          for (var bp of obj[data[i].cloud_name].results) {
            if (bp.Results.length == 0) {
              bp.Importance = "Addressed"
            }
          }
        }
      }
      return obj;
    }
    function toggleStatusType(status) {
      if (status == statusType) {
        setStatusType("");
      } else {
        setStatusType(status);
      }
    }
    function countStatusTypeNums(bestPractices, priority) {
      let statusTypeNums = {Addressed: 0, Minor: 0, Major: 0};
      for (var item of bestPractices) {
        if (priority == "") {
          statusTypeNums[getStatusType(item)]++;
        } else if (item.Importance == priority) {
          statusTypeNums[getStatusType(item)]++;
        }
      }
      return statusTypeNums;
    }
    function getStatusType(item) {
      if (item.Results.length == 0) {
        return "Addressed";
      } else {
        if ((item.Importance == "Low" || item.Importance == "Medium" || item.Importance == "Informational")) {
          return "Minor";
          // Importance == "High"
        } else {
          return "Major";
        }
      }
    }
    useAsyncEffect(
      async isMounted => {
        try {
          setIsLoading(true);

          let cloudAccounts = stateApp.cloudAccounts;

          // load clouds if empty
          if (cloudAccounts.length === 0) {
            let clouds = await PublicCloudService.list_clouds();
            cloudAccounts = clouds.data.clouds;
          }
          const accountIds = cloudAccounts.map((account) => {
            return account.account_id;
          })
          let best_practices = await CostService.bestPractices(accountIds);
         
          if (!isMounted()) return;
          let best_practice_result = createBestPractiseObject(best_practices.data);
          setSelectedAccount(Object.keys(best_practice_result)[0]);
          setBestPractices(best_practice_result);
          setIsLoading(false)
          setIsotope(
            new Isotope(".filter-container", {
              itemSelector: ".filter-item",
              masonry: {
                columnWidth: ".grid-sizer"
              },
              layoutMode: "fitRows"
            })
          );
        } catch (error) {
          setIsLoading(false);
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            throw error;
          }
        }
      },
      () => {},
      []
    );

    const setPagination = (data) => {
      let numPages = Math.ceil(data.length / offset);
      setPagesCount(numPages);
      if (numPages >= 5) {
        setPaginationRange(range(0,5));
      } else {
        setPaginationRange(range(0,numPages));
      }
      setCurrentPage(0);
    }

    const handlePageClick = (e, index) => {
      e.preventDefault();
      setCurrentPage(index);
      if (index > 2 && index < pagesCount-2) {
        setPaginationRange(range(index-2, index+3));
      } else if (index <= 2) {
        if (pagesCount <= 5) {
          setPaginationRange(range(0, pagesCount));
        } else {
          setPaginationRange(range(0, 5));
        }
      } else {
        setPaginationRange(range(pagesCount-5, pagesCount));
      }
    }

    React.useEffect(() => {
      //setStatusTypeNums(countStatusTypeNums(bestPractices[selectedAccount].results, importance));
      let filteredData = (bestPractices[selectedAccount] || { results: [] }).results;
      if (importance != "All") {
        filteredData = filteredData.filter((item) => {return item.Importance == importance});
      }
      setPagination(filteredData);
      setFilteredBestPractices(filteredData);
    }, [importance, selectedAccount, bestPractices, statusType]);

    return (
      <>
 
        <div
          className={
            (bestPractices[selectedAccount] || { results: [] }).results.length > 0
              ? "row"
              : "d-none"
          }
        >
          <div className="col-12">
            <>
              <div className="row mt-3">
              <h3 className="col-lg-4 mb-0 tria-sub-header">
              <GenericBrand>CloudCheckr</GenericBrand> Best Practices
              </h3>
              <div className="col-lg-4">
            <Dropdown
              isOpen={dropdownAccountOpen}
              toggle={() => setDropdownAccountOpen(!dropdownAccountOpen)}
              className="bp-account-filter mt-1"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownAccountOpen}
              >
                <React.Fragment>
                  <h4 className="mb-0">
                    <img
                      height="25"
                      width="25"
                      src={`https://tria.connectria.com/divvy/img/${(
                        bestPractices[selectedAccount] || { cloud_type_id: "" }
                      ).cloud_type_id.toLowerCase()}-logo.png`}
                    />{" "}
                    <span>{selectedAccount}</span>{" "}
                    <i className="fas fa-caret-down"></i>
                  </h4>
                </React.Fragment>
              </DropdownToggle>
              <DropdownMenu>
                {Object.keys(bestPractices).map((item, i) => {
                  return (
                    <DropdownItem
                      onClick={() => {
                        setSelectedAccount(item);
                      }}
                      key={i}
                    >
                      <h5>
                        <img
                          height="20"
                          width="20"
                          src={`https://tria.connectria.com/divvy/img/${(
                            bestPractices[selectedAccount] || {
                              cloud_type_id: ""
                            }
                          ).cloud_type_id.toLowerCase()}-logo.png`}
                        />{" "}
                        <span>{item}</span>{" "}
                      </h5>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            </div>
                <div className="col-lg-4">
                <Dropdown
                  className="bp-importance-filter pr-4 mt-1"
                  isOpen={dropdownOpen}
                  toggle={() => toggle("filter")}
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                  >
                    <React.Fragment>
                      <h4 className="mb-0">
                        Filter Tool:
                        <span style={{color: "rgba(34, 42, 66, 0.7)"}}>
                        {" " + selectedFilter}{" "}
                        </span>
                        <i className="fas fa-caret-down"></i>
                      </h4>
                    </React.Fragment>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {filterOptions.map(function(item, idx) {
                      return (
                        <DropdownItem
                          onClick={e => {
                            setImportance(item.value);
                            setSelectedFilter(item.name);
                          }}
                          key={idx}
                        >
                          <span className={"pr-2 bp-" + item.color}>&nbsp;</span>
                          {item.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
                </div>
                </div>
              <hr className="mt-2"/>
            </>
          </div>
        </div>
        <div
          className={
            (bestPractices[selectedAccount] || { results: [] }).results.length > 0
              ? "row"
              : "d-none"
          }
        >
            
            {/*<div className="col-lg-4">
              <ButtonGroup size="sm" className="d-flex justify-content-center" role="group" style={{paddingBottom: 12+"px"}}>
                <Button 
                  onClick={() => {toggleStatusType("Addressed")}}
                  className={`bm-status-btn btn-sm w-100 ${(statusType !== "Addressed") ? "btn-simple" : ""}`} style={{maxWidth:"130px", border:"unset"}}
                >
                  <div className="row justify-content-center fas">
                  <i className="text-success fas fa-check mr-1"></i>
                  {` Addressed (${statusTypeNums.Addressed})`}
                  </div>
                </Button>
                <Button 
                  onClick={() => {toggleStatusType("Minor")}}
                  className={`bm-status-btn btn-sm w-100 ${(statusType !== "Minor") ? "btn-simple" : ""}`} style={{maxWidth:"125px", border:"unset"}}
                >
                  <div className="row justify-content-center fas">
                  <i className="text-warning fas fa-exclamation-triangle mr-1"></i>
                  {` Minor (${statusTypeNums.Minor})`}
                  </div>
                </Button>
                <Button 
                  onClick={() => {toggleStatusType("Major")}}
                  className={`bm-status-btn btn-sm w-100 ${(statusType !== "Major") ? "btn-simple" : ""}`} style={{maxWidth:"125px", border:"unset"}}
                >
                  <div className="row justify-content-center fas">
                  <i className="text-danger fas fa-exclamation-circle mr-1"></i>
                  {` Major (${statusTypeNums.Major})`}
                  </div>
                </Button>
              </ButtonGroup>
              </div>*/}
        </div>
        { isLoading ?     <div className="text-center">
                <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
            </div> :
            <>
            <Card className="card-minimal mt-1" >
        <CardBody>
          <div className="table-responsive-sm">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody >
                {filteredBestPractices.slice(currentPage * offset, (currentPage+1) * offset).map( (item, i) => {
                  return (
                  <tr key={i} style={{cursor: "pointer"}}>
                    <td style={{width: "150px"}}>
                      <span className="row">
                        {getIcon(item)}{" "}
                        <p className={"ml-1 mb-0 bp-text-" + item.Importance}>{item.Importance}</p>
                      </span>
                    </td>
                    <td>
                      <b>{item.Name}</b>{" "}
                      {item.ShortDescription}
                    </td>
                    <td style={{width: "125px"}}>
                      <span style={{color: "#0098f0"}} onClick={() => showDashboardBlade(item)}>
                        View Details
                      </span>
                    </td>
               </tr>
                  )}
                )}
              </tbody>
            </table>
              <div className="pt-3 d-flex w-100 justify-content-center">
                <Pagination>
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink first href="#" onClick={(e) => handlePageClick(e, 0)}/>
                  </PaginationItem>
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink previous href="#" onClick={(e) => handlePageClick(e, currentPage-1)}/>
                  </PaginationItem>
                  {paginationRange.map((page, i) => (
                  <PaginationItem active={page === currentPage} key={page}>
                    <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
                      {page + 1}
                    </PaginationLink>
                  </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= pagesCount-1}>
                    <PaginationLink next href="#" onClick={(e) => handlePageClick(e, currentPage+1)}/>
                  </PaginationItem>
                  <PaginationItem disabled={currentPage >= pagesCount-1}>
                    <PaginationLink last href="#" onClick={(e) => handlePageClick(e, pagesCount-1)}/>
                  </PaginationItem>
                </Pagination>
            </div>
          </div>
          </CardBody>
          </Card>
            </>
        }
        {/*<div className="filter-container">
            <div className="row">
            {(bestPractices[selectedAccount] || { results: [] }).results.map(
              (item, i) => {
                let id = makeid(5);
                return (
                  <>
                    <div  key={i} className="grid-sizer col-lg-4"></div>
                    <div
                      className={
                        "col-lg-4 col-sm-12 mb-2  filter-item " + item.Importance + " " + getStatusType(item)
                      }
                    >
                      <Card
                        style={{ minHeight: 170 + "px" }}
                        className={"card-minimal mt-1 bp-" + item.Importance}
                      >
                        <CardBody>
                          <h3 style={{fontSize: "1.25rem"}}>
                            {item.Name}
                            //getIcon(item)
                          </h3>
                          <p style={{fontSize: "1.0rem"}}>{item.ShortDescription}</p>
                        </CardBody>
                        <div className="p-3 d-flex justify-content-between">
                          <span className="row mb-0">
                            //<p className="pr-1 my-auto">Priority:</p>
                            <p className={"mb-0 pr-1 bp-text-" + item.Importance} style={{fontSize: "1.25rem"}}>{item.Importance}</p>
                            <span className="my-auto">{getIcon(item)}</span>
                          </span>
                          <span className="row my-auto cursor" onClick={() => showDashboardBlade(item)}>
                            <p 
                              className="mb-0" 
                              style={{color: "#0098f0", fontSize: "1rem"}}>
                                {"View Details "}
                            </p>
                            //<i
                              //id={id}
                              //className="fas fa-info-circle cursor pl-1 pt-1"
                              //style={{color: "#0098f0"}}
                            //></i>
                          </span>
                        </div>
                      </Card>
                    </div>
                  </>
                );
              }
            )}
          </div>
            </div>*/}
        {/*<fieldset
          className={
            (bestPractices[selectedAccount] || { results: [] }).results.length > 0
              ? ""
              : "d-none"
          }
        >
          <legend>Legend</legend>
          <i className="fas fa-exclamation-triangle text-warning"></i> *Items
          Requiring Attention |&nbsp;<i className="fas fa-check text-success"></i>{" "}
          *Optimized | <i className={"fas fa-arrow-circle-down"}></i> *View
          Details
        </fieldset>*/}
      </>
    );
  };
  
  export default BestPractices;
  
