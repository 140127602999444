import React from "react";
import { useState } from "react";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
import useTriaTicketsState from "./ConnectriaTicketsState";
import { appState } from "../../AppState";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import { Button, Row, Col, Badge, ButtonGroup, Tooltip, Label } from "reactstrap";
import { CardBody, Card, CardHeader, CardTitle } from "reactstrap";
import { BladeTabs, EditTicketSubject } from "./ConnectriaTicketsBlade";
import { isMobile, BrowserView, MobileOnlyView } from "react-device-detect";
import { useHistory } from "react-router-dom";


const TicketsBackButton = () => {
  const history = useHistory();
  return (
    <>
      <a href="#" onClick={() => {history.push("/app/support")}}>
        <div className="row text-primary">
          <i className="fas fa-chevron-left mr-1" style={{paddingRight: 2 + "px", marginTop: "auto", marginBottom: "auto"}}></i>
          <div className="">
            <div className="text-primary">Support & Ticketing</div>
  
          </div>
        </div>
      </a>
    </>
  );
}

const ConnectriaTicketFullPage = (props) => {
  //plugin state
  const [state, stateActions] = useTriaTicketsState();
  const [stateApp, stateAppActions] = appState();
  //local state
  const [isLoading, setIsLoading] = useState(true);
  const [detail, setDetail] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [errMsg, setErrMsg] = useState();
  const [reloadDetail, setReloadDetail] = useState(true);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const source = axios.CancelToken.source();
  useAsyncEffect(
    async (isMounted) => {
      try {
        if (!reloadDetail) return;
        console.log("loading ticket details");
        document.title = "Ticket ID#: " + props.match.params.id;
        stateAppActions.setPageBackTitle(<TicketsBackButton></TicketsBackButton>);
        setIsLoading(true);
        try {
          setDetail(
            await ConnectriaTicketsService.ticketDetail(props.match.params.id, {
              cancelToken: source.token,
            })
          );
        } catch (error) {
          setErrMsg(
            <div className="text-center mt-4 text-bold">
              <i className="fas fa-exclamation-triangle"></i> Error pulling
              ticket #{props.match.params.id}
            </div>
          );
        }
        if (!isMounted()) return;

        setReloadDetail(false);
        setIsLoading(false);
        stateActions.setCommentText("");
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [reloadDetail]
  );
  if (isLoading) {
    return (
      <div className="text-center">
        <i
          className="fas fa-spinner m-3 mt-4 fa-spin"
          style={{ fontSize: "30px" }}
        ></i>
      </div>
    );
  } else if (errMsg != null) {
    return errMsg;
  } else {
    return (
      <>
        {isMobile && (
          <div className="connectria-tickets-detail-blade h-100 d-flex flex-column">
            <EditTicketSubject detail={detail} id={props.match.params.id} setReloadDetail={setReloadDetail} />
            <ButtonGroup
              className="d-flex mb-2 justify-content-center"
              role="group"
            >
              <Button
                onClick={() => toggleTab("1")}
                active={activeTab === "1"}
                className={`btn-sm w-100 h-100 ${
                  activeTab !== "1" ? "btn-simple" : ""
                }`}
                style={{ border: "unset", padding: "5px 10px" }}
              >
                Details
              </Button>
              <Button
                onClick={() => toggleTab("2")}
                active={activeTab === "2"}
                className={`btn-sm w-100 h-100 ${
                  activeTab !== "2" ? "btn-simple" : ""
                }`}
                style={{ border: "unset", padding: "5px 10px" }}
              >
                Comments
              </Button>
              <Button
                onClick={() => toggleTab("3")}
                active={activeTab === "3"}
                className={`btn-sm w-100 h-100 ${
                  activeTab !== "3" ? "btn-simple" : ""
                }`}
                style={{ border: "unset", padding: "5px 10px" }}
              >
                Files{" "}
                <Badge
                  color="secondary"
                  style={{ float: "right", top: "unset" }}
                >
                  {detail.ticket_attachments.length}
                </Badge>
              </Button>
            </ButtonGroup>
            <BladeTabs
              activeTab={activeTab}
              detail={detail}
              setReloadDetail={setReloadDetail}
              state={state}
              history={history}
              actions={stateActions}
              fullPage={true}
            ></BladeTabs>
          </div>
        )}

        <BrowserView>
          <div className="d-none d-sm-block">
            <h3 className="mb-3 mt-4 tria-header-class">
              Ticket#: {props.match.params.id}
            </h3>
            <hr></hr>
            <Row>
              <Col
                md={{ size: 4 }}
                lg={{ size: 4 }}
              >
                <Card className="card-minimal mb-0">
                  <CardHeader className="card-header-minimal">
                    <CardTitle className="card-title-minimal" tag="h3">
                      <EditTicketSubject detail={detail} id={props.match.params.id} />
                    </CardTitle>
                  </CardHeader>
                  <CardBody
                    id="connectria-support-new-ticket-body"
                    className="d-flex flex-column"
                  >
                    <div className="connectria-tickets-detail-blade d-flex flex-column">
                      <BladeTabs
                        activeTab={1}
                        detail={detail}
                        setReloadDetail={setReloadDetail}
                        state={state}
                        history={history}
                        actions={stateActions}
                        showCommentsMsg={false}
                        fullPage={true}
                      ></BladeTabs>
                    </div>
                    {detail.ticket_attachments.length > 0 && (
                       <div className="connectria-tickets-detail-blade h-100 d-flex flex-column">
                         <hr></hr>
                         <Label><strong>Ticket Attachments</strong></Label>
                       <BladeTabs
                         activeTab={3}
                         detail={detail}
                         setReloadDetail={setReloadDetail}
                         state={state}
                         history={history}
                         actions={stateActions}
                       ></BladeTabs>
                       </div>
                       )}
                  </CardBody>
                </Card>
              </Col>
              <Col
                md={{ size: 8 }}
                lg={{ size: 8 }}
              >
                <Card className="card-minimal mb-0 h-100">
                  <CardBody
                    id="connectria-support-new-ticket-body"
                    className="d-flex flex-column"
                  >
                    <div className="connectria-tickets-detail-blade h-100 d-flex flex-column">
                      <BladeTabs
                        activeTab={2}
                        detail={detail}
                        setReloadDetail={setReloadDetail}
                        state={state}
                        history={history}
                        actions={stateActions}
                        showCommentsMsg={false}
                      ></BladeTabs>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </BrowserView>
      </>
    );
  }
};

export { ConnectriaTicketFullPage };
