import React from "react";
import { Table } from 'reactstrap';
import { Input, Label, Button} from "reactstrap";

import axios from "axios/index";
import useAsyncEffect from "../../utility/use-async-effect";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { appState } from "../../AppState";
import CommonService from "../../services/CommonService";

const AddACloud = props => {
    const [stateApp, stateAppActions] = appState();
    const history = useHistory();
    const source = axios.CancelToken.source();
    const saasUpdate = "https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?stackName=TRiARole&templateURL=https://dev-tria-crossaccount-onborading-role.s3.amazonaws.com/Tria-SaaS-And-Update-Role.yml";
    const saasOnly = "https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?stackName=TRiARole&templateURL=https://dev-tria-crossaccount-onborading-role.s3.amazonaws.com/Tria-SaaS-Role-only.yml";
    const orgResId = "divvyorganization:" + stateApp.userInfo.organization_id;
    const [state, setState] = useState({cftUrl: ''});
    const [saaSRoleARNID, setSaaSRoleArn] = useState("");
    const [cloudName, setCloudName] = useState("");

    useAsyncEffect(
        async isMounted => {
            const env = {
                env: 'dev',
                sourceAccountId: ''
            }
            env['env'] === 'dev' ? env['sourceAccountId'] = '447323267748' : env['sourceAccountId'] = '483114006085';
            console.log(env['env'])
            const externalID = stateApp.userInfo.organization_name.replace(/ /g,"_") + Math.floor(Math.random() * 100001);
            let restCftUrl = "&param_ExternalId=" + externalID + "&param_SourceAccountId=" + env['sourceAccountId'];
            console.log(saasUpdate + restCftUrl)
            try {
                setState(values => ({...values, cftUrl: saasOnly + restCftUrl, externalID: externalID}));
                let resProps = await CommonService.getResProperties(orgResId, {cancelToken: source.token});
                resProps.data.forEach(res => {
                    if (res['name'] == 'saas_policy_update' && res['value']) {
                        setState(values => ({...values, cftUrl: saasUpdate + restCftUrl,externalID: externalID}));
                    }

                })
                if (!isMounted()) return;
            } catch (error) {
                if (axios.isCancel(error)) {
                    // request cancelled
                } else {
                    throw error;
                }
            }
        },
        () => {
            source.cancel();
        },
        []
    );
     const AddACloud = async event => {
        event.preventDefault();
        try {
            let newCloud = await
                CommonService.addCloud({
                    "creation_params":{
                        'cloud_type': "AWS",
                        'authentication_type': "instance_assume_role",
                        'session_name': "TRiA",
                        'role_arn': saaSRoleARNID,
                        'name': cloudName,
                        'account_number': saaSRoleARNID.substring(13, 25),
                        'external_id': state.externalID,
                        'strategy_id': 862
                }},{cancelToken: source.token});
            let resProp = await
                CommonService.setResProperty({
                    'type':'string',
                    'name':'saas-role',
                    'resource_id':newCloud.data['resource_id'],
                    'value':saaSRoleARNID},{cancelToken: source.token});
            let resProp2 = await
                CommonService.setResProperty({'type':'string',
                'name':'external-id',
                'resource_id':newCloud.data['resource_id'],
                'value': state.externalID},{cancelToken: source.token});
        }catch(error){
            if (axios.isCancel(error)) {
                    // request cancelled
            } else {
                    throw error;
            }
        }
    }


    return (
        <>
            <ol>
                <li>Launch&nbsp;<a target="_blank" href={state.cftUrl}>
                    CloudFormation Stack link.
                </a>
                </li>
                <li>Type a new name for your stack and Donot modify the values provided for External ID and source
                    account ID
                </li>
                <li>Select the type of role you would like to create. <strong>Standard</strong> user would have read
                    only permissions. <strong>Power</strong> user would have all the permissions for the rresources
                    mentioned in the IAM policy
                </li>
                <li>Select the following checkbox:
                    <ul>
                        <li>I acknowledge that AWS Cloudformation might create IAM resources</li>
                        <li>I acknowledge that AWS CloudFormation might create IAM resources with custom names</li>
                        <li>I acknowledge that AWS CloudFormation might require the following capability:
                            CAPABILITY_AUTO_EXPAND
                        </li>
                    </ul>
                </li>
                <li>Click Create</li>
                <li>When the stack creation is complete, select your stack name from the list and click
                    on <strong>Output</strong> tab
                </li>
                <li>Copy the <strong>SaaSRoleARNID</strong> Role ARN value</li>
                <li> Paste the Role ARN value in the field below:
                    <form onSubmit={AddACloud}>
                        <Input
                            onChange={(e) => setSaaSRoleArn(e.target.value)}
                            type="text"
                            placeholder="SaaSRoleARNID"
                            value={saaSRoleARNID}
                            required
                        />
                        <li>The name you would like to assign to this account: (eg: Development AWS)</li>
                        <Input
                            onChange={(e) => setCloudName(e.target.value)}
                            type="text"
                            placeholder="Cloud Name"
                            value={cloudName}
                            required
                        />
                        <input type="submit" value="Submit" />
                    </form>
                </li>

            </ol>
        </>
    )
}


export { AddACloud }