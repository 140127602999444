import React, { Fragment } from "react";
import { useEffect, useState } from "react"
import { appState } from "../../AppState";;
import { useHistory, Link } from "react-router-dom";
import useAsyncEffect from "../../utility/use-async-effect";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from "axios";

import ConnectriaBackupService from "../../services/ConnectriaBackupService";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";
import {AllJobsReusable} from "./AllJobsReusable";
import moment from 'moment';

const BackupAllJobs = (props) => {
  
  const source = axios.CancelToken.source();
  const [isLoading, setIsLoading] = useState(false);
  const [stateApp, stateAppActions] = appState();
  const [jobs,setJobs] = useState([])
  const pageSize = 10;
  const [jobsPagesCount, setJobsPagesCount] = useState(0);
  const [timeRange, setTimeRange] = useState(stateApp.commserveTimeRange || "Last 7 Days");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const history = useHistory();
  let totalRecordsWithoutPaging = 0


  const createNewTicket = (e) => {
    if (e != null) {
        e.preventDefault();
    }
    history.push({
      pathname: "/app/create-ticket",
      state: {
        closeOnSuccess: true,
        subject: 'Error Report',
        description: 'TRiA did not register displayable backup information and this is an error',
        attachmentElementId: ''
      }
    });
  }

  async function getRestOfJobs(totalRecords,totalFetchedJobs, body, initialJobs){
    let offset = totalFetchedJobs;
    while (totalFetchedJobs < totalRecords){
      var copiedBody = JSON.parse(JSON.stringify(body));
      copiedBody['pagingConfig']['offset'] = offset;
      let res = await ConnectriaBackupService.getCommServeJobs(copiedBody, {cancelToken: source.token})
      initialJobs.push(res['data']['jobs'])
      offset = offset + res['data']['jobs'].length
      totalFetchedJobs = res['data']['jobs'].length + totalFetchedJobs
    }
    setJobs(initialJobs.flat())
    setJobsPagesCount(Math.ceil(initialJobs.flat().length / pageSize))
  }

  useEffect(() => {
    document.title = "Client ID#: " + props.match.params.id;
    stateAppActions.setPageBackTitle(
      <Link to="/app/backup" className="text-primary">
        <i className="fas fa-chevron-left mr-1" />
        <span>Backup Clients</span>
      </Link>
    )
  }, []);

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        var copiedBody = ConnectriaBackupService.getJobsApiCallBody();
        copiedBody['jobFilter']['entity']['clientId'] = parseInt(props.match.params.id)
        let numDays = 1;
        if (timeRange == "Last 7 Days") {
          numDays = 7
        } else if (timeRange == "Last 30 Days") {
          numDays = 30;
        }
        copiedBody["jobFilter"]["endTimeRange"]["fromTime"] = moment().subtract(numDays, "days").unix()
        copiedBody["jobFilter"]["endTimeRange"]["toTime"] = moment().unix()

        let jobsRes = await ConnectriaBackupService.getCommServeJobs(copiedBody, {cancelToken: source.token});
        if (!isMounted()) return;

        if (jobsRes['data'].hasOwnProperty('jobs')){
          totalRecordsWithoutPaging = jobsRes['data']['totalRecordsWithoutPaging']
          // setJobs(jobsRes['data']['jobs'])
          // setJobsPagesCount(Math.ceil(jobsRes['data']['jobs'].length / pageSize))
          await getRestOfJobs(totalRecordsWithoutPaging, jobsRes['data']['jobs'].length, copiedBody, jobsRes['data']['jobs'])
        } else{
          setJobs([])
          setJobsPagesCount(0)
        }
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
          // request cancelled
        } else {
          console.log(error);
          setIsLoading(false);
          setErrMsg(
            <div className="text-center mt-4 text-bold">
              <i className="fas fa-exclamation-triangle"></i> Error pulling
              jobs for client #{props.match.params.id}
            </div>
          );
          //setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    [timeRange]
  );
  
  const toggleTimeRange = (newRange) => {
    setTimeRange(newRange);
    stateAppActions.setCommserveTimeRange(newRange)
  }

  return (
    <div className="row justify-content-center mt-2">
  <div className="col-lg-12">
    <Card className="card-minimal">
      <CardHeader className="card-header-minimal mb-2">
        <div className="d-flex">
          <CardTitle className="card-title-minimal" tag="h3" style={{fontSize: 2 + "rem"}}>
            BackUp Jobs
          </CardTitle>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen(!dropdownOpen)}
              style={{paddingLeft: "15px", paddingTop: "12px"}}
              className=""
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
              >
                {timeRange}
                <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { toggleTimeRange("Last 24 Hours") }}>
                  Last 24 Hours
                </DropdownItem>
                <DropdownItem onClick={() => { toggleTimeRange("Last 7 Days") }}>
                  Last 7 Days
                </DropdownItem>
                <DropdownItem onClick={() => { toggleTimeRange("Last 30 Days") }}>
                  Last 30 Days
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
      </CardHeader>
      {isLoading ? (
          <div className="text-center">
            <i
                className="fas fa-spinner m-3 mt-4 fa-spin"
                style={{fontSize: "30px"}}
            ></i>
          </div>
      ) : (<CardBody>
        {jobs?.length > 0 ?
          <AllJobsReusable
              allJobs={jobs} pageSize={pageSize} pagesCount = {Math.ceil(jobs.length / pageSize)}
          />
        :
          <div className="text-center">
            <p className="pt-3 pb-3">
              TRiA did not register displayable backup information. Please contact us if this is an error.
            </p>
            <Button className="btn-report" size="md" onClick={createNewTicket}>Report an Error</Button>
          </div>
        }
      </CardBody>)}
    </Card>
  </div>
</div>
  )
}

export {
  BackupAllJobs
};
