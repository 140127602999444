import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const Resources = {
  getResources: async function(params={}) {
    return axios.post(base_api + '/v2/public/resource/query', params);
  },
  getSecurityResources: async function (offset=0) {
    let params = {
      filters: [{name: "divvy.query.service_check_category_all", config: {category: ["security"]}}],
      limit: 100,
      offset: offset,
      scopes: [],
      selected_resource_type: "servicecheck"
    }
    return await this.getResources(params);
  },
  getServiceCheckDescription: async function(id) {
    return await axios.get(base_api + '/v2/servicechecks/' + id + '/description')
  }
}

export default Resources;