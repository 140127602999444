import React, {useState, useEffect} from "react";
import axios from "axios";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import FadeIn from "react-fade-in";
import {appState} from "../AppState";
import moment from "moment";
import useAsyncEffect from "../utility/use-async-effect";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import classnames from "classnames";
import {Line} from "react-chartjs-2";
import IBMService from "../services/IBMService";
import {CSVUtility} from "../utility/table-to-csv";
import Utils from "../utility/ibm_utility";
import Select from "react-select";
import makeAnimated from "react-select/animated";

let chartOptions = {
  responsive: true,
  // hoverMode: 'index',
  // stacked: false,
  title: {
    display: false,
    text: 'SYSTEM ASP GB % USED'
  },
  scales: {
    yAxes: [{
      type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
      display: true,
      position: 'left',
      id: 'y-axis-1',
    }, {
      type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
      display: true,
      position: 'right',
      id: 'y-axis-2',

      // grid line settings
      gridLines: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    }],
  }
};

const getDeltaIcon = (category, value) => {
  if (category.indexOf("Delta") == -1) return "";

  if (value > 0)
    return <i style={{color: "red"}} className="fas fa-plus"></i>;
  else if (value < 0)
    return <i style={{color: "green"}} className="fas fa-minus"></i>;

  return "";
};

const IbmHostUtilizationDashboard = (props) => {

  const [stateApp, stateAppActions] = appState();

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [selectedOption, setSelectedOption] = useState([]);
  const [state, setState] = useState({host: {}, services: []});
  const [table, setTable] = useState({dates: [], categories: [], hash: {}});
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [options, setOptions] = useState([]);
  const [additonalColumns, setAdditionalColumns] = useState(<></>);
  const [modal, setModal] = useState(false);
  const [chart, setChart] = useState({});

  let isSystemASP1 = false;
  let selectedColumns = [
    "ASP 1 Percent Disk Space",
    "ASP 1 Percent Disk Space Delta",
    "ASP 1 Used Disk Space",
    "ASP 1 Used Disk Space Delta",
    "Jobs in MSGW",
    "Total Jobs in System",
    "Maximum Jobs",
    "Active Jobs"
  ];
  let deltaTargets = ["ASP 1 Percent Disk Space", "ASP 1 Used Disk Space"];
  let graphSeries = ['ASP 1 Percent Disk Space', 'ASP 1 Used Disk Space'];
  const toggleModal = () => setModal(!modal);

  const handleChange = selectedOption => {
    console.log(selectedOption);
    selectedOption = selectedOption == null ? [] : selectedOption;

    setSelectedOption(selectedOption || []);

    // if(selectedOption|| [].length == 0) setAdditionalColumns('');

    setAdditionalColumns(
      selectedOption.map((item, indx) => {
        return <th key={indx}>{item.value}</th>;
      })
    );

    console.log(table.categories.length);

    setTable({
      dates: table.dates,
      categories:
        selectedOption.length == 0
          ? selectedColumns
          : [
            ...new Set(
              table.categories
                .slice()
                .concat(selectedOption.map(a => a.value))
            )
          ],
      hash: table.hash
    });

  };

  useAsyncEffect(
    async isMounted => {
      console.log('in report');
      try {
        let detail = await IBMService.details(props.id);
        let history = await IBMService.service_metric_history(
          props.id,
          "",
          today,
          15//days back
        );

        for (var i = 0; i < history.data.length; i++) {
          //do we have asp 1 or just systemt asp total?
          if (history.data[i].name.indexOf('ASP 1') > -1) isSystemASP1 = true;

          history.data[i].parsed_value = Utils.parseValue(
            history.data[i].value,
            history.data[i].status_text_long
          );
        }
        if (!isSystemASP1) {
          selectedColumns.forEach(function (item, i) {
            selectedColumns[i] = selectedColumns[i].replace('ASP 1', 'ASP Total');
          });
          deltaTargets.forEach(function (item, i) {
            deltaTargets[i] = deltaTargets[i].replace('ASP 1', 'ASP Total');
          });
          graphSeries.forEach(function (item, i) {
            graphSeries[i] = graphSeries[i].replace('ASP 1', 'ASP Total');
          });

        }
        let hash = {};
        let dates = [
          ...new Set(
            history.data.map(bill => bill.date.replace("T00:00:00Z", ""))
          )
        ].sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b) - new Date(a);
        });
        let categories = [...new Set(history.data.map(bill => bill.name))];

        setOptions(
          categories
            .filter(function (item) {
              return !selectedColumns.includes(item);
            })
            .map(item => {
              return {value: item, label: item};
            })
        );

        for (var i = 0; i < categories.length; i++) {
          for (var x = 0; x < history.data.length; x++) {
            if (history.data[x].name == categories[i]) {
              if (!hash.hasOwnProperty(categories[i])) {
                hash[categories[i]] = [];
              }
              hash[categories[i]].push(history.data[x].parsed_value || "");
            }
          }
        }
        for (var i = 0; i < deltaTargets.length; i++) {

          //check if the has has the delta target if not skip
          if (!hash.hasOwnProperty(deltaTargets[i])) continue;
          //initalize hash item
          let newKey = deltaTargets[i] + " Delta";
          hash[newKey] = [];

          for (var x = 0; x < hash[deltaTargets[i]].length; x++) {
            if (x == hash[deltaTargets[i]].length - 1) {
              hash[newKey].push(0);
            } else {
              hash[newKey].push(
                (hash[deltaTargets[i]][x] - hash[deltaTargets[i]][x + 1]).toFixed(
                  2
                )
              );
            }
          }
        }
        if (!isMounted()) return;
        setTable({dates: dates, categories: selectedColumns, hash: hash});
        setState({
          host: detail.data.hosts[0],
          services: detail.data.hosts[0].services
        });

        let y = {
          labels: dates.slice(0, 10).reverse(),
          datasets: [{
            label: 'ASP Percent Disk Space',
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            fill: false,
            data: hash[graphSeries[0]].slice(0, 10).reverse(),
            yAxisID: 'y-axis-1',
          }, {
            label: 'ASP Used Disk Space',
            backgroundColor: "#0C618D",
            borderColor: "#0C618D",
            fill: false,
            data: hash[graphSeries[1]].slice(0, 10).reverse(),
            yAxisID: 'y-axis-2'
          }]
        };
        setChart(y);

        setIsLoading(false);

      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {

    },
    [props.visible]
  );

  const animatedComponents = makeAnimated();

  return (
    <FadeIn delay={500}>
      <div className="row justify-content-center mt-2">
        <div className="col-lg-12">
          <Card className="card-minimal">
            <CardHeader className="card-header-minimal">

              <CardTitle className="card-title-minimal" tag="h3">
                LPAR Trend Data
                <div className="pull-right" style={{width: 250 + 'px'}}>
                  <Select
                    className="h5"
                    style={{width: 200 + 'px'}}
                    value={selectedOption}
                    onChange={handleChange}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={options}
                    isMulti
                    placeholder="Additional Columns"
                  />
                </div>
              </CardTitle>
            </CardHeader>
            <CardBody>

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                  <tr>
                    <th style={{paddingBottom: 0 + "px"}}>Date</th>
                    <th colSpan="4" style={{textAlign: "left"}}>
                      System ASP  &nbsp; &nbsp;<i onClick={toggleModal} className="far fa-chart-bar fa-2x pointer"
                    ></i>
                      <span
                        className="link-txt"
                        style={{
                          fontSize: "20" + "px",
                          paddingLeft: "5" + "px"
                        }}
                      >
                        <i onClick={() => toggleModal()}
                           className="mdi mdi-chart-bar"
                           style={{fontSize: "18" + "px"}}
                        ></i>
                      </span>
                    </th>
                    <th style={{paddingBottom: 0 + "px"}}>QSYS</th>
                    <th style={{paddingBottom: 0 + "px"}}>Total</th>
                    <th style={{paddingBottom: 0 + "px"}}>Max</th>
                    <th style={{paddingBottom: 0 + "px"}}>Active</th>
                  </tr>
                  <tr>
                    <th style={{paddingTop: 0 + "px"}}>Reported</th>
                    <th style={{paddingTop: 0 + "px"}}>Used %</th>
                    <th style={{paddingTop: 0 + "px"}}>+/- (%)</th>
                    <th style={{paddingTop: 0 + "px"}}>Used (GB)</th>
                    <th style={{paddingTop: 0 + "px"}}>+/- (GB)</th>
                    <th style={{paddingTop: 0 + "px"}}>MSGW</th>
                    <th style={{paddingTop: 0 + "px"}}>Jobs</th>
                    <th style={{paddingTop: 0 + "px"}}>Jobs</th>
                    <th style={{paddingTop: 0 + "px"}}>Jobs</th>
                    {additonalColumns}
                  </tr>
                  </thead>
                  <tbody>
                  {table.dates.map((d, dIndex) => {
                    return (
                      <tr key={dIndex}>
                        <td key={d}>{moment(d).format('MM/DD/YYYY')}</td>
                        {table.categories.map((c, index) => {
                          return (
                            <td key={index}>
                              {(c in table.hash) ? table.hash[c][dIndex] : ''} {(c in table.hash) ? getDeltaIcon(c, table.hash[c][dIndex]) : ''}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            </CardBody>
            <CardFooter>
              <button
                className="btn btn-primary pull-right"
                onClick={() => CSVUtility.downloadCSV("ibmi-report-" + state.host.host_name + ".csv")}
              >
                Export
              </button>
            </CardFooter>
          </Card>
        </div>
      </div>
      <Modal style={{zIndex: 0}} isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Last 10 Days</ModalHeader>
        <ModalBody>
          <Line data={chart} options={chartOptions}/>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </FadeIn>
  );
}

export default IbmHostUtilizationDashboard;
