import React, { useState,useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const LabsModal = (props) => {
    const {
      buttonLabel,
      className,
      componenet,
      title,
      showModal
    } = props;
  
    const [modal, setModal] = useState(showModal);
  
    useEffect(() => {
      if(showModal)toggle();
    }, [showModal]);
    const toggle = () => setModal(!modal);

    return (
      <div className="expando"> 
      
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            {typeof(componenet) =='undefined' ? '' : componenet}
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
        
      </div>
   
    );
  }
  
  export default LabsModal;