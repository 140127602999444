import axios from "axios";
import moment from "moment";

const base_api = 'https://sandbox.tria.connectria.com';



const ConnectriaBackupService = {

    getJobsApiCallBody: function(){
        let body = {
          "pagingConfig": {
            "sortDirection": 1,
            "offset": 0,
            "sortField": "jobStartTime",
            "limit": 100
          },
          "jobFilter": {
            "endTimeRange": {
              "fromTime": moment().unix() - 604800,
              "toTime": moment().unix()
            },
            "showAgedJobs": false,
            "entity": {
              "clientId": 0
            }
          }
        }
        return JSON.parse(JSON.stringify(body))
    },
    getCommServeJobs: async function (body, params = {}) {
        let res = axios.post(base_api + "/plugin/tria_interface/commserve/jobs", body, params)
        return res
    },
    getCommServeJobDetails: async function (jobId, params = {}) {
        let res = axios.get(base_api + "/plugin/tria_interface/commserve/jobDetails/" + jobId, params)
        return res
    },
    getCommServeClient: async function (id,params = {}) {
        let res = axios.get(base_api + "/plugin/tria_interface/commserve/client/" + id, params)
        return res
    },
    getCommServeClientGroup: async function (params = {}) {
        let res = axios.get(base_api + "/plugin/tria_interface/commserve/clientgroup", params)
        return res
    },
    getCommServeClientGroupStats: async function (clientGroupId, params = {}) {
      let res = axios.get(base_api + `/plugin/tria_interface/commserve/clientGroupStats/${clientGroupId}`, params)
      return res
    },
    getCommServeSubclientDetails: async function(subclientId, params = {}){
        console.log(base_api + "/plugin/tria_interface/commserve/sub_client/" + subclientId)
        let res = axios.get(base_api + "/plugin/tria_interface/commserve/sub_client/" + subclientId, params)
        return res
    }
}

export default ConnectriaBackupService;