import React from "react";
import globalHook from "./UseGlobalHook";

const initialState = {
    env: 'dev',
    inboxCount: "",
    account_id: null,
    clouds: [],
    cloudAccounts: [],
    cloud_type_id: null,
    date: null,
    pageNavTitle: "",
    pageBackTitle: "",
    alert: {
        className: "",
        content: "",
        color: "",
        visible: false
    },
    blade: {
        title: "",
        content: "",
        visible: false
    },
    contentPanel: {
        title: "",
        content: "",
        visible: false
    },
    authenticated: false,
    userInfo: null,
    organizations: [],
    integrations: [],
    portalCustomerMeta: {},
    reload: 0,
    sessionTimeoutTime: null,
    cache: {
        connectriaTicketCC: {
            loading: false,
            data: []
        },
        connectriaTicketCategories: {
            loading: false,
            data: []
        },
        connectriaTicketPreferences: {
            loading: false,
            data: []
        },
        connectriaTicketDevices: {
            loading: false,
            data: []
        },
        costSavings: {
            loading: true,
            data: []
        }
    },
    commserveTimeRange: null
};

const stateActions = {
    setCache: (store, cache) =>{
        let updatedState = store.state.cache;
        updatedState[cache.key] = {loading: cache.loading || false, data: cache.data}
       store.setState( { cache: updatedState });
    },
    setPageNavTitle: (store, newTitle) => {
        store.setState( { pageNavTitle: newTitle });
    },
    setPageBackTitle: (store, newTitle) => {
        store.setState( { pageBackTitle: newTitle });
    },
    setClouds: (store, clouds) => {
        store.setState( { clouds: clouds });
    },
    setCloudAccounts: (store, accounts) => {
      store.setState({cloudAccounts: accounts});
    },
    setInboxCount: (store, newCount) => {
        store.setState( { inboxCount: newCount });
    },
    setBlade: (store, newBladeState) => {
        store.setState( { blade: newBladeState })
    },
    setContentPanel: (store, newContentPanelState) => {
        store.setState( { contentPanel: newContentPanelState })
    },
    setAlert: (store, newAlertState) => {
        store.setState( { alert: newAlertState })
    },
    setLogout: (store) => {
        //reset UI state
        store.setState( { blade: initialState.blade });
        store.setState( { alert: initialState.alert });
        store.setState( { userInfo: initialState.userInfo })
        store.setState( { authenticated: false });
        store.setState( { organizations: [] });
        store.setState( { reload: 0 });
    },
    setAuthenticated: (store, userInfo) => {
        store.setState( { authenticated: true });
        store.setState( { userInfo: userInfo });
    },
    setOrganizations: (store, organizations) => {
        store.setState( { organizations });
    },
    setIntegrations: (store, integrations) => {
        store.setState( { integrations });
    },
    setPortalCustomerMeta: (store, metadata) => {
        if (metadata.meta_key == null) {
            return;
        }
        store.setState( {
            portalCustomerMeta: {
                ...store.state.portalCustomerMeta,
                [metadata.meta_key.toLowerCase()]: metadata.meta_value,
            },
        });
    },
    setReload: (store) => {
        store.setState( { reload: store.state.reload + 1 });
    },
    setCloudAccount: (store, cloud_type_id, account_id) => {
      store.setState({cloud_type_id: cloud_type_id, account_id: account_id});
    },
    setDate: (store, date) => {
      store.setState({date: date});
    },
    setSessionTimeoutTime: (store, time) => {
        store.setState({sessionTimeoutTime: time});
    },
    setCommserveTimeRange: (store, timeRange) => {
        store.setState( { commserveTimeRange: timeRange });
    }
}

export const appState = globalHook(React, initialState, stateActions);