/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import FadeIn from "react-fade-in";
import { appState } from "../AppState";
import moment from "moment";
import useAsyncEffect from "../utility/use-async-effect";
// core components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Button,
  NavLink
} from "reactstrap";
import classnames from "classnames";
import { Line } from "react-chartjs-2";
import IBMService from "../services/IBMService";
import Utils from "../utility/ibm_utility";
let chartOptions =  {
  responsive: true,
  // hoverMode: 'index',
  // stacked: false,
  title: {
    display: false,
    text: 'SYSTEM ASP GB % USED'
  },
  scales: {
    yAxes: [{
      type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
      display: true,
      position: 'left',
      id: 'y-axis-1',
    }, {
      type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
      display: true,
      position: 'right',
      id: 'y-axis-2',

      // grid line settings
      gridLines: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    }],
  }
};
const versionDates = {
  '5.4.0': ['1/31/2006', '2/14/2006', '5/27/2011', '9/30/2013', '9/30/2017'],
  '6.1.0': ['1/29/2008', '3/21/2008', '12/9/2014', '9/30/2015', '9/30/2019'],
  '7.1.0': ['4/13/2010', '4/23/2010', '9/30/2017', '4/30/2018', '4/30/2021'],
  '7.2.0': ['4/28/2014', '5/2/2014', '4/30/2020', '4/30/2021', ''],
  '7.3.0': ['4/12/2016', '4/15/2016', '', '', ''],
  '7.4.0': ['4/23/2019', '6/21/2019', '', '', '']
}
const getDate = (version, type) => {

 //IF VERSION IS 0 We were not able to parse:
 if(version == 0) return ''; 
//ARRAY POSITIONS
// 0 ANNOUNCED, 1 AVAILBLE, 2 WITHDRAWN, 3 IBMVERSIONEOS, 4 IBMVERSIONEXT
switch(type){
  case 'ANNOUNCED':
    return versionDates[version][0];
    case 'AVAILABLE':
    return versionDates[version][1];
    case 'WITHDRAWN':
        return versionDates[version][2];
    case 'IBMVERSIONEOS':
    return versionDates[version][3];
    case 'IBMVERSIONEXT':
    return versionDates[version][4];

    default: break;
}
 return '';
}
const getDeltaIcon = (category, value) => {
  if (category.indexOf("Delta") == -1) return "";

  if (value > 0)
    return <i style={{ color: "red" }} className="fas fa-plus"></i>;
  else if (value < 0)
    return <i style={{ color: "green" }} className="fas fa-minus"></i>;

  return "";
};

const IbmHostReportDashboard = props => {
  //global state

 

  const [stateApp, stateAppActions] = appState();

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [state, setState] = useState({ host: {}, services: [] });
  const [table, setTable] = useState({ dates: [], categories: [], hash: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [chart, setChart] = useState({});
  let isSystemASP1 = false;
  let deltaTargets = ["ASP 1 Percent Disk Space", "ASP 1 Used Disk Space"];
  let graphSeries = ['ASP 1 Percent Disk Space','ASP 1 Used Disk Space'];
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useAsyncEffect(
    async isMounted => {
      console.log('in report');
      try {
        let detail = await IBMService.details(props.id);
      let history = await IBMService.service_metric_history(
        props.id,
        "",
        today,
        15//days back
      );

      for (var i = 0; i < history.data.length; i++) {
        //do we have asp 1 or just systemt asp total?
        if(history.data[i].name.indexOf('ASP 1') > -1) isSystemASP1 = true;

        history.data[i].parsed_value = Utils.parseValue(
          history.data[i].value,
          history.data[i].status_text_long
        );
      }
      if(!isSystemASP1){
        deltaTargets.forEach(function(item, i) { deltaTargets[i] = deltaTargets[i].replace('ASP 1','ASP Total'); });
        graphSeries.forEach(function(item, i) { graphSeries[i] = graphSeries[i].replace('ASP 1','ASP Total'); });
      
      }
      let hash = {};
      let dates = [
        ...new Set(
          history.data.map(bill => bill.date.replace("T00:00:00Z", ""))
        )
      ].sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b) - new Date(a);
      });
      let categories = [...new Set(history.data.map(bill => bill.name))];

      for (var i = 0; i < categories.length; i++) {
        for (var x = 0; x < history.data.length; x++) {
          if (history.data[x].name == categories[i]) {
            if (!hash.hasOwnProperty(categories[i])) {
              hash[categories[i]] = [];
            }
            hash[categories[i]].push(history.data[x].parsed_value || "");
          }
        }
      }
      for (var i = 0; i < deltaTargets.length; i++) {
        
        //check if the has has the delta target if not skip
        if(!hash.hasOwnProperty(deltaTargets[i])) continue;
        //initalize hash item
        let newKey = deltaTargets[i] + " Delta";
        hash[newKey] = [];
        
        for (var x = 0; x < hash[deltaTargets[i]].length; x++) {
          if (x == hash[deltaTargets[i]].length - 1) {
            hash[newKey].push(0);
          } else {
            hash[newKey].push(
              (hash[deltaTargets[i]][x] - hash[deltaTargets[i]][x + 1]).toFixed(
                2
              )
            );
          }
        }
      }
        if (!isMounted()) return;
        setState({
          host: detail.data.hosts[0],
          services: detail.data.hosts[0].services
        });

        let y = {
          labels:  dates.slice(0,10).reverse(),
          datasets: [{
            label: 'ASP Percent Disk Space',
            backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
            fill: false,
            data: hash[graphSeries[0]].slice(0,10).reverse(),
            yAxisID: 'y-axis-1',
          }, {
            label: 'ASP Used Disk Space',
            backgroundColor: "#0C618D",
            borderColor: "#0C618D",
            fill: false,
            data: hash[graphSeries[1]].slice(0,10).reverse(),
            yAxisID: 'y-axis-2'
          }]
        };
        setChart(y);
        
        setIsLoading(false);
       
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
   
    },
    [props.visible]
  );

  return (
    <>
      <FadeIn delay={500}>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Card className="card-minimal h-100">
              <CardHeader className="card-header-minimal">
                <h5 className="card-category">{state.host.host_name}</h5>
                <CardTitle className="card-title-minimal" tag="h3">
                  General Overview
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Hardware Overview
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Maintenance
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div>
                        {//Check if message failed
                        isLoading ? (
                          <div className="text-center">
                            <i
                              className="fas fa-spinner m-3 mt-4 fa-spin"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </div>
                        ) : (
                          <>
                            <Row>
                              <Col sm="12">
                                <table className="table">
                                  <tbody>
                                   
                                    <tr>
                                      <th scope="row">Serial Number:</th>
                                      <td>
                                        {Utils.get(
                                          "Serial Number",
                                          state.services,
                                          true,
                                          true
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Machine Type:</th>
                                      <td>8286</td>
                                    </tr>

                                    <tr>
                                      <th scope="row">Model:</th>
                                      <td>
                                        {Utils.get(
                                          "Model",
                                          state.services,
                                          true,
                                          true
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Announced:</th>
                                      <td>
                                        {getDate(Utils.extractIntegers(
                                Utils.get(
                                  "Version Information",
                                  state.services,
                                  false,
                                  true
                                )
                              ), 'ANNOUNCED')}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Available:</th>
                                      <td>
                                      {getDate(Utils.extractIntegers(
                                Utils.get(
                                  "Version Information",
                                  state.services,
                                  false,
                                  true
                                )
                              ), 'AVAILABLE')}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Withdrawn Marketing:</th>
                                      <td>
                                      {getDate(Utils.extractIntegers(
                                Utils.get(
                                  "Version Information",
                                  state.services,
                                  false,
                                  true
                                )
                              ), 'WITHDRAWN')}
                                      </td>
                                    </tr>
                                    {/* <tr>
                                      <th scope="row">End of Service:</th>
                                      <td>4/28/2018</td>
                                    </tr> */}
                                  </tbody>
                                </table>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </TabPane>
                    <TabPane className="" tabId="2">
                      <div>
                        {//Check if message failed
                        isLoading ? (
                          <div className="text-center">
                            <i
                              className="fas fa-spinner m-3 mt-4 fa-spin"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </div>
                        ) : (
                          <>
                            <Row>
                              <Col sm="12">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <th scope="row">HW Contract Start:</th>
                                      <td>7/28/19</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">HW Contract End:</th>
                                      <td>7/28/21</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">SW Contract Start:</th>
                                      <td>7/28/19</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">SW Contract End:</th>
                                      <td>7/28/21</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-4">
            <Card className="card-minimal h-100">
              <CardHeader className="card-header-minimal">
                <h5 className="card-category">{state.host.host_name}</h5>
                <CardTitle className="card-title-minimal" tag="h3">
                  Partition Overview
                </CardTitle>
              </CardHeader>
              <CardBody>
                {//Check if message failed
                isLoading ? (
                  <div className="text-center">
                    <i
                      className="fas fa-spinner m-3 mt-4 fa-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                ) : (
                  <Row>
                    <Col sm="12">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th scope="row">Configured CPU's:</th>
                            <td>
                            {Utils.get("Number of Processors", state.services)}
                            </td>
                          </tr>
                          {
                            (Utils.get(
                              "CPU Sharing",
                              state.services,
                              true)?.replace('\\n','').indexOf('UNCAPPED') > -1) ? <tr>
                                 <th scope="row">Burstable CPU's:</th>
                            <td>
                            {Utils.get("Burstable CPUs", state.services)}
                            </td>
                              </tr> : <></>
                          }
                          <tr className="d-none">

                            {/* CAPPED only display number of cpu's - if UNCAPPED display configured cpus and burstable cpus  Call field Burstable*/}

                            <th scope="row">CPU Sharing:</th>
                            <td>{Utils.get(
                                  "CPU Sharing",
                                  state.services,
                                  true)?.replace('\\n','')}</td>
                          </tr>
                          <tr>
                            <th scope="row">Partition ID:</th>
                            <td> {Utils.get(
                                  "Partition ID",
                                  state.services)}</td>
                          </tr>
                          {/* <tr>
                            <th scope="row">Total Partitions:</th>
                            <td>Unavailable</td>
                          </tr> */}
                          <tr>
                            <th scope="row">IBM i Version:</th>
                            <td>
                              {Utils.extractIntegers(
                                Utils.get(
                                  "Version Information",
                                  state.services,
                                  false,
                                  true
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">IBM i Extended Support:</th>
                            <td>
                            {getDate(Utils.extractIntegers(
                                Utils.get(
                                  "Version Information",
                                  state.services,
                                  false,
                                  true
                                )
                              ), 'IBMVERSIONEXT')}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">IBM i Version End Of Support:</th>
                            <td>
                            {getDate(Utils.extractIntegers(
                                Utils.get(
                                  "Version Information",
                                  state.services,
                                  false,
                                  true
                                )
                              ), 'IBMVERSIONEOS')}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Disk Capacity (GB):</th>
                            <td>
                           
                              {Utils.get(
                                          "ASP 1 Disk Space",
                                          state.services
                                        ).length == 0 ?
                                        Utils.get(
                                          "ASP Total Disk Space",
                                          state.services
                                        ) : ""}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Disk Units (System ASP):</th>
                            <td>
                              {Utils.get(
                                "ASP 1 Number of Disk Units",
                                state.services
                              )}
                              {Utils.get(
                                          "ASP 1 Number of Disk Units",
                                          state.services
                                        ).length == 0 ?
                                        Utils.get(
                                          "ASP Total Number of Disk Units",
                                          state.services
                                        ) : ""}
                            </td>
                          </tr>
                          {/* <tr>
                            <th scope="row">Disk Units Reporting:</th>
                            <td>ALL</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-4 d-none">
          <Card className="card-minimal h-100">
              <CardHeader className="card-header-minimal">
                <h5 className="card-category">{state.host.host_name}</h5>
                <CardTitle className="card-title-minimal" tag="h3">
                ASP Disk Space (Last 10 Days)
                </CardTitle>
              </CardHeader>
              <CardBody>
              <Line  data={chart} options={chartOptions} />
              </CardBody>
              </Card>
          </div>
          <div className="col-lg-4">
            <Card className="card-minimal h-100">
              <CardHeader className="card-header-minimal">
                <h5 className="card-category">{state.host.host_name}</h5>
                <CardTitle className="card-title-minimal" tag="h3">
                  PTF's <small>(Installed/Next IPL)</small>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {//Check if message failed
                isLoading ? (
                  <div className="text-center">
                    <i
                      className="fas fa-spinner m-3 mt-4 fa-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                ) : (
                  <Row>
                    <Col sm="12">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th scope="row">Cumulative:</th>
                            <td> {Utils.get(
                                "Cumulative PTF Level",
                                state.services
                              )}
                             </td>

                          </tr>
                          <tr>
                            <th scope="row">HIPER:</th>
                            <td> {Utils.get(
                                "Hiper PTF Level",
                                state.services
                              )}</td> 
                          </tr>
                          <tr>
                            <th scope="row">DB2:</th>
                            <td> {Utils.get(
                                "DB PTF Level",
                                state.services
                              )}</td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              colSpan="2"
                              align="center"
                              style={{borderTop:"unset", paddingBottom:"0", paddingLeft:"0", paddingTop:"30px"}}
                            >
                              <span className="h3">Licensing</span>
                            </th>
                          </tr>
                       
                           {
                            (Utils.get(
                              "CPU Sharing",
                              state.services,
                              true).replace('\\n','').indexOf('UNCAPPED') > -1) ? <tr>
                                 <th scope="row">Processor Usage Limits:</th>
                            <td>
                            {Utils.get("Burstable CPUs", state.services)}
                            </td>
                              </tr> : <></>
                          }
                          <tr>
                            <th scope="row">Processor Usage Count:</th>
                            <td> {Utils.get("Number of Processors", state.services)}</td>
                          </tr>
                          {/* <tr>
                            <th scope="row">User Limit:</th>
                            <td>Unlimited</td>
                          </tr>
                          <tr>
                            <th scope="row">User Count:</th>
                            <td>
                              {Utils.get(
                                "Number of Users Logged on",
                                state.services
                              )}
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </FadeIn>
    </>
  );
};

export default IbmHostReportDashboard;
