import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const BlueMatadorService = {
    
    graphs: function(params ={}) {
      let request = axios.get(
          base_api + "/plugin/tria_interface/bluematador/retrieve/graphs",
          params
          );
        return request;
    },
    openedEvents: function(start, end, params ={}) {
      let request = axios.get(
        base_api + `/plugin/tria_interface/bluematador/retrieve/events/${start}/${end}`,
        params
        );
      return request;
    },
    activeEvents: function(start, end, params ={}) {
      //if (project) { project = "&project="+project }
      let request = axios.get(
        base_api + `/plugin/tria_interface/bluematador/retrieve/events/active`,
        params
        );
      return request;
    },
    pageContent: function(url, params ={}) {
      let request = axios.post(
        base_api + `/plugin/tria_interface/monitoring/graphs/content`, {
        url: url, 
        ...params
        }
      );
      return request;
    },
    checkSetup: function(params ={}) {
      let request = axios.get(
        base_api + `/plugin/tria_interface/bluematador/check/setup`,
        params
      );
      return request;
    }
    
};

export default BlueMatadorService;
