import axios from "axios";

export const base_api = 'https://sandbox.tria.connectria.com';

const OpsgenieService = {
    
    reportAlert: function(params) {
        return axios.post(base_api + `/plugin/tria_interface/opsgenie/alerts`, params);
    }

};

export default OpsgenieService;