import React from "react";
import { useEffect, useState } from "react";
import useAsyncEffect from "../utility/use-async-effect";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { appState } from "../AppState";
import { autosize } from "../utility/misc";
import UserService from "../services/UserService";
import Login from "../layouts/Login/Login";
import { 
  Col,
  Button,
  Input, 
  Tooltip,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import CookieService from "../services/CookieService";

const ExpandingTextArea = (props) => {
  var textarea = document.querySelector(`textarea#${props.id}`);

  if (textarea) {
    textarea.addEventListener('keydown', autosize);
  }

  return (
    <>
    {props.label && <Label>{props.label}</Label>}
    <Input type="textarea" {...props} className={`expandable resizable${props.className ? props.className : ""}`}></Input>
    </>
  )
}

const NavBarBackButton = (props) => {
  const history = useHistory();

  return (
    <>
      <a href="#" onClick={() => {history.push(props.route)}}>
        <div className="row text-primary navbarexample">
          <i className="fas fa-chevron-left mr-1" style={{paddingRight: 2 + "px", marginTop: "auto", marginBottom: "auto"}}></i>
          <div className="">
            <div className="text-primary">{props.text}</div>
          </div>
        </div>
      </a>
    </>
  );
}

const ClipboardCopyButton = (props) => {
  const { value, tooltipMessage, alertMessage, idx, className, style } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [text, setText] = useState(tooltipMessage);
  const [stateApp, stateAppActions] = appState();

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
    // When hovering off of tooltip, reset the message
    if (!tooltipOpen) setText(tooltipMessage);
  }

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(value)
      setText("Copied!");
    
      // Show app level alert if message is given
      if (alertMessage) {
        stateAppActions.setAlert({
          content: <p style={{color:"white"}}>{alertMessage}</p>,
          className: "success",
          visible: true
        });
        setTimeout(() => {
          stateAppActions.setAlert(prevState => ({...prevState, visible: false }))
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setText('Failed to copy');
    }
  }

  return (
    <>
    <i 
      className={"far fa-copy clickable copy-clipboard cursor " + (className ? className : "")} 
      id={(props.target ?? "TooltipExample") + (idx+1 ? idx : "")} // Index is optional
      onClick={copyToClipboard} 
      style={{fontSize: "0.9rem", ...style}}/>
    <Tooltip placement="right" 
      target={(props.target ?? "TooltipExample") + (idx+1 ? idx : "")} // Index is optional
      isOpen={tooltipOpen} 
      toggle={toggle} 
      style={{backgroundColor: "black", color: "white"}}>
        {text}
    </Tooltip>
    </>
  );
}

const SessionTimeoutModal = (props) => {
  const [minutes, setMinutes] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const [stateApp, stateAppActions] = appState();
  const [modal, setModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (stateApp.sessionTimeoutTime != null) {
      var timeoutTime = parseInt(CookieService.getCookie("session_timeout_time"));
      var d = new Date();
      var minutes = Math.ceil((timeoutTime - d.getTime()) / 60000);
      setMinutes(minutes);
    }
  }, [stateApp.sessionTimeoutTime])

  useEffect(() => {
    if (stateApp.sessionTimeoutTime != null) {
      clearInterval(intervalId);
      const id = setInterval(() => {
        var d = new Date();
        var sessionTimeoutTime = parseInt(CookieService.getCookie("session_timeout_time"));
        var minutes = Math.ceil((sessionTimeoutTime - d.getTime()) / 60000);
        if (d > stateApp.sessionTimeoutTime && stateApp.sessionTimeoutTime != null) {
          //do logout
          setSessionExpired(true);
          setModal(true);
          clearInterval(intervalId);
        }
        setMinutes(minutes);
      }, 60000);
      setIntervalId(id);
    }
    return () => clearInterval(intervalId);
  }, [stateApp.sessionTimeoutTime])

  useEffect(() => {
    if (minutes == 5) {
      setModal(true);
      setSessionExpired(true);
    }
  }, [minutes])

  const logout = async (e) => {
    if (e) {
        e.preventDefault();
    }
    clearInterval(intervalId);
    setModal(false);
    try {
        await UserService.logout();
    } catch (error) {
        console.log(error);
    }      

    let cookieDomain = CookieService.getCookieDomain(stateApp.env);
    CookieService.eraseCookie("session_id", "/", cookieDomain);
    CookieService.eraseCookie("session_timeout_time", "/", cookieDomain);
    CookieService.eraseCookie("show_skylift", "/", cookieDomain);


    stateAppActions.setLogout();
    //history.push("/login");
  }

  const toggle = () => { setModal(!modal); };

  const resetSessionTimeout = async () => {
    let timeoutTime = await UserService.save_user_session();
    var d = Date.parse(timeoutTime.data.expiration);
    CookieService.setCookie("session_timeout_time", d, 1, stateApp.env);
    stateAppActions.setSessionTimeoutTime(d);
    setModal(false);
  }

  return (
    <>
    <Modal isOpen={modal} toggle={sessionExpired ? logout : toggle} className={props.className}>
      {sessionExpired ? 
         <ModalHeader toggle={logout}>You have been logged out due to inactivity.</ModalHeader> :
         <ModalHeader toggle={toggle}>In {minutes} minutes, you will be logged out due to inactivity.</ModalHeader>}
      <ModalBody>
      </ModalBody>
      <ModalFooter>
        {sessionExpired ?
          <Button color="secondary" onClick={logout}>Go to the login page</Button> :
          <>
          <Button color="secondary" onClick={resetSessionTimeout}>Stay in TRiA</Button>{' '}
          <Button color="secondary" onClick={logout}>Log out</Button> 
          </>}
      </ModalFooter>
    </Modal>
    </>
  ); 
};

export {
  ExpandingTextArea,
  NavBarBackButton,
  ClipboardCopyButton,
  SessionTimeoutModal,
}