import React from "react";
import {useEffect, useState} from "react"
import axios from "axios";
import moment from 'moment';

import useAsyncEffect from "../../utility/use-async-effect";
import CostService from "../services/CostService";

// reactstrap components
import {Input} from "reactstrap";
import {Card, CardBody, Collapse} from "reactstrap";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {appState} from "../../AppState";
import {CSVUtility} from "../../utility/table-to-csv";
import {NavBarBackButton} from "../../components/misc";


const DATE_FORMAT = 'MMM YYYY', EMPTY_RESOURCE_NAME = 'no-id';

const SpendByResourcesReport = (props) => {
  const source = axios.CancelToken.source();
  const [stateApp, stateAppActions] = appState();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [report, setReport] = useState([]);
  const [flatReport, setFlatReport] = useState([]);

  const allClouds = {cloud_name: 'All clouds', account: null}
  const [cloud, setCloud] = useState(allClouds);
  const [clouds, setClouds] = useState([]);
  const [cloudType, setCloudType] = useState('');
  const [cloudTypes, setCloudTypes] = useState([]);
  const [showCloudTypeFilter, setShowCloudTypeFilter] = useState(true);
  const [cloudDropdownOpen, setCloudDropdownOpen] = useState(false);
  const [cloudTypeDropdownOpen, setCloudTypeDropdownOpen] = useState(false);

  const [change, setChange] = useState(0);

  const [date, setDate] = useState(
    stateApp.date !== null
      ? moment(stateApp.date, 'YYYY-MM-DD').format(DATE_FORMAT)
      : moment().format(DATE_FORMAT)
  );

  let start = moment().startOf('month'), months = [start];
  for (let m = 1; m < 12; m++) {
    months.push(moment().subtract(m, 'months'));
  }
  const [dates, setDates] = useState(months);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);

  useEffect(() => {
    stateAppActions.setPageBackTitle(<NavBarBackButton route="/app/cloud/cost/dashboard" text={"Spend Dashboard"}/>);
  }, []);

  // Get cloud accounts list
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        let cloudAccounts = await CostService.getCloudAccountsWithCosts(
          moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        );

        if (!isMounted()) return;
        setClouds(cloudAccounts)

        let types = new Set()
        for (let cloud of cloudAccounts) {
          types.add(cloud.type)
        }

        cloudAccounts.splice(0, 0, allClouds)

        let typesList = [...types]
        setCloudTypes(typesList)
        if (typesList.length > 0) {
          setCloudType(typesList[0])
        }

        // set cloud chosen in Costs Dashboard
        if (stateApp.account_id !== null) {
          for (let n in cloudAccounts) {
            if (cloudAccounts[n].account === stateApp.account_id) {
              setCloud(cloudAccounts[n]);
            }
          }
        }

        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);

          // request cancelled
        } else {
          console.log(error);
          setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  // load tags and resources
  useAsyncEffect(
    async isMounted => {
      if (!cloudType) return

      try {
        setIsLoading(true);

        let chosenDate = date.indexOf(' ') < 0 ? moment(date) : moment(date, 'MMM YYYY'),
          start = chosenDate.startOf('month').format('YYYY-MM-DD'),
          end = chosenDate.endOf('month').format('YYYY-MM-DD');

        let costResponse = await CostService.cloudResourceCostMonths(start, end, cloud.account);

        if (!isMounted()) return;
        if (costResponse.data.length > 0 && costResponse.data[0].details.length > 0) {
          setData(costResponse.data[0].details)
        }
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    [date, cloud, cloudType]
  );

  const processItem = (item) => {
    item.resource_id = item.resource_id.replace('empty', EMPTY_RESOURCE_NAME)
    if (item.resource_id.indexOf('/subscriptions/') > -1) {
      // cut Azure resource id
      item.resource_id = item.resource_id.split('/').slice(4).join('/')
    }
    return item
  }

  useEffect(() => {
    let types = {}, flatReportData = [], searchQuery = search.toLowerCase().trim();

    for (let i = 0; i < data.length; i++) {
      let item = processItem(data[i]);

      if (search.length > 2) {
        let add = false;

        if (item.resource_id.toLowerCase().indexOf(searchQuery) > -1) add = true;
        if (item.resource_type.toLowerCase().indexOf(searchQuery) > -1) add = true;

        if (!add) continue;
      }

      flatReportData.push(item)

      if (!types.hasOwnProperty(item.resource_type)) {
        types[item.resource_type] = {
          charge: 0,
          name: item.resource_type,
          resources: [],
          visible: false
        }
      }

      types[item.resource_type].resources.push(item)
      types[item.resource_type].charge += item.charge
    }

    setFlatReport(flatReportData)

    let reportData = []
    for (let type in types) {
      reportData.push(types[type])
    }
    setReport(reportData);
  }, [data, search]);

  const toggleCloud = (cloud) => {
    setCloudDropdownOpen(!cloudDropdownOpen);
    if (typeof cloud === "object" && cloud.account === undefined) return;

    setCloud(cloud)
    if (cloud.account === null) {
      setCloudType(cloudTypes[0])
    } else {
      setCloudType(cloud.type)
    }
    setShowCloudTypeFilter(cloud.account === null)

    setData([])
  }

  const toggleCloudType = (type) => {
    setCloudTypeDropdownOpen(!cloudTypeDropdownOpen)
    if (typeof type === "object") return;
    setCloudType(type);
  }

  const toggleDate = (date) => {
    setDateDropdownOpen(!dateDropdownOpen);
    if (typeof date === "object") return;
    setDate(date);
  }

  const toggleVisible = (index) => {
    report[index].visible = !report[index].visible;
    setReport(report);

    // trigger update visibility
    setChange(change + 1);
  }

  const downloadReport = () => {
    if (cloud.account === null) {
      // get all resources for all clouds
      let chosenDate = date.indexOf(' ') < 0 ? moment(date) : moment(date, 'MMM YYYY'),
          start = chosenDate.startOf('month').format('YYYY-MM-DD'),
          end = chosenDate.endOf('month').format('YYYY-MM-DD');

      CostService.cloudResourceCostByMonth(start, end, cloudType, null).then((response) => {
        let resources = []
        for (let item of response.data) {
          resources.push(processItem(item))
        }
        CSVUtility.downloadCSVFromJson('spend-by-resources.csv', resources)
      })
    } else {
      CSVUtility.downloadCSV('spend-by-resources.csv')
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap">
        <h3 className="mb-0 mt-4 tria-header-class">
          Spend by Resources
        </h3>
        <div className="mr-2" style={{'paddingTop': '35px'}}>
          <a style={{'cursor': 'pointer'}} onClick={() => downloadReport() }>
            <i className="fa fa-file-export"></i> Export
          </a>
        </div>
      </div>
      <hr></hr>
      <Card className="card-minimal">
        <div className="d-flex flex-wrap flex-fill" style={{padding: 15 + "px"}}>
          <div style={{width: 280 + "px", paddingTop: 10 + "px"}}>
            <CloudDropdownFilter
              dropdownOpen = {cloudDropdownOpen}
              toggle = {toggleCloud}
              clouds = {clouds}
              cloud = {cloud}
            />
          </div>
          <div style={{width: 120 + "px", paddingTop: 10 + "px", display: showCloudTypeFilter ? 'block' : 'none'}}>
            <CloudTypeDropdownFilter
              dropdownOpen = {cloudTypeDropdownOpen}
              toggle = {toggleCloudType}
              types = {cloudTypes}
              type = {cloudType}
            />
          </div>
          <div style={{width: 120 + "px", paddingTop: 10 + "px"}}>
            <DatesDropdownFilter
              dropdownOpen = {dateDropdownOpen}
              toggle = {toggleDate}
              dates = {dates}
              date = {date}
            />
          </div>
          <div className="row" style={{width: 350 + "px"}}>
            <Input
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              value={search}
              type="text"
              placeholder="Search"
              style={{minWidth: 100 + '%'}}
            />
            <i
              className="fas fa-times-circle"
              id="clearButton"
              style={{marginLeft: -1.25 + "rem", marginTop: .75 + 'rem', cursor: "pointer"}}
              onClick={() => {
                setSearch("");
              }}
            />
          </div>
        </div>
        <CardBody>
          <>
            {isError ? (
                <div className="text-center text-bold">
                  <i className="fas fa-exclamation-triangle"></i>
                  Error Loading Spend By Resources data.
                </div>
              ) : isLoading ? (
                <div className="text-center">
                  <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
                </div>
              ) :
              (!isLoading && !isError && report.length === 0) ? (
                  <div className="text-center mt-4 text-bold">
                    <i className="fas fa-exclamation-triangle"></i> No Spend By Resources data found. Try adjusting your filters.
                  </div>
              ) :
              <div>
                <div className="table-responsive-sm d-none">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">Date</th>
                        <th className="text-center">Cloud name</th>
                        <th className="text-center">Account ID</th>
                        <th className="text-center">Resource Type</th>
                        <th className="text-center">Resource Group</th>
                        <th className="text-center">Resource ID</th>
                        <th className="text-right">Charge</th>
                      </tr>
                    </thead>
                    <tbody>
                  {flatReport.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.date}</td>
                        <td>{row.cloud_name}</td>
                        <td>{row.account}</td>
                        <td>{row.resource_type}</td>
                        <td>{row.resource_group}</td>
                        <td>{row.resource_id}</td>
                        <td className="text-right">{row.charge.toLocaleString("us-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </td>
                      </tr>
                    )
                  })}
                    </tbody>
                  </table>
                </div>
                {report.map((type, i) => {
                  return <div key={i} className="mb-1">
                    <div style={{'borderBottom': 'solid 1px #ccc'}}>
                      <div className="float-right font-weight-bold">
                        {type.charge.toLocaleString("us-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </div>
                      <i className={type.visible ? 'fa fa-minus cursor mr-2' : 'fa fa-plus cursor mr-2'} onClick={() => { toggleVisible(i) }} /> Group: { type.name }
                    </div>
                    <Collapse isOpen={type.visible}>
                      <table className="table table-hover no-csv border-bottom-2">
                        <thead>
                        <tr>
                          <th width="20%" className="d-none d-sm-table-cell">Cloud Name</th>
                          <th width="20%">Type</th>
                          <th width="20%" className={cloud.type === 'AWS' ? 'd-none' : ''}>Group</th>
                          <th>Resource ID</th>
                          <th width="10%" className="text-right">Charge</th>
                        </tr>
                        </thead>
                        <tbody>
                        {type.resources.map((resource, i) => {
                            return (
                              <tr key={i}>
                                <td className="d-none d-sm-table-cell">{resource.cloud_name}</td>
                                <td>{resource.resource_type}</td>
                                <td className={cloud.type === 'AWS' ? 'd-none' : ''}>{resource.resource_group}</td>
                                <td>{resource.resource_id}</td>
                                <td className="text-right">{resource.charge.toLocaleString("us-US", {
                                  style: "currency",
                                  currency: "USD",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}</td>
                              </tr>
                            )
                          }
                        )}
                        </tbody>
                      </table>
                    </Collapse>
                  </div>
                  })}
              </div>
            }
          </>
        </CardBody>
      </Card>
    </>
  );
}

const CloudDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const clouds = props.clouds;
  const cloud = props.cloud;
  const cloudIcons = props.cloudIcons;
  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        {cloud.cloud_name}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {clouds.map((cloud, i) => {
          return (
            <DropdownItem onClick={() => { toggle(cloud) }} key={i}>
              {cloud.cloud_name}
            </DropdownItem>
        )})}
      </DropdownMenu>
    </Dropdown>
  );
}

const CloudTypeDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const types = props.types;
  const type = props.type;
  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        {type}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {types.map((type, i) => {
          return (<DropdownItem onClick={() => { toggle(type) }} key={i}>{type}</DropdownItem>)})
        }
      </DropdownMenu>
    </Dropdown>
  );
}

const DatesDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const dates = props.dates;
  const date = props.date;
  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        {date}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {dates.map((date, i) => {
          return (<DropdownItem onClick={() => { toggle(date.format(DATE_FORMAT)) }} key={i}>{date.format(DATE_FORMAT)}</DropdownItem>)})
        }
      </DropdownMenu>
    </Dropdown>
  );
}

export { SpendByResourcesReport }
