
import moment from "moment";

var REFERENCE = moment(new Date()); // fixed just for testing, use moment();
var TODAY = REFERENCE.clone().startOf('day');
var MONTH = REFERENCE.clone().startOf('month');

var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');


function isToday(momentDate) {
    return momentDate.isSame(TODAY, 'd');
}
function isYesterday(momentDate) {
    return momentDate.isSame(YESTERDAY, 'd');
}
function isWithinAWeek(momentDate) {
    return momentDate.isAfter(A_WEEK_OLD);
}
function isTwoWeeksOrMore(momentDate) {
    return !isWithinAWeek(momentDate);
}
function getReadableDate(date, momentDate) {

    if(isToday(moment(date))) return momentDate;

    if(isYesterday(moment(date))) return momentDate

    return moment(date, "YYYY-MM-DDThh:mm:ss.SSSZ").format('MM/DD/YY');
}

const timezone = date => {
    var zone = new Date(date).toLocaleTimeString('en-us',{timeZoneName:'short', timeZone: 'America/Chicago'}).split(' ')[2];
    return zone;
}

export {
    isToday, isYesterday, isWithinAWeek, isTwoWeeksOrMore, getReadableDate, timezone
}