import React, { useState, Suspense, lazy } from "react";
import CostService from "../services/CostService";

import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import { Bar, Doughnut } from "react-chartjs-2";
import StackedBarChart from "../components/StackedBarChart"
// core components
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardTitle,
  Row,
  Col,
  ButtonGroup,
  TabContent,
  TabPane
} from "reactstrap";
import { TriaSavingsRecommendor } from "../components/TriaSavingsRecommendor"
import { TrustedAdvisorRecs } from "../components/TrustedAdvisorRecs"
import { appState } from "../../AppState";
import CurrentSpendWidget from "../components/CurrentSpendWidget";

import { CloudAccountSpendList } from "../components/CloudAccountSpendList"
import CostUtility from "../utilities/cost-utility"
const getCloudAdvisorWidget =(cloud_type, data) =>{
console.log(cloud_type)
  switch(cloud_type){
    case "AWS":
  return ( <div className="col-lg-4 col-sm-12 mb-2">
  <TrustedAdvisorRecs data={data} type={'AWS'}></TrustedAdvisorRecs></div>
)
    break
    case "AZURE":
     return ( <div className="col-lg-4 col-sm-12 mb-2">
  <TrustedAdvisorRecs data={data} type={'AZURE'}></TrustedAdvisorRecs></div>
)
      break;
    
    default: break;
  }

}
const CostResourceDashboard = props => {
  const [state, setState] = useState({ host: {}, services: [] });
  const [awsMonthlySpend, setAwsMonthlySpend] = useState({});
  const [barChart, setBarChart] = useState({
    datasets: [],
    labels: [],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true
          }
        ]
      }
    }
  });
  //global state
  const [stateApp, stateAppActions] = appState();
  const [savings, setSavings] = useState([]);
  const source = axios.CancelToken.source();
  useAsyncEffect(
    async isMounted => {
      try {

    
        let savings = await CostService.cloudCostSavingsAll(props.match.params.account_id);
        if (!isMounted()) return;
        stateAppActions.setPageNavTitle(savings.data[0].cloud_name +  " Cost Dashboard");
        setSavings(savings)
        
        // setSpendSavings({cost: cost, savings: savings})
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-8 col-sm-12 mb-2">
         <StackedBarChart cloud_id={props.match.params.cloud_id} account_id={props.match.params.account_id}></StackedBarChart>
        </div>
        <div className="col-lg-4 col-sm-12 mb-2">
        <CurrentSpendWidget cloud_id={props.match.params.cloud_id} account_id={props.match.params.account_id} type={'ytd'}></CurrentSpendWidget>
        </div>
      </div>
      <div className="row">
      <div className="col-lg-4 col-sm-12 mb-2">
         <TriaSavingsRecommendor cloud_id={props.match.params.cloud_id} data={savings}></TriaSavingsRecommendor>
        </div>
        {getCloudAdvisorWidget(props.match.params.cloud_id,savings)}
       
      </div>
      <hr></hr>
      <h3 className="mb-0 mt-4">Monthly Breakdown</h3>
      <CloudAccountSpendList cloud_id={props.match.params.cloud_id} account_id={props.match.params.account_id}></CloudAccountSpendList>
  
   
    </>
  );
};

export default CostResourceDashboard;
