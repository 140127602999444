import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const CloudWatchService = {
  getAlarms: function(params ={}) {
    return axios.get(base_api + "/plugin/tria_interface/operations/get/cloudwatchalarms", params);
  }
};

export default CloudWatchService;