const resource_type = {
  topLevelResources: [
    'accessanalyzer',
    'apiaccountingconfig',
    'appserver',
    'autoscalinggroup',
    'autoscalinglaunchconfiguration',
    'backendservice',
    'backupvault',
    'bigdatainstance',
    'bigdatasnapshot',
    'brokerinstance',
    'buildproject',
    'container',
    'containercluster',
    'containerdeployment',
    'containerimage',
    'containerinstance',
    'containerregistry',
    'contentdeliverynetwork',
    'dataanalyticsworkspace',
    'database',
    'datastore',
    'datastream',
    'dbcluster',
    'dbinstance',
    'dbmigrationinstance',
    'dbsnapshot',
    'ddosprotection',
    'deliverystream',
    'directconnect',
    'directoryservice',
    'distributedtable',
    'distributedtablecluster',
    'dnszone',
    'domaingroup',
    'domainuser',
    'emailservicedomain',
    'esinstance',
    'etldatacatalog',
    'etlsecurityconfig',
    'forwardingrule',
    'hypervisor',
    'identityprovider',
    'instance',
    'instancereservation',
    'internetgateway',
    'kubernetesingress',
    'kubernetesservice',
    'loadbalancer',
    'mapreducecluster',
    'mcinstance',
    'mcsnapshot',
    'messagequeue',
    'mlinstance',
    'natgateway',
    'networkendpoint',
    'networkendpointservice',
    'networkflowlog',
    'networkpeer',
    'notificationsubscription',
    'notificationtopic',
    'pod',
    'podsecuritypolicy',
    'privateimage',
    'privatenetwork',
    'privatesubnet',
    'publicip',
    'resourceaccesslist',
    'route',
    'routetable',
    'searchcluster',
    'secret',
    'securefiletransfer',
    'serverlessfunction',
    'serviceaccesskey',
    'serviceaccesspoint',
    'servicealarm',
    'serviceapp',
    'servicecertificate',
    'servicecheck',
    'servicecontrolpolicy',
    'servicecost',
    'servicedataset',
    'servicedetector',
    'servicedomain',
    'serviceencryptionkey',
    'serviceencryptionkeyvault',
    'serviceeventbus',
    'servicegroup',
    'servicelimit',
    'serviceloggroup',
    'servicepolicy',
    'servicerole',
    'serviceuser',
    'sharedfilesystem',
    'sitetositevpn',
    'snapshot',
    'spanner',
    'sshkeypair',
    'stacktemplate',
    'storageaccount',
    'storagecontainer',
    'streaminstance',
    'targetproxy',
    'threatfinding',
    'trafficmirrortarget',
    'transitgateway',
    'userpool',
    'videostream',
    'volume',
    'waf',
    'webapp',
    'webappgroup',
    'workspace',
  ],
  computeResources: [
    'appserver',
    'autoscalinggroup',
    'autoscalinglaunchconfiguration',
    'bigdatainstance',
    'brokerinstance',
    'buildproject',
    'database',
    'dbcluster',
    'dbinstance',
    'distributedtable',
    'distributedtablecluster',
    'emailservicedomain',
    'esinstance',
    'hypervisor',
    'instance',
    'instancereservation',
    'mapreducecluster',
    'mcinstance',
    'messagequeue',
    'mlinstance',
    'notificationsubscription',
    'notificationtopic',
    'privateimage',
    'searchcluster',
    'serverlessapplication',
    'serverlessfunction',
    'serviceapp',
    'stacktemplate',
    'streaminstance',
    'webapp',
    'webappgroup',
    'workspace',
  ],
  containerResources: [
    'container',
    'containercluster',
    'containerdeployment',
    'containerimage',
    'containerinstance',
    'containerregistry',
    'kubernetesingress',
    'kubernetesservice',
    'pod',
    'podsecuritypolicy',
  ],
  storageResources: [
    'backupvault',
    'bigdatasnapshot',
    'coldstorage',
    'dataanalyticsworkspace',
    'datalakestorage',
    'datastore',
    'datastream',
    'dbmigrationinstance',
    'dbsnapshot',
    'deliverystream',
    'etldatacatalog',
    'etlsecurityconfig',
    'mcsnapshot',
    'securefiletransfer',
    'servicedataset',
    'sharedfilesystem',
    'snapshot',
    'spanner',
    'storageaccount',
    'storagecontainer',
    'videostream',
    'volume',
  ],
  networkResources: [
    'backendservice',
    'contentdeliverynetwork',
    'ddosprotection',
    'directconnect',
    'dnszone',
    'forwardingrule',
    'internetgateway',
    'loadbalancer',
    'natgateway',
    'networkendpoint',
    'networkendpointservice',
    'networkflowlog',
    'networkinterface',
    'networkpeer',
    'privatenetwork',
    'privatesubnet',
    'publicip',
    'resourceaccesslist',
    'resourceaccesslistrule',
    'restapi',
    'restapikey',
    'restapistage',
    'route',
    'routetable',
    'sitetositevpn',
    'targetproxy',
    'trafficmirrortarget',
    'transitgateway',
    'waf',
  ],
  identityResources: [
    'accessanalyzer',
    'apiaccountingconfig',
    'directoryservice',
    'divvyorganizationservice',
    'resourcegroup',
    'domaingroup',
    'domainuser',
    'identityprovider',
    'secret',
    'serviceaccesskey',
    'serviceaccesspoint',
    'servicealarm',
    'servicecertificate',
    'servicecheck',
    'servicecontrolpolicy',
    'servicecost',
    'servicedetector',
    'servicedomain',
    'serviceencryptionkey',
    'serviceencryptionkeyvault',
    'serviceeventbus',
    'servicegroup',
    'servicelimit',
    'serviceloggroup',
    'servicepolicy',
    'serviceregion',
    'servicerole',
    'serviceuser',
    'sshkeypair',
    'threatfinding',
    'userpool'
  ],
};

class Enum {}

Enum.ResourceTypes = resource_type;

export default Enum;