
function parseQueryParams() {
    var queryDict = {}
    window.location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]});
    return queryDict;
}
function buildRurl() {
    if (
      window.location.pathname == null ||
      window.location.pathname.length == 0 ||
      window.location.pathname == "/" ||
      window.location.href.indexOf('rurl') > -1
    ) {
      return "";
    }
    //is there already a rurl? if so, use it.
    var queryParams = parseQueryParams();
    if (queryParams[rurl] != null) return queryParams[url];
    //build new rurl
    var rurl = "?rurl=" + window.location.pathname;
    if (window.location.search != null && window.location.search.length > 0) {
      return rurl + "?" + window.location.search;
    }
    return rurl;
  }
  
function range(start, end) {
    let result = []
    for (var i=start; i<end; i++) {
        result.push(i)
    }
    return result;
}

function autosize() {
    // Computes new height for textboxes when user is typing so they can expand
    var el = this;
    setTimeout(function(){
        el.style.height = 'inherit';
    
        // Get the computed styles for the element
        var computed = window.getComputedStyle(el);

        // Calculate the height
        var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                        + parseInt(computed.getPropertyValue('padding-top'), 10)
                        + el.scrollHeight
                        + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                        + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        el.style.height = height + 'px';
    },0);
}


function getDateString() {
  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day =`${date.getDate()}`.padStart(2, '0');
  var minutes = date.getMinutes();
  var hour = date.getHours();
  return `_${year}${month}${day}_${hour}:${minutes}`
}

function insertString(a, b, at)
{
  var position = a.lastIndexOf(at); 

  if (position !== -1)
  {
      return a.substr(0, position) + b + a.substr(position);    
  }  

  return a
}


export {
    parseQueryParams,
    range,
    autosize,
    buildRurl,
    getDateString,
    insertString

}