const Env = {
    LOCAL: 'local',
    DEV : 'dev',
    PROD: 'prod',
    DEMO: 'demo',
    ALL: 'all'
}

const CloudCategory = {
    AWS: 'aws',
    AZURE: 'azure',
    PUBLIC_CLOUD: 'public_cloud',
    VMWARE: 'vmware',
    IBMI: 'ibmi',
    ALL: 'all'
}

const Integration = {
    BLUEMATADOR: 'bluematador',
    CLOUDWATCH: 'cloudwatch',
    TRENDMICRO: 'trendmicro'
}

class Enum{}

Enum.Env = Env;

Enum.CloudCategory = CloudCategory;

Enum.Integration = Integration;

export default Enum;