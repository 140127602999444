import React, { useState, Suspense, lazy } from "react";
import CostService from "../services/CostService";

import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";

// core components
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledCollapse,
  Button,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { appState } from "../../AppState";

import CostUtility from "../utilities/cost-utility";
function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const getUnorderedList = item => {
  let items = item.split("|");
  let html = [];
  for (var i = 0; i < items.length; i++) {
    html.push(<li>{items[i]}</li>);
  }
  return html;
};

const getCollpasibile = (item, idx) => {
  let id = makeid(5)
  return (
    <div key={idx}>
      <span className="pointer"
        color="primary"
        id={id}
        style={{ marginBottom: "1rem" }}
      >
        <h4>
          <i className="fas fa-level-down-alt flip"></i> {item.Name}{" "}
          <span>
            <strong>
              {item.TotalSaving.toLocaleString("us-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
            </strong>
          </span>
        </h4>
      </span>
      <UncontrolledCollapse toggler={"#" + id}>
        <div className="ml-5">
          <ul>{getUnorderedList(item.Detail[0])}</ul>
        </div>
      </UncontrolledCollapse>
    </div>
  );
};

const TrustedAdvisorRecs = props => {
  const [stateApp, stateAppActions] = appState();
  const [isLoading, setIsLoading] = useState(true);
  const [savings, setSavings] = useState([]);
  const [triaSavingsRecs, setTriaSavingsRecs] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    cloud_name: "",
    cloud_type_id: "",
    savingCategories: []
  });
  const selectAccount = name => {
    setSelectedAccount(triaSavingsRecs[name]);
  };
  const findAccountIndex = cloud_name =>
  {
    return savings.data.findIndex(e => e.cloud_name == cloud_name)
  }
  useAsyncEffect(
    async isMounted => {
      try {
       
        let savings =  (props.data) == null ? []: props.data.data
        if(typeof(savings) == 'undefined') return <></>
        let triaRecs = false;
        console.log(savings)
        savings = savings.filter(x => x.cloud_type_id.indexOf(props.type) > -1)
     
        let triaSavings = {};
       
        if (!isMounted()) return;
  
        setSavings(savings);
        setSelectedAccount(savings[0]);
        setTriaSavingsRecs(triaSavings);
        setIsLoading(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {},
    [props.data]
  );
    return (
        <>
         
              <Card style={{minHeight: 275+ 'px'}} className="card-minimal mt-1">
                <CardHeader className="card-header-minimal">
                  <CardTitle className="card-title-minimal" tag="h3">
                    <CardTitle tag="h3">{props.type} Trusted Advisor</CardTitle>
                  </CardTitle>
                  <h5 className="card-category"></h5>
                </CardHeader>
                <hr className="mb-0"></hr>
                <CardBody>
                
                  {isLoading ?
           
                <div className="text-center">
                    <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
                </div>
            : 
    
                  <>
                    <Dropdown
                    isOpen={dropdownOpen}
                    toggle={() => setDropdownOpen(!dropdownOpen)}
                  >
                    <DropdownToggle
                      tag="span"
                      data-toggle="dropdown"
                      aria-expanded={dropdownOpen}
                    >
                      <React.Fragment>
                        <h4 style={{ marginBottom: 15 + "px" }}>
                          <img
                            height="25"
                            width="25"
                            src={`https://tria.connectria.com/divvy/img/${props.type.toLowerCase()}-logo.png`}
                          />{" "}
                          <span>{(selectedAccount || {} ).cloud_name}</span>{" "}
                          <i className="fas fa-caret-down"></i>
                        </h4>
                      </React.Fragment>
                    </DropdownToggle>
                    <DropdownMenu>
                      {savings
                        .filter(e => e.has_tria_recs && e.cloud_name != (selectedAccount || {} ).cloud_name)
                        .map((item, i) => {
                          return (
                            <DropdownItem
                              onClick={() => {
                                selectAccount(savings[findAccountIndex(item.cloud_name)]);
                              }}
                              key={i}
                            >
                              <h5>
                                <img
                                  height="20"
                                  width="20"
                                  src={`https://tria.connectria.com/divvy/img/${((item || {}).cloud_type_id || '').toLowerCase()}-logo.png`}
                                />{" "}
                                <span>{item.cloud_name}</span>{" "}
                              </h5>
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                  <table className="table table-borderless">
                  <thead>
                       <tr>
                         <th>Recommendation</th>
                         <th className="text-right">Est. Monthly Savings</th>
                       </tr>
                     </thead>
                    <tbody>
                    
                  
                  {
                  (selectedAccount || {}).service_checks || [].length == 0 ? ((selectedAccount || {}).service_checks || []).map((item, i) => {
                    return  <tr  key={i}>
                    <td title={item.name} style={{width: 75 + '%'}}>{item.name}</td>
                    <td className="text-right">{item.estimated_monthly_savings}</td>
                  </tr>
                  })
                  : <h4 className="text-center">No {props.type} Trusted Advisor Recommendations Found</h4>
                  }
                {((selectedAccount || {})['service_checks'] || []).length == 0 ? <h4 className="text-center">No {props.type} Trusted Advisor Recommendations Found</h4> : <></>}
                  </tbody>
                 </table>
                  </>
            }
                  
                </CardBody>
              </Card>
        </>
      );
}

export { TrustedAdvisorRecs } 