import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const SolarwindService = {

    forecast: function(params ={}) {
    let res = axios.get(
        base_api + "/plugin/tria_interface/solarwind/forecast",
        params
        );
      return res;
    },
    device_detail: function(deviceId, params ={}) {
      let res = axios.get(base_api + `/plugin/tria_interface/device/${deviceId}`, params);
      
      return res;
    }, 
    device_detail: function(deviceId, params ={}) {
      let res = axios.get(base_api + `/plugin/tria_interface/device/${deviceId}`, params);
      
      return res;
    }, 
    get_device_maintenance_window: function(deviceId, params ={}) {
      let res = axios.get(base_api + `/plugin/tria_interface/device/${deviceId}/mm`, params);
      
      return res;
    },
    get_device_volume_info: function(deviceId, params ={}) {
      let res = axios.get(base_api + `/plugin/tria_interface/device/${deviceId}/volume`, params);
      
      return res;
    },
    create_device_maintenance_window: function(deviceId, payload, params ={}) {
      let res = axios.post(base_api + `/plugin/tria_interface/device/${deviceId}/mm`, payload, params);
      
      return res;
    },
    delete_device_maintenance_window: function(deviceId, params ={}) {
      let res = axios.delete(base_api + `/plugin/tria_interface/device/${deviceId}/mm`, params);
      
      return res;
    },
};

export default SolarwindService;