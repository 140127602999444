import React from "react";
import { useEffect, useState, useRef } from "react";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import moment from 'moment';
import CostService from "../services/CostService";
import {Card, CardHeader, CardBody, CardTitle, ButtonGroup, Button, TabContent, TabPane, CardFooter} from "reactstrap";
import "../css/desktop-widget.css";
import CostUtility from "../utilities/cost-utility";
import { CSVUtility } from "../../utility/table-to-csv";
import {useTablePagination, TablePagination} from "../../components/Pagination";
import Enum from "../../utility/enum.js";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import MonthlyBarChart from "../components/MonthlyBarChart";
import TotalSpendWidget from "../components/TotalSpendWidget";
import {useHistory} from "react-router-dom";
import FeatureToggle from "../../utility/FeatureToggle";

const getHumanReadable = item => {
  let month = item.split("-");

  return CostUtility.monthNames[parseInt(month[1]) - 1] + " " + month[0];
};

const getStartAndEndOfMonth = (month) => {
  const DATE_FORMAT = 'YYYY-MM-DD';
  var d = month === null ? moment() : moment(month, 'MMMM YYYY');
  let startDate = d.startOf('month').format(DATE_FORMAT);
  let endDate = d.endOf('month').format(DATE_FORMAT);
  return {startDate, endDate};
}

const EMPTY_RESOURCE_NAME = 'no-id'

const CloudAccountSpendList = props => {
  const pageSize = 10;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1');
  const accountView = typeof(props.account_id) != 'undefined';
  const source = axios.CancelToken.source();
  const [state, setState] = useState([]);
  const [months, setMonths] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [costData, setCostData] = useState([]);
  const [predictions, setPredictions] = useState({AWS: 0, AZURE_ARM: 0});

  const toggle = item => {
    switch (item) {
      case "month":
        setDropdownOpen(!dropdownOpen);
        break;
      default:
        break;
    }
  };

  const selectMonth = e => {
    let date = e.target.innerText.split(" ");
    let month = CostUtility.monthNames.indexOf(date[0]) + 1;
    month = parseInt(month) > 9 ? month : "0" + month;
    let monthsIndx = months.findIndex(e => e == date[1] + "-" + month);
    setSelectedMonth(getHumanReadable(months[monthsIndx]));
  };

  useAsyncEffect(
    async isMounted => {
      try {
        //lets get all available spend months from the 12 of data
        let aws, awsGov, azure;

        // if we pass in a specific account only call one getcloudspend methods for the passed in cloud
        if (typeof(props.account_id) != 'undefined'){
           aws = props.cloud_id === 'AWS' ? await CostUtility.getCloudSpend("AWS", props.account_id || null) : 
                  props.cloud_id === 'AWS_GOV' ? await CostUtility.getCloudSpend("AWS_GOV", props.account_id || null) : null;
           azure = props.cloud_id === 'AZURE_ARM' ? await CostUtility.getCloudSpend("AZURE", props.account_id || null) : null;
        } else {
          aws =  await CostUtility.getCloudSpend("AWS");
          awsGov =  await CostUtility.getCloudSpend("AWS_GOV");
          azure = await CostUtility.getCloudSpend("AZURE");
        }

        let cost = [];
        if (aws) {
          if (awsGov) {
            awsGov.data.map(function(x, i) {
              aws.data[i].charge += x.charge
            })
          }
          cost.push({ type: "aws", data: aws.data });
        } else if (awsGov) {
          cost.push({ type: "aws", data: awsGov.data });
        }
        if (azure) {
          cost.push({ type: "azure", data: azure.data });
        }
        setCostData(cost);

        //prediction logic
        let awsPrediction = [], azurePrediction= [];
        try{
          if (aws){
            let awsCharges = aws.data.map(a => a.charge);
            if (awsGov) {
              awsGov.data.map((a, i) => awsCharges[i] = awsCharges[i] + a.charge)
            }
            awsPrediction = CostUtility.get_prediction(awsCharges, 1, 1);
          } else if (awsGov) {
            let awsGovCharges = awsGov.data.map(a => a.charge);
            awsPrediction = CostUtility.get_prediction(awsGovCharges, 1, 1);
          }
          if (azure) {
          let azureCharges = azure.data.map(a => a.charge);
           azurePrediction = CostUtility.get_prediction(azureCharges, 1, 1);
          }
           setPredictions({AWS: aws ? awsPrediction[0] : null, AZURE_ARM: azure ? azurePrediction[0] : null})
          }
          catch{
            setPredictions(null)
          }

        // get all aws/azure months
        let awsMonths = aws == null ? [] : aws.data.map(e => e.month);
        let azureMonths = azure == null ? [] : azure.data.map(e => e.month);
        let months = [...new Set(awsMonths.concat(azureMonths))];

        // find min/max dates
        let minDate = moment(), maxDate = null;
        for (let month of months) {
          let curDate = moment(month);
          if (curDate.isBefore(minDate)) {
            minDate = curDate;
          }
          if (maxDate === null || curDate.isAfter(maxDate)) {
            maxDate = curDate;
          }
        }

        // iterate from min to max dates to full a list of months
        months = [];
        for (let m = minDate; m.isSameOrBefore(maxDate); m.add(1, 'month')) {
          months.push(m.format('YYYY-MM'))
        }

        if (!isMounted()) return;
        setMonths(months.reverse());

        setSelectedMonth(
          selectedMonth == null ? getHumanReadable(months[0]) : selectedMonth
        );
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      console.log("unmount canceled");
      source.cancel();
    },
    [props.cloud_id, props.account_id]
  );

  return (
    <>
      <div className="row ">
        <div className="col-lg-12 col-sm-12">
          <Card id="cost-month-select" className={` card-minimal mt-2 `}>
            <CardHeader className="card-header-minimal">
              <div className="d-flex flex-row justify-content-between">
                <Dropdown className="cursor" isOpen={dropdownOpen} toggle={() => toggle("month")}>
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                  >
                    <React.Fragment>
                      <h3 style={{ marginBottom: 15 + "px" }}>
                        <i className="far fa-calendar"></i> {selectedMonth}{" "}
                        <i className="fas fa-caret-down"></i>
                      </h3>
                    </React.Fragment>
                  </DropdownToggle>
                  <DropdownMenu>
                    {months.map(function(item, idx) {
                      return (
                        <DropdownItem onClick={e => selectMonth(e)} key={idx}>
                          {getHumanReadable(item)}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
                <TotalSpendWidget selectedMonth={selectedMonth} data={costData} />
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          <Card className="card-minimal mt-1">
            <CardHeader className="card-header-minimal">
              <CardTitle className="card-title-minimal" tag="h3">
                <CardTitle>Monthly Overview</CardTitle>
              </CardTitle>
              <h5 className="card-category">{selectedMonth}</h5>
            </CardHeader>
            <hr className="mb-0"></hr>
            <CardBody>
              <div>
                <MonthlyBarChart
                  selectedMonth={selectedMonth}
                  data={costData}
                  predictions={predictions}
                ></MonthlyBarChart>
              </div>
            </CardBody>
          </Card>
        </div>
        {!accountView ?
        <div className="col-lg-4 col-sm-12">
          <SpendByAccount selectedMonth={selectedMonth}/>
        </div>
        :
        <div className="col-lg-4 col-sm-12">
          <Card className="card-minimal mt-1">
            <CardHeader className="card-header-minimal">
              <FeatureToggle clouds={[Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.DEV]}>
                <div className="float-left mr-4">
                  <CardTitle className="card-title-minimal" tag="h3">
                    <CardTitle>Spend By</CardTitle>
                  </CardTitle>
                  <h5 className="card-category">{selectedMonth}</h5>
                </div>
                <ButtonGroup size="sm" className="d-flex justify-content-center mb-3" role="group">
                  <Button color="secondary" onClick={() => setActiveTab('1')} active={activeTab === '1'} className="w-100 btn-sm btn-simple">Resources</Button>
                  <Button color="secondary" onClick={() => setActiveTab('2')} active={activeTab === '2'} className="w-100 btn-sm btn-simple">Tags</Button>
                </ButtonGroup>
              </FeatureToggle>
              <FeatureToggle clouds={[Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.DEMO, Enum.Env.PROD]}>
                <div className="float-left mr-4">
                  <CardTitle className="card-title-minimal" tag="h3">
                    <CardTitle>Spend By Resources</CardTitle>
                  </CardTitle>
                  <h5 className="card-category">{selectedMonth}</h5>
                </div>
              </FeatureToggle>
            </CardHeader>
            <hr className="mt-2 mb-0"></hr>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <ResourcesCardBody account_id={props.account_id} accountView={accountView} selectedMonth={selectedMonth}/>
                </TabPane>
                <TabPane tabId="2">
                  <ResourcesTagsCardBody account_id={props.account_id} cloud_id={props.cloud_id} accountView={accountView} selectedMonth={selectedMonth}/>
                </TabPane>
              </TabContent>
            <CardFooter>
              <p><small>*Monthly Figures</small></p>
              {activeTab === "1"
                ? <ResourcesExportButton selectedMonth={selectedMonth} account_id={props.account_id}/> :
                <ResourcesTagsExportButton selectedMonth={selectedMonth} account_id={props.account_id} cloud_id={props.cloud_id}/>
              }
              <button type="button"
                className={"btn btn-light pull-right btn-sm"}
                onClick={() => { history.push(`/app/cloud/cost/${activeTab === "1" ? "spend-resources" : "spend-by-tags-report"}`) }}>
                View Full Report
              </button>
            </CardFooter>
          </Card>
        </div>
        }
        <div className="col-lg-4 col-sm-12">
          <Top10ServiceSpend account_id={props.account_id} accountView={accountView} selectedMonth={selectedMonth} />
        </div>
      </div>
    </>
  );
};

const ResourcesExportButton = (props) => {
  const [costData, setCostData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      setIsLoading(true);
      let {startDate, endDate} = getStartAndEndOfMonth(props.selectedMonth)

      try {
        let resourceCost = await CostService.cloudResourceCostMonths(startDate, endDate, props.account_id);

        if (!isMounted()) return;

        let flatResource = []
        for (let i = 0; i < resourceCost.data.length; i++) {
          // generate column headers for csv
          let details = resourceCost.data[i].details.map(item => {
            return {
              "Account": item.account,
              "Date": props.selectedMonth,
              "Resource Type": item.resource_type,
              "Resource ID": item.resource_id.replace('empty', EMPTY_RESOURCE_NAME),
              "Charge": item.charge,
              "Name": item.cloud_name
            }
          });
          flatResource = flatResource.concat(details);
        }

        setCostData(flatResource);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      console.log("unmount canceled");
      source.cancel();
    },
    [props.selectedMonth, props.account_id]
  );
  return (
    <a href="#" className="btn btn-default btn-sm" onClick={e => !isLoading && CSVUtility.downloadCSVFromJson("Resources Spend Report " + props.selectedMonth + ".csv", costData)}>
      <i className="fa fa-file-export"></i> Export Resources
    </a>
  )
}

const ResourcesTagsExportButton = (props) => {
  const [costData, setCostData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      setIsLoading(true);
      let {startDate, endDate} = getStartAndEndOfMonth(props.selectedMonth)

      try {
        let response = await CostService.cloudTagResourceCostMonths(startDate, endDate, props.cloud_id, props.account_id);

        if (!isMounted()) return;

        let flatResource = []
        for (let i in response.data) {
          let item = response.data[i]
          if (item.tags) {
            for (let tag in item.tags) {
              flatResource.push({
                "Account": item.account_id,
                "Date": props.selectedMonth,
                "Tag": tag,
                "Name": item.user_defined[tag],
                "Resource Type": item.resource_type,
                "Resource ID": item.resource_id.replace('empty', EMPTY_RESOURCE_NAME),
                "Charge": item.charge,
              })
            }
          }
        }

        setCostData(flatResource);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      console.log("unmount canceled");
      source.cancel();
    },
    [props.selectedMonth, props.account_id, props.cloud_id]
  );
  return (
    <a href="#" className="btn btn-default btn-sm" onClick={e => !isLoading && CSVUtility.downloadCSVFromJson("Resources Tags Spend Report " + props.selectedMonth + ".csv", costData)}>
      <i className="fa fa-file-export"></i> Export Resources Tags
    </a>
  )
}


const ResourcesCardBody = (props) => {
  const [resourceCostData, setResourceCostData] = useState([]);
  const { next, prev, jump, currentData, currentPage, maxPage, setCurrentPage } = useTablePagination(resourceCostData, 10);
  const [isLoading, setIsLoading] = useState(false);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let {startDate, endDate} = getStartAndEndOfMonth(props.selectedMonth)

        let resourceCost = await CostService.cloudResourceCostMonths(startDate, endDate, props.account_id);

        if (!isMounted()) return;

        let flatResource = []
        for (var i = 0; i < resourceCost.data.length; i++) {
          //add type
          resourceCost.data[i].details.forEach((element) => {
            element.type = resourceCost.data[i].type;
            element.resource_id = element.resource_id.replace('empty', EMPTY_RESOURCE_NAME)
            if (element.resource_id.indexOf('/subscriptions/') > -1) {
              // cut Azure resource id
              let parts = element.resource_id.split('/')
              element.resource_id_small = parts[parts.length - 1]
              element.resource_id = parts.slice(4).join('/')
            } else {
              // resource_id in other formats
              element.resource_id_small = element.resource_id
            }
          });
          flatResource = flatResource.concat(resourceCost.data[i].details);
        }

        setResourceCostData(flatResource.slice(0, 155));
        setCurrentPage(1);
        setIsLoading(false);

      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [props.selectedMonth, props.account_id]
  );

  return (
    <>
      {isLoading &&
      <div className="text-center">
        <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: '30px' }}></i>
      </div>}
      {!isLoading &&
      <CardBody>
        <h3 className={resourceCostData.length > 0 ? 'd-none ' : 'text-center'}>No Resource Spend Found</h3>
        <table className="w-100 no-csv" id="spendByResource">
          <tbody>
            {currentData()
            .map((item, i) => {
              return (
                <tr className="cursor" key={i}>
                  <td className="cursor">
                    <span>
                      <img height="20" width="20" src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}/>
                    </span>{" "}
                    {item.resource_type}
                  </td>
                  <td><span title={item.resource_id}>{item.resource_id_small}</span></td>
                  <td title={item.charge} className="text-right cursor">
                    {item.charge.toLocaleString("us-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-wrapper mt-3">
          <TablePagination
            pagesCount={maxPage}
            currentPage={currentPage}
            handlePageClick={jump}
            handlePreviousClick={prev}
            handleNextClick={next}
          />
        </div>
      </CardBody>}
    </>
  );
}

const ResourcesTagsCardBody = (props) => {
  const [resourceTagsCostData, setResourceTagsCostData] = useState([]);
  const { next, prev, jump, currentData, currentPage, maxPage, setCurrentPage } = useTablePagination(resourceTagsCostData, 10);
  const [isLoading, setIsLoading] = useState(false);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let {startDate, endDate} = getStartAndEndOfMonth(props.selectedMonth)

        let resourcesTagsCost = await CostService.cloudTagResourceCostMonths(startDate, endDate, props.cloud_id, props.account_id);

        if (!isMounted()) return;

        let flatTagsResources = [];
        let tags = {'Untagged': {resources: [], name: 'Untagged', charge: 0}};
          for (let i = 0; i < resourcesTagsCost.data.length; i++) {
            let item = resourcesTagsCost.data[i];

            if (item.tags) {
              for (let tag in item.tags) {
                if (!tags.hasOwnProperty(tag)) {
                  tags[tag] = {resources: [], name: tag, charge: 0}
                }
                tags[tag].resources.push(item)
                tags[tag].charge += item.charge;
              }
            } else {
              let tag = 'Untagged'
              tags[tag].resources.push(item)
              tags[tag].charge += item.charge;
            }
          }

          // reformat tags to a list
          for (let tag in tags) {
            flatTagsResources.push(tags[tag]);
          }

        setResourceTagsCostData(flatTagsResources);
        setCurrentPage(1);
        setIsLoading(false);

      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [props.selectedMonth, props.account_id, props.cloud_id]
  );

  return (
    <>
      {isLoading &&
      <div className="text-center">
        <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: '30px' }}></i>
      </div>}
      {!isLoading &&
      <CardBody>
        <h3 className={resourceTagsCostData.length > 0 ? 'd-none ' : 'text-center'}>No Resource Tags Spend Found</h3>
        <table className="w-100 no-csv" id="spendByTags">
          <tbody>
            {currentData()
            .map((item, i) => {
              return (
                <tr className="cursor" key={i}>
                  <td className="cursor">
                    {item.name}
                  </td>
                  <td title={item.charge} className="text-right cursor">
                    {item.charge.toLocaleString("us-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-wrapper mt-3">
          <TablePagination
            pagesCount={maxPage}
            currentPage={currentPage}
            handlePageClick={jump}
            handlePreviousClick={prev}
            handleNextClick={next}
          />
        </div>
      </CardBody>}
    </>
  );
}

const SpendByAccount = (props) => {
  const [accountSpend, setAccountSpend] = useState([]);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      try {
        let {startDate, endDate} = getStartAndEndOfMonth(props.selectedMonth)

        let cost = await CostService.cloudAccountCostMonths(startDate, endDate);

        if (!isMounted()) return;

        let flat = []
        for (var i = 0; i < cost.data.length; i++) {
          //add type
          cost.data[i].details.forEach(function(element) {
            element.type = cost.data[i].type;
          });
          flat = flat.concat(cost.data[i].details);
        }

        flat.sort(function(a, b) {
          return b.charge - a.charge;
        });

        setAccountSpend(flat);

      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [props.selectedMonth]
  );

  return (
    <Card className="card-minimal mt-1">
      <CardHeader className="card-header-minimal">
        <CardTitle className="card-title-minimal" tag="h3">
          <CardTitle>Spend By Account</CardTitle>
        </CardTitle>
        <h5 className="card-category">{props.selectedMonth}</h5>
      </CardHeader>
      <hr className="mb-0"></hr>
      <CardBody>
        <table className="w-100 no-csv" id="spendByAccount">
          <tbody>
            {accountSpend.map((item, i) => {
              return (
                <tr key={i}>
                  <td>
                    <span>
                      <img
                        height="20"
                        width="20"
                        src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}
                      />
                    </span>{" "}
                    {item.cloud_name}
                  </td>
                  <td className="text-right">
                    {item.charge.toLocaleString("us-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p><small>*Monthly Figures</small></p>
      </CardBody>
    </Card>
  );
}

const Top10ServiceSpend = (props) => {
  const source = axios.CancelToken.source();
  const [serviceCostData, setServiceCostData] = useState([]);

  useAsyncEffect(
    async isMounted => {
      try {
        let {startDate, endDate} = getStartAndEndOfMonth(props.selectedMonth)

        let serviceCost = await CostService.cloudServiceCostMonths(startDate, endDate, props.account_id);

        if (!isMounted()) return;

        let flatService = []
        for (var i = 0; i < serviceCost.data.length; i++) {
          //add type
          serviceCost.data[i].details.forEach(function(element) {
            element.type = serviceCost.data[i].type;
          });
          flatService = flatService.concat(serviceCost.data[i].details);
        }

        setServiceCostData(flatService);

      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [props.selectedMonth, props.account_id]
  );

  return (
    <Card className="card-minimal mt-1">
      <CardHeader className="card-header-minimal">
        <CardTitle className="card-title-minimal" tag="h3">
          <CardTitle>{props.accountView == false ? 'Top 10 Service Spend' : 'Service Spend'}</CardTitle>
        </CardTitle>
        <h5 className="card-category">{props.selectedMonth}</h5>
      </CardHeader>
      <hr className="mb-0"></hr>
      <CardBody>
        <table className="w-100 no-csv" id="spendByService">
          <tbody>
            {serviceCostData
              .filter(item => props.account_id ? item.account == props.account_id : true)
              .map((item, i) => {
              if (i < (props.accountView === false ? 10 : serviceCostData.length)) {
                return (
                  <tr className="" key={i}>
                    <td className="text-left">
                      <span>
                        <img
                          height="20"
                          width="20"
                          src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}
                        />
                      </span>{" "}
                      {item.cloud_name}
                    </td>
                    <td className="text-left">{item.resource_type}</td>

                    <td className="text-right">
                      {item.charge.toLocaleString("us-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
        <p>
          <small>*Monthly Figures</small>
        </p>
      </CardBody>
    </Card>
  );
}

export { CloudAccountSpendList };
