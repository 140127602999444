import React from "react";
import {useEffect, useState} from "react"
import axios from "axios";
import moment from 'moment';

import useAsyncEffect from "../../utility/use-async-effect";

import CookieService from "../../services/CookieService";
import PublicCloudService from "../../public_cloud/services/service";
import CostService from "../services/CostService";

// reactstrap components
import {Input} from "reactstrap";
import {Card, CardBody, Collapse} from "reactstrap";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {appState} from "../../AppState";
import {useHistory} from "react-router-dom";
import {CSVUtility} from "../../utility/table-to-csv";
import {NavBarBackButton} from "../../components/misc";


const DATE_FORMAT = 'MMM YYYY', EMPTY_RESOURCE_NAME = 'no-id';

const SpendByTagsReport = (props) => {
  const source = axios.CancelToken.source();
  const [stateApp, stateAppActions] = appState();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [report, setReport] = useState([]);
  const [flatReport, setFlatReport] = useState([]);

  const [cloud, setCloud] = useState({cloud_name: 'All clouds', account: null});
  const [clouds, setClouds] = useState([]);
  const [cloudDropdownOpen, setCloudDropdownOpen] = useState(false);

  const [date, setDate] = useState(
    stateApp.date !== null
      ? moment(stateApp.date, 'YYYY-MM-DD').format(DATE_FORMAT)
      : moment().format(DATE_FORMAT)
  );

  let start = moment().startOf('month'), months = [start];
  for (let m = 1; m < 12; m++) {
    months.push(moment().subtract(m, 'months'));
  }
  const [dates, setDates] = useState(months);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);

  const [change, setChange] = useState(0);

  useEffect(() => {
    stateAppActions.setPageBackTitle(<NavBarBackButton route="/app/cloud/cost/dashboard" text={"Spend Dashboard"}/>);
  }, []);

  // Get cloud accounts list
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        let cloudAccounts = await CostService.getCloudAccountsWithCosts(
          moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        );

        if (!isMounted()) return;
        setClouds(cloudAccounts);

        // set cloud chosen in Costs Dashboard
        if (stateApp.account_id !== null) {
          for (let n in cloudAccounts) {
            if (cloudAccounts[n].account === stateApp.account_id) {
              setCloud(cloudAccounts[n]);
            }
          }
        }

        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);

          // request cancelled
        } else {
          console.log(error);
          setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  // load tags and resources
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        let chosenDate = date.indexOf(' ') < 0 ? moment(date) : moment(date, 'MMM YYYY'),
          start = chosenDate.startOf('month').format('YYYY-MM-DD'),
          end = chosenDate.endOf('month').format('YYYY-MM-DD');

        let tagsCostResponse = await CostService.cloudTagResourceCostMonths(start, end, cloud.type, cloud.account);

        if (!isMounted()) return;

        setData(tagsCostResponse.data);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    [date, cloud]
  );

  useEffect(() => {
    let tags = {}, flat = [];

    let searchQuery = search.toLowerCase().trim();

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      item.resource_id = item.resource_id.replace('empty', EMPTY_RESOURCE_NAME)
      if (item.resource_id.indexOf('/subscriptions/') > -1) {
        // cut Azure resource id
        item.resource_id = item.resource_id.split('/').slice(4).join('/')
      }

      if (item.tags) {
        for (let tag in item.tags) {
          let newItem = {...item};
          newItem.name = item.user_defined[tag];

          if (search.length > 2) {
            let add = false;
            if (newItem.resource_id.toLowerCase().indexOf(searchQuery) > -1) add = true;
            if (newItem.name.toLowerCase().indexOf(searchQuery) > -1) add = true;

            if (!add) continue;
          }

          if (!tags.hasOwnProperty(tag)) {
            tags[tag] = {resources: [], name: tag, charge: 0, visible: false}
          }

          tags[tag].resources.push(newItem);
          tags[tag].charge += item.charge;

          let flatItem = {...newItem};
          flatItem.tag = tag;
          flat.push(flatItem)
        }
      } else {
        let tag = 'Untagged'

        let newItem = {...item};
        newItem.name = '(no tag)';

        if (search.length > 2) {
          let add = false;
          if (newItem.resource_id.toLowerCase().indexOf(searchQuery) > -1) add = true;
          if (newItem.name.toLowerCase().indexOf(searchQuery) > -1) add = true;

          if (!add) continue;
        }

        if (!tags.hasOwnProperty(tag)) {
          tags[tag] = {resources: [], name: tag, charge: 0, visible: false}
        }

        tags[tag].resources.push(newItem)
        tags[tag].charge += item.charge;

        let flatItem = {...newItem};
        flatItem.tag = tag;
        flat.push(flatItem)
      }
    }

    // set flat results to table
    let tagsList = [];
    for (let tag in tags) {
      tagsList.push(tags[tag]);
    }

    setReport(tagsList);
    setFlatReport(flat);
  }, [data, search]);

  const toggleCloud = (cloud) => {
    setCloudDropdownOpen(!cloudDropdownOpen);
    if (typeof cloud === "object" && cloud.account === undefined) return;
    setCloud(cloud);
  }

  const toggleDate = (date) => {
    setDateDropdownOpen(!dateDropdownOpen);
    if (typeof date === "object") return;
    setDate(date);
  }

  const toggleVisible = (index) => {
    report[index].visible = !report[index].visible;
    setReport(report);

    // trigger update visibility
    setChange(change + 1);
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap">
        <h3 className="mb-0 mt-4 tria-header-class">
          Spend by Tags Report
        </h3>
        <div className="mr-2" style={{'paddingTop': '35px'}}>
          <a style={{'cursor': 'pointer'}} onClick={() => { CSVUtility.downloadCSV('spend-by-tags.csv') }}>
            <i className="fa fa-file-export"></i> Export
          </a>
        </div>
      </div>
      <hr></hr>
      <Card className="card-minimal">
        <div className="d-flex flex-wrap flex-fill" style={{padding: 15 + "px"}}>
          <div style={{width: 280 + "px", paddingTop: 10 + "px"}}>
            <CloudDropdownFilter
              dropdownOpen = {cloudDropdownOpen}
              toggle = {toggleCloud}
              clouds = {clouds}
              cloud = {cloud}
            />
          </div>
          <div style={{width: 120 + "px", paddingTop: 10 + "px"}}>
            <DatesDropdownFilter
              dropdownOpen = {dateDropdownOpen}
              toggle = {toggleDate}
              dates = {dates}
              date = {date}
            />
          </div>
          <div className="row" style={{width: 350 + "px"}}>
            <Input
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              value={search}
              type="text"
              placeholder="Search"
              style={{minWidth: 100 + '%'}}
            />
            <i
              className="fas fa-times-circle"
              id="clearButton"
              style={{marginLeft: -1.25 + "rem", marginTop: .75 + 'rem', cursor: "pointer"}}
              onClick={() => {
                setSearch("");
              }}
            />
          </div>
        </div>
        <CardBody>
          <>
            {isError ? (
                <div className="text-center text-bold">
                  <i className="fas fa-exclamation-triangle"></i>
                  Error Loading Spend By Tags data.
                </div>
              ) : isLoading ? (
                <div className="text-center">
                  <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
                </div>
              ) :
              (!isLoading && !isError && report.length === 0) ? (
                  <div className="text-center mt-4 text-bold">
                    <i className="fas fa-exclamation-triangle"></i> No Spend By Tags data found. Try adjusting your filters.
                  </div>
              ) :
              <div className="table-responsive-sm">
                <table className="d-none">
                  <tbody>
                  {flatReport.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.tag}</td>
                        <td>{row.name}</td>
                        <td>{row.resource_type}</td>
                        <td>{row.resource_group}</td>
                        <td>{row.resource_id}</td>
                        <td>{row.charge}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
                {report.map((item, i) => {
                  return (
                    <div key={i} className="mb-1">
                      <div style={{'width': '33%'}}>
                        <div className="float-right font-weight-bold">
                          {item.charge.toLocaleString("us-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })}
                        </div>
                        <i className={item.visible ? 'fa fa-minus cursor mr-2' : 'fa fa-plus cursor mr-2'} onClick={() => { toggleVisible(i) }} /> Tag: { item.name }
                      </div>
                      <Collapse isOpen={item.visible}>
                        <table className="table table-hover no-csv border-bottom-2">
                          <thead>
                          <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th className={cloud.cloud_type_id === 'AWS' ? 'd-none' : ''}>Group</th>
                            <th>Resource ID</th>
                            <th className="text-right">Charge</th>
                          </tr>
                          </thead>
                          <tbody>
                          {item.resources.map((resource, i) => {
                              return (
                                <tr key={i}>
                                  <td>{resource.name}</td>
                                  <td>{resource.resource_type}</td>
                                  <td className={cloud.cloud_type_id === 'AWS' ? 'd-none' : ''}>{resource.resource_group}</td>
                                  <td>{resource.resource_id}</td>
                                  <td className="text-right">{resource.charge.toLocaleString("us-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                  })}</td>
                                </tr>
                              )
                            }
                          )}
                          </tbody>
                        </table>
                      </Collapse>
                    </div>
                  )
                })}
              </div>
            }
          </>
        </CardBody>
      </Card>
    </>
  );
}

const CloudDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const clouds = props.clouds;
  const cloud = props.cloud;
  const cloudIcons = props.cloudIcons;
  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        {cloud.cloud_name}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {clouds.map((cloud, i) => {
          return (
            <DropdownItem onClick={() => { toggle(cloud) }} key={i}>
              {cloud.cloud_name}
            </DropdownItem>
        )})}
      </DropdownMenu>
    </Dropdown>
  );
}

const DatesDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const dates = props.dates;
  const date = props.date;
  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        {date}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {dates.map((date, i) => {
          return (<DropdownItem onClick={() => { toggle(date.format(DATE_FORMAT)) }} key={i}>{date.format(DATE_FORMAT)}</DropdownItem>)})
        }
      </DropdownMenu>
    </Dropdown>
  );
}

export { SpendByTagsReport }
