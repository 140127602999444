/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState,  useEffect, Suspense, lazy } from "react";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import FadeIn from "react-fade-in";

import useAsyncEffect from "../utility/use-async-effect";
import axios from "axios";
import NewTicketButton from "../components/SupportAndTicketing/NewTicketButton"
import { CountdownSeconds } from "../components/IBMHost/IBMHost";
// core components
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardTitle,
  Row,
  Col,
  ButtonGroup,
  TabContent,
  TabPane
} from "reactstrap";

import IBMService from "../services/IBMService";

import Utils from "../utility/ibm_utility";

import { IBMLineChart } from "../components/IBMChart/LineChart";

import { appState } from "../AppState";

import IbmHostReportDashboard from "../views/IbmHostReportDashboard"
import IbmHostUtilizationDashboard from "../views/IbmHostUtilizationDashboard"


import { useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const IbmHostDashboard = props => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [state, setState] = useState({ host: {}, services: [] });
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [reportTabLoaded, setReportTabLoaded] = useState(false);
  const [utilizationTabLoaded, setUtilizationTabLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const history = useHistory();
  const [isMonitoringDisabled, setIsMonitoringDisabled] = useState(false);
  //global state
  const [stateApp, stateAppActions] = appState();
  //auto refresh fun
  const [count, setCount] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [timeLeft, setTimeLeft] = useState(15);
  const source = axios.CancelToken.source();

  useEffect(() => {
    stateAppActions.setPageBackTitle(
      <a href="/app/ibm-dashboard">
      <span className="text-primary"><i className="fas fa-chevron-left mr-1"></i>LPARS</span>
    </a>
    );
  }, []);

  useAsyncEffect(
    async isMounted => {
      try {
        
        let detail = await IBMService.details(props.match.params.id,{
          cancelToken: source.token
        });
        let hostsRes = await IBMService.hosts({
          cancelToken: source.token
        });
        if (!isMounted()) return;
        //stateAppActions.setPageNavTitle("LPAR DETAIL");
        setState({
          host: detail.data.hosts[0],
          services: detail.data.hosts[0].services
        });
        setHosts(hostsRes.data.map(host => { return {'host_id': host.host_id, 'host_name': host.host_name}; }));
        //is monitoring enabled
        if (Utils.get("Active Jobs", detail.data.hosts[0].services) == "") {
          setIsMonitoringDisabled(true);
        } else {
          setIsMonitoringDisabled(false);
        }

        setIsLoading(false);
        
        ////////lets set up our auto refresh all
        if (intervalId == null) {
          setIntervalId(
            setInterval(async () => {
              flip_bits();
            }, 15000)
          );
        }
        async function flip_bits() {
          setTimeLeft(0);
          setTimeLeft(15);
         
          setCount(prevTime => prevTime + 1);
         
        }
        ////////////////
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [count]
  );


  const toggleMenuDropdown = (item) => {
    setDropdownMenuOpen(!dropdownMenuOpen);
    if(typeof item === "object") return;
    window.location.href = '/app/ibm/host/' + item;
  };
  
  const toggleTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
    if (activeTab === "1") {
      setAutoRefresh(false);
    } else if (activeTab === "2") {
      setAutoRefresh(false);
      setReportTabLoaded(true);
    } else {
      setAutoRefresh(false);
      setUtilizationTabLoaded(true);
    }
  }

  const setRefresh = () => {
    setAutoRefresh(autoRefresh ? false : true);
  };
  
  return (
    <>
      <h3 className="mb-3 mt-4 tria-header-class">
        <div className="row">
          <div className="col-sm-3">
            <IbmHostMenuDropdown dropdownOpen={dropdownMenuOpen} toggle={toggleMenuDropdown} hosts={hosts} currentHost={state.host} />
          </div>
          <div className="col-sm-9 col-xl-6">
            <ButtonGroup role="group" className={"d-flex my-2 justify-content-center" + (isMonitoringDisabled ? " d-none" : "")}>
              <Button onClick={() => toggleTab('1')} active={activeTab === '1'} className={`btn-sm w-100 ${(activeTab !== '1') ? "btn-simple" : ""}`} style={{maxWidth:"250px", border:"unset"}}>Dashboard</Button>
              <Button onClick={() => toggleTab('2')} active={activeTab === '2'} className={`btn-sm w-100 ${(activeTab !== '2') ? "btn-simple" : ""}`} style={{maxWidth:"250px", border:"unset"}}>Hardware/Software Detail</Button>
              <Button onClick={() => toggleTab('3')} active={activeTab === '3'} className={`btn-sm w-100 ${(activeTab !== '3') ? "btn-simple" : ""}`} style={{maxWidth:"250px", border:"unset"}}>Utilization Trends</Button>
            </ButtonGroup>
          </div>
        </div>
      </h3>
      <hr />
      {isMonitoringDisabled ? (
          <div className="text-center mt-4 text-bold">
            <h3 className="mb-3 text-muted">LPAR Monitoring Disabled</h3>
              <NewTicketButton subject={`Enable LPAR Monitoring for ${state.host.host_name}.`}>
                <i className="fas fa-question-circle"></i> <span style={{color:"#0098f0"}}>Create Support Ticket</span>
              </NewTicketButton>
          </div>
          ) : (
            <>
              <TabContent activeTab={activeTab} id="tab-content">
                  <TabPane tabId="1">
                    <FadeIn delay={500}>
                      {/* <div className="p-10">
                        <span className="switch d-none d-sm-block">
                          <input
                          checked="checked"
                            type="checkbox"
                            onClick={() => {
                              setRefresh();
                            }}
                            className="switch"
                            id="switch-id"
                          />
                          <label htmlFor="switch-id">Auto-Refresh</label>
                        </span>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-4">
                          <Card className="card-minimal">
                            <CardHeader className="card-header-minimal">
                              <h5 className="card-category">LPAR</h5>
                              <CardTitle className="card-title-minimal" tag="h3">
                                {state.host.host_name} -{" "}
                                {Utils.get(
                                  "Version Information",
                                  state.services,
                                  false,
                                  true
                                )}
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <div>
                                {//Check if message failed
                                isLoading ? (
                                  <div className="text-center">
                                    <i
                                      className="fas fa-spinner m-3 mt-4 fa-spin"
                                      style={{ fontSize: "30px" }} />
                                  </div>
                                ) : (
                                  <>
                                    <Row>
                                      <Col sm="12">
                                        <table className="table">
                                          <tbody>
                                            <tr>
                                              <th scope="row">System Model:</th>
                                              <td>
                                                {Utils.get(
                                                  "Model",
                                                  state.services,
                                                  true,
                                                  true
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Serial Number:</th>
                                              <td>
                                                {Utils.get(
                                                  "Serial Number",
                                                  state.services,
                                                  true,
                                                  true
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">CPUs:</th>
                                              <td>
                                              {Utils.get("Number of Processors", state.services)}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Processor Feature:</th>
                                              <td>
                                                {Utils.get(
                                                  "Processor Feature",
                                                  state.services,
                                                  true,
                                                  true
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Memory:</th>
                                              <td>{Utils.getAppended("Memory", state.services, " (GB)")}</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Storage:</th>
                                              <td>
                                                {Utils.getAppended(
                                                  "ASP Total Disk Space",
                                                  state.services,
                                                  " (GB)"
                                                ).length == 0 ?
                                                Utils.getAppended(
                                                  "ASP 1 Disk Space",
                                                  state.services,
                                                  " (GB)"
                                                ) : Utils.getAppended(
                                                  "ASP Total Disk Space",
                                                  state.services,
                                                  " (GB)"
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Page Faults: </th>
                                              <td>
                                                {Utils.get("Page Faults", state.services)}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Total Jobs:</th>
                                              <td>
                                                {Utils.get(
                                                  "Total Jobs in System",
                                                  state.services
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Active Jobs:</th>
                                              <td>
                                                {Utils.get("Active Jobs", state.services)}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Interactive Jobs:</th>
                                              <td>
                                                {Utils.get(
                                                  "Interactive Jobs",
                                                  state.services
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Batch Jobs:</th>
                                              <td>
                                                {Utils.get(
                                                  "Active Batch Jobs",
                                                  state.services
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">Max Jobs:</th>
                                              <td>
                                                {Utils.get("Maximum Jobs", state.services)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                          <Card className="card-minimal">
                            <CardHeader className="card-header-minimal">
                              <CardTitle className="card-title-minimal" tag="h3">
                                Custom Monitors
                                <div className="pull-right">
                                  <NewTicketButton subject={`Add a Custom Monitor to LPAR ${state.host.host_name}.`}>
                                    <i className="fas fa-plus"></i>
                                  </NewTicketButton>
                                </div>
                              </CardTitle>
                              <CardBody>
                                <div className="mr-auto ml-auto mt-4 mb-3" style={{maxWidth:"300px"}}>
                                  <p className="h4">You can request a Custom Monitor by clicking the plus icon in the header above.</p>
                                </div>
                              </CardBody>
                            </CardHeader>
                          </Card>
                        </div>
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-xl-6">
                              <IBMLineChart tooltipPrefix="CPU"
                              displayUnit="%"
                                type="CPU Utilization"
                                auto_refresh={autoRefresh}
                                host={state.host}
                              ></IBMLineChart>
                            </div>
                            <div className="col-xl-6">
                              <IBMLineChart
                              tooltipPrefix="Disk"
                              displayUnit="%"
                                type="Disk Utilization"
                                auto_refresh={autoRefresh}
                                host={state.host}
                              ></IBMLineChart>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-6">
                              <Card className="card-minimal">
                                <CardHeader className="card-header-minimal">
                                  <h5 className="card-category">{state.host.host_name}<span className="pull-right">Refreshing in approx. <CountdownSeconds timeLeft={timeLeft}></CountdownSeconds> seconds...</span></h5>
                                  <CardTitle className="card-title-minimal" tag="h3">
                                    Memory Pools
                                  </CardTitle>
                                </CardHeader>
                                <CardBody style={{minHeight: 150 + 'px'}}>
                                  {//Check if message failed
                                  isLoading ? (
                                    <div className="text-center">
                                      <i
                                        className="fas fa-spinner m-3 mt-4 fa-spin"
                                        style={{ fontSize: "30px" }}
                                      ></i>
                                    </div>
                                  ) : (
                                    <div>
                                      {Utils.get(
                                        "Memory Pools",
                                        state.services,
                                        true,
                                        true,
                                        true
                                      ).length > 0 ?
                                      Utils.get(
                                        "Memory Pools",
                                        state.services,
                                        true,
                                        true,
                                        true
                                      )
                                        .split("\\n")
                                        .map((item, key) => {
                                          return <p key={key}>{item}</p>;
                                        }) : <p>No Items In Memory Pool</p>}
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            </div>
                            <div className="col-xl-6">
                              <Card className="card-minimal">
                                <CardHeader className="card-header-minimal">
                                  <h5 className="card-category">{state.host.host_name}<span className="pull-right">Refreshing in approx. <CountdownSeconds timeLeft={timeLeft}></CountdownSeconds> seconds...</span></h5>
                                  <CardTitle className="card-title-minimal" tag="h3">
                                    Jobs In Message Wait:{" "}
                                    <span
                                      className={Utils.getStatusIcon(
                                        "Jobs in MSGW",
                                        Utils.get("Jobs in MSGW", state.services)
                                      )}
                                    >
                                      {Utils.get("Jobs in MSGW", state.services)}
                                    </span>
                                  </CardTitle>
                                </CardHeader>
                                <CardBody style={{minHeight: 150 + 'px'}}>
                                  {//Check if message failed
                                  isLoading ? (
                                    <div className="text-center">
                                      <i
                                        className="fas fa-spinner m-3 mt-4 fa-spin"
                                        style={{ fontSize: "30px" }}
                                      ></i>
                                    </div>
                                  ) : (
                                    <div>
                                      {Utils.get(
                                        "Jobs in MSGW",
                                        state.services,
                                        true,
                                        true,
                                        true
                                      ).length > 0 ? Utils.get(
                                        "Jobs in MSGW",
                                        state.services,
                                        true,
                                        true,
                                        true
                                      )
                                        .split("\\n")
                                        .map((item, i) => {
                                          if(item.indexOf('return status value is 0') > -1) return <p key={i}>No Jobs In Msg Wait</p>
                                          if(item.indexOf('return status value is') > -1) return <p key={i}>&nbsp;</p>
                                          return <p key={i}>{item}</p>;
                                        }): <p>No Jobs in MSGW</p>}
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                      </FadeIn>
                    </TabPane>
                    <TabPane tabId="2">
                      <FadeIn delay={500}>
                        {(activeTab == 2 || reportTabLoaded) && <IbmHostReportDashboard id={props.match.params.id}></IbmHostReportDashboard>}
                      </FadeIn>
                    </TabPane>
                    <TabPane tabId="3">
                      <FadeIn delay={500}>
                        {(activeTab == 3 || utilizationTabLoaded) && <IbmHostUtilizationDashboard id={props.match.params.id}></IbmHostUtilizationDashboard>}
                      </FadeIn>
                    </TabPane>
              </TabContent>
            </>
      )}
    </>
  );
};

const IbmHostMenuDropdown = props => {
  let options = props.hosts.filter(
    (host) => { return host.host_id !== props.currentHost.host_id }
  ).map(
    (host, i) => { return <DropdownItem key={i} onClick={() => {props.toggle(host.host_id)}}>{host.host_name}</DropdownItem> }
  );

  return (
    <Dropdown isOpen={props.dropdownOpen} toggle={props.toggle} style={{display: "inline-block", zIndex: 1}}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={props.dropdownOpen}
      >
        <React.Fragment>
          {"LPAR: " + (props.currentHost.host_name ? props.currentHost.host_name : "")}
        </React.Fragment>
        <i className={options.length==0 ? "d-none" : "" + " fas fa-caret-" + (props.dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {options}
      </DropdownMenu>
    </Dropdown>
  )
}

const IbmLparsBackButton = () => {
  const history = useHistory();
  return (
    <>
      <a href="#" onClick={(e) => { history.push("/app/ibm-dashboard")}}>
        <span className="text-primary"><i className="fas fa-chevron-left mr-1"></i>LPARS</span>
      </a>
    </>
  );
}

export default IbmHostDashboard;
