import React, { Fragment } from 'react';
import { useHistory } from "react-router-dom";

import {TabContent, TabPane, Row, Col, ButtonGroup, Button} from "reactstrap";

const DevicesWidget = () => {
  const history = useHistory();
  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <h3 className="text-left">Devices</h3>
        <p className="text-left mt-3">View your device inventory</p>
      </div>
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {history.push("/app/devices")}}>
        View Devices
      </button>
    </div>
  );
};

export { DevicesWidget };
