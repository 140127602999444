import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const AlertsService = {
  getAzureAlerts: function(params ={}) {
    return axios.get(base_api + "/plugin/tria_interface/azure/monitor/alerts", params);
  }
};

export default AlertsService;