
import { appState } from "../AppState";
import Enum from "./enum";

const FeatureToggle = props => {
  const [stateApp, stateAppActions] = appState();

  const shouldIshow = (envs) => {
    try{
      if (typeof envs == "undefined") return false;
      if ((envs.length > 0) && (envs[0] == Enum.Env.ALL)) return true;

      return envs.includes((stateApp.env || Enum.Env.DEV));
    }
    catch{

        return false;
    }
  };

  const matchedIntegrations = (state, integrations) => {
    try{
      if (typeof integrations == "undefined") return true;
      for(var i = 0; i < (integrations || [] ).length; i++){
        if(state.integrations.includes(integrations[i])) return true;
      }
      return false;
    }
    catch{
        return false;
    }
  }

  const criteriaMet = (env,cloud, integrations) =>{

    return(shouldIshow(env, props)&& matchedClouds(stateApp, cloud) && matchedIntegrations(stateApp, integrations));
  }
  return criteriaMet(props.envs, props.clouds, props.integrations) ? props.children : null;
};

export default FeatureToggle;

export function matchedClouds(state, clouds) {
  for(var i = 0; i < (clouds || [] ).length; i++){
    if(clouds[i] == Enum.CloudCategory.ALL) return true;
    if(state.clouds.includes(clouds[i])) return true;
  }
  return false;
}