/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classesFtimezone

import classnames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react-grid
// import { Responsive, WidthProvider } from 'react-grid-layout';
// import '../../node_modules/react-grid-layout/css/styles.css'
// import '../../node_modules/react-resizable/css/styles.css'

import { appState } from "../AppState";
import TabbedDashboard from "../components/Dashboard/TabbedDashboard";
import DesktopDashboard from "../components/Dashboard/DesktopDashboard";
import { DynamicDashboardComponents } from "../components/Dashboard/DynamicDashboard";
//const ResponsiveGridLayout = WidthProvider(Responsive);
import { ResourceMatrix } from "../public_cloud/components/ResourceMatrix";
import { dashBoardConfig, dashBoardConfigIbmi } from "../routes.js";
import Enum from "../utility/enum.js";
import ConnectriaTicketsService from "../services/ConnectriaTicketsService";
import CostService from "../costexplorer/services/CostService";
//setTimeout handle for resize debounce
var waitForIt;
const _retries = 3
let retryCount = 0;
const loadCache = async (stateApp, stateAppActions) => {
  try{
  let isCustomer = stateApp.userInfo.organization_id != 0;
  let cc = ConnectriaTicketsService.getAllUsersLike({}, isCustomer).then();

  let categories = await ConnectriaTicketsService.ticketCategories({}).then();

  let devices = await ConnectriaTicketsService.getAllDevicesLike({
   
    ar_assoc: 'devicetype',
    limit: 100
}).then()
  
  }catch(e){
    console.error(e);
    if(retryCount < _retries){
    //loadCache(stateApp, stateAppActions)
    retryCount++; 
  }else{
    //set to empty
    console.error('setting empty state');
    stateAppActions.setCache({ key: "connectriaTicketCC", data: [] });
    stateAppActions.setCache({
      key: "connectriaTicketCategories",
      data: [],
    });
    stateAppActions.setCache({
      key: "connectriaTicketDevices",
      data: [],
    });
    stateAppActions.setCache({
      key: "costSavings",
      data: [],
    });
  }
  }
 
};

function ResponsiveDashboard(props) {
  //global state
  const [stateApp, stateAppActions] = appState();
  useEffect(() => {
    loadCache(stateApp, stateAppActions);
  }, []);
  let checker = (arr, target) => target.every((v) => arr.includes(v));
  if (props.width == 0) {
    return null;
  }
  var dconfig = dashBoardConfigIbmi;
  if (stateApp.clouds.includes(Enum.CloudCategory.PUBLIC_CLOUD)) {
    dconfig = dashBoardConfig;
  }

  if (props.width <= 768) {
    return (
      <div id="db-pane">
        <TabbedDashboard>
          <DynamicDashboardComponents
            config={dconfig}
          ></DynamicDashboardComponents>
        </TabbedDashboard>
      </div>
    );
  } else {
    return (
      <div id="db-pane">
        <div className="row">
          <DynamicDashboardComponents
            config={dconfig}
          ></DynamicDashboardComponents>
        </div>
      </div>
    );
  }
}

const Dashboard = (props) => {
  //local state
  const [width, setWidth] = useState(0);
  //global state
  const [stateApp, stateAppActions] = appState();
  useEffect(() => {
    loadCache(stateApp, stateAppActions);
  }, []);
  useEffect(() => {
    // stateAppActions.setPageNavTitle("TRiA HOME");
    window.addEventListener("resize", () => {
      console.log("resized event caught");
      if (window.innerWidth > 0 && window.innerWidth != width) {
        if (waitForIt) {
          clearTimeout(waitForIt);
        }
        waitForIt = setTimeout(() => {
          setWidth(window.innerWidth);
        }, 300);
      }
    });
    if (window.innerWidth > 0) {
      setWidth(window.innerWidth);
    }
  }, []);

  return (
    <>
      <ResponsiveDashboard width={width} />
    </>
  );
};

export default Dashboard;
