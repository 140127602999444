import styled from "styled-components";

export const WizardTopContainer = styled.div`
  display: grid;

`;

export const WizardHeader = styled.header`
  // background: #19ad76;
  // padding: 20px;
  // color: white;
  // font-size: 15px;
  // font-weight: 700;
  // & > * {
  //   margin: 0;
  // }
`;

export const WizardContainer = styled.div`
  padding: 20px;
`;

export const WizardFooter = styled.footer`
  border-top: 1px solid #dddddd;
  padding: 20px;
  background: #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ButtonGroup = styled.div`
  &:nth-child(1) > button {
    margin-right: 5px;
  }
`;
