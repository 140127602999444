import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const ComplianceService = {

    getCloudsList: function(payload, params) {
        let res = axios.get(
            base_api + "/v2/public/clouds/list",{params: payload},
            params
        );
      return res;
    },
    getCompliancePacks: function(params = {}){
        let res = axios.get(base_api + "/plugin/tria_interface/watching_compliance_packs", params);
        return res;
    },
    getComplianceData: function(packId, source, params){
        let res = axios.get(base_api + "/v2/prototype/" + packId +"/"+ source +"/counts/get",params);
        return res;
    }
};

export default ComplianceService;