import React, { useEffect, useState } from "react";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import { appState } from "../../AppState";
import { useHistory } from "react-router-dom";
import { NewTicketForm } from "../ConnectriaTickets/ConnectriaTicketsSupportDashboard";
import { NavBarBackButton } from "../misc";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody
} from "reactstrap";

const ConnectriaNewTicketFullPage = (props) => {
  const [stateApp, stateAppActions] = appState();
  const sentProps = props.location.state;

  useEffect(() => {
    stateAppActions.setPageBackTitle(<NavBarBackButton route="/app/support" text={"Support & Ticketing"}/>);
  }, []);

  return (
    <>
    <h3 className="mb-3 mt-4 tria-header-class">
      Create New Ticket
    </h3>
    <hr></hr>
    <Card className="card-minimal">
      <CardHeader className="card-header-minimal">
        <CardTitle className="card-title-minimal">
        </CardTitle>
      </CardHeader>
      <CardBody>
        <NewTicketForm fullPage={true} textAreaRows={5} {...sentProps}></NewTicketForm>
      </CardBody>
    </Card>
    </>

  );
}

export { ConnectriaNewTicketFullPage }