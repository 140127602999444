import React from "react";
import { useState } from "react"

import axios from "axios";
import moment from "moment";
import useAsyncEffect from "../../utility/use-async-effect";

import Resources from "../services/resources";


const SecurityCheckDescriptionBlade = (props) => {
  const source = axios.CancelToken.source();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({communications: []});

  // Get Security check description
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let response = await Resources.getServiceCheckDescription(props.id);
        setIsLoading(false);

        if (!isMounted()) return;
        setData(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <>
      {isError ? (
        <div className="text-center text-bold">
          <i className="fas fa-exclamation-triangle"></i>
          Error loading Security Check description.
        </div>
      ) :
      isLoading ? (
        <div className="text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
      ) :
        <div dangerouslySetInnerHTML={{ __html: data.description  }}></div>
      }
    </>
  );
}

export { SecurityCheckDescriptionBlade }
