import axios from "axios";
import moment from "moment";

import ConnectriaTicketsService from './ConnectriaTicketsService';

const base_api = 'https://sandbox.tria.connectria.com';


async function _getPortalCustomerMeta(filter, params={}) {
  try {
    let response = await axios.get(base_api + "/plugin/support/portal/customer_meta/get", { ...params, params: filter });
    if ((response.data.response.errors != null) && (response.data.response.errors.length > 0)) {
      return { 
        metadata: {
          meta_value: "FALSE"
      }};
    }
    return response.data.response.data.customer_meta[0];
  } catch (error) {
    console.log(error);
    throw(error);
  }
}


const PortalService = {
  getPortalCustomerMeta: async function (filter, params={}) {
    filter["customer_id"] = await ConnectriaTicketsService.portalUserId(params);
    return await _getPortalCustomerMeta(filter, params);
  },
};

export default PortalService;
