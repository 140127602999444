import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IBMService from "../../services/IBMService";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import moment from 'moment';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle
} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Line } from "react-chartjs-2";
import { Flippy, FrontSide, BackSide } from "react-flippy";
import NewTicketButton from "../SupportAndTicketing/NewTicketButton";
import Utils from "../../utility/ibm_utility";
import LabsModal from "../../utility/Modal"

const findItem = (name, arr) => {
  let item = arr.filter(obj => {
    return obj.name === name;
  });

  return item;
};

const parseValue = (value, status_text_long) => {
  if ((value || "").indexOf("cannot find") > -1) return "";
  if ((status_text_long || "").indexOf("com.ibm") > -1) return "";
  value = value.replace("=", ":");
  if (value.indexOf(":") > -1) {
    let stripped = value.split(":")[1].trim();
    var floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
    if (stripped.match(floatValues)) {
      let parsed = stripped.match(floatValues)[0];
      return parsed;
    }
    return stripped;
  }
};
const get = (service_name, services, default_text = "") => {
  let item = findItem(service_name, services);

  if (item.length === 0) return default_text

  let returnVal = parseValue(item[0].value, item[0].status_text_long);
  return (returnVal || "").length > 0 ? returnVal : default_text
};

const getChart = async (name, host) => {

  //default to 24 hours
  var currentTime = moment().unix()
  var previousDay = moment().subtract(1, 'd').unix()

  let response = await IBMService.chart_series(host.host_name, name,previousDay, currentTime);
  if (response.data.error) return;

  let timeseries = response.data.data.row.map(obj => {
    return new Date(parseInt(obj.t) * 1000);
  });

  let data = response.data.data.row.map(obj => {
    return Number(obj.v).toFixed(2);
  });

  let y = {
    labels: timeseries,
    datasets: [
      {
        label: host.display_name + " " + name,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data
      }
    ]
  };

  return y;
};

const IBMHost = props => {
  var flippyIBMi;
  const [carousel, setCarousel] = useState(0);

  return (
    <Flippy
      style={{
        height: "100%",
        paddingBottom: 10 + "px"
      }}
      ref={r => (flippyIBMi = r)}
      flipOnClick={false}
    >
      <FrontSide>
        <IBMHostCard
          ibm_host={props.host}
          setCarousel={setCarousel}
          viewDashBoard={() => viewIbmHostDashboard}
          onToggle={() => flippyIBMi.toggle()}
          auto_refresh={props.auto_refresh}
        ></IBMHostCard>
      </FrontSide>
      <BackSide>
        <IBMHostBackSide
          ibm_host={props.host}
          carousel={carousel}
          onToggle={() => flippyIBMi.toggle()}
          auto_refresh={props.auto_refresh}
        ></IBMHostBackSide>
      </BackSide>
    </Flippy>
  );
};

const IBMHostCard = props => {

  let host = props.ibm_host;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [interval, setCustomInterval] = useState(null);
  const history = useHistory();
  const [isMonitoringDisabled, setIsMonitoringDisabled] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  let setCarousel = props.setCarousel;

  useEffect(() => {
    //test for enabled monitoring
    if (Utils.getHostStatus(host) === "disabled") {
      setIsMonitoringDisabled(true);
    } else {
      setIsMonitoringDisabled(false);
    }
  }, []);

  return (
    <Card
      className=" card-minimal h-100 mb-0"
    >
      <CardHeader className="card-header-minimal">
        <h5 className="card-category" style={{fontSize: "1.0rem"}}>LPAR STATUS <span className={"dot " + Utils.getHostStatus(host)} style={{width: "10px", height: "10px"}}></span></h5>
        <CardTitle className="card-title-minimal" tag="h3" style={{fontSize: "1.5rem"}}>
          {host.host_name}

          {/* <Dropdown
            className="pull-right"
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              aria-expanded={dropdownOpen}
            >
              <Button className="btn btn-link m-0 p-0 card-notification-bell pull-right">
                <span className="tim-icons icon-minimal-down text-success"></span>
              </Button>
            </DropdownToggle>
            <DropdownMenu right> */}
              {/* <DropdownItem disabled title="Coming Soon">
                Create Support Ticket
              </DropdownItem>
              <DropdownItem disabled title="Coming Soon">
                Alerts
              </DropdownItem> */}
              {/* <DropdownItem onClick={() => {history.push("/app/ibm/host/" + host.host_id )}} title="Dashboard">
                View Dashboard
              </DropdownItem>
              <DropdownItem  onClick={() => {history.push("/app/ibm/host/report/" + host.host_id )}} title="Report">
                View Report
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </CardTitle>
      </CardHeader>
      <hr className="mb-0"></hr>
      <CardBody>
        {isMonitoringDisabled ? (
          <div className="text-center mt-4 mb-4 text-bold">
            <h3 className="text-muted mb-3">LPAR Requires Updates to Display Monitoring</h3>
            <NewTicketButton subject={`LPAR updates inquiry for ${host.host_name}`} description={`What updates are required for LPAR ${host.host_name} to display monitoring in TRiA?`}>
              <span style={{color:"#0098f0"}}>Create Support Ticket</span>
            </NewTicketButton>
          </div>
          ) : (
            <div>
              <table
                className={
                  host.services == 0 ? "d-none" : "table table-borderless"
                }
              >
                <tbody md-body="" className="">
                  <tr scope="row">
                    <td>Active Jobs</td>
                    <td style={{fontSize: "1rem"}}>{get("Active Jobs", host.services,"N/A")}</td>
                  </tr>
                  <tr scope="row">
                    <td>CPU Utilization</td>
                    <td style={{fontSize: "1rem"}}>
                      <span>
                        {Utils.getColoredSpan(
                          "CPU Utilization",
                          get("CPU Utilization", host.services, "N/A"),
                          "%"
                        )}
                      </span>
                      <i
                        onClick={() => {
                          props.onToggle();
                          setCarousel(0);
                        }}
                        className="far fa-chart-bar fa-2x pull-right"
                      ></i>
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>Disk Utilization</td>
                    <td style={{fontSize: "1rem"}}>
                      {Utils.getColoredSpan(
                        "Disk Utilization",
                        get("Disk Utilization", host.services, "N/A"),
                        "%"
                      )}{" "}
                      <i
                        onClick={() => {
                          props.onToggle();
                          setCarousel(1);
                        }}
                        className="far fa-chart-bar fa-2x pull-right"
                      ></i>
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>Jobs In Message Wait</td>
                    <td style={{fontSize: "1rem"}}>
                      {Utils.getColoredSpan("Jobs in MSGW",
                       get("Jobs in MSGW", host.services, "N/A"), ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                className={
                  host.services == 0
                    ? "h3 d-flex justify-content-center align-items-center"
                    : "d-none"
                }
                style={{ paddingTop: 50 + "px" }}
              >
                <p>0 Services</p>
              </div>
              <div
                className={host.services == 0 ? "d-none" : ""}
                style={{ padding: 10 + "px" }}
              >
                <button type="button" className="btn btn-light pull-right  btn-sm" onClick={() => {history.push("/app/ibm/host/" + host.host_id )}}>
                  {" "}
                  <span
                    style={{ display: "inline", verticalAlign: "middle" }}
                    className="fas fa-chart-line"
                  ></span>{" "}
                  LPAR Detail
                </button>
              </div>
            </div>
          )
        }
      </CardBody>
    </Card>
  );
};
const getOptions = type =>{
  switch(type){
    case 'cpu':
      let customText = ''
      let displayUnit = ''
        return {
          title: { text: "" },plugins: {
            labels: {
              render: () => {}
            },
            datalabels: {display: false}
          },
          tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    return 'CPU' + ' ' + tooltipItem.yLabel + '%';
                }
            }
        },
          response: true,
          legend: { display: true },
          scales: {
            xAxes: [
              {
                title: "time",
                type: "time",
      
                gridLines: {
                  lineWidth: 2
                },
              
              }
            ]
          }
        };
 

    case 'disk':
  
       return {
          title: { text: "" },plugins: {
            labels: {
              render: () => {}
            },
            datalabels: {display: false}
          },
          tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    return 'Disk' + ' ' + tooltipItem.yLabel + 'GB';
                }
            }
        },
          response: true,
          legend: { display: true },
          scales: {
            xAxes: [
              {
                title: "time",
                type: "time",
      
                gridLines: {
                  lineWidth: 2
                },
              
              }
            ]
          }
        };
 

    default:
    break;
  }
}

const CountdownSeconds = (props) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setSeconds(props.timeLeft);
    const interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSeconds(props.timeLeft);
  }, [props.timeLeft]);

  return (
    <span>{seconds}</span>
  );
};

const IBMHostBackSide = props => {
  const [chart, setChart] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [interval, setCustomInterval] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);
  let carousel = props.carousel;
  let ibm_host = props.ibm_host;
  const source = axios.CancelToken.source();
  const toggle = () => setShowModal(!showModal);
  if (typeof ibm_host == "undefined") return "";

  useAsyncEffect(

    async isMounted => {
    
      try {
        setIsLoading(true);
        async function update_graphs() {
          let cpu = await getChart("CPU Utilization", ibm_host);
          let disk = await getChart("Disk Utilization", ibm_host);
          if (isMounted) {
            setChart({ cpu: cpu, disk: disk });
            setTimeLeft(0);
            setTimeLeft(15);
            setIsLoading(false);
          }  
        }
        async function create_interval() {
          setInterval(async () => {
            update_graphs();
          }, 15000);
        }
        await update_graphs();
        // if (props.auto_refresh) create_interval();
        // if (!props.auto_refresh) clearInterval(interval);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [props.auto_refresh]
  );

  return (
    <>
      <Card className={" card-minimal h-100"}>
        <CardBody className="h-102 line-chart-host-dashboard">
        <i onClick={() => toggle()} className="fas fa-expand cursor pull-left"></i>
          <Button
            className="btn btn-link m-0 p-0 card-notification-bell pull-right"
            onClick={() => props.onToggle()}
          >
            <span className="tim-icons icon-double-left"></span>
          </Button>
          <div style={{ paddingTop: 0 + "px" }}>
            <Carousel selectedItem={carousel} showThumbs={false}>
              <div>
                {//Check if message failed
      
          <>
          {/* <p><span className="">Refreshing in approx. <CountdownSeconds timeLeft={timeLeft}></CountdownSeconds> seconds...</span></p> */}
        
          <LabsModal className={"modal-full"} showModal={showModal} title={''} componenet={carousel == 0 ? <Line data={chart.cpu || {}} options={getOptions('cpu')} /> :  <Line  data={chart.disk || {}} options={getOptions('disk')} />}></LabsModal>
          <Line data={chart.cpu || {}} options={getOptions('cpu')} />
                <small>*Last 24 hours</small>
                </>
                }
              </div>
              <div>
                {//Check if message failed
     
          <>
           {/* <><span className="">Refreshing in approx. <CountdownSeconds timeLeft={timeLeft}></CountdownSeconds> seconds...</span></> */}
                <Line  data={chart.disk || {}} options={getOptions('disk')} />
                <small>*Last 24 hours</small>
                </>
                
              
                }
          
              </div>
            </Carousel>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export { IBMHostBackSide, IBMHostCard, IBMHost, CountdownSeconds };
