import { Wizard, WizardStep, parentCallback } from "../Wizard";
import React, { useState } from "react";
var QRCode = require("qrcode.react");
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import UserService from "../../services/UserService";
import { appState } from "../../AppState";
import { useHistory } from "react-router-dom";
const MfaWizard = props => {
  const [modal, setModal] = useState(props.showMfa || false);
  const [mfaid, setMfa] = useState("");
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();
  const toggle = () => {
    setModal(!modal); 
    if (props.setShowMfa) {
      props.setShowMfa(!modal);
    }
  }
  const cancel = async () => {
 
    try {
      await UserService.disable_mfa({ user_id: stateApp.userInfo.user_id });
    } catch (e) {
      console.error(e);
    }
    toggle();
  };
  const callback = async count => {

    if (count == 1 && mfaid == "") {
      let mfa = await UserService.enable_mfa({
        user_id: stateApp.userInfo.user_id
      });
      setMfa(mfa.data.otp_secret || "");
      console.log(mfa.data.otp_secret);
    }
    // do something with value in parent component, like save to state
  };
  const success = async () => {
    await UserService.set_mfa_required({
      user_resource_ids: [`${stateApp.userInfo.resource_id}`],
      required: true
    });
    await UserService.logout();
    history.push("/login");
  };
  return (
    <Modal isOpen={modal}>
      {/* <ModalHeader toggle={x}>Enable 2FA</ModalHeader> */}
      <ModalBody>
        <Wizard
          onSubmit={() => success()}
          onCancel={() => cancel()}
          parentCallback={callback}
          header=""
        >
          <WizardStep>
            <p className="h3">
              This functionality requires 2FA enabled on your account.
            </p>
            <p>
              2FA can be used to help protect your account from unauthorized
              access by requiring you to enter an additional code when you sign
              in.
            </p>
            <p>
              The Two-Factor Authentication feature currently supports the use
              of an authenticator app such as{" "}
              <a href="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345">
                {" "}
                Google Authenticator
              </a>
            </p>
          </WizardStep>
          <WizardStep>
            <h3>Authenticator App Instructions</h3>
            <ul>
              <li>Click the checkbox next to Authentication app.</li>
              <li>Scan the QR code, click Next.</li>
              <li>
                Once you click complete you will be signed out and asked to log
                back in, enter the code generated by your authentication app,
                then click login.
              </li>
            </ul>
            <div className="text-center">
              {" "}
              <QRCode
                value={`otpauth://totp/${(stateApp.userInfo || {}).username ||
                  ""}?secret=${mfaid}&issuer=TRiA-Labs`}
              />
              <p>
                Or Manually Enter the Code:{" "}
                <strong style={{ fontWeight: "bold" }}>{mfaid}</strong>
              </p>
            </div>
          </WizardStep>
          <WizardStep>
            <h2>
              <i className="fas 2x fa-check-circle text-success"></i> Complete 2FA
              Enrollment
            </h2>
            <p>
              Once you hit submit we will log you out and you will log back in
              with you username and password and finally the 6 digit code
              generated from your Authenticator mobile app.
            </p>
          </WizardStep>
        </Wizard>
      </ModalBody>
    </Modal>
  );
};
export default MfaWizard;
