import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const IBMService = {
    

    hosts: function(params={}) {
      let request = axios.get(
        base_api + "/plugin/tria_interface/iseries",
        params
        );
      return request;
    },
    details: function(host_id, params={}){
      let request = axios.get(
        base_api + "/plugin/tria_interface/iseries/detail/" + host_id,
        params
      );
      return request;
    },
    chart_series: function(host, service, start, end, params ={}){
      let request = axios.get(base_api + `/plugin/tria_interface/iseries/export?host_name=${host}&service_description=${service}&start=${start}&end=${end}`,params);
      return request
    },
    service_metric_history: function(host_id, service_name, start_date, days_back = 15,params ={}){
      let request = axios.post(
        base_api + `/plugin/tria_interface/clouds/ibm/servicemetric/history`,
        {
          host_id: host_id,
          service_name: service_name,
          start_date: start_date,
          days_back: days_back,
          ...params
      }
        )
      return request
    }
    
};

export default IBMService;