import CostService from "../services/CostService";
export default class CostUtils {
  static getCloudColor = cloud => {
    let colors = {
      AWS: "#fabf08",
      AWS_GOV: "#fabf08",
      AZURE_ARM: "#33adee",
      AZURE: "#33adee",
      AZURE_GOV: "#33adee"
    };

    return colors[cloud];
  };
  static monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  static rollupSavings = payload => {
    let result = {};
    let overallSavings = 0;
    let overallSavingsCount = 0;
    for (var i = 0; i < (payload || []).length; i++) {
      if (typeof result[payload[i].cloud_type_id] == "undefined")
        result[payload[i].cloud_type_id] = {}; //initlaize the object

      if ((Object.keys(payload[i].cost_savings || {})).length > 0) {
        //this has a tria advisor response and use this instead of aws/azure truested advisor

        result[payload[i].cloud_type_id]["total_savings"] =
          (result[payload[i].cloud_type_id]["total_savings"] || 0) +
            payload[i].cost_savings.TotalSaving || 0;
        overallSavings += result[payload[i].cloud_type_id]["total_savings"];
        overallSavingsCount++;
      } else if (payload[i].service_checks.length > 0) {
        //roll up the check savings
        //cant do additional assign since total savings is initally unknown
        overallSavingsCount += payload[i].service_checks.length;
        result[payload[i].cloud_type_id]["total_savings"] =
          (result[payload[i].cloud_type_id]["total_savings"] || 0) +
          payload[i].service_checks.reduce(
            (a, b) => +a + +b.estimated_monthly_savings,
            0
          );
        overallSavings += result[payload[i].cloud_type_id]["total_savings"];
      } else result[payload[i].cloud_type_id]["total_savings"] += 0;
    }
    result.allSavings = overallSavings;
    result.overallSavingsCount = overallSavingsCount;
    return result;
  };
  static getForecastedSpend = (spend, type = 'm') => {

 
    let thisTime = new Date();
    let prevTime = new Date(thisTime.getFullYear(), thisTime.getMonth(), 1);
    let diff = thisTime.getTime() - prevTime.getTime();
    let hours = parseInt(diff / (1000 * 60 * 60));
    let forecastedSpend = ((spend)/ hours) * 24 * (type == 'ytd' ? 30.5 : 30.5);

    return forecastedSpend;
  };
  static float2dollar = value => {
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  static getCloudSpend = async (cloud_type, account_id = null) => {
    try {
      return await CostService.costByMonth(cloud_type, account_id);
    } catch (e) {
      console.error(e);
    }
    return null;
  };
  //returns predicted month + 1 (pass in this month 1 and get next month predction) spend based on array of historical data.. need atleast 6 months
  static get_prediction = (cost_arr, month = 1, forecastMonths = 1) =>{
    //month represents position in the cost_data array 1 would be current month, 2 would be last month etc
    let cost_data = cost_arr.splice(0,(cost_arr.length -month));
    if (cost_data.length >= 6) {
      var d = new Date();
      let day = d.getDay();
      
      let forcastedMonthsArray = [], year = d.getFullYear();

      for (var i = 1; i <= forecastMonths; i++) {
        var month = d.getMonth() + (i + 1); // Since getMonth() returns month from 0-11 not 1-12 and add an additional 1 to get us to the next month
        forcastedMonthsArray.push(year + "-" + "0" + month);
      }
      
      const historyIndex = cost_data.map((d, i) => [i, d]);

      let forecastedValues = forcastedMonthsArray.map((d, i) =>
        this.predict(historyIndex, historyIndex.length - 1 + i)
      );
     
      // remove negative values
      for (let n in forecastedValues) {
        if (forecastedValues[n] < 0) forecastedValues[n] = 0;
      }

      return forecastedValues;
     

  };
}
  static predict = (data, newX) => {
    const round = n => Math.round(n * 100) / 100;

    const sum = data.reduce(
      (acc, pair) => {
        const x = pair[0];
        const y = pair[1];

        if (y !== null) {
          acc.x += x;
          acc.y += y;
          acc.squareX += x * x;
          acc.product += x * y;
          acc.len += 1;
        }

        return acc;
      },
      { x: 0, y: 0, squareX: 0, product: 0, len: 0 }
    );
    const run = sum.len * sum.squareX - sum.x * sum.x;
    const rise = sum.len * sum.product - sum.x * sum.y;
    const gradient = run === 0 ? 0 : round(rise / run);
    const intercept = round(sum.y / sum.len - (gradient * sum.x) / sum.len);
    return round(gradient * newX + intercept);
  };
}
