import React, { useState } from "react";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

  const DesktopOnlyWidget = props =>{
      return (

        isMobile ? <div className="text-center"><h3> This page is unavailable on mobile</h3></div>
        : props.children
        
      )
  }

  export  { DesktopOnlyWidget }