import React, { Fragment } from "react";
import { useEffect, useState } from "react"

import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";

import {Card, CardHeader, CardBody, CardTitle, ButtonGroup, Button} from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {appState} from "../../AppState";
import Resources from "../services/resources";
import {SecurityCheckDescriptionBlade} from "./SecurityAdvisorDetailBlade";
import { useHistory } from "react-router-dom";
import moment from "moment";

const sortByStatus = (a, b) => {
  switch (a) {
    case "ok":
      if (b === "ok") return 0;
      else return 1;
    case "warning":
      if (b === "ok") return -1;
      if (b === "error") return 1;
      else return 0;
    case "error":
      if (b === "error") return 0;
      else return -1;
  }
}

const getCheckStatusCounts = (checks) => {
  var statusCounts = {"ok": 0, "warning": 0, "error": 0};
  for (var check of checks) {
    statusCounts[check.servicecheck.status]++;
  }
  return statusCounts;
}

const SecurityAdvisorWidget = (props) => {
  const title = (props.cloudType == "AWS" ? "AWS" : "Azure") + ' Security Advisor';
  const detailUrl = "/app/cloud/" + (props.cloudType == "AWS" ? "aws" : "azure") + "-security-advisor";
  const source = axios.CancelToken.source();
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [resources, setResources] = useState([]);
  const [checkCounts, setCheckCounts] = useState({});
  const [allChecksOk, setAllChecksOk] = useState(true);
  const DATETIME_FORMAT = 'YYYY-MM-DD hh:mm:ss', today = moment();

  useAsyncEffect(
    async isMounted => {
      try {
        let response = await Resources.getSecurityResources();
        
        if (!isMounted()) return;
        
        let sortedResources = response.data.resources
          .filter((resource) => {
            resource.dt = moment(resource.servicecheck.common.discovered_timestamp, DATETIME_FORMAT)
            return !(resource.servicecheck.status === 'ok' && today.diff(resource.dt, 'days') > 90)
          })
          .filter((resource) => { return resource.servicecheck.common.cloud === props.cloudType })
          .sort((a, b) => sortByStatus(a.servicecheck.status, b.servicecheck.status));
        setCheckCounts(getCheckStatusCounts(sortedResources));
        setResources(sortedResources.slice(0, 3));
        for (const resource of sortedResources) {
          if (resource.status != "ok") {
            setAllChecksOk(false);
            break;
          }
        }

        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );
  
  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <h3 className="text-left">{title}</h3>
        {isLoading ? (
        <div className="text-center">
            <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
        ) : (
        resources.length==0 ?
          <div className="w-100 justify-content-center">There are no Security Advisor checks available</div> :
        allChecksOk ? 
          <div className="w-100 justify-content-center">All Security Advisor checks have OK status</div> :
          <>
          <SecurityCheckCounts {...checkCounts} url={detailUrl}/>
          <div className="table-responsive-sm">
            <table className="table">
              <tbody>
                {resources.map((resource, i) => {
                  return (
                    <tr key={i}>
                      <td style={{width: "25px"}}>{getStatusIcon(resource.servicecheck.status)}</td>
                      <td>
                        <div className="row"><b className="pr-1">Cloud:</b>{resource.servicecheck.common.account}</div>
                        <div className="row flex-nowrap d-flex text-truncate"><b className="pr-1">Check:</b>{resource.servicecheck.name}</div>
                      </td>
                    </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
          </>
        )}
      </div>
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push(detailUrl, {statusFilter: ""});
        }}
      >
        {" "}
        <span
          style={{ display: "inline", verticalAlign: "middle" }}
          className="fas fa-chart-line"
        ></span>{" "}
        View All
      </button>
    </div>
  );

}

const SecurityCheckCounts = (props) => {
  const history = useHistory();
  return (
    <div className="d-flex w-100" style={{padding: "12px", justifyContent: "space-evenly"}}>
      <i className="cursor fas fa-check text-success" style={{fontSize: "1rem"}}
        onClick={() => {history.push(props.url, {statusFilter: "ok"}) }}> {props.ok}
      </i>
      <i className="cursor fas fa-exclamation-triangle text-warning" style={{fontSize: "1rem"}}
        onClick={() => { history.push(props.url, {statusFilter: "warning"}) }}> {props.warning}
      </i>
      <i className="cursor fas fa-bell text-danger" style={{fontSize: "1rem"}}
        onClick={() => { history.push(props.url, {statusFilter: "error"}) }}> {props.error}
      </i>
    </div>
  );
}

const getStatusIcon = (status) => {
  switch (status) {
    case 'ok':
      return <i className="fas fa-check text-success"></i>;
    case 'warning':
      return <i className="fas fa-exclamation-triangle text-warning"></i>
    case 'error':
      return <i className="fas fa-bell text-danger"></i>
  }
}

export {SecurityAdvisorWidget}