import React, { useEffect, useState, useRef } from "react";
import { appState } from "../../AppState";
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { isMobile, isTablet } from "react-device-detect";

export const ToggleSwitch = (props) => {
  return (
    <label className="switch">
      <input type="checkbox" {...props} />
      <span className="slider round" />
    </label>
  )
}

const ButtonCollapse = ({ label, title, className, children, ...props }) => {
  const [stateApp, stateAppActions] = appState();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const containerRef = useRef(null);

  const toggle = (e) => {
    if (isMobile || isTablet) {
      e.preventDefault();
      stateAppActions.setContentPanel({
        title: title || "",
        visible: true,
        content: children
      });
    } else {
      setIsOpen(!isOpen);
    }
    
    if (props.toggleCallback) {
      props.toggleCallback();
    }
  }

  const handleDocumentClick = (e) => {
    var button = buttonRef?.current;
    var container = containerRef?.current;

    if (button && container && e.target !== container && !container.contains(e.target) && !button.contains(e.target)) {
      toggle();
    }
  }

  useEffect(() => {
    isOpen ?
      document.addEventListener('click', handleDocumentClick, true) :
      document.removeEventListener('click', handleDocumentClick, true)
  }, [isOpen]);

  return (
    <div className={`${className || ''} position-relative`}>
      <Button innerRef={buttonRef} className="collapse-button" color="blue" onClick={toggle}>
        <div className="d-flex justify-content-center">
          <i className="fas fa-plus my-auto pr-2"/>
          <p className="my-auto tria-btn" style={{color: "#ffffff"}}>{label}</p>
        </div>
      </Button>
      <div
        ref={containerRef}
        className={!isOpen ? 'd-none' : ''}
        style={{zIndex: "999", position: "absolute", right: "3px", width: "315px"}}
      >
        <Card className="card-minimal" style={{top: "15px"}}>
        <CardHeader className="card-header-minimal" style={{paddingLeft: "25px", paddingTop: "30px"}}>
            <CardTitle className="card-title-minimal tria-card-title">
              {title}
            </CardTitle>
          </CardHeader>
          <CardBody style={{paddingLeft: "35px", paddingRight: "35px"}}>
            {children}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ButtonCollapse;
