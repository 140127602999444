import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const OrganizationService = {
  
  customer_type: function(params = {}){
    let request = axios.get(
      base_api + "/plugin/tria_interface/customer_type",
      params
    );
    return request;
  },


  customer_message: function() {
    return axios.get(base_api + `/plugin/tria_interface/customer_message`);
  },

  list_orgs: function(params = {}){
    let request = axios.get(
      base_api + "/v2/prototype/domain/organizations/get",
      params
    );
    return request;
  },

  switch_org: function(params= {}) {
    let request = axios.post(
      base_api + "/v2/prototype/domain/switch_organization",
        params.payload,
        params.headers
    );
    return request;
  },

};

export default OrganizationService;
