import axios from "axios";

const base_api = 'https://sandbox.tria.connectria.com';

const CommonService = {

    getResProperties: function(orgId, params={}) {
        console.log("I am in commonservice")
        console.log(orgId)
    let res = axios.get(
        base_api + "/plugin/tria_interface/resource_properties/" + orgId,
        params
        );
      return res;
    },
    setResProperty: function(params){
        console.log(params)
        let res = axios.post(base_api + '/plugin/tria_interface/set_resource_property', params)
        return res
    },
    addCloud: function(params){
        let res = axios.post(base_api + '/v2/prototype/cloud/add', params)
        return res
    },
    status: function(params){
        let res = axios.get(base_api + '/Status', params)
        return res
    },
    statusOk: async function(params){
        try{
        let res = await axios.get(base_api + '/Status', params);
        return res.data == '<STATUS>OK</STATUS>'
        }
        catch{
            return false;
        }
    }


};

export default CommonService;