import moment from "moment";
import React, { useEffect, useState } from "react";

const getFormattedDate = (date) => {
  if(date == null) return null;
  try{
  let newDate = date.split(" ");

  switch (newDate[0]) {
    case "Janurary":
      return newDate[1] + "-01";
    case "February":
      return newDate[1] + "-02";
    case "March":
      return newDate[1] + "-03";
    case "April":
      return newDate[1] + "-04";
    case "May":
      return newDate[1] + "-05";
    case "June":
      return newDate[1] + "-06";
    case "July":
      return newDate[1] + "-07";
    case "August":
      return newDate[1] + "-08";
    case "September":
      return newDate[1] + "-09";
    case "October":
      return newDate[1] + "-10";
    case "November":
      return newDate[1] + "-11";
    case "December":
      return newDate[1] + "-12";
    default: return null;
  }
}
catch(ex){
  console.error(ex);
  return null;
}
};

const TotalSpendWidget = (props) => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    let spend = 0;
    let formattedDate = getFormattedDate(props.selectedMonth);
    let date = formattedDate ? moment(formattedDate) : moment();
   
    date = date.format("YYYY-MM");

    for (var cloudData of props.data) {
      for (var data of cloudData.data) {
       
        if (data.month === date) {
          spend += data.charge;
        }
      }
    }

    if (spend != 0) {
      setAmount(spend);
    }
  }, [props.selectedMonth, props.data]);

  return (
    <div style={{ fontSize: "1.3rem" }}>
      Total spend:{" "}
      {amount.toLocaleString("us-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </div>
  );
};

export default TotalSpendWidget;
