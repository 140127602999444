import axios from 'axios';
import moment from 'moment';
import {cacheAdapterEnhancer} from 'axios-extensions';
import CookieService from "../../services/CookieService";

//create a cache axios instance
const http = axios.create({
  baseURL: '/',
  headers: {"x-auth-token": CookieService.getCookie("session_id")},
  // disable the default cache and set the cache flag
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {enabledByDefault: false, cacheFlag: 'useCache'})
});

const base_api = 'https://sandbox.tria.connectria.com';

//example function call: ScreenshotService.createScreenshot('root')
//TODO: Lock down sentiment urls
const CostService = {

  cloudSpendExists: async function (cloud_type, params = {}) {
    let request = axios.get(base_api + `/plugin/tria_interface/clouds/${cloud_type}/exists`, params)

    return request;
  },
  costByMonth: async function (cloud_type, account_id = null, params = {}) {
    //if passing in a account id we will be callig a different api method.
    let method = (account_id == null) ? `cost_by_month/${cloud_type}` : `account_cost_by_month/${account_id}`;
    let request = axios.get(base_api + `/plugin/tria_interface/costs/cloudcheckr/${method}`, params)

    return request;
  },
  accountBreakdown: async function (type, account, month, params = {}) {
    let request = axios.get(base_api + `/plugin/tria_interface/costs/cloudcheckr/${type}/${account}/${month}/breakdown`, params)

    return request;
  },
  costByMonthExact: async function (cloud_type, month, params = {}) {
    let request = axios.get(base_api + `/plugin/tria_interface/costs/cloudcheckr/cost_by_month/${cloud_type}/${month}`, params)

    return request;
  },
  cloudCostMonthAll: async function (month, params = {}) {
    let request = axios.get(base_api + `/plugin/tria_interface/cloud/cost/all/${month}`, params)

    return request;
  },
  getCloudAccountsWithCosts: async function (start, end, params = {}) {
    let resp = await axios.get(base_api + `/plugin/tria_interface/cloud/accounts/cost?start=${start}&end=${end}`, params)
    let accounts = [];
    for (var i = 0; i < resp.data.length; i++) {
      resp.data[i].details.forEach((element) => {
        element.type = resp.data[i].type
      })
      accounts = accounts.concat(resp.data[i].details);
    }
    accounts.sort((a, b) => {
      return a.cloud_name.localeCompare(b.cloud_name);
    })
    return accounts;
  },
  cloudAccountCostMonths: async function (start, end, account_id = null, params = {}) {
    //0 months back just returns current month
    let filter_criteria = account_id == null ? '' : '&account_id=' + account_id;

    let request = axios.get(base_api + `/plugin/tria_interface/cloud/accounts/cost?start=${start}&end=${end}` + filter_criteria, params)

    return request;
  },
  cloudTagResourceCostMonths: async function (start, end, cloud_id, account_id = null, params = {}) {
    let filter_criteria = account_id == null ? '' : '?account_id=' + account_id;
    let year_month = moment(start).format('YYYY-MM')

    let request = axios.get(base_api + `/plugin/tria_interface/costs/cloudcheckr/resource_tags_cost_by_month/${cloud_id}/${year_month}${filter_criteria}`, params)

    return request;
  },
  cloudResourceCostByMonth: async function (start, end, cloud_id, account_id = null, params = {}) {
    let filter_criteria = account_id == null ? '' : '?account_id=' + account_id;
    let year_month = moment(start).format('YYYY-MM')

    let request = axios.get(base_api + `/plugin/tria_interface/costs/cloudcheckr/resource_cost_by_month/${cloud_id}/${year_month}${filter_criteria}`, params)

    return request;
  },
  cloudResourceCostMonths: async function (start, end, account_id = null, params = {}) {
    //0 months back just returns current month
    let filter_criteria = account_id == null ? '' : '&account_id=' + account_id;

    let request = axios.get(base_api + `/plugin/tria_interface/cloud/resource/cost?start=${start}&end=${end}${filter_criteria}`, params)

    return request;
  },
  cloudServiceCostMonths: async function (start, end, account_id = null, params = {}) {
    //0 months back just returns current month
    let filter_criteria = account_id == null ? '' : '&account_id=' + account_id;

    let request = axios.get(base_api + `/plugin/tria_interface/cloud/service/cost?start=${start}&end=${end}${filter_criteria}`, params)

    return request;
  },
  cloudCostSavings: async function (id, params = {}) {
    params.useCache = true;
    http.defaults.headers['x-auth-token'] = CookieService.getCookie('session_id');
    let request = await http.get(base_api + `/plugin/tria_interface/cloud/cost/savings?org_service_id=${id}`, params);

    return request;
  },
  cloudCostSavingsAll: async function (account_id = null, params = {}) {
    let filter_criteria = account_id == null ? '' : '?account_id=' + account_id;
    params.useCache = true;
    let request = await http.get(base_api + `/plugin/tria_interface/cloud/all/cost/savings/${filter_criteria}`, params)

    return request;
  },
  bestPractices: async function (accounts = [], params = {}) {
    const url = base_api + `/plugin/tria_interface/cloud/all/cost/best_practices`

    if (accounts.length === 0) {
      // load all accounts in one request
      return await axios.get(url, params)
    } else {
      // load one account per request
      let promises = []
      for (let account of accounts) {
        if (!account) continue;
        promises.push(axios.get(url + '?account=' + account));
      }
      const results = await Promise.all(promises);

      let result = {data: []};
      for (let res of results) {
        result.data.push(res.data[0])
      }
      return result;
    }
  },
  trusted_advisor_description: function (resource_id, params = {}) {
    let request = axios.get(
      base_api + `/v2/servicechecks/${resource_id}/description`,
      params
    );
    return request
  },
  trusted_advisor_resources: function (resource_id, params = {}) {
    let request = axios.post(
      base_api + `/v2/servicechecks/${resource_id}/resources`,
      params
    );
    return request
  },
  cloudCostServiceChecks: async function (id, params = {}) {
    let request = await axios.get(base_api + `/plugin/tria_interface/cloud/service_checks?org_service_id=${id}&category=cost_optimizing`, params);

    return request;
  },
};

export default CostService;
