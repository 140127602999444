import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import moment from "moment";
//import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

import TrendMicroService from "../../services/TrendMicroService.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input
} from "reactstrap";

const Table = styled.table`
  th, td {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    border-bottom: none !important;
  }

  th:first-child {
    width: 110px;
  }
`;

const Td = styled.td`
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  border-bottom: none !important;
`;

const TrendMicroWidget = props => {

  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(moment().subtract(7, "days").format("YYYY-MM-DD"));
  const [operator, setOperator] = useState("ge"); 
  const [eventsType, setEventsType] = useState("malware");
  const [events, setEvents] = useState([]);
  const [integrityEvents, setIntegrityEvents] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isIntegrityError, setIsIntegrityError] = useState(false);
  const source = axios.CancelToken.source();
  const history = useHistory();

  const [query, setQuery] = useState("");

  // Don't limit the number of items
  const maxItems = 0;

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        let resp;
        if (eventsType == "malware") {
          resp = await TrendMicroService.antimalware(date, operator, maxItems, {
            cancelToken: source.token
          });
        } else {
          resp = await TrendMicroService.integrity(date, operator, maxItems, {
            cancelToken: source.token
          });
        }

        if (!isMounted()) return;
        
        if (eventsType === 'malware' && resp.data.antiMalwareEventListing && resp.data.antiMalwareEventListing.events) { 
          resp.data.antiMalwareEventListing.events.sort(function (a, b) {
            return moment(b.logTime) - moment(a.logTime);
          });
          setEvents(resp.data.antiMalwareEventListing.events.slice(0, 5));
        } 
        else if (eventsType === 'integrity' && resp.data.ListEventsResponse && resp.data.ListEventsResponse.events) { 
          resp.data.ListEventsResponse.events.sort(function (a, b) {
            return moment(b.logTime) - moment(a.logTime);
          });
          setEvents(resp.data.ListEventsResponse.events.slice(0, 5));
        }

        setIsLoading(false);
       
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
            
          // request cancelled
        } else {
          console.log(error);
          if (eventsType === 'malware') { setIsError(true) }
          else if (eventsType === 'integrity') { setIsIntegrityError(true) }
          
        }
      }
    },
    () => {
      source.cancel();
    },
    [eventsType]
  );

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="w-100 mb-2">
          <h3 className="text-left">Trend Micro: Anti-Malware</h3>
        </div>
        <div className="flex-fill">
      {/* <ButtonGroup className="d-flex mb-2 justify-content-center" role="group" style={{zIndex:0}}>
        <Button onClick={() => setEventsType("malware")} active={eventsType == "malware" && !isError} className={`btn-sm w-100 ${(eventsType !== "malware") ? "btn-simple" : ""}`} style={{maxWidth:"250px", border:"unset"}}>Anti-Malware</Button>
        <Button onClick={() => setEventsType("integrity")} active={eventsType == "integrity" && !isIntegrityError} className={`btn-sm w-100 ${(eventsType !== "integrity") ? "btn-simple" : ""}`} style={{maxWidth:"250px", border:"unset"}}>Integrity Monitoring</Button>
      </ButtonGroup> */}
      {isError ? ( 
        <div className="text-center text-bold">
          <i className="fas fa-exclamation-triangle"></i> 
          Error Loading Events.
        </div>
      ) :
      isLoading ? (
        <div className="text-center">
            <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
      ) : (
        <>
        <div className="d-flex w-100">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Malware</th>
                <th>Agent</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => {
                  return (
                    <tr key={event.antiMalwareEventID}>
                      <td>{moment(event.logTime).format('MM/DD/YY')}</td>
                      <td><span title={event.summaryScanResult}>{event.summaryScanResult}</span></td>
                      <td><span title={event.malwareName}>{event.malwareName}</span></td>
                      <td><span title={event.hostName}>{event.hostName}</span></td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
          </div>
          <div className="d-flex w-100 justify-content-between mt-3">
            <p>
              <small>{events && events.length > 0 ? '*Last 7 Days' : 'No recent threats'}</small>
            </p>
          </div>
          
          </>
      )}
        </div>
        <button
          type="button"
          className="btn btn-light align-self-end btn-sm"
          onClick={() => {
            history.push("/app/anti-malware/events");
          }}
        >
          {" "}
          <span
            style={{ display: "inline", verticalAlign: "middle" }}
            className="fas fa-chart-line"
          ></span>{" "}
          View All
        </button>
      </div>
    </>
  );
}

export {TrendMicroWidget};
