/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import useAsyncEffect from "../utility/use-async-effect";
import axios from "axios";
import { Responsive, WidthProvider } from "react-grid-layout";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import FadeIn from "react-fade-in";
// core components

import IBMService from "../services/IBMService";
import {
  IBMHostCard,
  IBMHost,
  CountdownSeconds
} from "../components/IBMHost/IBMHost";
import { appState } from "../AppState";
import Utils from "../utility/ibm_utility";

const ResponsiveGridLayout = WidthProvider(Responsive);

const IbmDashboard = props => {
  //hook context
  const [shouldIRefresh, setShouldIRefresh] = useState(false);
  const [count, setCount] = useState(0);
  const [state, setState] = useState({ hosts: [], hostEmpty: [] });
  const [intervalId, setIntervalId] = useState(null);
  const [timeLeft, setTimeLeft] = useState(15);
  //global state
  const [stateApp, stateAppActions] = appState();
  const [autoRefresh, setAutoRefresh] = useState(true);

  const getStatusIcon = host => {
    switch (host.status_type) {
      case "ok":
        return (
          <i
            id={host.host_id}
            data-toggle="tooltip"
            title={host.status_text}
            className="tim-icons icon-check-2"
          ></i>
        );
      case "warning":
        return (
          <i
            id={host.host_id}
            data-toggle="tooltip"
            title={host.status_text}
            className="tim-icons icon-alert-circle-exc text-warning"
          ></i>
        );
      case "error":
        return (
          <i
            id={host.host_id}
            data-toggle="tooltip"
            title={host.status_text}
            className="tim-icons icon-alert-circle-exc text-danger"
          ></i>
        );
    }

    return icon;
  };

  const setRefresh = () => {
    setAutoRefresh(autoRefresh ? false : true);
  };

  const source = axios.CancelToken.source();
  useAsyncEffect(
    async isMounted => {
      try {
        console.log(count);
        
        let hosts = await IBMService.hosts({});
        for (let i = 0; i < hosts.data.length; i++) {
          Utils.getHostStatus(hosts.data[i]);
        }

        setTimeLeft(0);
        setTimeLeft(15);
        async function flip_bits() {
          setTimeLeft(0);
          setTimeLeft(15);
         
          setCount(prevTime => prevTime + 1);
         
        }
        if (intervalId == null) {
          setIntervalId(
            setInterval(async () => {
              flip_bits();
            }, 15000)
          );
        }

        if (!isMounted()) return;
        console.log('setting new values')
        setState(values => ({ ...values, hosts: hosts.data }));
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [count]
  );
  return (
    <>
      <FadeIn delay={500}>
        <div className="d-flex justify-content-between" style={{marginTop: -0.6 + "rem"}}>
          <h3 className= "mb-3 mt-4 tria-header-class" style={{paddingRight: 10+"px"}}>IBM i LPARS
          </h3>
          <p className="mb-4" style={{marginTop: "auto"}}>
            <span>
              Refreshing in {" "}
              <CountdownSeconds timeLeft={timeLeft}></CountdownSeconds> seconds...
            </span>
          </p>
        </div>
        <hr style={{marginTop: 0 + "rem"}}></hr>
        <div className="container-fluid" style={{ overflow: "hidden" }}>
          {state.hosts.length > 0 ? (
            <div className="row">
              <div className="p-10">
                {/* <span className="switch d-none d-sm-block">
                  <input
                    type="checkbox"
                    checked="checked"
                    onClick={() => {
                      setRefresh();
                    }}
                    className="switch"
                    id="switch-id"
                  />
                  <label htmlFor="switch-id">Auto-Refresh</label>
                </span> */}
              </div>{" "}
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            {state.hosts
              .filter(function(item) {
                return item.services.length > 0;
              })
              .map(function(host) {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <IBMHost
                      auto_refresh={count}
                      key={host.host_id}
                      host={host}
                    ></IBMHost>
                  </div>
                );
              })}
          </div>
        </div>
      </FadeIn>
    </>
  );
};

export default IbmDashboard;
