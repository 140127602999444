import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
         Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledTooltip,
         Card, CardHeader, CardBody, CardTitle, Form, FormGroup, Label, Input, CustomInput, Badge } from 'reactstrap';
import Select from "react-select";
import DropdownList from 'react-widgets/lib/DropdownList'
import debounce from 'lodash.debounce';

import { appState } from "../../AppState";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";

import { CSVUtility } from "../../utility/table-to-csv";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
import { NewTicketForm } from "../ConnectriaTickets/ConnectriaTicketsSupportDashboard";
import CloudAdminService from "../../services/CloudAdminService";
import OpsgenieService, { base_api } from "../../services/OpsgenieService";
import CookieService from "../../services/CookieService";
import NewTicketButton from "../SupportAndTicketing/NewTicketButton";
import IBMService from "../../services/IBMService";
import { ExpandingTextArea } from "../misc";
import MfaWizard from "../../utility/mfa/MfaWizard";
import ButtonCollapse, { ToggleSwitch } from './ButtonCollapse';
import FeatureToggle from "../../utility/FeatureToggle";
import Enum from "../../utility/enum.js";

const CustomLink = (device,key, lookup) =>{

  //lets find the host based on name:
  if(device.devicetype.name.includes('LPAR')){
    let id = lookup.filter(e => e.host_name == device[key]);
    if(id.length > 0)
     return <a href={`/app/ibm/host/${id[0].host_id}`}>{device[key]}</a>
  }
  return device[key]
}
const DevicesPage = props => {
  const history = useHistory();

  const [devices, setDevices] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [metricsLoaded, setMetricsLoaded] = useState([]);
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState('');
  const [searchIP, setSearchIP] = useState('');
  const [category, setCategory] = useState({label: "All", value: []});
  const [categoryOptions, setCategoryOptions] = useState([]);

  const orderFields = ['id', 'ipaddress', 'operatingsystem'];
  const [orderBy, setOrderBy] = useState(orderFields[0]);
  const [orderByDesc, setOrderByDesc] = useState(false);

  const [deviceTypes, setDeviceTypes] = useState([]);
  const [selectedDeviceTypeIds, setSelectedDeviceTypeIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMetricsLoading, setIsMetricsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const source = axios.CancelToken.source();
  const [task, setTask] = useState("");
  const [ipAddress, setIpAddress] = useState('');
  const [devicesLoaded, setDevicesLoaded] = useState(false);
  const [deviceTypesLoaded, setDeviceTypesLoaded] = useState(false);
  const [getMoreData, setGetMoreData] = useState(false);
  const [ibmHosts, setIbmHosts] = useState([]);
  const [showOpenTicketDevices, setShowOpenTicketDevices] = useState(false);
  const [showHostDevicesOnly, setShowHostDevicesOnly] = useState(false);
  const [memoryHealthLimit, setMemoryHealthLimit] = useState(100);
  const [cpuHealthLimit, setCpuHealthLimit] = useState(100);
  const [assetTags, setAssetTags] = useState([]);
  const [stateApp, stateAppActions] = appState();

  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState({});

  const onChangeSearchKeyword = debounce(setSearch, 500);
  const onChangeMemoryHealthLimit = debounce(setMemoryHealthLimit, 500);
  const onChangeCpuHealthLimit = debounce(setCpuHealthLimit, 500);

  // Functions to get all possible combinations of multiple lists
  const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))));
  const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

  // set order field and ascending or descending flag
  const setOrdering = (field) => {
    if (field === orderBy) {
      // change ascending or descending flag
      setOrderByDesc(!orderByDesc);
    } else {
      if (!orderFields.includes(field)) field = orderFields[0];
      setOrderByDesc(false);
      setOrderBy(field);
    }

    devices.sort(sortDevicesFn);
    setDevices(devices);
  }

  // function to sort devices by field and ascending or descending flag
  const sortDevicesFn = (a, b) => {
    let desc = orderByDesc ? -1 : 1;
    const leftVal = (orderBy === 'id' ? `${a.assettag}, ${a.servicetag}` : a[orderBy]).toLowerCase();
    const rightVal = (orderBy === 'id' ? `${b.assettag}, ${b.servicetag}` : b[orderBy]).toLowerCase();
    if (leftVal < rightVal) return -1 * desc;
    else if (leftVal > rightVal) return desc;
    else return 0;
  }

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        setDevicesLoaded(false);

        var onlyCategorySearch = false;
        var allEmptyFilters = (category.label=='All' && !search && !searchIP) ? true : false;
        const isDefaultOrg = stateApp.userInfo.organization_id == 1 ? true : false;
        const isDemoOrProd = (stateApp.env == "demo" || stateApp.env == "prod") ? true : false;
        const fields = ['assettag', 'servicetag', 'description', 'devicetypes[name]'];
        // Set of params that will be sent to devices
        var filters = [];
        // Params that will be sent with all api calls
        var baseFilter = {ar_assoc: 'operatingsystems,meta,addresses,devicetype', sort_by: "assettag asc"};

        if (isDefaultOrg && isDemoOrProd) {
          baseFilter['customer_id'] = '0';
        }

        if (allEmptyFilters) {
          // no filters, limit for speed
          filters = [{ ...baseFilter }];
        } else if (category.label != "All" && !search && !searchIP) {
          onlyCategorySearch = true;
          filters = selectedDeviceTypeIds.map((id) => { return {...baseFilter, devicetype_id: id}})
        }

        if (search && searchIP) {
          // Filter by searching the selected fields and ip address
          filters = fields.map((field) => {
            let filter = {...baseFilter, 'addresses[name]': searchIP};
            filter[field] = search;
            return filter;
          });
        } else if (search) {
          // search by only the selected fields
          filters = fields.map((field) => {
            let filter = {...baseFilter};
            filter[field] = search;
            return filter;
          });
        } else if (searchIP) {
          // search by only the ip address
          filters = [{...baseFilter, 'addresses[name]': searchIP}];
        }

        let devices = [];
        // load device by ID
        if (history.location.state && history.location.state.deviceID) {
          let device = await ConnectriaTicketsService.getDevice(history.location.state.deviceID, {
            cancelToken: source.token
          })
          devices.push(device.devices[0]);
        } else {
          // load all devices by filters
          let promises = filters.map((filter) => {
            return (onlyCategorySearch || allEmptyFilters) ? ConnectriaTicketsService.getAllDevicesById(filter, {
              cancelToken: source.token
            }) : ConnectriaTicketsService.getAllDevicesLike(filter, {
              cancelToken: source.token
            });
          });
          devices = deDuplicateDevices(await Promise.all(promises));
        }

        // Filter devices by the selected devicetype ids if there is a category selected
        if (!onlyCategorySearch && category.label != "All") {
          devices = devices.filter((device) => { return selectedDeviceTypeIds.includes(device.devicetype_id) })
        }
        // GetAllDevicesLike returns anything with "0" in customer_id, filter for only those from connectria
        if (isDemoOrProd && isDefaultOrg && !allEmptyFilters) {
          devices = devices.filter((device) => { return device.customer_id == "0"})
        }

        // populate first-level fields for sorting
        let loadIbm = false;
        for (let n in devices) {
          devices[n].ipaddress = (devices[n].addresses && devices[n].addresses.length > 0) ? devices[n].addresses.map(a => a.name).join(',') : '';
          devices[n].operatingsystem = (devices[n].operatingsystems && devices[n].operatingsystems.length > 0) ? devices[n].operatingsystems[0].name : '';

          if(devices[n].devicetype.name.toLowerCase().includes('lpar')){
            loadIbm = true;
            break;
          }
        }

        if (loadIbm) {
          try {
            let hosts = await IBMService.hosts({
              cancelToken: source.token
            });
            setIbmHosts(hosts.data);
          }
          catch (error) {
            console.log("error loading IBM hosts. AIX LPARS only?");
          }
        }
        
        if (!isMounted()) return;
        
        setOrdering(orderFields[0]);
        setDevices(devices);
        setAllDevices(devices);
        setDevicesLoaded(true);
       
      } catch (error) {
        setIsLoading(false);
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          //setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    [search, searchIP, getMoreData]
  );

  useAsyncEffect(
    async isMounted => {
      try {
        if (!isMounted()) return;

        setIsMetricsLoading(true);

        let deviceMetrics = {};
        let deviceMetricsLoaded = [];
        setMetrics({});
        setMetricsLoaded([]);

        if (allDevices && allDevices.length > 0) {
          const chunkSize = 50; // we can adjust the chunk size as to whatever value that wouldn't cause timeout issue.
          const chunkCount = Math.ceil(allDevices.length / chunkSize);
          const dids = allDevices.map(d => d.id); // '4981,4425,51988'; // '54527';
          const didGroups = [];
          for (let i = 0; i < chunkCount; i += 1) {
            // didGroups.push(dids.slice(i * chunkSize, (i + 1) * chunkSize).join(','));
            const deviceGroup = dids.slice(i * chunkSize, (i + 1) * chunkSize);
            const deviceNodes = await ConnectriaTicketsService.getDeviceMetrics({ dids: deviceGroup.join(',') }, { cancelToken: source.token }) || [];
            deviceMetrics = { ...deviceMetrics, ...deviceNodes.reduce((rr, d) => ({ ...rr, [`${d.DeviceID}`]: d }), {}) };
            deviceMetricsLoaded = deviceMetricsLoaded.concat(deviceGroup);
            setMetrics(deviceMetrics);
            setMetricsLoaded(deviceMetricsLoaded);
          }

          // const requestPromises = didGroups.map(g => ConnectriaTicketsService.getDeviceMetrics({ dids: g }, { cancelToken: source.token }));

          // const deviceNodes = await Promise.all(requestPromises) || [];
          // deviceMetrics = deviceNodes.reduce((r, g) => ({
          //   ...r,
          //   ...((g || []).reduce((rr, d) => ({ ...rr, [`${d.DeviceID}`]: d }), {})),
          // }), {});
        }

        // setMetrics(deviceMetrics);
       
      } catch (error) {
        console.log('error loading device metrics: ', error);
      } finally {
        setIsMetricsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    [allDevices]
  );

  useAsyncEffect(
    async isMounted => {
      try {
        stateAppActions.setPageNavTitle('Devices View');
        setDeviceTypesLoaded(false);
        // All device types in portal with category, name, etc.
        let allDeviceTypes = await ConnectriaTicketsService.getAllDeviceTypes({limit: 10000}, {
          cancelToken: source.token
        });
        // Select just the devicetype_id for all devices for org
        // Much faster than selecting all device properties
        let applicableDeviceTypeIds = await ConnectriaTicketsService.getAllDevicesLike({select: "devicetype_id", limit: 10000}, {
          cancelToken: source.token
        });

        if (!isMounted()) return;

        let applicableDeviceTypeIdsSet = new Set();
        // Get all possible device type ids from this orgs devices
        for (var id of applicableDeviceTypeIds) {
          applicableDeviceTypeIdsSet.add(id.devicetype_id);
        }
        let ids = [...applicableDeviceTypeIdsSet];
        // Get the category and name for each of the possible devicetype_ids
        let applicableDeviceTypes = allDeviceTypes.filter((type) => { return ids.includes(type.id)});
        let categories = [];
        // Map each category with all of its corresponding devicetype_ids
        for (var type of applicableDeviceTypes) {
          var inCategories = false;
          for (var category of categories) {
            if (category.label === type.category) {
              category.value.push(type.id);
              inCategories = true;
            }
          }
          if (!inCategories) {
            // Put them in this format to use in select dropdown
            categories.push({label: type.category, value: [type.id]})
          }
        }

        categories.sort((a, b) => {
          if (a.label < b.label) return -1;
          else if (a.label > b.label) return 1;
          else return 0;
        })
        
        categories.unshift({label: 'All', value: []});

        setDeviceTypes(applicableDeviceTypes);
        setCategoryOptions(categories);
        setDeviceTypesLoaded(true);
        
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          //setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  useAsyncEffect(
    async isMounted => {
      try {
        if (!showOpenTicketDevices) return
        if (assetTags.length > 0) return;

        setIsLoading(true);

        // get open tickets device names
        let ticketsResponses = await ConnectriaTicketsService.allTicketsByStatus(['New', 'Open', 'Scheduled', 'WaitOnCust']);

        // extract devices assettag
        let tags = new Set();
        for (let ticket of ticketsResponses) {
          if (ticket && ticket.device && ticket.device !== 'undefined') {
            tags.add(ticket.device)
          }
        }

        if (!isMounted()) return;
        setAssetTags([...tags]);

        setIsLoading(false);

      } catch (error) {
        setIsLoading(false);
        console.log(error)
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          //setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    [showOpenTicketDevices]
  );

  useEffect(() => {
    if (devicesLoaded && deviceTypesLoaded) {
      setIsLoading(false);
    }
  }, [devicesLoaded, deviceTypesLoaded]);

  useEffect(() => {
    var devices = allDevices;

    if (category.label !== "All" && (search || searchIP)) {
      devices = devices.filter((device) => {
        return category.value.includes(device.devicetype.id);
      });
    }

    if (showOpenTicketDevices) {
      // console.log(assetTags);
      devices = devices.filter((device) => assetTags.includes(device.assettag));
    }

    if (showHostDevicesOnly) {
      devices = devices.filter((device) => metrics[`${device.id}`]?.VMHost);
    }

    if (memoryHealthLimit) {
      devices = devices.filter((device) => (metrics[`${device.id}`]?.metrics?.PercentMemoryUsed || 0) <= memoryHealthLimit);
    }

    if (cpuHealthLimit) {
      devices = devices.filter((device) => (metrics[`${device.id}`]?.metrics?.CPULoad || 0) <= cpuHealthLimit);
    }

    setDevices(devices);
  }, [allDevices, metrics, search, searchIP, category, assetTags, showOpenTicketDevices, showHostDevicesOnly, memoryHealthLimit, cpuHealthLimit]);

  const deDuplicateDevices = (res) => {
    let devices = [];
    let idSet = new Set();
    // Iterate through the device list for each field there was a successful response from
    for (var deviceList of res) {
      // Skip fields which didn't get any results back
      if (deviceList) {
        for (var device of deviceList) {
          if (!idSet.has(device.id)) {
            idSet.add(device.id);
            devices.push(device);
          }
        }
      }
    }
    return devices;
  }

  const handleCategoryChange = (category) => {
    setCategory(category)
    setSelectedDeviceTypeIds(category.value);
    if (!search && !searchIP) {
      // Trigger call to devices endpoint
      setGetMoreData(!getMoreData);
    }
  }

  const editDescription = (device, event) => {
    //console.log('Key: ' + event.key);
    if (event.key == "Enter" ) {
      device.description = task;
      setTask('');
    }
  }

  const exportDevices = async ()  => {
    let filter = {limit: 10000, ar_assoc: 'operatingsystems,meta,addresses,devicetype', sort_by: "assettag asc"},
      flatDevices = [];

    setIsExporting(true);
    let devicesResp = await ConnectriaTicketsService.getAllDevicesLike(filter)
    setIsExporting(false);

    for (let device of devicesResp) {
      flatDevices.push({
        assettag: device.assettag,
        servicetag: device.servicetag,
        ipaddresses: device.addresses.map((addr) => { return addr.name }).join(', '),
        decription: device.description,
        operatingsystem: device.operatingsystems.length > 0 ? device.operatingsystems[0].name : '',
        devicetype: device.devicetype.name,
      })
    }

    CSVUtility.downloadCSVFromJson('devices.csv', flatDevices)
  }

  const toggleModal = (id, assettag, description, index) => {
    setModalState({
      id: id,
      assettag: assettag,
      description: description,
      index: index
    })
    setShowModal(!showModal);
  }

  const handleDescriptionUpdate = (updateSuccessful, newModalState) => {
    let alertMessage = `Description for "${newModalState.assettag}" failed to update`;
    let alertClass = "danger";

    if (updateSuccessful) {
      // Refresh the devices on the page with the new description
      var devicesCopy = [...devices];
      devicesCopy[newModalState.index].description = newModalState.description;
      setDevices(devicesCopy);

      alertMessage = `Description for "${newModalState.assettag}" successfully updated`;
      alertClass = "success";
    }

    setModalState(newModalState);
    // Hide the modal
    setShowModal(false);

    // Show success/failure alert
    stateAppActions.setAlert({
      content: <p style={{color:"white"}}>{alertMessage}</p>,
      className: alertClass,
      visible: true
    });
  }

  const reportError = (e) => {
    if (e != null) {
        e.preventDefault();
    }
    history.push({
      pathname: "/app/create-ticket",
      state: {
        closeOnSuccess: true,
        subject: 'Error Report',
        description: 'TRiA did not identify Devices data associated with your account and this is an error',
        attachmentElementId: ''
      }
    });
  };

  return (
        <div className="container-fluid">
        <EditDescriptionModal modalState={modalState} showModal={showModal} toggle={toggleModal} handleUpdate={handleDescriptionUpdate}/>
        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
          <h3 className="my-0 tria-header-class text-dark d-none d-md-block">
            Devices
          </h3>
          <h5 className="my-0 text-info d-none d-md-block font-weight-bold">{devices?.length || 0} Total</h5>
          <div className="d-flex align-items-center flex-fill flex-md-grow-0">
            <button
              type="button"
              className="btn btn-neutral btn-semi-blue rounded d-none d-xl-block mr-2"
              onClick={exportDevices}
            >
              Export to CSV
            </button>
            <ButtonCollapse label="Filter List" title="FILTER" className="flex-fill">
              <Form>
                <FormGroup className="mb-4">
                  <Label for="queryInput" className="text-dark">Search</Label>
                  <Input
                    type="text"
                    className="tria-input bg-white"
                    name="query"
                    id="queryInput"
                    placeholder="All"
                    defaultValue={search}
                    onChange={(e) => onChangeSearchKeyword(e.target.value) }
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for="categorySelect" className="text-dark">View By Category</Label>
                  <DropdownList 
                    data={categoryOptions}
                    value={category}
                    placeholder="Choose Category"
                    textField="label"
                    valueField="value"
                    containerClassName="rw-dropdown-font rw-container"
                    selectIcon={<i className="fas fa-chevron-down text-header" />}
                    onChange={handleCategoryChange}
                  />
                </FormGroup>
                <FeatureToggle envs={[Enum.Env.DEV]} clouds={[Enum.CloudCategory.ALL]}>
                  <FormGroup className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <Label for="memoryHealthInput" className="text-dark">Memory Health</Label>  
                      <p className="text-info mb-0">&lt;{memoryHealthLimit}%</p>
                    </div>
                    <Input
                      type="range"
                      name="memoryHealth"
                      id="memoryHealthInput"
                      defaultValue={memoryHealthLimit}
                      onChange={e => onChangeMemoryHealthLimit(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <Label for="cpuHealthInput" className="text-dark">CPU Health</Label>
                      <p className="text-info mb-0">&lt;{cpuHealthLimit}%</p>
                    </div>
                    <Input
                      type="range"
                      name="cpuHealth"
                      id="cpuHealthInput"
                      defaultValue={cpuHealthLimit}
                      onChange={e => onChangeCpuHealthLimit(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="text-dark">Devices with Tickets</Label>
                    <div className="mt-2">
                      <div className="d-flex justify-content-between mb-2">
                        <p className="label-font mr-2">Show devices with tickets</p>
                        <ToggleSwitch checked={showOpenTicketDevices} onChange={(e) => setShowOpenTicketDevices(e.target.checked)} />
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="label-font mr-2">Show host devices only</p>
                        <ToggleSwitch checked={showHostDevicesOnly} onChange={(e) => setShowHostDevicesOnly(e.target.checked)} />
                      </div>
                      {/* <CustomInput type="switch" id="showWithTicketsSwitch" name="showWithTickets" label="Show devices with tickets" />
                      <CustomInput type="switch" id="showHostsOnlySwitch" name="showHostsOnly" label="Show host devices only" /> */}
                    </div>
                  </FormGroup>
                </FeatureToggle>
              </Form>
            </ButtonCollapse>
          </div>
        </div>
          {isExporting && (
            <div className="row">
              <div className="col-6 offset-3">
                <div className="text-center alert alert-primary">
                  <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: "30px" }}></i> Exporting devices...
                </div>
              </div>
            </div>
            )
          }
          {isLoading ? (
            <div className="text-center">
            <i
              className="fas fa-spinner m-3 mt-4 fa-spin"
              style={{ fontSize: "30px" }}
            ></i>
            </div>
          ) : (
          <div className="table-responsive-sm">
            <table className="table devices-table mb-0">
            <thead>
              <tr>
                <th>
                  <a className="cursor" onClick={() => setOrdering('id')}>
                    <h6 className="text-header m-0">
                      Name and ID
                      {orderBy === 'id' && (orderByDesc ? <i className="fas fa-chevron-down text-blue ml-2"></i> : <i className="fas fa-chevron-up text-blue ml-2"></i>)}
                    </h6>
                  </a>
                </th>
                <th><h6 className="text-header text-center m-0">Health</h6></th>
                <th className="d-none d-md-table-cell"><h6 className="text-header text-center m-0">Host</h6></th>
                <th className="d-none d-md-table-cell">
                  <a className="cursor" onClick={() => setOrdering('ipaddress')}>
                    <h6 className="text-header m-0">
                      IP address
                      {orderBy === 'ipaddress' && (orderByDesc ? <i className="fas fa-chevron-down text-blue ml-2"></i> : <i className="fas fa-chevron-up text-blue ml-2"></i>)}
                    </h6>
                  </a>
                </th>
                <th className="d-none d-md-table-cell"><h6 className="text-header m-0">Description</h6></th>
                <th className="d-none d-lg-table-cell">
                  <a className="cursor" onClick={() => setOrdering('operatingsystem')}>
                    <h6 className="text-header m-0">
                      Operating System
                      {orderBy === 'operatingsystem' && (orderByDesc ? <i className="fas fa-chevron-dow text-blue ml-2"></i> : <i className="fas fa-chevron-up text-blue ml-2"></i>)}
                    </h6>
                  </a>
                </th>
                <th className="d-none d-lg-table-cell"><h6 className="text-header m-0">Device Type</h6></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device, index) => {
                const metricsAvailable = !!metrics[`${device.id}`]?.metrics;
                return (
                  <tr key={index}>
                    <td className="border-0">
                      <h6 className="text-dark font-weight-normal">{device.assettag}</h6>
                      <h6 className="text-info small mb-0">{CustomLink(device,'servicetag', ibmHosts)}</h6>
                    </td>
                    <td className="border-0 text-center">
                      {isMetricsLoading && !metricsLoaded.includes(device.id) ? (
                        <i className="fas fa-spinner fa-spin icon" />
                      ) : (
                        <>
                          <div className="text-center" id={`device-health-bars-${device.id}`}>
                            <div className="d-flex justify-content-center">
                              <HealthBarStack
                                value={metrics[`${device.id}`]?.metrics?.CPULoad || 0}
                                label={metricsAvailable ? 'CPU' : null}
                                threshold={metrics[`${device.id}`]?.metrics?.threshold}
                                empty={!metricsAvailable}
                              />
                              <HealthBarStack
                                value={metrics[`${device.id}`]?.metrics?.PercentMemoryUsed || 0}
                                label={metricsAvailable ? 'MEM' : null}
                                threshold={metrics[`${device.id}`]?.metrics?.threshold}
                                empty={!metricsAvailable}
                              />
                            </div>
                            {!metricsAvailable && <div className="health-bar-label">NO DATA</div>}
                          </div>
                          {!metricsAvailable &&
                            <UncontrolledTooltip placement="bottom" target={`device-health-bars-${device.id}`}>
                              Health data is currently unavailable, please check back soon.
                            </UncontrolledTooltip>
                          }
                        </>
                      )}
                    </td>
                    <td className="border-0 text-center d-none d-md-table-cell">
                      {isMetricsLoading && !metricsLoaded.includes(device.id) ? (
                        <i className="fas fa-spinner fa-spin icon" />
                      ) : (typeof metrics[`${device.id}`]?.VMHost &&
                        metrics[`${device.id}`]?.VMHost && <i className="fas fa-server text-header icon" />
                      )}
                    </td>
                    <td className="border-0 d-none d-md-table-cell">
                      <h6 className="text-dark mb-0 font-weight-normal">
                        {device.ipaddress?.split(',').map(a => (
                          <span key={a}>
                            {a}<br/>
                          </span>
                        ))}
                      </h6>
                    </td>
                    <td className="border-0 d-none d-md-table-cell">
                      <h6 className="text-dark mb-0 font-weight-normal cursor" id={"device-description-"+index} onClick={() => {toggleModal(device.id, device.assettag, device.description, index) }}>
                        {device.description || "[Empty - Click to edit]"}
                      </h6>
                      <UncontrolledTooltip placement="right" target={"device-description-"+index}>
                        Edit your device description
                      </UncontrolledTooltip>
                    </td>
                    <td className="border-0 d-none d-lg-table-cell">
                      <h6 className="text-dark mb-0 font-weight-normal">
                        {device.operatingsystem}  
                      </h6>
                    </td>
                    <td className="border-0 d-none d-lg-table-cell">
                      <h6 className="text-dark mb-0 font-weight-normal">
                        {device.devicetype.name}
                      </h6>
                    </td>
                    <td width="120" className="dropdown-cell border-0 text-center">
                      <ActionsDropdown device={device} hasMetrics={!!metrics[`${device.id}`]?.metrics} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
            </table>
            {/* { category.label === 'All' && !search && !searchIP && devices.length > 0 &&
              <small className="mt-1">
                *Up to the first 50 devices displayed. Please search or select a category for more devices.
              </small>
            } */}
            {!isLoading && devices.length === 0 &&
              <div className="bg-white text-dark p-4 text-center">
                <div className="mb-2">
                  <i className="fas fa-ban text-blue mr-md-3" />
                  <br className="d-md-none" />
                  <span>TRiA did not identify Devices data associated with your account. Please contact us if this is an error.</span>
                </div>
                <button className="btn btn-link btn-manage font-weight-normal" onClick={reportError}>Report if this is an error</button>
              </div>
            }
            
            </div> )}
        </div>
  )
}

const HealthBarStack = ({ value, label, threshold, empty, numBars }) => {
  const interval = 100 / numBars;
  const barColor = value >= threshold ? 'red' : (value >= threshold - 5 ? 'yellow' : 'green');
  return (
    <div>
      {[...new Array(numBars || 5)].map((_, i) => (
        <div
          key={i}
          className={`bar health-bar ${empty ? 'empty-bar' : ''}`}
          color={value > 0 && value > i * interval ? barColor : 'gray'}
        />
      )).reverse()}
      {label && <div className="health-bar-label text-center">{label}</div>}
    </div>

  )
};

HealthBarStack.defaultProps = {
  value: -1,
  threshold: 95,
  numBars: 5,
};

const EditDescriptionModal = (props) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [modalState, setModalState] = useState(props.modalState);
  const [stateApp, stateAppActions] = appState();
  const source = axios.CancelToken.source();

  // Initial modalState is undefined. Set new state on re-render
  useEffect(() => {
    setModalState(props.modalState);
  }, [props])

  const updateDescription = async () => {
    
    setModalLoading(true);
    let response = await ConnectriaTicketsService.updateDevice(modalState.id, {
      description: modalState.description,
    }, {
      cancelToken: source.token
    });

    props.handleUpdate(response.status, modalState);

    setModalLoading(false);

    // Hide success/failure alert after 2 seconds
    setTimeout(() => {
      stateAppActions.setAlert(prevState => ({...prevState, visible: false }))
    }, 2000);
  }

  return (
    <Modal className="modal-lg" isOpen={props.showModal} toggle={() => props.toggle()}>
      <ModalHeader>Edit description for "{modalState.assettag}"</ModalHeader>
      <ModalBody>
      <ExpandingTextArea id="editDescriptionArea" onChange={(e) => { setModalState({...modalState, description: e.target.value}) }} 
        value={modalState.description} style={{maxHeight: "350px"}} rows={5}></ExpandingTextArea>
      </ModalBody>
      <ModalFooter style={{paddingLeft: "1.75rem"}}>
        <div className="row">
          <Button color="primary" size="sm" onClick={() => updateDescription()} disabled={modalLoading}>Save</Button>{' '}
          {modalLoading && <div className="my-auto pl-2"> <i className="fas fa-spinner fa-spin" style={{fontSize: "20px"}}></i> </div>}
        </div>
        <Button color="secondary" size="sm" onClick={() => props.toggle()} disabled={modalLoading}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

const createNewTicket = (e, device, history) => {
  if (e != null) {
      e.preventDefault();
  }
  history.push({
    pathname: "/app/create-ticket",
    state: {
      closeOnSuccess: true,
      subject: '',
      description: '',
      attachmentElementId: '',
      deviceId: device.id}
  });
}

const ActionsDropdown = (props) => {
  const [stateApp, stateAppActions] = appState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [consoleModalVisible, setConsoleModalVisible] = useState(false);
  const [consoleErrorMsg, setConsoleErrMsg] = useState();
  const [consoleIsLoading, setConsoleIsLoading] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const history = useHistory();
  const getConsoleUri = async (e, device) => {
    if ((!stateApp.userInfo.domain_admin) && (stateApp.userInfo.two_factor_required || false) == false) {
      setShowMfa(true);
      return;
    }
    setConsoleModalVisible(true);
    //domain admins cannot use vmrc - until we can figure out a 2FA option
    if (stateApp.userInfo.domain_admin) {
      setConsoleErrMsg("Domain Admins cannot use VMRC.");
      return;
    }
    //device must have a meta entry for vcenter
    let vcenter = device.meta.filter(d => { return d.meta_key === "vCenter"});
    if (vcenter.length === 0) {
      setConsoleErrMsg(<div>Device not configured for console access. <NewTicketButton style={{color:"#0098f0"}} subject={`Setup device ${device.servicetag} for VMware Console access.`} onBladeShow={(e) => setConsoleModalVisible(false)}>Create a new support ticket to resolve the issue.</NewTicketButton></div>);
      // report the error to opsgenie
      await OpsgenieService.reportAlert({
        message: 'Failed to connect to VMWare console',
        description: 'Device not configured for console access',
        source: 'TriA Labs',
        entity: 'VMWare Console',
        details: { device_id: device.id, device_assettag: device.assettag },
      });
      return;
    }
    setConsoleIsLoading(true);
    try {
      var resp = await CloudAdminService.getConsoleUri(vcenter[0].meta_value, device.assettag);
      if (resp.data.ws_url === undefined) {
        throw "Cloudapi returned undefined ws_url for VMRC.";
      }
      //creating a faux delay to ensure kong has established the routes/services before we redirect
      setTimeout((e) => {
        window.open(`/vmrc.html?id=${device.id}&vm=${device.assettag}&u=${resp.data.ws_url}&tria_token=${CookieService.getCookie("session_id")}&tria_api=${base_api}`, "_blank");
        setConsoleModalVisible(false);
        setConsoleIsLoading(false);
      }, 3000);
    } catch (e) {
      console.log(e);
      setConsoleErrMsg(<div>Error creating console connection. <NewTicketButton style={{color:"#0098f0"}} subject={`Device ${device.servicetag} VM console connection error.`} onBladeShow={(e) => setConsoleModalVisible(false)}>Create a new support ticket to resolve the issue.</NewTicketButton></div>);
      // report the error to opsgenie
      await OpsgenieService.reportAlert({
        message: 'Failed to connect to VMWare console',
        description: `Error creating console connection.`,
        source: 'TriA Labs',
        entity: 'VMWare Console',
        details: { device_id: device.id, device_assettag: device.assettag, error: e.message },
      });
      setConsoleIsLoading(false);
    }
  }

  const toggle = (item) => {
    setDropdownOpen(!dropdownOpen);
    if(typeof item === "object") return;
    console.log('Menu Option: ' + item);
  };
  return (
    <>
      {showMfa ?
        <MfaWizard showMfa={showMfa} setShowMfa={setShowMfa}></MfaWizard> :

        <Modal isOpen={consoleModalVisible}>
          <ModalBody>
            {consoleIsLoading ? (
              <>
                <h4 style={{textAlign:"center"}}><i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: "30px" }}></i>Creating console connection...</h4>
              </>
            ) : (
              <>
                {consoleErrorMsg && 
                  <div style={{textAlign:"center"}}>
                    {consoleErrorMsg}
                  </div>
                }
                  <button
                      type="button"
                      className={"btn btn-light pull-right btn-sm mt-3"}
                      onClick={() => {
                        setConsoleModalVisible(false);
                      }}
                    >Close</button>
              </>
            )}
          </ModalBody>
        </Modal>
      }
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          className="btn btn-link btn-manage font-weight-normal"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
          caret
        >
          Manage
          {/* <i className={"" + " fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i> */}
        </DropdownToggle>
        <DropdownMenu className="no-arrow" right>
          <DropdownItem onClick={(e) => {createNewTicket(e, props.device, history)} }>Create Ticket</DropdownItem>
          {(props.device.meta.filter(d => { return d.meta_key === "vCenter"} ) != false) && ([38,39,44,58,59,186].indexOf(props.device.devicetype_id) > -1 ) &&
            <DropdownItem onClick={(e) => { getConsoleUri(e, props.device)}}>Open Console</DropdownItem>
          }
          <DropdownItem disabled={!props.hasMetrics}>
            {props.hasMetrics ?
              <a href={`/app/devices/${props.device.id}`} style={{color: "black"}}>
                Device Detail
              </a>
            :
              'Details Not Available'
            }
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export {
  DevicesPage
};