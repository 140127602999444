import React, { Fragment } from "react";
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";

import axios from "axios";
import moment from "moment";
import { appState } from "../../AppState";;
import useAsyncEffect from "../../utility/use-async-effect";


import CookieService from "../../services/CookieService";
import PublicCloudService from "../../public_cloud/services/service";
// reactstrap components
import { Input, Label, Button} from "reactstrap";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CloudsListing = (props) => {
  const source = axios.CancelToken.source();
  const history = useHistory();

  const [stateApp, stateAppActions] = appState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [clouds, setClouds] = useState([]);
  const [cloudTypes, setCloudTypes] = useState([]);
  const [badges, setBadges] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedBadgeOption, setSelectedBadgeOption] = useState([]);
  const [resourceBadgeCounts, setResourceBadgeCounts] = useState([]);

  const [cloudTypeDropdownOpen, setCloudTypeDropdownOpen] = useState(false);
  const [harvestStatusDropdownOpen, setHarvestStatusDropdownOpen] = useState(false);
  const [eventHarvestRoleDropdownOpen, setEventHarvestRoleDropdownOpen] = useState(false);

  const [offset, setOffset] = useState(0);
  const [allBadgesCheck, setAllBadgesCheck] = useState(false);
  const [noBadgesCheck, setNoBadgesCheck] = useState(false);
  const [selectedCloudType, setSelectedCloudType] = useState({cloud_type_id: "All", name: "All Cloud Types"});
  const [selectedHarvestStatus, setSelectedHarvestStatus] = useState({id: "All", label: "All Harvest States"});
  const [selectedEventHarvestRole, setSelectedEventHarvestRole] = useState({id: "All", label: "All Harvest Roles"});

  const [requestFilter, setRequestFilter] = useState(
    { limit: 25, offset: 0, order_by: "name", filters: [] }
  );

  const iconMapping = {
    BUSY: "search",
    INVALID_CREDS: "exclamation-circle text-danger",
    SUSPENDED: "exclamation-circle text-danger",
    PAUSED: "pause-circle",
    REFRESH: "check-circle text-success",
    DEFAULT: "check-circle text-success",
    ASSUME_ROLE_FAIL: "exclamation-circle text-danger"
  };

  const harvestStates = [
    {id: "All", label: "All Harvest States"},
    {id: "0", label: "Active"},
    {id: "5", label: "Invalid Credentials"},
    {id: "6", label: "Paused"},
    {id: "8", label: "Assume Role Failure"},
    {id: "9", label: "Suspended"}
  ];

  const eventHarvestRoles = [
    {id: "All", label: "All Harvest Roles"},
    {id: "idle", label: "Not Configured"},
    {id: "producer", label: "Producer"},
    {id: "consumer", label: "Consumer"}
  ];

  useEffect(() => {
    var filter = {
      limit: 25,
      offset: offset,
      order_by: "name",
      filters: []
    }

    if (search) {
      filter = {...filter, search_string: search}
    }
    if (selectedBadgeOption.length > 0) {
      filter = {
        ...filter, 
        badges: selectedBadgeOption.map((o) => { return {key: o.key, value: o.value} }),
        badge_filter_operator: "OR"
      }
    }
    if (selectedHarvestStatus.id != "All") {
      if (selectedHarvestStatus.id != "0") {
        filter.filters.push({field_name: "status", filter_type: "EXACT", filter_value: selectedHarvestStatus.id});
      } else {
        filter.filters.push({field_name: "status", filter_type: "IN", filter_list_value: ["0", "1"]});
      }
    }
    // Mutually exclusive filters
    if (selectedCloudType.cloud_type_id != "All" && selectedEventHarvestRole.id == "All") {
      filter.filters.push({field_name: "cloud_type_id", filter_type: "EXACT", filter_value: selectedCloudType.cloud_type_id});
    } else if (selectedEventHarvestRole.id != "All") {
      filter.filters.push({field_name: "event_driven_harvest_role", filter_type: "EXACT", filter_value: selectedEventHarvestRole.id});
      filter.filters.push({field_name: "cloud_type_id", filter_type: "EXACT", filter_value: "AWS"});
    }
    if (allBadgesCheck) {
      filter = {...filter, badge_filter_operator: "AND"}
    }
    if (noBadgesCheck) {
      filter = {...filter, empty_badges: true}
    }

    setRequestFilter(filter);

    }, [search, allBadgesCheck, noBadgesCheck, selectedBadgeOption, selectedCloudType, selectedHarvestStatus, selectedEventHarvestRole]);

  // Get the cloud types and badges. Only neeed to get these on initial page load
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
    
        let cloudTypesResp = await PublicCloudService.cloud_types_list({
          params: {
            active_only: true
          },
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        });

        let cloudBadgesResp = await PublicCloudService.cloud_badges_list({
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        });
        
        if (!isMounted()) return;

        cloudTypesResp.data.clouds.unshift({cloud_type_id: "All", name: "All Cloud Types"});
        setCloudTypes(cloudTypesResp.data.clouds);
        setBadges(cloudBadgesResp.data.map((badge) => { 
          return {
            ...badge,
            label: badge.key + ":" + badge.value,
          }
        }));
    
      
      } catch (error) {
        if (axios.isCancel(error)) {
        console.log(error);
          
        // request cancelled
        } else {
        console.log(error);
        setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  // Get the clouds and their data. Do this after each filter update
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);                                                                                                                                                                        
    
        let cloudsResp = await PublicCloudService.list_clouds_filtered({
          payload: requestFilter,
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        });
        let badgeResourceCountResp = await PublicCloudService.list_badge_count_for_resources({
          payload: {resource_ids: cloudsResp.data.clouds.map((cloud) => { return cloud.resource_id })},
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        })

        if (!isMounted()) return;

        setClouds(cloudsResp.data.clouds);
        setResourceBadgeCounts(badgeResourceCountResp.data.resource_count);
    
        setIsLoading(false);
      
      } catch (error) {
        if (axios.isCancel(error)) {
        console.log(error);
          
        // request cancelled
        } else {
        console.log(error);
        setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    [requestFilter]
  );

  // Toggle functions which control the opening and closing of the dropdowns
  const toggleCloudTypeDropdown = () => {
    setCloudTypeDropdownOpen(!cloudTypeDropdownOpen);
  }
  const toggleHarvestStatusDropdown = () => {
    setHarvestStatusDropdownOpen(!harvestStatusDropdownOpen);
  }
  const toggleEventHarvestRoleDropdown = () => {
    setEventHarvestRoleDropdownOpen(!eventHarvestRoleDropdownOpen);
  }

  // Mutually exclusive filters, reset the cloud type filter when choosing a harvest role filter
  const toggleEventHarvestRole = (role) => {
    setSelectedEventHarvestRole(role);
    if (selectedCloudType.id != "All") {
      setSelectedCloudType({cloud_type_id: "All", name: "All Cloud Types"});
    }
  }
  // Mutually exclusive filters, reset the harvest role filter when choosing a cloud type filter
  const toggleCloudType = (type) => {
    setSelectedCloudType(type);
    if (selectedEventHarvestRole.id != "All") {
      setSelectedEventHarvestRole({id: "All", label: "All Harvest Roles"});
    }
  }

  // Set the selected options for the badge select box
  const handleBadgeChange = selectedBadgeOption => {
    selectedBadgeOption = selectedBadgeOption == null ? [] : selectedBadgeOption;
    setSelectedBadgeOption(selectedBadgeOption || []);
  }

  const findBadgeCountByResourceId = (resourceId) => {
    var count = 0; 
    for (badgeCount of resourceBadgeCounts) {
      if (badgeCount.resource_id == resource_id) {
        count = badgeCount.count;
        break;
      }
    }
    return count;
  }

  const animatedComponents = makeAnimated();

  return (
      <>
      <div className="d-flex justify-content-between flex-wrap">
        <h3 className="mb-0 mt-4 tria-header-class">
          Clouds Listing
        </h3>
        {stateApp.env === 'dev' ? (<Button className="mb-0 mt-4" onClick={() => { history.push("/app/add/cloud"); }}><i className="mr-2 fas fa-plus"></i>ADD CLOUD</Button>) : ''}
      </div>
      <hr></hr>
      <Card className="card-minimal">
          <div className="d-flex flex-wrap flex-fill" style={{padding: 15+"px"}}>
            <div className="row" style={{width: 350+"px"}}>
              <Input
                onChange={(e) => { setSearch(e.target.value) }}
                value={search}
                type="text"
                placeholder="Search"
                style={{minWidth: 100 + '%'}}
              />
              <i  
                className="fas fa-times-circle"
                id="clearButton"
                style={{marginLeft: -1.25 + "rem", marginTop: .75 + 'rem', cursor: "pointer"}} 
                onClick={() => {setSearch("");}}
              />
            </div>
          </div>
          <div className="d-flex flex-wrap" style={{padding: 15+"px", paddingLeft: 13+"px", alignItems: "center"}}>
            <div style={{width: 350+"px", paddingTop: 10+"px"}}>
              <Select
                  className="h5"
                  value={selectedBadgeOption}
                  onChange={handleBadgeChange}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={badges}
                  isMulti
                  placeholder="Badge Search"
                  isDisabled={noBadgesCheck}
                />
            </div>
            <div style={{paddingLeft: 30+"px"}}>
            <Label style={{paddingLeft: 20+"px", color: (selectedBadgeOption.length < 2 ? "gray" : "")}}>
              <Input type="checkbox" onChange={() => { setAllBadgesCheck(!allBadgesCheck) }} disabled={selectedBadgeOption.length < 2}/>{" "}
              Must Have All Badges
            </Label>
            </div>
            <div style={{paddingLeft: 30+"px"}}>
              <Label style={{paddingLeft: 20+"px", color: (selectedBadgeOption.length > 0 ? "gray" : "")}}>
              <Input type="checkbox" onChange={() => { setNoBadgesCheck(!noBadgesCheck) }} disabled={selectedBadgeOption.length > 0}/>{" "}
              Show Clouds Without Badges 
            </Label>
            </div>
          </div>
          <div className="d-flex flex-wrap" style={{padding: 15+"px"}}>
            <Dropdown className="my-1" isOpen={harvestStatusDropdownOpen} toggle={toggleHarvestStatusDropdown} style={{width: 250+"px"}}>
              <DropdownToggle 
                tag="span"
                data-toggle="dropdown"
                aria-expanded={harvestStatusDropdownOpen}
              >
                {selectedHarvestStatus.id != "All" ? selectedHarvestStatus.label : "Harvest Status"}
              <i className={"fas fa-caret-" + (harvestStatusDropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
              </DropdownToggle>
              <DropdownMenu>
                {harvestStates.map((state, i) => { 
                  return (
                    <DropdownItem onClick={() => { setSelectedHarvestStatus(state) }} key={i}>
                      {state.label}
                    </DropdownItem> 
                  )
                })}
              </DropdownMenu>
            </Dropdown>
            <Dropdown className="my-1" isOpen={cloudTypeDropdownOpen} toggle={toggleCloudTypeDropdown} style={{width: 250+"px"}}>
            <DropdownToggle 
              tag="span"
              data-toggle="dropdown"
              aria-expanded={cloudTypeDropdownOpen}
            >
              <img
                className={selectedCloudType.cloud_type_id == "All" ? "d-none" : ""}
                style={{ width: 25 + "px" }}
                src={selectedCloudType.cloud_type_id == "All" ? "" : `https://tria.connectria.com/divvy/img/${selectedCloudType.cloud_type_id.toLowerCase()}-logo.png`}
              />{" "}
              {selectedCloudType.cloud_type_id != "All" ? selectedCloudType.name : "Filter Cloud"}
              <i className={"fas fa-caret-" + (cloudTypeDropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
            </DropdownToggle>
            <DropdownMenu>
              {cloudTypes.map((type, i) => { 
                return (
                  <DropdownItem onClick={() => { toggleCloudType(type) }} key={i}>
                    <img
                      className={type.cloud_type_id == "All" ? "d-none" : ""}
                      style={{ width: 25 + "px" }}
                      src={type.cloud_type_id == "All" ? "" : `https://tria.connectria.com/divvy/img/${type.cloud_type_id.toLowerCase()}-logo.png`}
                    />{" "}
                    {type.name}
                  </DropdownItem> 
                )
              })}
            </DropdownMenu>
          </Dropdown>
            <Dropdown className="my-1" isOpen={eventHarvestRoleDropdownOpen} toggle={toggleEventHarvestRoleDropdown} style={{width: 250+"px"}}>
              <DropdownToggle 
                tag="span"
                data-toggle="dropdown"
                aria-expanded={eventHarvestRoleDropdownOpen}
              >
                {selectedEventHarvestRole.id != "All" ? selectedEventHarvestRole.label : "Event Driven Role"}
              <i className={"fas fa-caret-" + (eventHarvestRoleDropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
              </DropdownToggle>
              <DropdownMenu>
                {eventHarvestRoles.map((role, i) => { 
                  return (
                    <DropdownItem onClick={() => { toggleEventHarvestRole(role) }} key={i}>
                      {role.label}
                    </DropdownItem> 
                  )
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        <CardBody>
          <>
              {isError ? ( 
            <div className="text-center text-bold">
              <i className="fas fa-exclamation-triangle"></i> 
              Error Loading Clouds.
            </div>
          ) :
          isLoading ? (
            <div className="text-center">
                <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
            </div>
          ) : 
          (!isLoading && !isError && clouds.length==0) ? (
            <div className="text-center mt-4 text-bold"><i className="fas fa-exclamation-triangle"></i> No clouds found. Try adjusting your filter.</div>
          ) :
          <div className="table-responsive-sm">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th style={{paddingBottom: 0 + "px"}}>Account</th>
                  <th style={{paddingBottom: 0 + "px"}}>Date</th>
                  <th style={{paddingBottom: 0 + "px"}}>Resource</th>
                  <th></th>
                  <th style={{paddingBottom: 0 + "px"}}>Disabled</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Cloud</th>
                  <th>ID</th>
                  <th>Added</th>
                  <th>Count</th>
                  <th>Badges</th>
                  <th>Resource</th>
                  <th>Harvest</th>
                  <th>Visibility</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody >
                {clouds.map( (cloud, i) => {
                  return (
                  <tr key={i}>
                    <td>{cloud.name}</td>
                    <td>
                      <img
                        style={{ width: 25 + "px" }}
                        src={`https://tria.connectria.com/divvy/img/${cloud.cloud_type_id.toLowerCase()}-logo.png`}
                      />
                    </td>
                    <td>{cloud.account_id}</td>
                    <td>{cloud.creation_time}</td>
                    <td>{cloud.resource_count}</td>
                    <td>{findBadgeCountByResourceId(cloud.resource_id)}</td>
                    <td>{cloud.disabled_resource_types ? cloud.disabled_resource_types.length : "0"}</td>
                    <td><i className={"fas fa-" + iconMapping[cloud.status]}/></td>
                    <td>
                      <i className={"fas fa-" + (!cloud.failed_resource_types || cloud.failed_resource_types==0 ? "check-circle text-success" : "exclamation-triangle text-warning")}/>
                    </td>
                    <td>{cloud.role_arn}</td>
                </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
            }
          </>
        </CardBody>
      </Card>
    </>
  );
}

export { CloudsListing }
