import React, { Fragment } from "react";
import { useEffect, useState } from "react"

import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";

import {Card, CardHeader, CardBody, CardTitle, ButtonGroup, Button} from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {appState} from "../../AppState";
import Resources from "../services/resources";
import {SecurityCheckDescriptionBlade} from "./SecurityAdvisorDetailBlade";
import { useHistory } from "react-router-dom";

import AWSBusinessLevelSupportNotification from "../../utility/AWSBusinessLevelSupport"
import moment from "moment";
const SecurityAdvisor = (props) => {
  console.log('props cloud type', props.cloudType);
  const allClouds = 'All Clouds';
  const title = (props.cloudType === "AWS" ? "AWS" : "Azure") + ' Security Advisor';
  const source = axios.CancelToken.source();
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [allResources, setAllResources] = useState([]);
  const [resources, setResources] = useState([]);

  const [clouds, setClouds] = useState([]);
  const [cloudDropdownOpen, setCloudDropdownOpen] = useState(false);
  const [selectedCloud, setSelectedCloud] = useState(allClouds);

  const [statusTypes, setStatusTypes] = useState({ok: 0, warning: 0, error: 0});
  const [statusType, setStatusType] = useState("");

  const DATETIME_FORMAT = "YYYY-MM-DD hh:mm:ss", today = moment()

  // Get CloudWatch Alarms
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let response = await Resources.getSecurityResources();
        setIsLoading(false);

        if (!isMounted()) return;

        var resources = response.data.resources
          .filter((resource) => { return resource.servicecheck.common.cloud === props.cloudType })
          // remove all OK status resources older than 90 days
          .filter((resource) => {
            resource.dt = moment(resource.servicecheck.common.discovered_timestamp, DATETIME_FORMAT)
            return !(resource.servicecheck.status === 'ok' && today.diff(resource.dt, 'days') > 90)
          })
        setAllResources(resources);

        if (history.location.state != null) {
          setStatusType(history.location.state.statusFilter ? history.location.state.statusFilter : '');
        } else {
          setStatusType('');
        }
        // reset the pushed state to be blank
        history.replace(history.location.pathname, {})

      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  // Apply the filters when the user selects one
  useEffect(() => {
    let resources = allResources;

    /*if (selectedCloudType !== '') {
      resources = resources.filter((resource) => { return resource.servicecheck.common.cloud === selectedCloudType });
    }*/

    // populate clouds list
    let clouds = [...new Set(
      resources.map((resource) => { return resource.servicecheck.common.account })
    )];
    clouds.unshift(allClouds);
    setClouds(clouds);

    if (selectedCloud !== allClouds) {
      resources = resources.filter((resource) => { return resource.servicecheck.common.account === selectedCloud });
    }

    // calculate states
    let states = {ok: 0, warning: 0, error: 0};
    resources.map((resource) => { states[resource.servicecheck.status] += 1 });
    setStatusTypes(states);

    if (statusType !== '') {
      resources = resources.filter((resource) => { return resource.servicecheck.status === statusType });
    }

    setResources(resources);
  }, [selectedCloud, statusType, allResources]);

  const toggleCloud = (cloud) => {
    setCloudDropdownOpen(!cloudDropdownOpen);
    if (typeof cloud === 'object') return;
    setSelectedCloud(cloud);
  }

  /*const toggleCloudType = (cloud) => {
    setCloudTypeDropdownOpen(!cloudDropdownOpen);
    if (typeof cloud === 'object') return;
    setCloudType(cloud);
  }*/

  const toggleStatusType = (type) => {
    if (statusType !== type) {
      setStatusType(type);
    } else {
      setStatusType("");
    }
  }

  const getStatus = (state) => {
    let result = {icon: '', color: ''};
    switch (state.servicecheck.status) {
      case 'ok':
        result.icon = (<i className="fas fa-check text-success"> Ok</i>);
        break;
      case 'warning':
        result.icon = (<i className="fas fa-exclamation-triangle text-warning"> Warning</i>);
        result.color = 'table-warning';
        break;
      case 'error':
        result.icon = (<i className="fas fa-bell text-danger"> Critical</i>);
        result.color = 'table-danger';
        break;
    }
    return result;
  }

  return (
    <>
      <h3 className="mb-0 mt-4 tria-header-class">
        {title}
      </h3>
      <hr></hr>
      <Card className="card-minimal">
        <CardBody>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xs-1 pt-3">
              <CloudDropdownFilter
              dropdownOpen = {cloudDropdownOpen}
              toggle = {toggleCloud}
              clouds = {clouds}
              cloud = {selectedCloud}
            />
            </div>
            <div className="col-md-9 col-lg-6 col-xs-1 pt-2">
              <StatusButtonGroup
                toggle={toggleStatusType}
                type={statusType}
                types={statusTypes}
              />
            </div>
          </div>
          {isError ? (
            <div className="text-center text-bold">
              <i className="fas fa-exclamation-triangle"></i>
              Error Loading {title} info.
            </div>
          ) :
          isLoading ? (
            <div className="text-center">
                <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
            </div>
          ) :
          (!isLoading && !isError && resources.length === 0) ? (
            <div className="text-center mt-4 text-bold">
         
            {title.indexOf("AWS Security Advisor") > -1 ? <AWSBusinessLevelSupportNotification></AWSBusinessLevelSupportNotification> :
            <span><i className="fas fa-exclamation-triangle"></i> No {title} records found. Try adjusting your filter.</span>}
            </div>
            
          ) :
          <div className="table-responsive-sm">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>State</th>
                  <th>Cloud name</th>
                  <th>Date</th>
                  <th>Security Checks</th>
                </tr>
              </thead>
              <tbody>
                {resources.map((resource, i) => {
                  let status = getStatus(resource);
                  return (
                  <tr key={i} onClick={() => {showDetailBlade(resource, stateApp, stateAppActions)}}>
                    <td>{status.icon}</td>
                    <td>{resource.servicecheck.common.account}</td>
                    <td>{resource.dt.format('LLL')}</td>
                    <td><span>{resource.servicecheck.name}</span></td>
                  </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
          }
        </CardBody>
      </Card>
    </>
  );
}

const CloudDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const clouds = props.clouds;
  const cloud = props.cloud;

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {cloud}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {clouds.map((cloud) => {
          return (<DropdownItem onClick={() => { toggle(cloud) }} key={cloud}>

            {cloud}
            </DropdownItem>
        )})}
      </DropdownMenu>
    </Dropdown>
  );
}

const CloudTypesGroup = (props) => {
  const toggle = props.toggle;
  const types = props.types;
  const type = props.type;
  return (
    <ButtonGroup size="sm" className="d-flex justify-content-center mb-3" role="group">
      {types.includes('aws') && <Button
        onClick={() => {toggle("AWS")}}
        className={`bm-status-btn btn-sm w-100 ${(type !== "AWS") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
          <img src="https://dev.tria.connectria.com/divvy/img/aws-logo.png" width="20" className="mr-2" />
          <span className="mt-1">AWS</span>
        </div>
      </Button>}
      {types.includes('azure') && <Button
        onClick={() => {toggle("AZURE_ARM")}}
        className={`bm-status-btn btn-sm w-100 ${(type !== "AZURE_ARM") ? "btn-simple" : ""}`} style={{maxWidth:"230px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
          <img src="https://dev.tria.connectria.com/divvy/img/azure_arm-logo.png" width="20" className="mr-2" />
          <span className="mt-1">AZURE</span>
        </div>
      </Button>}
    </ButtonGroup>
  );
}

const StatusButtonGroup = (props) => {
  const toggle = props.toggle;
  const types = props.types;
  const type = props.type;
  return (
    <ButtonGroup size="sm" className="d-flex justify-content-center mb-3" role="group">
      <Button
        onClick={() => {toggle("ok")}}
        className={`bm-status-btn btn-sm w-100 ${(type !== "ok") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="text-success fas fa-check mr-1 d-none d-md-block mr-3"></i>
        {" Ok (" + types.ok + ")"}
        </div>
      </Button>
      <Button
        onClick={() => {toggle("warning")}}
        className={`bm-status-btn btn-sm w-100 ${(type !== "warning") ? "btn-simple" : ""}`} style={{maxWidth:"230px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="text-warning fas fa-exclamation-triangle mr-1 d-none d-md-block mr-3"></i>
        {" Warning (" + types.warning + ")"}
        </div>
      </Button>
      <Button
        onClick={() => {toggle("error")}}
        className={`bm-status-btn btn-sm w-100 ${(type !== "error") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="text-danger fas fa-bell mr-1 d-none d-md-block"></i>
        {" Critical (" + types.error + ")"}
        </div>
      </Button>
    </ButtonGroup>
  );
}

const showDetailBlade = (resource, stateApp, stateAppActions) => {
  console.log(resource);
  stateAppActions.setBlade({
    title: "Security Check Details",
    visible: true,
    content: (<SecurityCheckDescriptionBlade id={resource.servicecheck.common.resource_id} />)
  });
}

export { SecurityAdvisor }
