import axios from "axios";
import { cacheAdapterEnhancer } from 'axios-extensions';
import moment from "moment";
import CookieService from "../services/CookieService";
import { getDateString, insertString } from "../utility/misc"


//create a cache axios instance
function _createCacheCall() { return axios.create({
	baseURL: '/',
	headers: {  "x-auth-token": CookieService.getCookie("session_id") },
	// disable the default cache and set the cache flag
	adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache'})
});
}
const base_api = 'https://sandbox.tria.connectria.com';
const _portalTicketsPlugin = '/plugin/tria_interface';
const portal_url = 'https://testportal.mh.connectria.com';
const files_url = 'https://files.dev.tria.connectria.com/';
var ticketTypes = [
    {
        "id": 1,
        "name": "Question"
    },
    {
        "id": 2,
        "name": "Informational"
    },
    {
        "id": 3,
        "name": "Incident"
    },
    {
        "id": 4,
        "name": "Problem"
    },
    {
        "id": 5,
        "name": "Change Request"
    }
];
var inbox = [];
let ticketsByStatus = {};
var ticketDetail = null;
var ticketHistory = null;
var lastTicketDetailId = null;
var lastTicketHistoryId = null;
var ticketAudits = null;
var ticketPriorityMetrics = null;
var userId = null;
var users = [];
var usersMap = {};
var userRatings = null;

function _inboxReturn(limit, offset) {
    return inbox.slice(offset);
}
function _virtualUpdateUserRating(item){
    if(userRatings == null) userRatings = [];

    //if item exists update if not add
    let indx = userRatings.findIndex(x => x.id == item.id);
    if(indx > -1){
        userRatings[indx] = item;
    }else{
    userRatings.push(item);
    }
}
function _virtualDeleteUserRating(id){
    userRatings = userRatings.filter(item => item.id !== id)
}

async function _getAnnoucments(params = {}) {
   
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/v2/getAnnoucments/getAllLike`, params);
        return request;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}
async function _getPortalAssumeToken(params = {}) {
   
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/v2/get_assume_token`, params);
        return request;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}
async function _getUserTicketRatings(params = {}) {
    if (userRatings != null) {
        return userRatings;
    }
    try {
        let request = await axios.get(base_api + _portalTicketsPlugin + `/portal/v2/ticket_history_ratings/getAll`, params);
        
        userRatings = Array.isArray(request.data.response.data.ticket_history_ratings) ? request.data.response.data.ticket_history_ratings : [];
        return userRatings;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}
async function _getTicketDetail(id, params = {}) {
    if (lastTicketDetailId == id && ticketDetail) {
        return ticketDetail;
    }
    try {
        let request = await axios.get(base_api + _portalTicketsPlugin + `/portal/v2/ticket/detail/${id}?ar_assoc=user,meta,ticket_attachments,ticketcategory,ticket_type,ticket_carbon_copies,enteredby`, params);
        lastTicketDetailId = id;
        ticketDetail = request.data.response.data.tickets[0];
        return ticketDetail;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getDevice(deviceID, params = {}) {
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/devices/get/${deviceID}?ar_assoc=operatingsystems,meta,addresses,devicetype`, params);
        return request.data.response.data;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getPortalDevice(deviceID, params = {}) {
    let url = "/plugin/support/portal/devices/get";
    if (deviceID) {
        url += "/" + deviceID;
    }
    try {
        let request = await axios.get(base_api + url, {params: params});
        return request.data.response.data;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getAllDevices(params = {}) {
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/devices/getAll`, params);
        return request.data.response.data;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getAllDevicesLike(payload, params = {}) {
    try {
      
        params.useCache = true;
        let http = _createCacheCall();
        let request = await http.get(base_api + `/plugin/support/portal/devices/getAllLike?`, {params: payload}, params);
        return request.data.response.data.devices;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}
async function _getAllDevicesById(payload, params = {}) {
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/devices/getAll?`, {params: payload}, params);
        return request.data.response.data.devices;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getAllDeviceCount(payload, params = {}) {
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/devices/getCount`, {params: payload}, params);
        return request.data.response.data;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getAllDeviceTypes(payload, params = {}) {
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/devicetypes/getAll`, {params: payload}, params);
        return request.data.response.data.devicetypes;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getDeviceMetrics(params, options = {}) {
    try {
        let request = await axios.get(base_api + `/plugin/tria_interface/devices/metrics`, { params }, options);
        return request.data.nodes;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}


async function _getDeviceOSType(id, params = {}) {
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/ostypes/get/${id}`, params);
        return request.data.response.data.ostypes[0];
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _updateDevice(id, data, params = {}) {
    try {
        let request = await axios.put(base_api + `/plugin/support/portal/devices/update/${id}`, data, params);
        return request.data.response;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getAllTicketsCount(filter, params = {}) {
    try {
        let request = await axios.get(base_api + "/plugin/support/portal/tickets/getCount", filter, params);
        return request.data.response.data.tickets[0];
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getAllTicketsLike(filter, params = {}) {
    try {
        let request = await axios.post(base_api + _portalTicketsPlugin + "/portal/v2/tickets/getAllLike", filter, params);
        if (request.data.response.data.tickets == null) {
            return [];
        }
        return request.data.response.data.tickets;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getTicketHistory(id, params = {}) {
    if (lastTicketHistoryId === id && ticketHistory !== null) {
        return ticketHistory.ticket_histories;
    }
    try {
        let request = await axios.get(base_api + _portalTicketsPlugin + `/portal/ticket_histories/getAll?ar_assoc=user&ticket_id=${id}`, params);
        lastTicketHistoryId = id;
        ticketHistory = request.data.response.data;

        if (ticketHistory.ticket_histories) {
            return ticketHistory.ticket_histories.reverse();
        }
        return null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

async function _downloadAttachment(id) {
    try {
        let request = await axios.get(base_api + `/plugin/support/portal/v2/ticket_attachments/getRawFile/${id}`);
        return request;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _addCommentAttachment(id, filename, content) {
  let hasFile = filename && filename.length > 0 && content && content.length > 0;
  if (!hasFile) return false;

  let payload = {
    attachment: {
      filename: insertString(filename, getDateString(), '.'),
      json: content
    },
    comment: {
      comment: 'Attached file: ' + filename
    }
  }

  let request;
  try {
    request = await axios.post(base_api + `/plugin/support/portal/tickets/addAttachment/${id}`, payload);
    // if upload was successful, clear cache
    if (request.data.response.status) {
      ticketHistory = null;
      ticketAudits = null;

      // clear ticket detail cache when attachments are added
      if (hasFile) {
          console.log("clearing ticket cache after attachment")
          ticketDetail = null;
      }
    }
    return request.data.response.message;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function _postNewComment(id, comment) {
  if (!comment || (comment.length < 3)) return;
  try {
    var payload = {
      comment: {
        comment: comment
      }
    }
    let request = await axios.post(base_api + `/plugin/support/portal/tickets/addComment/${id}`, payload);

    //if update was successful, clear cache
    if (request.data.response.status) {
      ticketHistory = null;
      ticketAudits = null;
    }
    return request.data.response.message;
  } catch (error) {
      console.log(error);
  }
}

async function _getTicketTypes(params = {}) {
    if (ticketTypes.length != 0) {
        return ticketTypes;
    }
    try {
        let request = await axios.get(base_api + "/plugin/support/portal/ticket_types/getAll", params);
        ticketTypes = request.data.response.data.ticket_types;

        return ticketTypes;
    } catch (error) {
        console.log(error);
    }
}

async function _getAllTicketsByStatus(status) {
    if (ticketsByStatus[status] != null) {
        return ticketsByStatus[status];
    }
    try {
        let request = await axios.get(base_api + "/plugin/support/portal/tickets/getAll?limit=100&select=tickets.id,+subject,device,ticket_type_id,priority,created,+status,user_id,+lastUpdate&sort_by=lastUpdate+desc&status=" + status);
        ticketsByStatus[status] = request.data.response.data.tickets;
        return ticketsByStatus[status];
    } catch (error) {
        console.log(error);
    }
}

async function _getTicketAudits(limit, params = {}) {
    if (ticketAudits != null) {
        return ticketAudits;
    }
    try {
        let request = await axios.get(`${base_api}/plugin/support/portal/tickets/audit?limit=${(limit != null) ? limit : 15}&sort_by=lastUpdate+desc`, params);
        ticketAudits = request.data.response.data.ticket_audits;
        return ticketAudits;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

async function _closeTicket(id, comment, rating) {
    try {
        var payload = {
            "comment": {
                "comment": comment
            }
        }
        await axios.put(`${base_api}/plugin/support/portal/tickets/closeTicket/${id}`, payload);
        if (rating) {
            var payloadRating = {
                "closed_ticket_rating": {
                    "ticket_id": id,
                    "closed_ticket_rating_status_id": rating,
                    "comment": comment
                }
            };
            await axios.post(`${base_api}/plugin/support/portal/closed_ticket_ratings/create`, payloadRating);
        }
        //clear cache
        ticketDetail = null;
    } catch (error) {
        console.log(error);
    }
}

async function _getChildTicket(id) {
    try {
        return await axios.get(`${base_api}/plugin/support/portal/tickets/child_ticket/getAll?parent_ticket_id=${id}`);
    } catch (error) {
        console.log(error);
    }
}

async function _reopenTicket(id, comment) {
    try {
        var payload = {
            "comment": {
                "comment": comment
            }
        }
        await axios.put(`${base_api}/plugin/support/portal/tickets/reopenTicket/${id}`, payload);
        //clear cache
        ticketDetail = null;
    } catch (error) {
        console.log(error);
    }
}

async function _getTicketsFiltered(filter, params = {}) {
    try {
        let request = await axios.post(base_api + "/plugin/support/portal/tickets/getAll", filter, params);
        if (request.data.response.data.tickets == null) {
            return [];
        }
        return request.data.response.data.tickets;
    } catch (error) {
        console.log(error);
        throw (error);
    }
}

async function _getTicketCategories(params = {}) {
  try {
    params.useCache = true;
    let http = _createCacheCall();
    let request = await http.get(base_api + "/plugin/support/portal/ticketcategories/getAll?customeraccess=1&active=1", params);
    return request.data.response.data?.ticketcategories?.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.log(error);
    throw(error);
  }
}

async function _getAllUsersLike(params={}, filterConnectria = true) {
    if (users.length !== 0) {
        return users;
    }
    try {
        params.useCache = true;
        console.log(CookieService.getCookie("session_id"));
        let http = _createCacheCall();
        let response = await http.get(base_api + "/plugin/support/portal/v2/users/getAllLike", params);
        users = response.data.response.data.users;
        
        if (filterConnectria) {
            users = (users || []).filter((user) => { return !user.email.includes('@connectria.com') })
        }
        
        for (let n in users) {
            usersMap[users[n].id] = users[n];
        }
       
        return users;
    } catch (error) {
        console.log(error);
        throw(error);
    }
}

async function _getPreferences(filter, params={}) {
    try {
        let response = await axios.get(base_api + "/plugin/support/portal/preferences/get", {params: filter}, params);
        return response.data.response.data.preferences;
    } catch (error) {
        console.log(error);
        throw(error);
    }
}

async function _getParamData(params={}) {
    try {
        let response = await axios.get(base_api + "/plugin/support/get_param_data", params);
        return response.data;
    } catch (error) {
        console.log(error);
        throw(error);
    }
}

const ConnectriaTicketsService = {
    getAllUsersLike: async function (params={}, filterConnectriaUsers = true) {
        return await _getAllUsersLike(params, filterConnectriaUsers);
    },
    getPreferences: async function (filter, params={}) {
        filter["user_id"] = await ConnectriaTicketsService.portalUserId(params);
        return await _getPreferences(filter, params);
    },
    updateTicket: async function(id, data) {
      try {
            await axios.put(`${base_api}/plugin/support/portal/tickets/update/${id}`, data);
        } catch (error) {
            console.log(error);
            throw(error);
        }
    },
    updateUsersTicketCarbonCopies: function (ticket) {
        for (let n in ticket.ticket_carbon_copies) {
            ticket.ticket_carbon_copies[n].user = usersMap[ticket.ticket_carbon_copies[n].user_id];
        }
    },
    deleteTicketCarbonCopy: async function(ccID) {
        try {
            await axios.delete(`${base_api}/plugin/support/portal/ticket_carbon_copies/delete/${ccID}`);
        } catch (error) {
            console.log(error);
            throw(error);
        }
    },
    getAttachmentUrl: async function(ticket_id, attachment_id){
        let assumeToken = await _getPortalAssumeToken()
        return files_url + `?id=${attachment_id}&tid=${ticket_id}&api_key=${assumeToken.data.portal_assume_token}`;

        //return portal_url + `/support/getAttachment?id=${attachment_id}&tid=${ticket_id}&api_key=${assumeToken.data.portal_assume_token}`;
    },
    getPortalAssumeToken: async function(){
        return await _getPortalAssumeToken
    },
    getAnnoucments: async function(params){
        return await _getAnnoucments(params)
    },
    getTicketCommentRatings: async function(params){
       
        return await _getUserTicketRatings( params)
      
    },
    createTicketCommentRating: async function(id, rating, comment = ''){
        
        let url = `${base_api}/plugin/support/portal/ticket_history_ratings/create`;
        try {
            let payload = {ticket_history_rating: {
                comment: comment,
                ticket_history_id: id,
                rating: rating
            }
        }
        console.log(payload)
            let response = await axios.post(url, payload);
            if(response.data.response.status){
                _virtualUpdateUserRating(response.data.response.data.ticket_history_ratings[0])
            }
            return response.data.response.data;
        } catch (error) {
            console.log(error);
            throw(error);
        }
    },
    updateTicketCommentRating: async function(id, rating,comment = ''){
        
        let url = `${base_api}/plugin/support/portal/ticket_history_ratings/update`;
        try {
            let payload = {ticket_history_rating: {
                comment: comment,
                id: id,
                rating: rating
            }
        }
            let response = await axios.put(url, payload);
            if(response.data.response.status){
                _virtualUpdateUserRating(response.data.response.data.ticket_history_ratings[0])
            }
            return response.data.response.data.ticket_carbon_copies;
        } catch (error) {
            console.log(error);
            throw(error);
        }
    },
    deleteTicketCommentRating: async function(id){
        
        let url = `${base_api}/plugin/support/portal/ticket_history_ratings/delete/${id}`;
        try {
           
            let response = await axios.delete(url);
            if(response.data.response.status){
            _virtualDeleteUserRating(id)
            }
            return response.data.response.data;
        } catch (error) {
            console.log(error);
            throw(error);
        }
    },
    saveTicketCarbonCopies: async function (id, users) {
        // create ticket carbon copies
        let users_params = '';
        for (let user_id of users) {
            users_params += '&ticket_carbon_copies[][user_id]=' + user_id;
        }

        let url = `${base_api}/plugin/support/portal/ticket_carbon_copies/create?ticket_carbon_copies[][ticket_id]=${id}${users_params}`;
        try {
            let response = await axios.post(url);
            return response.data.response.data.ticket_carbon_copies;
        } catch (error) {
            console.log(error);
            throw(error);
        }
    },
    getCustomerTicketCarbonCopies: async function (filter, params={}) {
        filter["user_id"] = await ConnectriaTicketsService.portalUserId(params);
        var preferences = await _getPreferences(filter, params);
        if (preferences != null && preferences.length > 0 && preferences[0].value != "") {
          return JSON.parse(preferences[0].value);
        } else {
          return [];
        }
    },
    downloadAttachment: async function (id) {
        return await _downloadAttachment(id);
    },
    getDevice: async function (deviceID, params={}) {
        return await _getDevice(deviceID, params);
    },
    getAllDevices: async function (params = {}) {
        return await _getAllDevices(params);
    },
    getDeviceOSType: async function (id, params = {}) {
        return await _getDeviceOSType(id, params);
    },
    getDeviceIpBulk: async function(host_names = []){
        let promises = [];
        for(var i = 0; i < host_names.length; i++){
                //name consist of device asset tag and .ipam.connpriv.com
                promises.push(axios.get(base_api + `/plugin/support/portal/hosts/get?name=${host_names[i].toLowerCase() + '.ipam.connpriv.com'}`, {}))
        }
        return await Promise.all(promises);
    },
    getAllDevicesLike: async function (filter, params = {}) {
        return await _getAllDevicesLike(filter, params);
    },
    getAllDevicesById: async function (filter, params = {}) {
        return await _getAllDevicesById(filter, params);
    },
    getAllDeviceCount: async function (filter, params = {}) {
        return await _getAllDeviceCount(filter, params);
    },
    getAllDeviceTypes: async function (filter, params = {}) {
        return await _getAllDeviceTypes(filter, params);
    },
    getDeviceMetrics: async function (params, options = {}) {
        return await _getDeviceMetrics(params, options);
    },
    getAllTicketsCount: async function (query, params = {}) {
        return await _getAllTicketsCount(query, params);
    },
    getAllTicketsLike: async function (query, params = {}) {
        return await _getAllTicketsLike(query, params);
    },
    portalUserId: async function (parmas = {}) {
        if (userId != null) {
            return userId;
        }
        var request = await axios.get(base_api + "/plugin/support/portal/user_id", parmas);
        userId = request.data.user_id;
        return userId;
    },
    createTicket: async function (subject, issue, error, priorityId, typeId, deviceId, carbonCopies, categoryId, ticketMeta, doSentiment = false) {
        var payload = {
            "tickets": [{
                "active": false,
                "errorDesc": error,
                "id": null,
                "issue": issue,
                "priority": priorityId,
                "status": null,
                "subject": subject,
                "device": deviceId || 'undefined',
                "ticket_carbon_copies": carbonCopies,
                "ticket_type_id": typeId,
                "ticketcategory_id": categoryId,
                "ticket_meta": ticketMeta
            }]
        };
        var request = await axios.post(base_api + "/plugin/support/portal/tickets/create", payload);
        return request.data.response.data.tickets[0].id;
    },
    createTicketMeta: async function (ticket_id, key, value) {

        var payload = {
            "ticket_metum": {
                "meta_key": key,
                "meta_value": value,
                "ticket_id": ticket_id
            }
        };
        var request = await axios.post(base_api + "/plugin/support/portal/ticket_meta/create", payload);
        return request.data.response.data;
    },
    deleteTicketMeta: async function (id) {
        var request = await axios.delete(base_api + `/plugin/support/portal/ticket_meta/delete/${id}`);
        return request.data.response.data;
    },
    ticketPriorityMetric: async function (daysToInclude, params = {}) {
        if (ticketPriorityMetrics != null) {
            return ticketPriorityMetrics;
        }
        var payload = {
            select: "priority,created",
            limit: "200",
            sort_by: "created desc"
        };
        var raw = await _getTicketsFiltered(payload, params);
        var result = {
            labels: ["Emergency", "High", "Normal", "Low"],
            datasets: [{
                data: [0, 0, 0, 0],
                backgroundColor: [
                    '#dc3545',
                    '#ffc107',
                    '#777777',
                    '#adb5bd'
                ],
                hoverBackgroundColor: [
                    '#dc3545',
                    '#ffc107',
                    '#777777',
                    '#adb5bd'
                ]
            }]
        };
        for (var i = 0; i < raw.length; i++) {
            if (moment(raw[i].created, "YYYY-MM-DDThh:mm:ss.SSSZ").days() > daysToInclude) {
                break;
            }
            result.datasets[0].data[raw[i].priority - 1] = result.datasets[0].data[raw[i].priority - 1] + 1;
        }
        ticketPriorityMetrics = result;
        return result;
    },
    closeTicket: async function (id, comment, rating) {
        await _closeTicket(id, comment, rating);
    },
    getChildTicket: async function (id) {
        return await _getChildTicket(id);
    },
    reopenTicket: async function (id, comment) {
        await _reopenTicket(id, comment);
    },
    ticketAudits: async function (limit, params = {}) {
        return await _getTicketAudits(limit, params);
    },
    clearTicketCache: function() {
      ticketDetail = null
    },
    ticketDetail: async function (id, params = {}) {
        //ensure users and ticket types are loaded with ticket detail
        let promises = await Promise.all([
            _getTicketTypes(params), _getAllUsersLike(params), _getTicketDetail(id, params)
        ])
        let ticket = promises[2];
        this.updateUsersTicketCarbonCopies(ticket);

        return ticket;
    },
    ticketHistory: async function (id, params = {}) {
        return await _getTicketHistory(id, params);
    },
    newComment: async function (id, comment) {
        // clear ticket cache
        ticketDetail = null
        ticketHistory = null

        return await _postNewComment(id, comment);
    },
    addAttachment: async function(id, filename, content) {
        return await _addCommentAttachment(id, filename, content);
    },
    ticketCategories: async function(params) {
      return await _getTicketCategories(params);
    },
    allTicketsByStatus: async function (status, params = {}) {
        // return await _getAllTicketsByStatus(status);
        return await _getAllTicketsLike({
            ar_assoc: 'user',
            limit: '0,100',
            select: 'tickets.id,subject,device,ticket_type_id,priority,created,status,user_id,lastUpdate',
            sort_by: 'lastUpdate desc',
            status,
        }, params);
    },
    ticketTypes: async function (params = {}) {
        return await _getTicketTypes(params);
    },
    ticketType: function (id) {
        for (var i = 0; i < ticketTypes.length; i++) {
            if (ticketTypes[i].id == id) {
                return ticketTypes[i];
            }
        }
    },
    ticketPriorities: function () {
        return [{
            id: 1,
            name: "1 - Emergency"
        },
        {
            id: 2,
            name: "2 - High"
        },
        {
            id: 3,
            name: "3 - Normal"
        },
        {
            id: 4,
            name: "4 - Low"
        }
        ]
    },
    ticketPriority: function (id) {
        var priorities = ConnectriaTicketsService.ticketPriorities();
        for (var i = 0; i < priorities.length; i++) {
            if (priorities[i].id == id) {
                return priorities[i];
            }
        }
    },
    inbox: async function (filter, params = {}) {
        var payload = {
            limit: filter.limit,
            select: "tickets.id,subject,priority,created,status,user_id,ticket_type_id,lastUpdate",
            sort_by: filter.sort
        };
        if (filter.statuses != null) {
            payload["status"] = filter.statuses;
        }
        if (filter.limit != null) {
            payload["limit"] = filter.limit;
            if (filter.offset != null) {
                payload["limit"] = `${filter.offset},${filter.limit}`
            }
        }
        if (filter.justMine) {
            payload["user_id"] = await ConnectriaTicketsService.portalUserId(params);
        }
        
        if (filter.search != "") {
            
            if(filter.subjectOnly){
            payload['subject'] = filter.search;
            }else{
             payload['issue'] = filter.search;
            }
            return await _getAllTicketsLike(payload, params);
        } else {
            return await _getTicketsFiltered(payload, params);
        }
    },
    getParamData: async function (params={}) {
        return await _getParamData(params);
    },
    updateDevice: async function (data, params={}) {
        return await _updateDevice(data, params);
    },
    getPortalDevice: async function(deviceId, params={}) {
        return await _getPortalDevice(deviceId, params);
    }
};

export default ConnectriaTicketsService;
