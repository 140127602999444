import React, { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  ButtonGroup,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { uniq, range } from 'ramda';

import useAsyncEffect from '../../utility/use-async-effect';

import AlertsService from '../../services/AlertsService';
import { appState } from '../../AppState';
import { useHistory } from "react-router-dom";


const AzureAlerts = (props) => {
  const allClouds = 'All Clouds';
  const allResourceGroups = 'All Resource Groups';
  const source = axios.CancelToken.source();
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [allAlerts, setAllAlerts] = useState([]);
  const [alerts, setAlerts] = useState([]);

  const [clouds, setClouds] = useState([]);
  const [cloudDropdownOpen, setCloudDropdownOpen] = useState(false);
  const [selectedCloud, setSelectedCloud] = useState(allClouds);
  const [cloudIcons, setCloudIcons] = useState({});

  const [resourceGroups, setResourceGroups] = useState([]);
  const [resourceGroupDropdownOpen, setResourceGroupDropdownOpen] = useState(false);
  const [selectedResourceGroup, setSelectedResourceGroup] = useState(allResourceGroups);

  const [statusTypes, setStatusTypes] = useState({ Closed: 0, Acknowledged: 0, New: 0 });
  const [statusType, setStatusType] = useState('');

  // pagination variables
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationRange, setPaginationRange] = useState([]);
  const PAGE_SIZE = 25, DATETIME_FORMAT = "YYYY-MM-DDThh:mm:ss.SSSZ", today = moment();

  // Get Azure Monitor Alerts
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        let response = await AlertsService.getAzureAlerts();
        setIsLoading(false);

        if (!isMounted()) return;
        setAllAlerts(response.data.clouds_alerts_list);
        // remove all Closed alerts older than 90 days
        setAlerts(response.data.clouds_alerts_list.filter((alert) => {
          alert.dt = moment(alert.start_date_time, DATETIME_FORMAT)
          return !(alert.state === 'Closed' && today.diff(alert.dt, 'days') > 90)
        }));

        // add default option to clouds list
        let cloudsList = response.data.clouds_names_list.map((cloud) => cloud.name);
        cloudsList.unshift(allClouds);
        setClouds([...new Set(cloudsList)]);

        // add default option to resoure group list
        let resourceGroupList = uniq(response.data.clouds_alerts_list.map(alert => alert.target_resource_group));
        resourceGroupList.unshift(allResourceGroups);
        setResourceGroups([...new Set(resourceGroupList)]);

        // calculate states
        setupStatusTypes(response.data.clouds_alerts_list);

        // initialize pagination configs
        setupPagination(response.data.clouds_alerts_list.length);

        // set status type to the selected filter from the dashboard or empty to run useEffect and populate alarms & filters\
        if (history.location.state != null) {
          setStatusType(history.location.state.statusFilter ? history.location.state.statusFilter : '');
        } else {
          setStatusType('');
        }
        // reset the pushed state to be blank
        history.replace(history.location.pathname, {})
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  // Apply the filters when the user selects one
  useEffect(() => {
    let _alerts = allAlerts;

    if (selectedCloud !== allClouds) {
      _alerts = _alerts.filter((_alert) => _alert.tria_cloud_name === selectedCloud);
    }

    if (selectedResourceGroup !== allResourceGroups) {
      _alerts = _alerts.filter((_alert) => _alert.target_resource_group === selectedResourceGroup);
    }

    setupStatusTypes(_alerts);

    if (statusType !== '') {
      _alerts = _alerts.filter((_alert) => _alert.state === statusType);
    }

    setAlerts(_alerts);
    setupPagination(_alerts.length);
  }, [selectedCloud, selectedResourceGroup, statusType, allAlerts]);

  const toggleCloud = (cloud) => {
    setCloudDropdownOpen(!cloudDropdownOpen);
    if (typeof cloud === 'object') return;
    setSelectedCloud(cloud);
  }

  const toggleResourceGroup = (resourceGroup) => {
    setResourceGroupDropdownOpen(!resourceGroupDropdownOpen);
    if (typeof resourceGroup === 'object') return;
    setSelectedResourceGroup(resourceGroup);
  }

  const toggleStatusType = (type) => {
    if (statusType !== type) {
      setStatusType(type);
    } else {
      setStatusType('');
    }
  }

  const getAlertIcon = (_alert) => {
    switch (_alert.state) {
      case 'Closed':
        return (<i className="fas fa-check-sqaure text-success"> Closed</i>);
      case 'Acknowledged':
        return (<i className="fas fa-exclamation-triangle text-warning"> Acknowledged</i>);
      case 'New':
        return (<i className="fas fa-exclamation-circle text-danger"> New</i>);
      default:
        break;
    }
    return '';
  }

  const setupStatusTypes = (_alerts) => {
    let states = { Closed: 0, Acknowledged: 0, New: 0 };
    _alerts.forEach((alert) => { states[alert.state] += 1; });
    setStatusTypes(states);
  }

  const setupPagination = (size) => {
    const numPages = Math.ceil(size / PAGE_SIZE);
    setPagesCount(numPages);
    if (numPages >= 5) {
      setPaginationRange(range(0, 5));
    } else {
      setPaginationRange(range(0, numPages));
    }
    setCurrentPage(0);
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    if (index > 2 && index < pagesCount - 2) {
      setPaginationRange(range(index - 2, index + 3));
    } else if (index <= 2) {
      if (pagesCount <= 5) {
        setPaginationRange(range(0, pagesCount));
      } else {
        setPaginationRange(range(0, 5));
      }
    } else {
      setPaginationRange(range(pagesCount - 5, pagesCount));
    }
  }

  return (
    <>
      <Card className="card-minimal">
        <CardHeader className="card-header-minimal mb-2 d-flex justify-content-between flex-wrap">
          <CardTitle className="card-title-minimal" tag="h3" style={{ fontSize: '2rem' }}>
            Azure Monitor
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12 col-sm-2 pt-2">
              <CloudDropdownFilter
                dropdownOpen={cloudDropdownOpen}
                toggle={toggleCloud}
                clouds={clouds}
                cloud={selectedCloud}
                cloudIcons={cloudIcons}
              />
            </div>
            <div className="col-12 col-sm-2 pt-2">
              <ResourceGroupDropdownFilter
                dropdownOpen={resourceGroupDropdownOpen}
                toggle={toggleResourceGroup}
                resourceGroups={resourceGroups}
                resourceGroup={selectedResourceGroup}
              />
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <StatusButtonGroup
                toggle={toggleStatusType}
                statusType={statusType}
                statusTypes={statusTypes}
              />
            </div>
          </div>
          {isError ? (
            <div className="text-center text-bold">
              <i className="fas fa-exclamation-triangle"></i>
              Error Loading Monitor Alerts.
            </div>
          ) :
          isLoading ? (
            <div className="text-center">
                <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: '30px' }}></i>
            </div>
          ) :
          (!isLoading && !isError && alerts.length === 0) ? (
            <div className="text-center mt-4 text-bold"><i className="fas fa-exclamation-triangle"></i> No alerts found. Try adjusting your filter.</div>
          ) :
          <>
            <div className="table-responsive-sm">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>State</th>
                    <th>Cloud</th>
                    <th>Name</th>
                    <th>Resource Group</th>
                    <th>Date</th>
                    <th>Threshold</th>
                  </tr>
                </thead>
                <tbody>
                  {alerts.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE).map((alert) => (
                    <tr key={alert.alert_id} onClick={() => {showAlertBlade(alert, stateApp, stateAppActions)}}>
                      <td>{getAlertIcon(alert)}</td>
                      <td>{alert.tria_cloud_name}</td>
                      <td>{alert.alert_name}</td>
                      <td>{alert.target_resource_group}</td>
                      <td>{alert.dt.format('LLL')}</td>
                      <td>{alert.threshold}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex w-100 justify-content-center">
              <Pagination>
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink first href="#" onClick={(e) => handlePageClick(e, 0)}/>
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink previous href="#" onClick={(e) => handlePageClick(e, currentPage - 1)}/>
                </PaginationItem>
                {paginationRange.map((page) => (
                  <PaginationItem active={page === currentPage} key={page}>
                    <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
                      {page + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= pagesCount-1}>
                  <PaginationLink next href="#" onClick={(e) => handlePageClick(e, currentPage + 1)}/>
                </PaginationItem>
                <PaginationItem disabled={currentPage >= pagesCount-1}>
                  <PaginationLink last href="#" onClick={(e) => handlePageClick(e, pagesCount - 1)}/>
                </PaginationItem>
              </Pagination>
            </div>
          </>
          }
        </CardBody>
      </Card>
    </>
  );
}

const CloudDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const clouds = props.clouds;
  const cloud = props.cloud;
  const cloudIcons = props.cloudIcons;

  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {cloud}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {clouds.map((cloud) => {
          return (<DropdownItem onClick={() => { toggle(cloud) }} key={cloud}>

            {cloud}
            </DropdownItem>
        )})}
      </DropdownMenu>
    </Dropdown>
  );
}

const ResourceGroupDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const resourceGroups = props.resourceGroups;
  const resourceGroup = props.resourceGroup;

  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {resourceGroup || '(Empty)'}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu right>
        {resourceGroups.map((_resourceGroup) => (
          <DropdownItem onClick={() => { toggle(_resourceGroup) }} key={_resourceGroup}>
          {_resourceGroup || '(Empty)'}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

const StatusButtonGroup = (props) => {
  const toggle = props.toggle;
  const statusTypes = props.statusTypes;
  const statusType = props.statusType;
  return (
    <ButtonGroup size="sm" className="d-flex justify-content-center mb-3" role="group">
      <Button
        onClick={() => toggle('Closed')}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== 'Closed') ? 'btn-simple' : ''}`}
        style={{ maxWidth: '200px', border: 'unset' }}
      >
        <div className="row justify-content-center fas">
          <i className="text-success fas fa-check-square mr-1 d-none d-md-block"></i>
          {` Closed (${statusTypes.Closed})`}
        </div>
      </Button>
      <Button
        onClick={() => toggle('Acknowledged')}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== 'Acknowledged') ? 'btn-simple' : ''}`}
        style={{ maxWidth: '230px', border: 'unset' }}
      >
        <div className="row justify-content-center fas">
          <i className="text-warning fas fa-exclamation-triangle mr-1 d-none d-md-block"></i>
          {` Acknowledged (${statusTypes.Acknowledged})`}
        </div>
      </Button>
      <Button
        onClick={() => toggle('New')}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== 'New') ? 'btn-simple' : ''}`}
        style={{ maxWidth: '200px', border: 'unset' }}
      >
        <div className="row justify-content-center fas">
          <i className="text-danger fas fa-exclamation-circle mr-1 d-none d-md-block"></i>
          {` New (${statusTypes.New})`}
        </div>
      </Button>
    </ButtonGroup>
  );
}

const showAlertBlade = (alert, stateApp, stateAppActions) => {
  stateAppActions.setBlade({
    title: "Alert Details",
    visible: true,
    content: (<>
      <div className="ml-1 mb-2"><b>Severity:</b><p>{alert.severity}</p></div>
      <div className="ml-1 mb-2"><b>Description:</b><p>{alert.affected_resource}</p></div>
      <div className="ml-1 mb-2"><b>State:</b><p>{alert.state}</p></div>
      <div className="ml-1 mb-2"><b>Monitor condition:</b><p>{alert.monitor_condition}</p></div>
      <div className="ml-1 mb-2"><b>Properties Title:</b><p>{alert.properties_title}</p></div>
      <div className="ml-1 mb-2"><b>Incident Type:</b><p>{alert.properties_incident_type}</p></div>
      <div className="ml-1 mb-2">
        <b>Communication:</b>
        <p dangerouslySetInnerHTML={{ __html: alert.properties_communication}}></p>
      </div>
      <div className="ml-1 mb-2">
        <b>Impacted Services:</b>
        <p dangerouslySetInnerHTML={{ __html: alert.properties_impacted_services}}></p>
      </div>
      <div className="ml-1 mb-2"><b>Event source:</b><p>{alert.event_source}</p></div>
      <div className="ml-1 mb-2"><b>Channels:</b><p>{alert.channels}</p></div>
      <div className="ml-1 mb-2"><b>Level:</b><p>{alert.level}</p></div>
      <div className="ml-1 mb-2"><b>Event data id:</b><p>{alert.event_data_id}</p></div>
      <div className="ml-1 mb-2"><b>Status:</b><p>{alert.status}</p></div>
      <div className="ml-1 mb-2"><b>Operation id:</b><p>{alert.operation_id}</p></div>
      <div className="ml-1 mb-2"><b>Operation name:</b><p>{alert.operation_name}</p></div>
      <div className="ml-1 mb-2"><b>Start time stamp:</b><p>{moment(alert.start_date_time).format('M/D/YYYY, h:m:s A')}</p></div>
      <div className="ml-1 mb-2"><b>Monitor service:</b><p>{alert.monitor_service}</p></div>
      <div className="ml-1 mb-2"><b>Signal type:</b><p>{alert.signal_type}</p></div>
      <div className="ml-1 mb-2"><b>Alert id:</b><p>{alert.alert_id}</p></div>
      <div className="ml-1 mb-2"><b>Alert rule:</b><p>{alert.alert_rule}</p></div>
    </>)
  });
}

export { AzureAlerts }
