import React, { useState, Suspense, lazy } from "react";
import CostService from "../services/CostService";

import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";

// core components
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledCollapse,
  Button,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { appState } from "../../AppState";

import CostUtility from "../utilities/cost-utility";
function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const getUnorderedList = item => {
  let items = item.split("|");
  let html = [];
  for (var i = 0; i < items.length; i++) {
    html.push(<li>{items[i]}</li>);
  }
  return html;
};

const getCollpasibile = (item, idx) => {
  let id = makeid(5)
  return (
    <div>
      <span className="pointer"
        color="primary"
        id={id}
        style={{ marginBottom: "1rem" }}
      >
        <h4>
          <i className="fas fa-level-down-alt flip"></i> {item.Name}{" "}
          <span>
            <strong>
              {item.TotalSaving.toLocaleString("us-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
            </strong>
          </span>
        </h4>
      </span>
      <UncontrolledCollapse toggler={"#" + id}>
        <div className="ml-5">
          <ul>{getUnorderedList(item.Detail[0])}</ul>
        </div>
      </UncontrolledCollapse>
    </div>
  );
};
const getLineItem = item => {
  let headLines = [];
  for (var i = 0; i < item.data.length; i++) {
    headLines.push(getCollpasibile(item.data[i], i));
  }
  return headLines;
};
const TriaSavingsRecommendor = props => {
  const [stateApp, stateAppActions] = appState();
  const [isLoading, setIsLoading] = useState(true);
  const [savings, setSavings] = useState([]);
  const [triaSavingsRecs, setTriaSavingsRecs] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    cloud_name: "",
    cloud_type_id: "",
    savingCategories: []
  });
  const selectAccount = name => {
    setSelectedAccount(triaSavingsRecs[name]);
  };
  const rollupData = data =>
  {

  }
  useAsyncEffect(
    async isMounted => {
      try {
    
        if(props.data == null) return <></>
       
        let savings =  (props.data) == null ? []: props.data.data
        let triaRecs = false;
     
        let triaSavings = {};
        for (var i = 0; i < savings.length; i++) {
         
          if (Object.keys(savings[i].cost_savings) != 0) {
            savings[i].has_tria_recs = true;
            let item = {
              cloud_name: savings[i].cloud_name,
              cloud_type_id: savings[i].cloud_type_id,
              savingCategories: []
            };

            //lets only get items that are actionable.
            Object.keys(savings[i].cost_savings).forEach(function(
              key,
              index
            ) {
            
              if (
                Array.isArray(savings[i].cost_savings[key]) &&
                savings[i].cost_savings[key].length > 0
              ) {
                item.savingCategories.push({
                  name: key,
                  data: savings[i].cost_savings[key],
                
                });
               
              }
            });

            triaSavings[savings[i].cloud_name] = item;
          }
        }
        console.log('fuck');
      
        if (!isMounted()) return;
  
        setSavings(savings);
        setSelectedAccount(typeof(triaSavings[Object.keys(triaSavings)[0]]) == 'undefined' ? {
          cloud_name: "",
          cloud_type_id: "",
          savingCategories: []
        }: triaSavings[Object.keys(triaSavings)[0]]);
        setTriaSavingsRecs(triaSavings);
        setIsLoading(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {},
    [props.data]
  );
    return (
        <>
         
              <Card style={{minHeight: 275+ 'px'}} className="card-minimal mt-1">
                <CardHeader className="card-header-minimal">
                  <CardTitle className="card-title-minimal" tag="h3">
                    <CardTitle tag="h3"><GenericBrand>CloudCheckr</GenericBrand></CardTitle>
                  </CardTitle>
                  <h5 className="card-category"></h5>
                </CardHeader>
                <hr className="mb-0"></hr>
                <CardBody>
                
                  {isLoading ?
           
                <div className="text-center">
                    <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
                </div>
            : 
    
                  <>
               
                  {
                  selectedAccount.savingCategories.length > 0 ? 
               
                  <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                  >
                    <React.Fragment>
                      <h4 style={{ marginBottom: 15 + "px" }}>
                        <img
                          height="25"
                          width="25"
                          src={`https://tria.connectria.com/divvy/img/${(props.cloud_id || selectedAccount.cloud_type_id).toLowerCase()}-logo.png`}
                        />{" "}
                        <span>{selectedAccount.cloud_name || ''}</span>{" "}
                        <i className="fas fa-caret-down"></i>
                      </h4>
                    </React.Fragment>
                  </DropdownToggle>
                  <DropdownMenu>
                    {savings
                      .filter(e => e.has_tria_recs)
                      .map((item, i) => {
                        return (
                          <DropdownItem
                            onClick={() => {
                              selectAccount(item.cloud_name);
                            }}
                            key={i}
                          >
                            <h5>
                              <img
                                height="20"
                                width="20"
                                src={`https://tria.connectria.com/divvy/img/${item.cloud_type_id.toLowerCase()}-logo.png`}
                              />{" "}
                              <span>{item.cloud_name}</span>{" "}
                            </h5>
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown> 
                
                  
                  : <h4 className="text-center mt-3">No Recommendations Found</h4>
                  }
     { selectedAccount.savingCategories.map((item, i) => {
                    return getLineItem(item);
                  })}
                  </>
            }
                  
                </CardBody>
              </Card>
        </>
      );
}

export { TriaSavingsRecommendor } 
