import React from "react";
import { useEffect, useState } from "react";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import CostService from "../services/CostService";
import PublicCloudService from "../../public_cloud/services/service";
import { appState } from "../../AppState";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import moment from 'moment'
import "../css/desktop-widget.css";
import CostUtility from "../utilities/cost-utility"
import { useHistory } from "react-router-dom";

const getForecast = (predictions,type,item) =>{
  switch(type){
    case 'AZURE_ARM':
        return predictions == null ? CostUtility.getForecastedSpend(item.total_spend,'M') : predictions[type];

    case 'AWS':
      return predictions == null ? CostUtility.getForecastedSpend(item.total_spend,'M') : predictions[type]
  }
  return 0;
}
const renderLine = (predictions,item, savings, i) => {
  if (Object.keys(savings).length === 0) return;
  console.log(predictions)
  return (
    <tr key={i}>
      <td className=""><img height="25" width="25" src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`} /></td>
      <td className="">{getSavingsGrade(savings, item)} </td>
      <td className="text-right" style={{fontSize: "1rem"}}>US {item.total_spend.toLocaleString('us-US', { style: 'currency', currency: 'USD',
    minimumFractionDigits: 0, maximumFractionDigits: 0 })} </td>
      <td className="text-right" style={{fontSize: "1rem"}}>US {getForecast(predictions,item.type, item).toLocaleString('us-US', { style: 'currency', currency: 'USD',
    minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
    </tr>
  )
}

const getDelta = (current, previous) => {
  let delta = current - (previous || 0);
  if (delta === current) return '';
   
  if (delta > 0) return <span title={"Last Month Spend:"} className="small badge badge-secondary" style={{backgroundColor: 'red', color: 'white'}}><i className="fa fa-caret-up" style={{color: 'white'}}  aria-hidden="true"></i>{delta}</span>
  if (delta < 0) return <span className="small badge badge-secondary" style={{backgroundColor: 'green', color: 'white'}}><i className="fa fa-caret-down" style={{color: 'white'}}  aria-hidden="true"></i>{Math.abs(delta)}</span>
}

const getSavingsGrade = (savings, cost_item) => {
  if (!savings.hasOwnProperty(cost_item.type)) return <span>N/A</span>;

  if(savings[cost_item.type].total_savings === 0) return <a href={`/app/cloud/savings/dashboard?cloud=${cost_item.type}`}><i className="fas fa-check text-success"></i> Low</a>
  if(savings[cost_item.type].total_savings < 500) return <a href={`/app/cloud/savings/dashboard?cloud=${cost_item.type}`}><i className="fas fa-exclamation-triangle text-warning"></i> Moderate</a>
  if(savings[cost_item.type].total_savings >= 500) return <a href={`/app/cloud/savings/dashboard?cloud=${cost_item.type}`}><i className="fas fa-exclamation-circle text-danger"></i> High</a>

  if(cost_item.hasOwnProperty("unconfigured_cost")) return <a href="#"><i className="fas fa-exclamation-triangle text-warning"></i> Unconfigured</a>
  return <a href="#"><i className="fas fa-check text-success"></i> Optimized</a>
}

const renderHeader = () => {
  return (
    <thead>
      <tr>
        <th>Cloud Type</th>
        <th>Potential Savings</th>
        <th className="text-right">Current Spend </th>
        <th className="text-right">Forecasted Spend</th>
      </tr>
    </thead>
  )
}

const CostSavingsCard = props => {
  const source = axios.CancelToken.source();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savings, setSavings] = useState({});
  const [predictions, setPredictions] = useState({AWS: 0, AZURE_ARM: 0});
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();

  useAsyncEffect(
    async isMounted => {
      try {
        setLoading(true);

        let cost = await CostService.cloudCostMonthAll(moment().format('YYYY-MM'))
        let awsMonthlySpend = await CostUtility.getCloudSpend("AWS") ;
        let azureMonthlySpend = await CostUtility.getCloudSpend("AZURE");
        
        let clouds = await PublicCloudService.list_clouds();
        let cloud_ids = clouds.data.clouds.map(cloud => cloud.id);

        let savingsPromises = cloud_ids.map(id => CostService.cloudCostSavings(id));
        let savings = await Promise.all(savingsPromises);

        let serviceCheckPromises = cloud_ids.map(id => CostService.cloudCostServiceChecks(id));
        let serviceChecks = await Promise.all(serviceCheckPromises);

        savings = savings.map((item, i) => { return {...item.data, service_checks: serviceChecks[i].data }});
        if (props.account_id) {
          savings = savings.filter(item => item.account_id === props.account_id)
        }

        if (!isMounted()) return;

        try{
        let awsCharges = awsMonthlySpend.data.map(a => a.charge);
        let awsPrediction = CostUtility.get_prediction(awsCharges, 1, 1);
        let azureCharges = azureMonthlySpend.data.map(a => a.charge);
        let azurePrediction = CostUtility.get_prediction(azureCharges, 1, 1);
        setPredictions({AWS: awsPrediction[0], AZURE_ARM: azurePrediction[0]})
        }
        catch{
          setPredictions(null)
        }

        var rolledUpSavings = CostUtility.rollupSavings(savings);
        var configuredCloudTypes = Object.keys(rolledUpSavings).filter((key) => {return key === "AWS" || key === "AZURE_ARM"});
        var cloudTypesWithCost = cost.data.map((item) => {return item.type});
        for (var type of configuredCloudTypes) {
          if (!cloudTypesWithCost.includes(type)) {
            cost.data.push({type: type, total_spend: 0, unconfigured_cost: true})
          }
        }
        setState(cost.data);
        setSavings(rolledUpSavings);
        setLoading(false);

      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      console.log("unmount canceled");
      source.cancel();
    },
    []
  );

  return (
    <div className="d-flex flex-column h-100 pb-3">
      <div className="flex-fill">
        <h3 className="w-100">Spend Dashboard</h3>
        {loading ?
          <div className="text-center">
            <i
              className="fas fa-spinner m-3 mt-4 fa-spin"
              style={{ fontSize: "30px" }}
            ></i>
          </div>
        :
          <table className="table">
            {renderHeader()}
            <tbody style={{ whiteSpace: 'nowrap' }}>
              {state.map(function(object, i) {
                return renderLine(predictions, object, savings, i);
              })}
            </tbody>
          </table>
        }

        <p><small>*Monthly Figures</small></p>
      </div>
    
      <button disabled={loading}
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3 mb-3"
        onClick={() => {
          history.push("/app/cloud/cost/dashboard");
        }}
      >
        {" "}
        <span
          style={{ display: "inline", verticalAlign: "middle" }}
          className="fas fa-dollar-sign"
        ></span>{" "}
        View Dashboard
      </button>
    </div>
  );
};

export { CostSavingsCard };
