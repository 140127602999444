import React from "react";
import { useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { range } from 'ramda';

// Custom hook for setting pagination numbers and current data
function useTablePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);
  
  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next(e) {
    e.preventDefault();
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev(e) {
    e.preventDefault();
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(e, page) {
    e.preventDefault();
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage, setCurrentPage };
}

// Reusable table pagination component
const TablePagination = ({
  pagesCount,
  currentPage,
  handlePageClick,
  handlePreviousClick,
  handleNextClick
 }) => {

  return (
    <Pagination aria-label="Resource Spend">
      <PaginationItem disabled={currentPage <= 0}>
        <PaginationLink onClick={handlePreviousClick} previous href="#" />
      </PaginationItem>
      {range(
          Math.max(1, Math.min(currentPage - 2, pagesCount - 4)), 
          Math.min(pagesCount + 1, Math.max(6, currentPage + 3))
        ).map((page, i) =>
        <PaginationItem active={page === currentPage} key={i}>
          <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
            {page}
          </PaginationLink>
        </PaginationItem>
      )}
      <PaginationItem disabled={currentPage >= pagesCount}>
        <PaginationLink onClick={handleNextClick} next href="#" />
      </PaginationItem>
    </Pagination>
  );
}

export {useTablePagination, TablePagination}