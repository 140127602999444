import React, { Fragment } from "react";
import { useEffect, useState } from "react"

import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { appState } from "../../AppState";;
import useAsyncEffect from "../../utility/use-async-effect";

import BlueMatadorService from "../../services/BlueMatadorService";
// reactstrap components
import { Input, Button, ButtonGroup, Collapse} from "reactstrap";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const BlueMatadorEventsInbox = props => {
  const [eventTypeDropdownOpen, setEventTypeDropdownOpen] = useState(false);
  const [cloudDropdownOpen, setCloudDropdownOpen] = useState(false);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);

  // All values for each filter
  const [statusTypes, setStatusTypes] = useState({alert: 0, warning: 0, anomaly: 0});
  const [eventTypes, setEventTypes] = useState([]);
  const [clouds, setClouds] = useState([]);

  // Current Filter Values
  const [cloudIcons, setCloudIcons] = useState({});
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [statusType, setStatusType] = useState("");
  const [cloud, setCloud] = useState("All Clouds");
  const [end, setEnd] = useState(moment().toISOString());
  const [start, setStart] = useState(moment().toISOString());
  const [eventType, setEventType] = useState("All Event Types");
  const [dateRangeLabel, setDateRangeLabel] = useState("Active Events");

  // Copy of events data for filtering
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);

  const [getActive, setGetActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [stateApp, stateAppActions] = appState();
  const source = axios.CancelToken.source();

  const statusNums = {alert: 0, warning: 0, anomaly: 0};
  const iconFiles = {};

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        // Reset the displayed filter labels when getting new data
        setCloud("All Clouds")
        setQuery("");
        setSearch("");
        setEventType("All Event Types");
        setStatusType("");

        let resp;
        // Get Active Events
        if (getActive) {
          resp = await BlueMatadorService.activeEvents({
              cancelToken: source.token
          });
        // Get Opened Events for the date range
        } else {
          resp = await BlueMatadorService.openedEvents(start, end, {
              cancelToken: source.token
          });
        }
        
        if (!isMounted()) return;

        let eventTypesList = [];
        let cloudsList = [];
        // Gather the data needed for the filters
        for (var event of resp.data) {
          eventTypesList.push(event.typeText);
          // alert, warning, anomaly
          statusNums[event.severity] += 1;
          if (event.projects.length === 0) {
            event.projects.push({'name': ''});
          }
          // Find which cloud this event belongs to
          if (event.source.label == "AWS Account" || event.source.label == "Azure Subscription") {
            event.cloudType = event.source.label;
            event.cloudName = event.source.text;
          }
          for (var tag of event.source.tags) {
            // Find which cloud this event belongs to
            if (tag.key == "AWS Account" || tag.key == "Azure Subscription") {
              event.cloudType = tag.key;
              event.cloudName = tag.value;
              cloudsList.push(tag.value);
            }
          }
          if (event.cloudType == "AWS Account") { 
            iconFiles[event.cloudName] = "aws-logo.png" 
          } else if (event.cloudType == "Azure Subscription") { 
            iconFiles[event.cloudName] = "azure-logo.png" 
          } 
        }
        // Insert the default filter settings
        eventTypesList.unshift('All Event Types');
        cloudsList.unshift('All Clouds');

        // Sort the data from most recent to oldest
        resp.data.sort(function (a, b) {
          return moment(b.opened) - moment(a.opened);
        });

        setStatusTypes(statusNums);
        setEvents(resp.data);
        setAllEvents(resp.data);
        setEventTypes([...new Set(eventTypesList)]);
        setClouds([...new Set(cloudsList)]);
        setCloudIcons(iconFiles);

        setIsLoading(false);
       
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
            
          // request cancelled
        } else {
          console.log(error);
          setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    [getActive, start, end]
  );
  
  // Apply the filters when the user selects one
  useEffect(() => {
    setIsLoading(true);

    let data = allEvents;
    if (eventType != "All Event Types") {
      data = data.filter((event) => { return event.typeText == eventType });
    }
    if (cloud != "All Clouds") {
      data = data.filter((event) => { return event.cloudName == cloud });
    }
    if (search) {
      data = data.filter((event) => { return searchEvent(search, event) });
    } 
    // Count up the number of status types there are before filtering by one
    data.map((event) => { statusNums[event.severity] += 1 });
    setStatusTypes(statusNums);

    if (statusType) {
      data = data.filter((event) => { return event.severity == statusType });
    }

    setEvents(data);
    setIsLoading(false);
  }, [search, eventType, cloud, statusType]);

  const searchEvent = (search, event) => {
    search = search.toLowerCase();
    if (event.typeText.toLowerCase().indexOf(search)    > -1 ||
        event.cloudName.toLowerCase().indexOf(search)   > -1 ||
        event.summaryText.toLowerCase().indexOf(search) > -1   ) {
      return true;
    }
    return false;
  }

  const toggleDateRange = (num, period, label) => {
    setDateDropdownOpen(!dateDropdownOpen);
    if (typeof num === "object") return;

    let now = moment().milliseconds(0);
    setEnd(now.toISOString());
    setStart(now.subtract(num, period).toISOString());

    if (num === 0) { setGetActive(true) } 
    else { setGetActive(false) }
    
    setDateRangeLabel(label);
  }

  const toggleEventType = (type) => {
    setEventTypeDropdownOpen(!eventTypeDropdownOpen);
    if (typeof type === "object") return;
    setEventType(type);
  }

  const toggleCloud = (cloud) => {
    setCloudDropdownOpen(!cloudDropdownOpen);
    if (typeof cloud === "object") return;
    setCloud(cloud);
  }

  const toggleStatusType = (type) => {
    if (statusType != type) {
      setStatusType(type);
    } else {
      setStatusType("");
    }
  }
  
  return (
    <>
    <h3 className= "d-flex mb-0 mt-4 tria-header-class">
      Cloud Monitoring
    </h3>
    <hr></hr>
    <Card className="card-minimal">
      <CardBody>
      <div className="row w-100 mb-3">
        <div style={{width: 250 + 'px'}} className="ml-2 mt-1">
          <form 
            onSubmit={(e) => {
              setSearch(query);
              e.preventDefault();
            }}
          >
            <div className="row">
              <Input
                onChange={(e) => setQuery(e.target.value) }
                value={query}
                type="text"
                placeholder="Search for events"
                style={{width: 250 + 'px'}}
              />
              <i  className="fas fa-times-circle"
                  id="clearButton"
                  style={{marginLeft: -1.25 + "rem", marginTop: .75 + 'rem', cursor: "pointer"}} 
                  onClick={() => {setQuery(""); setSearch("");}}
              >
              </i>
            </div>
          </form>
        </div>
        <div className="row" style={{marginTop: 12+"px"}}>
        <BlueMatadorDateRangeDropdownFilter
          toggle={toggleDateRange}
          dropdownOpen={dateDropdownOpen}
          label={dateRangeLabel}
        />
        <BlueMatadorEventTypeDropdownFilter
          dropdownOpen = {eventTypeDropdownOpen}
          toggle = {toggleEventType}
          eventType = {eventType}
          eventTypes = {eventTypes}
        />
        <BlueMatadorCloudDropdownFilter
          dropdownOpen = {cloudDropdownOpen}
          toggle = {toggleCloud}
          clouds = {clouds}
          cloud = {cloud}
          cloudIcons = {cloudIcons}
        />
        </div>
      </div>
      <BlueMatadorStatusButtonGroup
        toggle={toggleStatusType}
        statusType={statusType}
        statusTypes={statusTypes}
      />
        {isLoading ? (
          <div className="text-center">
          <i
            className="fas fa-spinner m-3 mt-4 fa-spin"
            style={{ fontSize: "30px" }}
          ></i>
          </div>
        ) : (
          <div className="list-group mt-1">
              <BlueMatadorEvents events={events} isLoading={isLoading}/>
          </div>
          )}
      </CardBody>
    </Card>
    </>
  )
}

const BlueMatadorEventTypeDropdownFilter = (props) => {
  return (
    <Dropdown className="ml-3 text-center" isOpen={props.dropdownOpen} toggle={props.toggle}>
      <DropdownToggle 
        tag="span"
        data-toggle="dropdown"
        aria-expanded={props.dropdownOpen}
      >
        {props.eventType}
        <i className={"fas fa-caret-" + (props.dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {props.eventTypes.map((type) => { return <DropdownItem onClick={() => { props.toggle(type) }} key={type}>{type}</DropdownItem> })}
      </DropdownMenu>
    </Dropdown>
  );
}

const BlueMatadorStatusButtonGroup = (props) => {
  const toggle = props.toggle;
  const statusTypes = props.statusTypes;
  const statusType = props.statusType;
  return (
    <ButtonGroup size="sm" className="d-flex justify-content-center mb-3" role="group">
      <Button 
        onClick={() => {toggle("alert")}}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== "alert") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="bm-alert fas fa-bell mr-1"></i>
        {" Alerts (" + statusTypes.alert + ")"}
        </div>
      </Button>
      <Button 
        onClick={() => {toggle("warning")}}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== "warning") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="bm-warning fas fa-exclamation-triangle mr-1"></i>
        {" Warnings (" + statusTypes.warning + ")"}
        </div>
      </Button>
      <Button 
        onClick={() => {toggle("anomaly")}}
        className={`bm-status-btn btn-sm w-100 ${(statusType !== "anomaly") ? "btn-simple" : ""}`} style={{maxWidth:"200px", border:"unset"}}
      >
        <div className="row justify-content-center fas">
        <i className="bm-anomaly fas fa-flag mr-1"></i>
        {" Anomalies ("+statusTypes.anomaly+")"}
        </div>
      </Button>
    </ButtonGroup>
  );
}

const BlueMatadorDateRangeDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen
  const toggle = props.toggle
  const label = props.label
  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle 
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {label}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle >
      <DropdownMenu>
        <DropdownItem onClick={() => {toggle(0, "days", "Active Events")}}>Active Events</DropdownItem>
        <DropdownItem onClick={() => {toggle(1, "days", "The Past Day")}}>The Past Day</DropdownItem>
        <DropdownItem onClick={() => {toggle(7, "days", "The Past Week")}}>The Past Week</DropdownItem>
        <DropdownItem onClick={() => {toggle(1, "month", "The Past Month")}}>The Past Month</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

const BlueMatadorCloudDropdownFilter = (props) => {
  const dropdownOpen = props.dropdownOpen;
  const toggle = props.toggle;
  const clouds = props.clouds;
  const cloud = props.cloud;
  const cloudIcons = props.cloudIcons;
  return (
    <Dropdown className="ml-3" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle 
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {cloud}
        <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        {clouds.map((cloud) => { 
          return (<DropdownItem onClick={() => { toggle(cloud) }} key={cloud}>
            <img 
              className={(cloud=="All Clouds" ? "d-none" : "") + " mr-1"} 
              style={{width: 20+"px", height: 20+"px"}}
              src={cloud=="All Clouds" ? "" : `//tria.connectria.com/divvy/img/${cloudIcons[cloud]}`}
            >
            </img>
            {cloud}
            </DropdownItem> 
        )})}
      </DropdownMenu>
    </Dropdown>
  );
}

const showEventBlade = (event, stateApp, stateAppActions) => {
  stateAppActions.setBlade({
    title: "Event Details",
    visible: true,
    content: <BlueMatadorEventBlade event={event}></BlueMatadorEventBlade>
  });
}

const BlueMatadorEventBlade = (props) => {
  let troubleshootingUrl = "";
  let awsUrl = "";
  for (var link of props.event.links) {
    if (link.linkType == "troubleshooting") {
      troubleshootingUrl = link.url;
    } else if (link.linkType == "aws") {
      awsUrl = link.url;
    }
  }
  return (
    <>
      <a href={awsUrl}><img className="mb-2" src="https://cdn.bluematador.com/images/app/integrations/bm-round-aws.svg"></img></a>
      <BlueMatadorBladeDetails event={props.event} link={awsUrl}/>
      <BlueMatadorBladeTroubleshooting link={troubleshootingUrl}/>
    </>
  );
}

const BlueMatadorBladeDetails = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const event = props.event;

  const convertToFriendlyDate = (isoDate) => {
    let date = moment(isoDate);
    let friendlyDate = date.format("MMM D, YYYY HH:mm A");
    return friendlyDate;
  }

  return (
    <>
      <Card className="card-minimal">
        <CardHeader className="card-header-minimal d-flex w-100 justify-content-between">
          <h3>Details</h3>
          <i className={"fas fa-chevron-" + (isOpen ? "down" : "up")} onClick={() => {setIsOpen(!isOpen)}} style={{cursor: "pointer"}}></i>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>
            <div className="ml-1 mb-2"><b>{event.source.label.toUpperCase()+":"}</b><p>{event.source.text}</p></div>
            {event.source.tags.map((tag) => {
              return <div className="ml-1 mb-2" key={tag.key}><b>{tag.key.toUpperCase()+":"}</b><p>{tag.value}</p></div>
            })}
            <div className="ml-1 mb-2"><b>PROJECTS:</b><p>{event.projects[0].name.toUpperCase()}</p></div>
            <div className="ml-1 mb-2"><b>OPENED:</b><p>{convertToFriendlyDate(event.opened)}</p></div>
            <div className="ml-1 mb-2"><b>Summary:</b><p>{event.summaryText}</p></div>
            <a className={props.link=="" ? "d-none" : ""} href={props.link} target="_blank">Open in AWS Console</a>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

const BlueMatadorBladeTroubleshooting = (props) => {
  const [content, setContent] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      try {
        let resp = await BlueMatadorService.pageContent(props.link, {
            cancelToken: source.token
        });
        let rawContent = resp.data;
        let parsedContent = 
          rawContent.replace("[","")
                    .replace("]","")
                    .replace(/\n/g, "")
                    .replace(/\\xa0/g, "")
                    .replace(/\\n/g, "")
                    .replace(/Blue Matador/g, "TRiA")
                    .replace(/\\u2019/g, "'")
                    .replace(/\\u201c/g, "_");

        if (!isMounted()) return;

        setContent(parsedContent);
       
      } catch (error) {
        if (axios.isCancel(error)) {
            console.log(error);
          // request cancelled
        } else {
            console.log(error);
          //setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <>
      <Card className="card-minimal">
        <CardHeader className="card-header-minimal d-flex w-100 justify-content-between">
          <h3>Troubleshooting</h3>
          <i className={"fas fa-chevron-" + (isOpen ? "down" : "up")} onClick={() => {setIsOpen(!isOpen)}}></i>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody className="mt-0">
            <div className="bluematador-aws-docs" dangerouslySetInnerHTML={{__html: content}}></div>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

const BlueMatadorEvents = (props) => {
  const [stateApp, stateAppActions] = appState();
  const statusIcons = {'alert': 'bell', 'warning': 'exclamation-triangle', 'anomaly': 'flag'};

  if (!props.isLoading && props.events.length === 0) {
    return (
      <h4 className="text-center mt-3">No data returned from monitoring system.</h4>
    )
  }

  return (
    <>
      {props.events.map((event) => {
        return (
          <a onClick={() => {showEventBlade(event, stateApp, stateAppActions)}} 
            key={event.id} 
            className={`mt-1 mb-1 list-group-item list-group-item-action pointer flex-column align-items-start event-${event.severity}`}
          >
        {/* Desktop layout for the event status type, time since, and event source.
            CSS found in "bluematador-events.scss". Will be given a value of "display: none" when
            screen width is under 770px*/}
            <div className="top-row-desktop row ml-2">
              <div className="event-type-desktop row">
                <i className={"mt-1 ml-1 fas fa-"+statusIcons[event.severity]+" bm-"+event.severity}></i>
                <p className="ml-1">{event.typeText}</p>
              </div>
              <small className="event-time-desktop">{moment(event.opened, "YYYY-MM-DDThh:mm:ssZ").fromNow()}</small>
              <div className="event-source-desktop row">
                <p>{event.source.label.toUpperCase()}:</p>
                <small className="ml-1 source-text-desktop">{event.source.text}</small>
              </div>
            </div>
        {/* Mobile layout for the event status type, time since, and event source.
            CSS found in "bluematador-events.scss". Will be given a value of "display: none" when
            screen width is above 770px*/}
            <div className="top-row-mobile row w-100 ml-2 justify-content-between">
              <div className="event-type row">
               <i className={"mt-1 ml-1 fas fa-"+statusIcons[event.severity]+" bm-"+event.severity}></i>
               <p className="ml-1">{event.typeText}</p>
              </div>
              <small className="event-time">{moment(event.opened, "YYYY-MM-DDThh:mm:ssZ").fromNow()}</small>
             </div>
             <div className="event-source-mobile row ml-2">
               <p>{event.source.label.toUpperCase()}:</p>
               <small className="source-text-mobile ml-1 mb-1">{event.source.text}</small>
             </div>
         {/* Used in both layouts */}
            <div className="ml-2 row" style={{marginTop: "auto"}}>PROJECT: {event.projects[0].name}</div>

            <div className="ml-2">
              <p className="mb-1">{event.summaryText}</p>
                <ul className="bm-details ml-2 mb-0">
                  {event.detailTexts.map((detail) => { return <li className="ml-1" key={detail}>{detail}</li>})}
                </ul>
            </div>
          </a>
        )}
      )}
    </>
  );
}

export {BlueMatadorEventsInbox}
