import React, { useState, Suspense, lazy, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";
import CostService from "../services/CostService";
import PublicCloudService from "../../public_cloud/services/service"
import { appState } from "../../AppState";
import "chartjs-plugin-labels";
import CostUtility from "../utilities/cost-utility";
// core components
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { months } from "moment";
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
import { useHistory } from "react-router-dom";
const getTopSpend = (spend) => {
  let totalSpendAccounts = [];
  for (var i = 0; i < spend.length; i++) {
   
    spend[i].details.forEach(function (element) {
      element.type = spend[i].type;
    });
   
    totalSpendAccounts = totalSpendAccounts.concat(spend[i].details);
  }
  totalSpendAccounts.sort(function(a, b) {
    return b.charge - a.charge;
  });

  return totalSpendAccounts;
};

const groupByService = (serviceCost) => {
  let result = [];
  for (var i=0; i<serviceCost.length; i++) {
    let hasService = false;
    for (var j=0; j<result.length; j++) {
      if (serviceCost[i].resource_type == result[j].resource_type) {
        hasService = true;
        // Add the cost to the service's total cost for the org
        result[j].charge += serviceCost[i].charge;
      }
    }
    // Add the service to the result if it is not already accounted for
    if (!hasService) {
      result.push({
        resource_type: serviceCost[i].resource_type,
        charge: serviceCost[i].charge,
        type: serviceCost[i].type
      })
    }
  }
  // Sort services by cost
  result = result.sort((a, b) => {
    if (a.charge > b.charge) {
      return -1;
    } else if (a.charge < b.charge) {
      return 1;
    }
    return 0;
  })
  return result;
}

const Top5MostExpensiveAccounts = props =>{
  return (
    <div className="p-1">
                <p className="lead">Top 5 Most Expensive Accounts</p>
                <table className="w-100 no-csv">
                  <tbody>
                    {getTopSpend(props.data).map((item, i) => {
                      if (i < 5) {
                        return (
                          <tr key={i}>
                            <td>
                                <span>
                        <img
                          height="20"
                          width="20"
                          src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}
                        />
                      </span>{" "}
                              {item.cloud_name}</td>
                            <td className="text-right">
                              {item.charge.toLocaleString("us-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                              })}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
  )
}
const Top5MostExpensiveResources = props =>{
  //Used for Account Level Widget 
  return (
    <div className="p-1">
                <p className="lead">Top 5 Most Expensive Resources</p>
                <table className="w-100 no-csv">
                  <tbody>
                    {(props.data).map((item, i) => {
                      if (i < 5) {
                        return (
                          <tr key={i}>
                            <td>
                                <span>
                        <img
                          height="20"
                          width="20"
                          src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}
                        />
                      </span>{" "}
                             <span title={item.resource_id}> {item.resource_type} | {item.resource_id}</span></td>
                            <td className="text-right">
                              {item.charge.toLocaleString("us-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                              })}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
                {props.data.length == 0 ? <div><h3 className="mt-5 text-center">No Resource Spend Found</h3></div> : <></>}
              </div>
  )
}

const Top5MostExpensiveServices = props =>{
  return (
    <div className="p-1">
                <p className="lead">Top 5 Most Expensive Services</p>
                <table className="w-100 no-csv">
                  <tbody>
                    {groupByService(props.data).map((item, i) => {
                      if (i < 5) {
                        return (
                          <tr key={i}>
                            <td>
                                <span>
                        <img
                          height="20"
                          width="20"
                          src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}
                        />
                      </span>{" "}
                              {item.resource_type}</td>
                            <td className="text-right">
                              {item.charge.toLocaleString("us-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                              })}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
                {props.data.length == 0 ? <div><h3 className="mt-5 text-center">No Service Cost Found</h3></div> : <></>}
              </div>
  )
}
const getForecast = (predictions,type,item) =>{
  switch(type){
    case 'AZURE_ARM':
        return predictions == null ? CostUtility.getForecastedSpend(item.total_spend,'M') : predictions[type];

    case 'AWS':
      return predictions == null ? CostUtility.getForecastedSpend(item.total_spend,'M') : predictions[type]
    
    case 'AWS_GOV':
      return predictions == null ? CostUtility.getForecastedSpend(item.total_spend,'M') : predictions[type]
  }
  return 0;
}
const CurrentSpendWidget = props => {
  const source = axios.CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState([]);
  const [resources, setResources] = useState([]);
  const [serviceCost, setServiceCost] = useState([]);
  const name = props.type == 'current' ? "Current Monthly" : monthNames[new Date().getMonth()]
  const isAccountLevel = props.account_id != null;
  const [predictions, setPredictions] = useState({AWS: 0, AZURE_ARM: 0});
  const [stateApp, stateAppActions] = appState();


  useAsyncEffect(
    async isMounted => {
      setIsLoading(true);
      try {
      
        let resourceCost = []
       
        console.log("loading history");
        var d = new Date();
        let data = [];
        var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        var year = d.getFullYear();
        let cost = [];
        let serviceCost = [];
        let flatResource = [];
        let awsMonthlySpend = {}, awsGovMonthlySpend = {}, azureMonthlySpend ={}; 
        var lastDayOfMonth = new Date(d.getFullYear(), d.getMonth()+ 1, 0).getDate()
        switch(props.type){
          case 'current':
            let processData = [];
            cost = await CostService.cloudAccountCostMonths(year + '-' + month + '-' + '01', year + '-' + month + '-' + lastDayOfMonth, props.account_id || null);
            serviceCost = await CostService.cloudServiceCostMonths(year + '-' + month + '-' + '01', year + '-' + month + '-' + lastDayOfMonth, props.account_id || null);
            awsMonthlySpend = await CostUtility.getCloudSpend("AWS", props.account_id || null) ;
            awsGovMonthlySpend = await CostUtility.getCloudSpend("AWS_GOV", props.account_id || null) ;
            azureMonthlySpend = await CostUtility.getCloudSpend("AZURE", props.account_id || null);
            /*if(isAccountLevel){
              resourceCost  = await CostService.cloudResourceCostMonths(
                year + '-' + '01' + '-' + '01',
                year + '-' + month + '-' + lastDayOfMonth,
                props.account_id
              );
           
              for (var i = 0; i < resourceCost.data.length; i++) {
                //add type
                resourceCost.data[i].details.forEach(function(element) {
                  element.type = resourceCost.data[i].type;
                });
                flatResource = flatResource.concat(resourceCost.data[i].details);
              }
            }*/
           
           
            break;
            //get the current month of spend

          default: 
          cost =  await CostService.cloudCostMonthAll(
            year + "-" + "0" + month
          );
        }

        if (!isMounted()) return;
        setState(cost.data);
        let flatService = [];
        for (var i = 0; i < serviceCost.data.length; i++) {
          //add type
          serviceCost.data[i].details.forEach(function(element) {
            element.type = serviceCost.data[i].type;
          });
          flatService = flatService.concat(serviceCost.data[i].details);
        }
        setServiceCost(flatService);
        
        let awsPrediction = [], awsGovPrediction = [], azurePrediction= [];
        try{
          if (awsMonthlySpend) {
            let awsCharges = awsMonthlySpend.data.map(a => a.charge);
            awsPrediction = CostUtility.get_prediction(awsCharges, 1, 1);
          }
          if (awsGovMonthlySpend) {
            let awsGovCharges = awsGovMonthlySpend.data.map(a => a.charge);
            awsGovPrediction = CostUtility.get_prediction(awsGovCharges, 1, 1);
          }
          if (azureMonthlySpend) {
            let azureCharges = azureMonthlySpend.data.map(a => a.charge);
            azurePrediction = CostUtility.get_prediction(azureCharges, 1, 1);
          }
          setPredictions({AWS: awsPrediction[0], AWS_GOV: awsGovPrediction[0], AZURE_ARM: azurePrediction[0]})
        }
        catch{
          setPredictions(null)
        }
        
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
          console.log("canceled");
        } else {
          throw error;
        }
      }
    },
    () => {
      console.log("unmount canceled");
      source.cancel();
    },
    [props.account_id]
  );

  return (
    <>
      <Card className=" card-minimal h-100 mb-0">
        <CardHeader className="card-header-minimal">
          <h5 className="card-category"></h5>
          <CardTitle className="card-title-minimal" tag="h3">
            {name} Overview
          </CardTitle>
        </CardHeader>
        <hr className="mb-0"></hr>
        <CardBody>
          {isLoading ?
            <div className="text-center">
              <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
            </div>
          :
          <>
          <div className="row">
            <div className="col-lg-6 border-right border-bottom">
              <div className="p-1">
                <p className="lead text-right">Current Spend</p>
                {state.map(function(item, idx) {
                  return (
                    <h1 key={idx} className="font-weight-bold text-right">
                      <span>
                        <img
                          height="25"
                          width="25"
                          src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}
                        />
                      </span>{" "}
                      <span>
                        {item.total_spend.toLocaleString("us-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </span>
                    </h1>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-6 border-bottom">
              <div className="p-1">
                <p className="lead text-right">Forecasted Spend</p>
                {state.map(function(item, idx) {
                  return (
                    <h1 key={idx} className="font-weight-bold text-right">
                      <span>
                        <img
                          height="25"
                          width="25"
                          src={`https://tria.connectria.com/divvy/img/${item.type.toLowerCase()}-logo.png`}
                        />
                      </span>{" "}
                      <span>
                        {getForecast(predictions, item.type, item).toLocaleString("us-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </span>
                    </h1>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 border-right">
              <Top5MostExpensiveServices data={serviceCost}></Top5MostExpensiveServices>
            </div>
            <SavingsOpportunityWidget account_id={props.account_id}/>
          </div>
          </>
          }
        </CardBody>
      </Card>
    </>
  );
};

const SavingsOpportunityWidget = React.memo((props) => {
  const [savings, setSavings] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [savingOpportunity, setSavingOpportunity] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const source = axios.CancelToken.source();
  
  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        let clouds = await PublicCloudService.list_clouds();
        let cloud_ids = clouds.data.clouds.map(cloud => cloud.id);

        let savingsPromises = cloud_ids.map(id => CostService.cloudCostSavings(id));
        let savings = await Promise.all(savingsPromises);

        let serviceCheckPromises = cloud_ids.map(id => CostService.cloudCostServiceChecks(id));
        let serviceChecks = await Promise.all(serviceCheckPromises);

        savings = savings.map((item, i) => { return {...item.data, service_checks: serviceChecks[i].data }});
        if (props.account_id) {
          savings = savings.filter(item => item.account_id == props.account_id)
        }

        if (!isMounted()) return;

        const rolledUpSavings = CostUtility.rollupSavings(savings);
        setSavings(savings);
        setAccounts(savings.map((item) => { if (item.service_checks.length > 0 || Object.keys(item.cost_savings).length > 0) return item.account_id}));
        
        if (rolledUpSavings.allSavings == 0) {
          setSavingOpportunity({label: "Low", color: "success"});
        } else if (rolledUpSavings.allSavings > 0 && rolledUpSavings.allSavings < 500) {
          setSavingOpportunity({label: "Moderate", color: "warning"});
        } else {
          setSavingOpportunity({label: "High", color: "danger"})
        }

        setIsLoading(false);
        
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          setError(true);
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <>
    <div className="col-lg-6" style={{marginTop: "auto", marginBottom: "auto"}}>
      <div className="p-1">
        <div className="text-center">
          {isLoading ? 
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: '30px' }}></i> :
          error ?
            <p>There was a problem loading you saving opportunity</p> :
            <div>
              <h1 
                className={`text-${savingOpportunity.color}`} 
                style={{ fontWeight: "bold", fontSize: 2 + "rem", marginBottom: 15 + "px"}}>
                {savingOpportunity.label}
              </h1>
              <h4 className="mb-0 ">Opportunity For Savings</h4>
              {(!props.account_id || accounts.includes(props.account_id)) &&
                <a
                  onClick={() => { 
                    history.push(
                      '/app/cloud/savings/dashboard', 
                      {
                        savingOpportunity: savingOpportunity,
                        savings: savings
                      }
                    )
                  }}
                  style={{color: "#0098f0"}}
                  className="cursor"
                > View Details
                </a>}
          </div>}
        </div>
      </div>
    </div>
    </>
  );
})

export default CurrentSpendWidget;
