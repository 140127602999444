import React, { useEffect, useState } from "react";
import { appState } from "../../AppState";;

import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";

import CostService from "../services/CostService";

// core components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const CostSavingsTable = (props) => {
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationRange, setPaginationRange] = useState([]);
  const [stateApp, stateAppActions] = appState();
  const [statusType, setStatusType] = useState("All");

  const offset = 10;

  var savingsData = [];
  if (props.type == "cloudcheckr") {
    savingsData = getCloudcheckrCostSavings(props.savings);
  } else if (props.type == "aws") {
    var filteredSavings = props.savings.filter((account) => { return account.cloud_type_id == "AWS" });
    savingsData = getServiceChecks(filteredSavings);
  } else {
    var filteredSavings = props.savings.filter((account) => { return (account.cloud_type_id == "AZURE" || account.cloud_type_id == "AZURE_ARM") });
    savingsData = getServiceChecks(filteredSavings);
  }

  const getStatusFromCheck = status => {
    if (statusType == "All") {
      return true;
    } else if (statusType == "success") {
      return status == "ok";
    } else if (statusType == "warning") {
      return status == "warning" || status == "Low";
    } else {
      return status == "error" || status == "High";
    }
  }

  const getStatusFromSavings = savings => {
    if (statusType == "All") {
      return true;
    } else if (statusType == "success") {
      return savings == 0;
    } else if (statusType == "warning") {
      return savings < 500;
    } else {
      return savings > 500;
    }
  }

  var savingsCategories = savingsData[0];
  var potentialSavings = savingsData[1];

  savingsCategories = savingsCategories.filter((category) => { return category.status != "not_available" });

  const statusTypeNums = {success: 0, warning: 0, danger: 0};
  for (category of savingsCategories) {
    if (props.type == "cloudcheckr") {
      if (category.savingsAmount == 0) {
        statusTypeNums.success++;
      } else if (category.savingsAmount > 500) {
        statusTypeNums.danger++;
      } else {
        statusTypeNums.warning++;
      }
    } else if (category.status == "ok") {
      statusTypeNums.success++;
    } else if (category.status == "warning" || category.status == "Low") {
      statusTypeNums.warning++;
    } else {
      statusTypeNums.danger++;
    }
  }
  
  if (props.type == "cloudcheckr") {
    savingsCategories = savingsCategories.filter((category) => {
      return getStatusFromSavings(category.savingsAmount);
    })
  } else {
    savingsCategories = savingsCategories.filter((category) => {
      return getStatusFromCheck(category.status);
    })
  }

  var numActionableChecks = 0;
  for (var category of savingsCategories) {
    if (category.savingsAmount > 0) {
      numActionableChecks += 1;
    }
  }

  useEffect(() => {
    
    let numPages = Math.ceil(savingsCategories.length / offset);
    setPagesCount(numPages);
    if (numPages >= 5) {
      setPaginationRange(range(0,5));
    } else {
      setPaginationRange(range(0,numPages));
    }
    setCurrentPage(0);
  }, [statusType]);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    if (index > 2 && index < pagesCount-2) {
      setPaginationRange(range(index-2, index+3));
    } else if (index <= 2) {
      if (pagesCount <= 5) {
        setPaginationRange(range(0, pagesCount));
      } else {
        setPaginationRange(range(0, 5));
      }
    } else {
      setPaginationRange(range(pagesCount-5, pagesCount));
    }
  }

  const getCheckIcon = category => {
    if (props.type == "cloudcheckr") {
      if (category.savingsAmount == 0) {
        return <i className="fas fa-check text-success"></i>;
      } else if (category.savingsAmount > 500) {
        return <i className="fas fa-exclamation-circle text-danger"></i>
      } else {
        return <i className="fas fa-exclamation-triangle text-warning"></i>;
      }
    }
    if (category.status == "ok") {
      return <i className="fas fa-check text-success"></i>;
    } else if (category.status == "warning" || category.status == "Low") {
      return <i className="fas fa-exclamation-triangle text-warning"></i>
    } else {
      return <i className="fas fa-exclamation-circle text-danger"></i>
    }
  };

  const toggle = status => {
    if (status == statusType) {
      setStatusType("All");
    } else {
      setStatusType(status);
    }
  }

  return (
    <>
      <Card className="card-minimal mt-1" >
        <CardBody>
          <div className="d-flex w-100 justify-content-between cost-savings-card-header">
            <div className="row">
              <p className="pr-2" style={{fontSize: 1.5+"rem"}}><b>{"$" + potentialSavings.toFixed(2) + " "}</b></p>
              <p style={{fontSize: 1.5+"rem", color: "rgba(34, 42, 66, 0.7)"}} > Potential Monthly Savings</p>
            </div>
          </div>
          <ButtonGroup size="sm" className="d-flex justify-content-center flex-wrap" role="group" style={{paddingTop: 12+"px", paddingBottom: 12+"px", width: 130+"%", marginLeft: -15+"%"}}>
            <Button 
              onClick={() => {toggle("success")}}
              className={`bm-status-btn btn-sm w-100 ${(statusType !== "success") ? "btn-simple" : ""}`} style={{maxWidth:"150px", border:"unset"}}
            >
              <div className="row justify-content-center fas">
              <i className="text-success fas fa-check mr-1"></i>
              {` Addressed (${statusTypeNums.success})`}
              </div>
            </Button>
            <Button 
              onClick={() => {toggle("warning")}}
              className={`bm-status-btn btn-sm w-100 ${(statusType !== "warning") ? "btn-simple" : ""}`} style={{maxWidth:"125px", border:"unset"}}
            >
              <div className="row justify-content-center fas">
              <i className="text-warning fas fa-exclamation-triangle mr-1"></i>
              {` Minor (${statusTypeNums.warning})`}
              </div>
            </Button>
            <Button 
              onClick={() => {toggle("danger")}}
              className={`bm-status-btn btn-sm w-100 ${(statusType !== "danger") ? "btn-simple" : ""}`} style={{maxWidth:"125px", border:"unset"}}
            >
              <div className="row justify-content-center fas">
              <i className="text-danger fas fa-exclamation-circle mr-1"></i>
              {` Major (${statusTypeNums.danger})`}
              </div>
            </Button>
            </ButtonGroup>
          <div className="table-responsive-sm justify-content-center small-desktop-table" style={{marginLeft: "auto", marginRight: "auto"}}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Cloud Name</th>
                  <th>Check</th>
                  <th>Savings</th>
                </tr>
              </thead>
              <tbody >
                {savingsCategories.slice(currentPage * offset, (currentPage+1) * offset).map( (category, i) => {
                  return (
                  <tr key={i} onClick={(e) => {showSavingsCheckBlade(e, category, props.type, stateAppActions)}} style={{cursor: "pointer"}}>
                    <td>
                      <img
                        style={{ width: 25 + "px" }}
                        src={`https://tria.connectria.com/divvy/img/${category.cloudType.toLowerCase()}-logo.png`}
                      />{" "}
                      {category.accountName}
                    </td>
                    <td>
                      {getCheckIcon(category)}{" "}
                      {category.savingsName}
                    </td>
                    {category.savingsAmount > 0 ?
                      <td>Monthly savings of up to <b>{"$" + category.savingsAmount}</b></td>
                    :
                      <td></td>
                    }
               </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex w-100 justify-content-center">
          <Pagination>
            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink first href="#" onClick={(e) => handlePageClick(e, 0)}/>
            </PaginationItem>
            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink previous href="#" onClick={(e) => handlePageClick(e, currentPage-1)}/>
            </PaginationItem>
            {paginationRange.map((page, i) => (
            <PaginationItem active={page === currentPage} key={page}>
              <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
                {page + 1}
              </PaginationLink>
            </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage >= pagesCount-1}>
              <PaginationLink next href="#" onClick={(e) => handlePageClick(e, currentPage+1)}/>
            </PaginationItem>
            <PaginationItem disabled={currentPage >= pagesCount-1}>
              <PaginationLink last href="#" onClick={(e) => handlePageClick(e, pagesCount-1)}/>
            </PaginationItem>
          </Pagination>
            </div>
        </CardBody>
      </Card>
    </>
  );
}

const showSavingsCheckBlade = (event, check, type, stateAppActions) => {
  if (type == "cloudcheckr") {
    stateAppActions.setBlade({
      title: "Check Details",
      visible: true,
      content: <CloudcheckrSavingsCheckDetails details={check.detail[0]}/>
    });
  } else {
    stateAppActions.setBlade({
      title: "Check Details",
      visible: true,
      content: 
        <>
          <SavingsCheckDescription resourceId={check.resourceId}/>
          <SavingsCheckResources resourceId={check.resourceId}/>
        </>
    });
  }
}

const CloudcheckrSavingsCheckDetails = (props) => {
  var details = props.details;
  details = details.split("|");
  details = details.map((detail) => { return detail.split(":") });

  return (
    <Card className="card-minimal mt-1">
      <CardHeader className="card-header-minimal">
        <h3>CloudCheckr Details</h3>
      </CardHeader>
      <CardBody>
        {details.map((detail) => {
          return (
            <>
            <span style={{fontSize: 1.0+"rem"}}>
            <b>{detail[0] + ": "}</b><p style={{paddingBottom: 10 + "px"}}>{detail[1]}</p>
            </span>
            </>
          )
        })}
      </CardBody>
    </Card>
  );
}

const SavingsCheckDescription = (props) => {
  const source = axios.CancelToken.source();
  const [description, setDescription] = useState("");

  useAsyncEffect(
    async isMounted => {
      try {
        let checkDescription = await CostService.trusted_advisor_description(props.resourceId, {
          cancelToken: source.token
        });

        if (!isMounted()) return;

        setDescription(checkDescription.data.description);

      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    },
    () => {
        source.cancel();
    }, []
  );

  return (
    <Card className="card-minimal mt-1">
      <CardHeader className="card-header-minimal">
        <h3>Check Description</h3>
      </CardHeader>
      <CardBody>
        <div dangerouslySetInnerHTML={{__html: description}}></div>
      </CardBody>
    </Card>
  );
}

const SavingsCheckResources = (props) => {
  const source = axios.CancelToken.source();
  const [resources, setResources] = useState([]);

  useAsyncEffect(
    async isMounted => {
      try {
        let checkResources = await CostService.trusted_advisor_resources(props.resourceId, {
          cancelToken: source.token
        });

        if (!isMounted()) return;

        setResources(checkResources.data.resources);

      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    },
    () => {
        source.cancel();
    }, []
  );

  return (
    <>
    {resources.length > 0 ?
      <Card className="card-minimal mt-1">
        <CardHeader className="card-header-minimal">
          <h3>Resources</h3>
        </CardHeader>
        <CardBody>
          <div className="table-responsive-sm">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Provider ID</th>
                  <th>Region</th>
                  <th>Alert Level</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {resources.map((resource) => {
                  return (
                    <tr>
                      <td>{resource.provider_id}</td>
                      <td>{resource.region_name}</td>
                      <td>{resource.alert_level}</td>
                      <td>{resource.reason}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      :
      <></>
    }
    </>
  );
}

const getCloudcheckrCostSavings = savings => {
  var savingsCategories = [];
  var potentialSavings = 0;

  for (var account of savings) {
    var keys = Object.keys(account.cost_savings);
    for (var key of keys) {
      if (Array.isArray(account.cost_savings[key]) &&
          account.cost_savings[key].length > 0 ) {

        for (var category of account.cost_savings[key]) {
          savingsCategories.push({
            accountName: account.cloud_name,
            cloudType: account.cloud_type_id,
            savingsName: category.Name, 
            savingsAmount: category.TotalSaving,
            detail: category.Detail
          });
          potentialSavings += category.TotalSaving;
        }
      }
    }
  }
  return [savingsCategories, potentialSavings];
}

const getServiceChecks = savings => {
  var serviceChecks = [];
  var potentialSavings = 0;

  for (var account of savings) {
    for (var check of account.service_checks) {
        serviceChecks.push({
          accountName: account.cloud_name,
          cloudType: account.cloud_type_id,
          savingsName: check.name, 
          savingsAmount: check.estimated_monthly_savings,
          resourceId: check.resource_id,
          status: check.status
        });
        potentialSavings += check.estimated_monthly_savings;
    }
  }
  return [serviceChecks, potentialSavings];
}

const range = (start, end) => {
  let result = []
  for (var i=start; i<end; i++) {
    result.push(i)
  }
  return result;
}



export { CostSavingsTable }  
