import React, {useState, Suspense, lazy, useEffect} from "react";
import {HorizontalBar, Doughnut} from "react-chartjs-2";

import "chartjs-plugin-labels";
import CostUtility from "../utilities/cost-utility"
import 'chartjs-plugin-datalabels';
import moment from 'moment';

import CostUtils from "../utilities/cost-utility";


const MonthlyBarChart = props => {
  const [doughtnut, setDoughnut] = useState({
    datasets: [],
    labels: [],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          formatter: function (value, context) {
            return value.toLocaleString("us-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
          }
        }
      },
      legend: {
        onClick: function (e, legendItem) {
          var index = legendItem.datasetIndex;
          var ci = this.chart;
          var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;

          ci.data.datasets.forEach(function (e, i) {
            var meta = ci.getDatasetMeta(i);

            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null;
              } else if (meta.hidden === null) {
                meta.hidden = true;
              }
            } else if (i === index) {
              meta.hidden = null;
            }
          });
          ci.update();
        }
      },
    }
  });


  useEffect(() => {
    let selectedMonthDate = props.selectedMonth ? moment(props.selectedMonth, 'MMMM YYYY') : moment();
    selectedMonthDate = selectedMonthDate.startOf('month');
    let currentMonthSelected = selectedMonthDate.isSame(moment().startOf('month'));
    let previousMonthDate = selectedMonthDate.clone().subtract(1, 'month');

    //see what we have for aws and azure
    let seriesData = [], categories = []
    for (var i = 0; i < props.data.length; i++) {
      let cloudSeriesData = [];
      let costItems = props.data[i];

      // try to find previous month data
      let previousMonthIndex = costItems.data.findIndex(e => e.month === previousMonthDate.format('YYYY-MM'));
      if (previousMonthIndex > -1) {
        //add the previous month data
        categories.push('Previous Month')
        cloudSeriesData.push(Math.round(costItems.data[previousMonthIndex].charge))
      }

      let selectedMonthIndex = costItems.data.findIndex(e => e.month === selectedMonthDate.format('YYYY-MM'));
      if (selectedMonthIndex > -1) {
        categories.push(props.selectedMonth)
        cloudSeriesData.push(Math.round(costItems.data[selectedMonthIndex].charge))
      }

      //if we are on this months date lets add in forecast data
      if (currentMonthSelected) {
        seriesData.push()
        categories.push('Forecasted')
        let prediction = props.predictions;
        let type = props.data[i].type.toUpperCase() == 'AZURE' ? 'AZURE_ARM' : props.data[i].type.toUpperCase();
        if (prediction[type] == null) {
          cloudSeriesData.push(Math.round(CostUtility.getForecastedSpend(costItems.data[selectedMonthIndex].charge)))
        }
        else {
          cloudSeriesData.push(Math.round(prediction[type]))
        }

      }
      seriesData.push({
        data: cloudSeriesData,
        stack: "Stack 1",
        backgroundColor: CostUtils.getCloudColor(props.data[i].type.toUpperCase()),
        label: props.data[i].type.toUpperCase()
      })
    }

    let labels = [...new Set(categories)];
    let data = seriesData;

    setDoughnut(prevState => {
      return {
        ...prevState,
        labels: labels,
        datasets: data
      };
    });
  }, [props]);

  return (
    <div style={{height: 200 + 'px'}}>
      <HorizontalBar data={doughtnut} options={doughtnut.options}/>
    </div>
  );
};

export default MonthlyBarChart;